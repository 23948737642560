import { Injectable } from '@angular/core';
import { CoursesService } from '../services/courses.service';


@Injectable()
export class MyCoursesResolverService {

    constructor(
        private coursesService: CoursesService,
    ) {}

    resolve() {
       return this.coursesService.getMyCourses()
    }
}
