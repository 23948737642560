import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagesV2Service {

  constructor(private http: HttpClient) { }

  getAllConversations() {
    return this.http.get(`${environment.MESSAGING_API}/conversations`)
      .pipe(map((data: Data) => data['data']))
  }

  getCourseConversations(courseId: string) {

    return this.http.get(`${environment.MESSAGING_API}/allConversations/${courseId}`)
      .pipe(map((data: Data) => data['data']))
  }

  getMessages(conversationId: string) {
    return this.http.get(`${environment.MESSAGING_API}/conversations/${conversationId}/messages`)
      .pipe(map((data: Data) => data['data']))
  }

  reply(conversation: any, newMessage: any) {
    const msgObj = newMessage
    return this.http.put(`${environment.MESSAGING_API}/conversations/${conversation.conversationId}/messages`, msgObj);
  }

  firstMessage(newMessage: any) {
    const msgObj = {
      courseId: newMessage.course.courseId,
      receiverId: newMessage.course.instructor.instructorId,
      message: {
        text: newMessage.text,
        attachment: newMessage.attachment
      }
    };
    return this.http.post(`${environment.MESSAGING_API}/conversations/firstMessage`, msgObj);
  }


  readMessages(conversation: any, msgObj: any) {
    return this.http.put(`${environment.MESSAGING_API}/conversations/${conversation.conversationId}/read`, msgObj);
  }

  aggregation() {
    return this.http.get(`${environment.MESSAGING_API}/conversations/aggregation`)
      .pipe(map((data: Data) => data['data']))
  }


  resolveConversation(conversation: any, newMessage: boolean) {
    const msgObj = {
      "resolved": newMessage
    }
    return this.http.put(`${environment.MESSAGING_API}/conversations/${conversation.conversationId}/resolve`, msgObj);
  }

}
