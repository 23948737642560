<app-spinner name="my-profile"></app-spinner>
<div style="padding:64px" fxLayout="column" fxLayoutAlign="center center">
    <div style="gap: 24px;width:640px" [ngStyle.sm]=" {'width': '560px'}" [ngStyle.xs]=" {'width': '300px'}" fxLayout="column" >

        <span class="cosmote-blue" style="font-size: 30px;font-style: normal;font-weight: 700;line-height: 38px;">Ρυθμίσεις προφίλ</span>


        <div  fxLayout="column" style="gap: 24px;">

            <div fxLayout="column">
                <span class="cosmote-blue" style="font-size: 18px;font-style: normal;font-weight: 700;line-height: 28px;">Αλλαγή email</span>
                <span class="gray-600" style="font-size: 14px;line-height: 20px; margin-bottom: 8px;">Το email σου αυτή τη στιγμή ειναι το: {{user.email}}</span>

                <mat-divider style="color:#EAECF0"></mat-divider>
            </div>

            <div fxLayout="column" [formGroup]="changeEmailFormGroup">
                <div fxLayout="column" >
                    <span class="cosmote-blue" style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Νεο email*</span>
                    <input formControlName="newEmail" placeholder="" type="text" 
                        (input) = "convertToLowercase(changeEmailFormGroup.get('newEmail'), $event)"
                        [ngClass]=" {'input-error': changeEmailFormGroup.get('newEmail')?.invalid && changeEmailFormGroup.get('newEmail')?.touched}"
                        class="input" matInput> 
                    <div style="height:16px; padding:0px 12px 8px">
                        <span class="error-message" *ngIf="changeEmailFormGroup.get('newEmail')?.hasError('pattern') && changeEmailFormGroup.get('newEmail')?.touched">
                            Το email δεν έχει σωστή μορφή
                        </span>

                        <span class="error-message" *ngIf="changeEmailFormGroup.get('newEmail')?.hasError('sameWithOldEmail') && changeEmailFormGroup.get('newEmail')?.touched">
                            Το νεο email δεν μπορεί να ειναι ιδιο με το τωρινό
                        </span>

                        <span class="error-message" *ngIf="changeEmailFormGroup.get('newEmail')?.hasError('alreadyExists') && changeEmailFormGroup.get('newEmail')?.touched">
                            Το email χρησιμοποιείτε ήδη
                        </span>

                        

                        
                    </div>
                </div>

                <div fxLayout="column">
                    <span class="cosmote-blue" style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Επιβεβαίωση email*</span>
                    <input formControlName="newEmailConfirmation" placeholder="" type="text" 
                        (input) = "convertToLowercase(changeEmailFormGroup.get('newEmailConfirmation'), $event)"
                        [ngClass]=" {'input-error': changeEmailFormGroup.get('newEmailConfirmation')?.invalid && changeEmailFormGroup.get('newEmailConfirmation')?.touched}"
                        class="input" matInput> 
                    <div style="height:16px; padding:0px 12px 8px">
                        <span class="error-message" *ngIf="changeEmailFormGroup.get('newEmailConfirmation')?.invalid && changeEmailFormGroup.get('newEmailConfirmation')?.touched">
                            Επαληθεύστε σωστά το email
                        </span>
                    </div>
                </div>

                <mat-divider style="color:#EAECF0; margin-bottom:20px"></mat-divider>


                <div fxlayout="row" fxLayoutAlign="end" style="gap: 12px;">
                    <span class="gray-600" style="padding: 8px 16px; cursor: pointer;
                    font-size: 14px;font-style: normal;font-weight: 700;line-height: 28px;" (click)="onClearForm(changeEmailFormGroup)">Άκυρο</span>
                    <button mat-raised-button color="primary" style="border-radius: 74px; padding:10px 16px" [disabled]="changeEmailFormGroup.invalid"
                     (click)="onChangeEmail()">Αλλαγή email</button>
                </div>
            </div>
        </div>

       
        <div  fxLayout="column" style="gap: 24px;">

            <div fxLayout="column">
                <span class="cosmote-blue" style="font-size: 18px;font-style: normal;font-weight: 700;line-height: 28px;
                 margin-bottom:8px">Αλλαγή password</span>
                <mat-divider style="color:#EAECF0"></mat-divider>

            </div>


            <form fxLayout="column" fxLayoutG="20px" [formGroup] = "changePasswordFormGroup">
                <div fxLayout="column" >
                    <span class="cosmote-blue" style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Τρέχον password*</span>
                    <div fxLayout="row" fxLayoutAlign="start center" style="gap: 12px;">
                        <input fxFlex formControlName="oldPassword" placeholder="" [type]="revealOldPassword ? 'text' : 'password'"
                            [ngClass]=" {'input-error': changePasswordFormGroup.get('oldPassword')?.invalid && changePasswordFormGroup.get('oldPassword')?.touched}"
                            class="input" matInput> 
                        <mat-icon style="cursor: pointer;" matSuffix (click)="revealOldPassword = !revealOldPassword">{{revealOldPassword ?
                        'visibility' : 'visibility_off'}}</mat-icon>
                    </div>
                    <div style="height:16px; padding:0px 12px 8px">
                        <span class="error-message" *ngIf="changePasswordFormGroup.get('oldPassword')?.hasError('required') && changePasswordFormGroup.get('oldPassword')?.touched">
                            Πληκτρολογείστε το τρέχον password
                        </span>

                        <span class="error-message" *ngIf="changePasswordFormGroup.get('oldPassword')?.hasError('incorrect') && changePasswordFormGroup.get('oldPassword')?.touched">
                            {{ changePasswordFormGroup.get('oldPassword')?.errors?.['incorrect'] }}
                        </span>
                    </div>
                </div>

                <div fxLayout="column" >
                    <span class="cosmote-blue" style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Nέο password</span>
                    <div fxLayout="row" fxLayoutAlign="start center" style="gap: 12px;">
                        <input fxFlex formControlName="newPassword" placeholder="" [type]="revealNewPassword ? 'text' : 'password'"
                            [ngClass]=" {'input-error': changePasswordFormGroup.get('newPassword')?.invalid && changePasswordFormGroup.get('newPassword')?.touched}"
                            class="input" matInput> 
                        <mat-icon style="cursor: pointer;" matSuffix (click)="revealNewPassword = !revealNewPassword">{{revealNewPassword ?
                        'visibility' : 'visibility_off'}}</mat-icon>
                    </div>
                    <div style="height:16px; padding:0px 12px 8px">
                        <span class="error-message" *ngIf="changePasswordFormGroup.get('newPassword')?.invalid && changePasswordFormGroup.get('newPassword')?.touched">
                            To password πρεπει να εχει τουλάχιστον 6 χαρακτήρες
                        </span>
                    </div>
                </div>

                <div fxLayout="column" >
                    <span class="cosmote-blue" style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Επιβεβαίωση password*</span>
                    <div fxLayout="row" fxLayoutAlign="start center" style="gap: 12px;">
                        <input fxFlex formControlName="newPasswordConfirm" placeholder="" [type]="revealNewPassword ? 'text' : 'password'"
                            [ngClass]=" {'input-error': changePasswordFormGroup.get('newPasswordConfirm')?.invalid && changePasswordFormGroup.get('newPasswordConfirm')?.touched}"
                            class="input" matInput> 
                        <mat-icon style="cursor: pointer;" matSuffix (click)="revealNewPassword = !revealNewPassword">{{revealNewPassword ?
                        'visibility' : 'visibility_off'}}</mat-icon>
                    </div>
                    <div style="height:16px; padding:0px 12px 8px">
                        <span class="error-message" *ngIf="changePasswordFormGroup.get('newPasswordConfirm')?.invalid && changePasswordFormGroup.get('newPasswordConfirm')?.touched">
                            Επαληθεύστε σωστά το password
                        </span>
                    </div>
                </div>

                <mat-divider style="color:#EAECF0; margin-bottom:20px"></mat-divider>

                <!-- <div fxlayout="row" fxLayoutAlign="end" style="gap: 12px;">
                    <span class="gray-600" style="padding: 8px 16px; cursor:pointer;
                        font-size: 14px;font-style: normal;font-weight: 700;line-height: 28px;" (click)="onClearForm(changePasswordFormGroup)">Άκυρο</span>
                    <button mat-raised-button color="primary" style="border-radius: 74px; padding:10px 16px" [disabled]="changePasswordFormGroup.invalid"
                    (click)="onChangePassword()">Αλλαγή password</button>
                </div> -->
                <div fxlayout="row" fxLayoutAlign="end" style="gap: 12px;">
                    <span class="gray-600" style="padding: 8px 16px; cursor:pointer;
                        font-size: 14px;font-style: normal;font-weight: 700;line-height: 28px;" (click)="onClearForm(changePasswordFormGroup)">Άκυρο</span>
                    <button mat-raised-button color="primary" style="border-radius: 74px; padding:10px 16px" [disabled]="changePasswordFormGroup.invalid"
                    >Αλλαγή password</button>
                </div>
            </form>
        </div>

       
    </div>

</div>