import { RouterModule, Routes } from "@angular/router";
import { UserResolverService } from "../common/resolvers/user-resolver.service";
import { NgModule } from "@angular/core";
import { GrowYourBusinessLayoutComponent } from "./layout/grow-your-business-layout.component";
import { EmployeeRegistrationDetailsResolverService } from "./employee-registration-details-resolver.service";
import { GrowYourBusinessEmployeeRegistrationComponent } from "./employee-registration/employee-registration.component";
import { GrowYourBusinessLoginComponent } from "./login/grow-your-business-login.component";
import { GrowYourBusinessVerifyComponent } from "./verify/grow-your-business-verify.component";
import { GrowYourBusinessEmployerRegistrationComponent } from "./employer-registration/grow-your-business-employer-registration.component";
import { GrowYourBusinessFaqComponent } from "./faq/grow-your-business-faq.component";
import { GrowYourBusinessContactUsComponent } from "./contact-us/grow-your-business-contact-us.component";
import { GrowYourBusinessCoursesComponent } from "./courses/grow-your-business-courses.component";
import { CoursesResolverService } from "../common/resolvers/courses-resolver.service";
import { GrowYourBusinessCourseDetailsComponent } from "./course-details/grow-your-business-course-details.component";
import { GrowYourBusinessMyProfileComponent } from "./my-profile/grow-your-business-my-profile.component";
import { GrowYourBusinessForgotPasswordComponent } from "./forgot-password/grow-your-business-forgot-password.component";
import { GrowYourBusinessLinkForgotPasswordComponent } from "./link-forgot-password/grow-your-business-link-forgot-password.component";
import { AuthGuard } from "../common/services/auth.guard.service";
import { CourseResolverService } from "../common/resolvers/course-resolver.service";
import { CourseEnrollmentResolverService } from "../common/resolvers/course-enrollment-resolver.service";
import { MyCoursesResolverService } from "../common/resolvers/my-courses-resolver.service";
import { GrowYourBusinessMyCoursesComponent } from "./my-courses/grow-your-business-my-courses.component";
import { GrowYourBusinessMyCourseComponent } from "./my-course/grow-your-business-my-course.component";
import { GrowYourBusinessManageEmployeesComponent } from "./manage-employees/grow-your-business-manage-employees.component";
import { GrowYourBusinessEnrollmentsWithEmployeesResolverService } from "./grow-your-business-enrollments-with-employees-resolver.service";
import { EmployerGuard } from "./employer.guard.service";
import { GrowYourBusinessTermsOfUseComponent } from "./terms-of-use/grow-your-business-terms-of-use.component";
import { GrowYourBusinessPrivacyPolicyComponent } from "./privacy-policy/grow-your-business-privacy-policy.component";
import { GrowYourBusinessRedirectFromLoginGuard } from "./grow-your-business-redirect-from-login.guard.service";
import { GrowYourBusinessCookiesPolicyComponent } from "./cookies/grow-your-business-cookies-policy.component";
import { GrowYourBusinessAskAQuestionComponent } from "./ask-a-question/grow-your-business-ask-a-question.component";

const appRouting: Routes = [
    {
        path: '',
        component: GrowYourBusinessLayoutComponent,
        resolve: {
            user: UserResolverService,
        },
        runGuardsAndResolvers: "always",
        children: [
            {
                path: '',
                component: GrowYourBusinessCoursesComponent,
                resolve: {
                    courses: CoursesResolverService
                },
                pathMatch: "full"
            },
            {
                path: 'growyourbusiness-terms-of-use',
                component: GrowYourBusinessTermsOfUseComponent
            },
            {
                path: 'growyourbusiness-privacy-policy',
                component: GrowYourBusinessPrivacyPolicyComponent
            },
            {
                path: 'growyourbusiness-cookies',
                component: GrowYourBusinessCookiesPolicyComponent
            },
            {
                path: 'certification-program/:courseId',
                component: GrowYourBusinessCourseDetailsComponent,
                resolve: {
                    courses: CoursesResolverService
                }
            },
            {
                path: 'employee-registration/:employeeId',
                component: GrowYourBusinessEmployeeRegistrationComponent,
                resolve: {
                    employeeRegistrationDetails: EmployeeRegistrationDetailsResolverService
                }
            },
            {
                path: 'growyourbusiness-login',
                component: GrowYourBusinessLoginComponent,
                canActivate: [GrowYourBusinessRedirectFromLoginGuard],
            },
            {
                path: 'verify',
                component: GrowYourBusinessVerifyComponent
            },
            {
                path: 'growyourbusiness-register',
                component: GrowYourBusinessEmployerRegistrationComponent
            },
            {
                path: 'growyourbusiness-faq',
                component: GrowYourBusinessFaqComponent
            },
            {
                path: 'growyourbusiness-contact',
                component: GrowYourBusinessContactUsComponent
            },
            {
                path: 'my-profile',
                component: GrowYourBusinessMyProfileComponent
            },
            {
                path: 'forgot-password',
                component: GrowYourBusinessForgotPasswordComponent
            },
            {
                path: 'link-forgot-password',
                component: GrowYourBusinessLinkForgotPasswordComponent
            },
            {
                path: 'my-courses',
                canActivate: [AuthGuard],
                component: GrowYourBusinessMyCoursesComponent,
                // resolve: {
                //     courses: MyCoursesResolverService
                // }
            },
            {
                path: 'my-courses/:courseId',
                canActivate: [AuthGuard],
                component: GrowYourBusinessMyCourseComponent,
                resolve: {
                    course: CourseResolverService,
                    courseEnrollment: CourseEnrollmentResolverService
                },
            },
            {
                path: 'my-courses/:courseId/lecture',
                canActivate: [AuthGuard],
                // resolve: {
                //     course: CourseResolverService,
                //     courseEnrollment: CourseEnrollmentResolverService
                // },
                loadComponent: () => import('./lecture/lecture.component').then(m => m.LectureComponent) 
            },
            {
                path: 'my-courses/:courseId/lecture/:lectureId',
                canActivate: [AuthGuard],
                // resolve: {
                //     course: CourseResolverService,
                //     courseEnrollment: CourseEnrollmentResolverService
                // },
                loadComponent: () => import('./lecture/lecture.component').then(m => m.LectureComponent) 
            },
            {
                path: 'manage',
                canActivate: [AuthGuard, EmployerGuard],
                component: GrowYourBusinessManageEmployeesComponent,
                resolve: {
                    enrollmentsWithEmployees: GrowYourBusinessEnrollmentsWithEmployeesResolverService,
                    courses: CoursesResolverService
                }
            },
            {
                path: 'ask-a-question',
                canActivate: [AuthGuard],
                component: GrowYourBusinessAskAQuestionComponent,
                children: [
                    {
                        path: ':conversationId',
                        component: GrowYourBusinessAskAQuestionComponent
                    }
                ]
            }
        ],
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/grow-your-business-admin.module').then(m => m.GrowYourBusinessAdminModule),
    },
    {
        path: '**',
        redirectTo: '/',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRouting, { scrollPositionRestoration: 'enabled' }),
    ],
    exports: [
        RouterModule
    ],
    providers: [
        UserResolverService,
        GrowYourBusinessRedirectFromLoginGuard,
        EmployeeRegistrationDetailsResolverService
    ],
    declarations: []
})
export class GrowYourBusinessRoutingModule { }