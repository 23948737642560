import { Component, effect, inject, OnInit, ViewChild } from '@angular/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { ActivatedRoute, Data } from '@angular/router';
import { catchError, finalize, switchMap, throwError } from 'rxjs';
import { Course, CourseEnrollment, CourseEnrollmentV2, CourseLesson } from '../shared/models/course.model';
import { User } from '../shared/models/user.model';
import { SpinnerService } from '../spinner/spinner.service';
import { FileService } from '../services/file.service';
import { UserService } from '../services/user.service';
import { MatTable } from '@angular/material/table';
import { CoursesService } from '../services/courses.service';

@Component({
	selector: 'app-my-courses',
	template: '',
})
export class MyCoursesComponent implements OnInit {
	protected coursesService = inject(CoursesService);
	public user!: User
	public errorMessage: string | null = null;
	public phone = "+30 210 220 9167";

	public userEnrollments: CourseEnrollmentV2[] = [];
	public displayedColumns: string[] = ['courseTitle', 'courseStatus'];
	public selected = new Date()

	public allLessons: { [key: string]: (CourseLesson & { courseTitle: string })[] } = {};
	public selectedLessons: any[] = [];

	public tableDataForUserEnrollments: any[] = [];

	@ViewChild(MatTable) table!: MatTable<any>;

	constructor(
		public route: ActivatedRoute,
		private fileService: FileService,
		public spinnerService: SpinnerService,
		private userService: UserService,
	) {

		effect(() => {
			this.userEnrollments = this.coursesService.courseEnrollments();
			this.userEnrollments = this.userEnrollments.map(d => {
				return {
					...d,
					completedLectures: d.lecturesProgress?.sections
						? Object.values(d.lecturesProgress.sections).reduce((sum, section) => {
							return sum + (Array.isArray(section?.lectures) ? section.lectures.length : 0);
						}, 0)
						: 0,
					totalLectures: d.course.sections?.reduce((acc: any, section: any) => {
						return acc + section.lectures.length
					}, 0),
					lectureLink: 'my-courses/' + d.course.courseId + '/lecture'
				}
			})
			console.log(this.userEnrollments)
			this.addEnrollmentsToTableData(this.userEnrollments)
		});


	}

	ngOnInit() {
		this.route?.parent?.data
			.subscribe((data: Data) => {
				this.user = data['user']
			})

		// this.route.data.subscribe((data: Data) => {
		// 	this.userEnrollments = data['courses']
		// 	this.userEnrollments = this.userEnrollments.map( d => {
		// 		return {
		// 			...d,
		// 			lectureLink: 'my-courses/' + d.course.courseId + '/lecture'
		// 		}
		// 	})
		// 	console.log(this.userEnrollments)

		// 	this.addEnrollmentsToTableData(this.userEnrollments)
		// });
	}



	public addEnrollmentsToTableData(enrollments: CourseEnrollmentV2[]) {

		for (let enrollment of enrollments) {
			let dataToPushOnTable = {
				courseTitle: enrollment.course.title,
				mainInstructor: enrollment.course.instructors?.[0]?.avatar,
				mainInstructorName: enrollment.course.instructors?.[0]?.name,
				courseId: enrollment.course.courseId,
			} as any;

			this.tableDataForUserEnrollments.push(dataToPushOnTable)
		}

		// γιατί μεχρι να γίνει το πρωτο  ρεντερ του table θα είναι undefined
		if (this.table) {
			this.table.renderRows()
		}

		console.log(123123, this.tableDataForUserEnrollments)
	}



	onUploadInvoice(event: any) {

		if (event.target.files.length <= 0) {
			return;
		}

		console.log(event.target.files)
		let fileData: any;
		this.spinnerService.show('upload-invoice')

		let imageUrl: string | null;
		this.errorMessage = null
		this.fileService.readImage(event.target.files[0], {})
			.pipe(
				switchMap(resultFile => {
					console.log(resultFile)
					fileData = resultFile;
					return this.fileService.requestPresignedUrl({ Mime: resultFile.image.type, type: 'invoice' })
						.pipe(catchError((err) => {
							this.errorMessage = 'Βεβαιωθείτε ότι ανεβάζετε καποιοα αρχείο εικόνας ή καποιο pdf';
							return throwError(err)
						}))
				}),
				switchMap(presigedUrlResponse => {
					console.log(presigedUrlResponse)
					imageUrl = presigedUrlResponse.url
					return this.fileService.uploadFileToPresignedUrl(presigedUrlResponse.url, fileData.requestedFormat);
				}),
				switchMap(result => {
					console.log(imageUrl)
					return this.userService.uploadInvoice(imageUrl?.split('?')[0]);
				}),
				finalize(() => this.spinnerService.hide('upload-invoice'))
			)
			.subscribe({
				next: (oneOffPayment) => {
					this.user.oneOffPayment = oneOffPayment;
				},
				error: (err) => {
					console.log(err)
				}
			})
	}

	dateClass() {
		return (date: Date): MatCalendarCellCssClasses => {

			let yyyymmdd = this.convertDateToYYYYMMDD(date)
			let lessonExistOnthisDate = this.allLessons.hasOwnProperty(yyyymmdd)

			if (lessonExistOnthisDate) {
				return 'lessonDate';
			} else {
				return '';
			}
		};
	}

	onSelect(date: any) {
		console.log(date)
		this.selected = date;
		let yyyymmdd = this.convertDateToYYYYMMDD(date)
		this.selectedLessons = this.allLessons[yyyymmdd] || []
		console.log(this.selectedLessons)
	}

	// Επιστρέφει την ώρα του μαθήματος
	getLessonTime() {
		console.log(this.selectedLessons.length)
		if (this.selectedLessons.length == 0) return ''
		return new Date(this.selectedLessons?.[0]?.startsAt * 1000).toLocaleTimeString('el-GR', { hour: '2-digit', minute: '2-digit', hour12: false })
	}

	public convertDateToYYYYMMDD(date: Date) {
		date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
		return date.toISOString().split('T')[0]
	}

}
