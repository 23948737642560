import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { SpinnerService } from '../spinner/spinner.service';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-link-forgot-password',
    template: '',
})
export class LinkForgotPasswordComponent {
    public error_message = null;
    public hidePassword = true;
    public forgotPasswordForm!: FormGroup;
    public sendedCodeSuccessMessage!: string;
    private email!: string | null;
    private code!: string | null;

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private spinnerService: SpinnerService
    ) { }

    ngOnInit() {
        this.email = this.route.snapshot.queryParamMap.get('username');
        this.code = this.route.snapshot.queryParamMap.get('code');

        this.forgotPasswordForm = new FormGroup({
            'newPassword': new FormControl('', {
                validators: [Validators.required, this.passwordIsValid],
                nonNullable: true
            }),
            'newPasswordConfirmation': new FormControl('', {
                validators: [Validators.required, this.checkPasswords.bind(this)],
                nonNullable: true
            })
        });
    }

    passwordIsValid(control: AbstractControl): ValidationErrors | null {
        if (!control.value || control.value.length < 6) {
            return { 'passwordIsNotLongEnough': true };
        }
        return null;
    }

    checkPasswords(control: AbstractControl): ValidationErrors | null {
        if (!this.forgotPasswordForm) {
            return null;
        }
        const newPassword = this.forgotPasswordForm.get('newPassword')?.value;
        const newPasswordConfirmation = control.value;
        if (newPassword === newPasswordConfirmation) {
            return null;
        }
        return { notSame: true };
    }

    onForgotPasswordSubmit() {

        this.spinnerService.show("link-forgot-password")
        this.authService.forgotPasswordSubmit({
            username: this.email as string,
            confirmationCode: this.code as string,
            newPassword: this.forgotPasswordForm?.get('newPassword')?.value as string
        }
        )
            .pipe(
                finalize(() => this.spinnerService.hide("link-forgot-password"))
            )
            .subscribe({
                next: (data) => {
                    this.sendedCodeSuccessMessage = 'O κωδικός σας αλλαξε επιτυχώς'
                }
            })
    }

}
