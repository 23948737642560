import { Component, inject } from "@angular/core";
import { ActivatedRoute, Data } from "@angular/router";
import { MyCoursesComponent } from "src/app/common/components/my-courses.component";
import { FileService } from "src/app/common/services/file.service";
import { UserService } from "src/app/common/services/user.service";
import { SpinnerService } from "src/app/common/spinner/spinner.service";
import { GrowYourBusinessAddMoreCoursesComponent } from "./add-more-courses/grow-your-business-add-more-courses.component";
import { MatDialog } from "@angular/material/dialog";
import { CoursesService } from "src/app/common/services/courses.service";
import { finalize, takeUntil } from "rxjs";
import { Course, CourseEnrollment, CourseEnrollmentV2 } from "src/app/common/shared/models/course.model";
import { DateAdapter } from "@angular/material/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    templateUrl: './grow-your-business-my-courses.component.html',
    styleUrls: ['./grow-your-business-my-courses.component.css']
})
export class GrowYourBusinessMyCoursesComponent extends MyCoursesComponent {
    public override displayedColumns: string[] = ['courseTitle', 'lecturesInfo', 'courseStatus', 'actions'];
    public breakpointObserver = inject(BreakpointObserver);

    constructor(
        route: ActivatedRoute,
        fileService: FileService,
        spinnerService: SpinnerService,
        userService: UserService,
        private dialog: MatDialog,
        // private coursesService: CoursesService,
        private dateAdapter: DateAdapter<any>
    ) {

        super(route, fileService, spinnerService, userService);


        this.breakpointObserver
            .observe(['(max-width: 768px)', '(max-width: 375px)'])
            .pipe(takeUntilDestroyed())
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    // Mobile view
                    this.displayedColumns = ['courseTitle', 'actions'];
                } else {
                    // Desktop view
                    this.displayedColumns = ['courseTitle', 'lecturesInfo', 'courseStatus', 'actions'];
                }
                console.log('Breakpoint state changed:', state);
            });
    }

    override ngOnInit(): void {
        this.dateAdapter.setLocale('el-GR');
        this.route.queryParams.subscribe(queryParams => {
            console.log(queryParams)
            if (queryParams['cpop-up']) {
                this.onOpenEnrollmentDialog();
            }
        });





        super.ngOnInit();
    }

    onOpenEnrollmentDialog() {
        this.spinnerService.show('my-courses')
        this.coursesService.getCourses()
            .pipe(finalize(() => this.spinnerService.hide('my-courses')))
            .subscribe((courses) => {


                // Παίρνουμε τα ids απο τα υπάρχοντα μαθήματα
                let existingCourseIds = this.userEnrollments.map((enrollment: any) => {
                    return enrollment.course.courseId
                })
                // επιλέγουμε όλα τα μαθήματα που δεν είναι ήδη εγγεγραμμένος ο χρήστης
                let newCourses = courses.filter((course: any) => {
                    return !existingCourseIds.includes(course.courseId)
                })
                console.log(newCourses)

                // ανοίγουμε το dialog για την εγγραφη σε περισσότερα μαθήματα
                // και αν ο χρηστης εγγραφή τότε κανουμε render τα καινούργια μαθήματα στο table
                this.dialog.open(GrowYourBusinessAddMoreCoursesComponent, {
                    data: { courses: newCourses },
                    disableClose: true,
                    height: '500px'
                }
                ).afterClosed().subscribe({
                    next: (newEnrollments: any) => {
                        console.log('new enrollments', newEnrollments);
                        if (newEnrollments) {
                            this.userEnrollments = [...this.userEnrollments, ...newEnrollments]
                            this.addEnrollmentsToTableData(newEnrollments)
                            this.coursesService.courseEnrollments.update((enrollments) => {
                                return [...enrollments, ...newEnrollments]
                            })
                        }
                    }, error(err: any) {
                        console.log(err)
                    }
                })
            })

    }


    shouldDisplayCtaButtonForEmployer() {
        return !(this.user as any)?.employerId && this.userEnrollments?.length === 0
    }

    override addEnrollmentsToTableData(enrollments: CourseEnrollmentV2[]) {
        // 
        // console.log(4, enrollments)
        // enrollments = [];
        this.tableDataForUserEnrollments = [];
        for (let enrollment of enrollments) {
            let dataToPushOnTable = {
                courseTitle: enrollment.course.title,
                completedLectures: enrollment.completedLectures,
                totalLectures: enrollment.totalLectures,
                status: enrollment.status,
                courseId: enrollment.course.courseId,
            } as any;

            this.tableDataForUserEnrollments.push(dataToPushOnTable)
        }

        // γιατί μεχρι να γίνει το πρωτο  ρεντερ του table θα είναι undefined
        if (this.table) {
            this.table.renderRows()
        }

        console.log(123123, this.tableDataForUserEnrollments)
    }
}
