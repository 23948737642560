import { AngularMaterialModule } from './angular-material.module';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { SpinnerService } from '../spinner/spinner.service';

@NgModule({
    declarations: [
        SpinnerComponent
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
    ],
    exports: [
        AngularMaterialModule,
        SpinnerComponent
    ],
    providers: [
        SpinnerService
    ]
})
export class SharedModule {
    // static forRoot(): ModuleWithProviders<SharedModule> {
    // return {
    //     ngModule: SharedModule,
    //     providers: [
    //         SpinnerService
    //     ]
    // };
// }
}