import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { RequestInterceptor } from '../request.interceptor';

@Injectable()
export class GrowYourBusinessRequestInterceptor extends RequestInterceptor implements HttpInterceptor {

    constructor() { super('cosmote')}

}
