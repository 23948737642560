import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CoursesService } from '../services/courses.service';

@Injectable()
export class CourseResolverService {

    constructor(
        private coursesService: CoursesService
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        const courseId =   route.params['courseId'];
        return this.coursesService.getCourse(courseId);
    }
}
