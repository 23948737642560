<div style="padding: 10px 0px 0px 0px;gap: 64px;" fxLayout="column" fxLayoutAlign="center center">

    <div [ngStyle]="{'width': '100%', 'height':'573px', 'background-image': 'url('+course.image+')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center'}"
        fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="65" fxFlex.xs="90" fxLayoutAlign="end end" style="padding-top:64px">
            <div [ngStyle.xs]="{'width':'auto'}"
                style="background-color:white; padding:24px;width:355px;border-radius:5px; box-shadow:0 1px 4px rgba(0,0,0,.6)"
                fxLayout="column">
                <span class="cosmote-blue"
                    style="font-size: 26px;line-height: 34px;font-style: normal;font-weight: 700;margin-bottom: 8px">{{course.title}}</span>
                <div class="badge-free" style="width:76px" *ngIf="!courseIsCompleted()">Δωρεάν</div>
                <div class="badge-completed" style="width:129px" *ngIf="courseIsCompleted()">Ολοκληρώθηκε</div>

                <div style="gap: 12px;margin:24px 0px 32px 0px" fxLayout="column">
                    <!-- <div style="margin-right:12px" fxLayout="row" fxLayoutAlign="start center">
                        <img style="width:20px; height:20px; margin-right:8px" src="/assets/calendar-date.png">
                        <span class="cosmote-blue" style="font-size:21px"> {{getCourseDurationDates() }}</span>
                    </div> -->

                    <div style="margin-right:12px" fxlayout="row" fxLayoutAlign="start center">
                        <img style="width:20px; height:20px; margin-right:8px" src="/assets/clock.png">
                        <span class="cosmote-blue"
                            style="font-size: 19px;font-weight: 400;line-height: 27px;">{{course.durationInHours}}
                            Ώρες</span>
                    </div>
                </div>

                <ng-container *ngIf="!courseIsCompleted()">
                    @if (!alreadyEnrolled()) {
                    <button mat-raised-button color="success"
                        style="border-radius: 50px; font-size:23px; line-height:32px" *ngIf="!user"
                        routerLink="/growyourbusiness-register"> Εγγραφή </button>
                    <button mat-raised-button color="success"
                        style="border-radius: 50px; font-size:23px; line-height:32px" *ngIf="user"
                        routerLink="/my-courses" [queryParams]="{'cpop-up': 'active'}"> Εγγραφή </button>

                    } @else {
                    <button mat-raised-button color="success"
                        style="border-radius: 50px; font-size:23px; line-height:32px" *ngIf="user"
                        [routerLink]="['/my-courses', course.courseId, 'lecture']"
                        [queryParams]="{'cpop-up': 'active'}"> Δείτε τα video </button>
                    }

                </ng-container>

            </div>
        </div>
    </div>
</div>

<div style="gap: 48px;padding:64px 0px 36px; background-color:rgb(250, 250, 250);" fxLayout="column"
    fxLayoutAlign="center center">
    <div [ngStyle.gt-sm]="{'padding':'0px 32px'}" [ngStyle.lt-md]="{'padding':'0px'}" fxLayout="column"
        fxLayoutAlign="center center" style="gap: 64px;">
        <div fxLayout="column" fxLayoutAlign="center center" style="gap: 48px;">
            <div style="width:900px;gap: 48px;" [ngStyle.sm]="{'width':'75%'}"
                [ngStyle.xs]="{'width':'100%', 'padding': '32px'}" fxLayout="column" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="start start" style="gap: 48px;">
                    <span class="cosmote-blue" style="font-size:30px; font-weight:600; line-height:42px">Περιγραφή
                        ενότητας μαθήματος</span>
                    <span class="cosmote-blue" style="font-size:17px;line-height: 23.8px"
                        *ngIf="course.description; else shortDescription">{{course.description}}</span>
                    <ng-template #shortDescription>
                        <span class="cosmote-blue" style="font-size:17px;line-height: 23.8px">
                            {{course.shortDescription}}</span>
                    </ng-template>

                </div>

                <!-- <app-course-lessons-schedule [course]="course"></app-course-lessons-schedule> -->
                <mat-accordion displayMode="flat" style="width: 100%;">
                    @for (section of this.course.sections; track section.id) {
                    <mat-expansion-panel class="mat-elevation-z0">
                        <mat-expansion-panel-header
                            style="background: var(--mat-expansion-header-hover-state-layer-color);border-top: 1px solid var(--gray-300);">
                            <mat-panel-title> {{section.topic}} </mat-panel-title>
                            <div style="width: 200px;text-align: right;padding-right: 12px;"> {{section.totalLectures}}
                                διαλέξεις |
                                {{section.totalDuration}} λεπτά </div>
                        </mat-expansion-panel-header>

                        <mat-list role="list" style="gap: 12px;display: flex;flex-flow: column;">
                            @for (lecture of section.lectures; track lecture; let i = $index) {
                            <mat-list-item>
                                <div style="display: flex; flex-direction: row; gap: 8px;">
                                    <div style="width: 24px;"></div>
                                    <div style="display: flex; flex-flow: column;">
                                        {{lecture.name}}
                                        <div style="gap: 8px;display: flex;align-items: center;">

                                            @if (lecture.type==='video') {
                                            <svg width="16" height="16" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M22 8.93137C22 8.32555 22 8.02265 21.8802 7.88238C21.7763 7.76068 21.6203 7.69609 21.4608 7.70865C21.2769 7.72312 21.0627 7.93731 20.6343 8.36569L17 12L20.6343 15.6343C21.0627 16.0627 21.2769 16.2769 21.4608 16.2914C21.6203 16.3039 21.7763 16.2393 21.8802 16.1176C22 15.9774 22 15.6744 22 15.0686V8.93137Z"
                                                    stroke="var(--gray-500)" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <path
                                                    d="M2 9.8C2 8.11984 2 7.27976 2.32698 6.63803C2.6146 6.07354 3.07354 5.6146 3.63803 5.32698C4.27976 5 5.11984 5 6.8 5H12.2C13.8802 5 14.7202 5 15.362 5.32698C15.9265 5.6146 16.3854 6.07354 16.673 6.63803C17 7.27976 17 8.11984 17 9.8V14.2C17 15.8802 17 16.7202 16.673 17.362C16.3854 17.9265 15.9265 18.3854 15.362 18.673C14.7202 19 13.8802 19 12.2 19H6.8C5.11984 19 4.27976 19 3.63803 18.673C3.07354 18.3854 2.6146 17.9265 2.32698 17.362C2 16.7202 2 15.8802 2 14.2V9.8Z"
                                                    stroke="var(--gray-500)" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                            <span
                                                style="font-size: 12px; color: var(--gray-500);">{{lecture.durationInMins}}
                                                mins</span>
                                            }

                                            @if (lecture.type==='resources') {
                                            <svg width="12" height="12" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M14 2.26946V6.4C14 6.96005 14 7.24008 14.109 7.45399C14.2049 7.64215 14.3578 7.79513 14.546 7.89101C14.7599 8 15.0399 8 15.6 8H19.7305M20 9.98822V17.2C20 18.8802 20 19.7202 19.673 20.362C19.3854 20.9265 18.9265 21.3854 18.362 21.673C17.7202 22 16.8802 22 15.2 22H8.8C7.11984 22 6.27976 22 5.63803 21.673C5.07354 21.3854 4.6146 20.9265 4.32698 20.362C4 19.7202 4 18.8802 4 17.2V6.8C4 5.11984 4 4.27976 4.32698 3.63803C4.6146 3.07354 5.07354 2.6146 5.63803 2.32698C6.27976 2 7.11984 2 8.8 2H12.0118C12.7455 2 13.1124 2 13.4577 2.08289C13.7638 2.15638 14.0564 2.27759 14.3249 2.44208C14.6276 2.6276 14.887 2.88703 15.4059 3.40589L18.5941 6.59411C19.113 7.11297 19.3724 7.3724 19.5579 7.67515C19.7224 7.94356 19.8436 8.2362 19.9171 8.5423C20 8.88757 20 9.25445 20 9.98822Z"
                                                    stroke="var(--gray-500)" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                            </svg>
                                            <span
                                                style="font-size: 12px; color: var(--gray-500);">{{lecture.files?.length
                                                ??
                                                0}} file{{lecture.files!.length > 1 ? 's' : ''}}</span>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </mat-list-item>
                            }
                        </mat-list>
                    </mat-expansion-panel>
                    }
                </mat-accordion>

            </div>
        </div>
    </div>
</div>

<div style="background-color:white ;padding-top:64px" [ngStyle.xs]="{'padding-top':'32px'}">
    <div [ngStyle.gt-sm]="{'padding':'0px 32px 32px 32px'}" fxLayout="column" fxLayoutAlign="center center"
        style="gap: 64px;">
        <div style="gap: 48px;width:900px" [ngStyle.sm]="{'width':'75%'}"
            [ngStyle.xs]="{'width':'100%', 'padding': '32px'}" fxLayout="column" fxLayoutAlign="center center">
            <div *ngFor="let instructor of course.instructors; let  i = index" fxLayout="row"
                fxLayoutAlign="start start" fxLayout.lt-md="column" style="gap: 24px;">
                <div fxFlex="50" [ngStyle.gt-sm]="{'order': i % 2} ">
                    <img [src]="instructor.avatar" [ngStyle.xs]="{'width': '150px'}" [ngStyle.sm]="{'width': '250px'}">
                </div>
                <div style="gap: 36px;padding: 0px" fxLayout="column" fxLayoutAlign="start start">
                    <div fxLayout="row" fxLayoutAlign="start start" style="gap: 4px;" fxLayout.lt-md="column">
                        <span class="cosmote-blue" style="font-size:30px; line-height:28px; font-weight: 600;"
                            [ngStyle.lt-md]="{'font-size': '24px', 'line-height': '28px'}">Καθηγητής:</span>
                        <span class="cosmote-blue" style="font-size:30px; line-height:28px; font-weight: 600;"
                            [ngStyle.lt-md]="{'font-size': '24px', 'line-height': '28px'}">{{instructor.name}}</span>
                    </div>

                    <div fxLayout="column" style="gap: 8px;">
                        <span class="cosmote-blue" style="font-size:17px; line-height:28px"
                            *ngFor="let paragraph of instructor.bio.split('\n')">{{paragraph}}</span>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<div style="gap: 64px;padding:48px 0px; background-color:#004B87" fxLayout="column" fxLayoutAlign="center center">
    <div [ngStyle.gt-sm]="{'padding':'0px 32px'}" [ngStyle.lt-md]="{'padding':'0px 16px'}" style="gap: 32px;"
        fxLayout="column" fxLayoutAlign="start center">
        <div style="gap: 48px;padding:0px;" fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="column" style="gap: 24px;width:900px" [ngStyle.sm]="{'width':'450px', 'padding': '0px 16px'}"
                [ngStyle.xs]="{'width':'300px', 'padding': '0px 16px'}" fxLayoutAlign="center start">
                <span style="font-size:30px; font-weight:600; line-height:38px; color:white">Βεβαίωση Παρακολούθησης /
                    Πιστοποιητικό Κατάρτισης</span>
                <span style="font-size:17px; line-height:1.8em; color:white; letter-spacing: 0.02em;">
                    Όλοι οι συμμετέχοντες έχουν τη δυνατότητα να λάβουν Βεβαίωση Παρακολούθησης για κάθε μάθημα που θα
                    παρακολουθήσουν, από το Κέντρο Επιμόρφωσης και Δια Βίου Μάθησης (ΚΕ.ΔΙ.ΒΙ.Μ.) του Οικονομικού
                    Πανεπιστημίου Αθηνών, αφού ολοκληρώσουν με επιτυχία το αντίστοιχο Online Test Αξιολόγησης. Όσοι
                    συμμετέχοντες παρακολουθήσουν και ολοκληρώσουμε με επιτυχία το σύνολο των μαθημάτων του προγράμματος
                    θα λάβουν πιστοποιητικό κατάρτισης από το ΚΕ.ΔΙ.ΒΙ.Μ. του Οικονομικού Πανεπιστημίου Αθηνών.
                </span>
            </div>
        </div>
    </div>
</div>