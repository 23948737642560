<div fxLayout="column" fxLayoutAlign="center center"
    style="gap: 32px;padding:44px 0px 96px; background-color: var(--gray-50);"
    [ngStyle.lt-lg]="{'padding': '32px 0px 48px'}">

    <div class="breadcrumb" fxLayout="row" fxLayoutAlign="start center"
        style="gap: 8px;width:1200px; border-radius: 8px;background: var(--gray-100, #F2F4F7);padding: 12px 20px;"
        [ngStyle.lt-lg]="{'width': '90%', 'padding': '8px 16px'}">
        <div routerLink="../" routerLinkActive="router-link-active" style="color: var(--gray-400, #98A2B3);font-size: 12px;font-style: normal;font-weight: 500;line-height: 18px; cursor:pointer;
            white-space: nowrap; text-overflow: ellipsis;overflow: hidden" [ngStyle.xs]="{'max-width':'150px'}"> Τα
            μαθήματα μου</div>

        <img src="/assets/chevron-right.png" alt="" style="width:16px; height:16px">

        <div routerLinkActive="router-link-active" style="color: var(--gray-700, #344054);font-size: 12px;font-style: normal;font-weight: 500;
        line-height: 18px;cursor:pointer; white-space: nowrap; text-overflow: ellipsis;overflow: hidden;">
            {{course.title}}</div>
    </div>

    <div fxLayout="column" fxLayoutAlign="start" [ngStyle.lt-lg]="{'width': '90%', 'padding': '0px 16px'}"
        style="gap: 12px;width:1200px">
        <span class="cosmote-blue"
            style="font-size: 30px;font-style: normal;font-weight: 700;line-height: 38px;">{{course.title}}</span>
        <span class="cosmote-blue"> {{course.shortDescription}}</span>

    </div>

    <!-- <app-course-lessons-schedule [course]="course" [enable]="['link']" version="v2" style="width:1200px"
        [ngStyle.lt-lg]="{'width': '90%', 'padding': '0px 16px'}"></app-course-lessons-schedule> -->

    <div fxLayout="column" fxLayoutAlign="start" [ngStyle.lt-lg]="{'width': '90%', 'padding': '0px 16px'}"
        style="gap: 12px;width:1200px">
        <div style="padding:0px 32px">
            <div *ngFor="let instructor of course.instructors" fxLayout="row" fxLayout.lt-md="column"
                style="margin-bottom:24px;gap: 24px;">
                <img [src]="instructor.avatar" alt="" style="max-width: 150px; max-height:150px">
                <div fxLayout="column">
                    <span class="gray-900"
                        style="margin-bottom:32px;font-size: 24px;font-style: normal;font-weight: 700;line-height: 32px;">Καθηγητής:
                        {{instructor.name}}</span>
                    <div fxLayout="column" style="gap: 8px;">
                        <span class="gray-600"
                            style="font-family: Arial;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;"
                            *ngFor="let paragraph of instructor.bio.split('\n')">{{paragraph}}</span>
                    </div>

                </div>
            </div>
        </div>
    </div>



    <div [ngStyle.lt-lg]="{'width': '90%', 'padding': '0px 16px'}"
        style="padding: 0px 32px; background-color: white; border-radius:8px; width:1200px">
        <!-- aν το 1ο μαθημα ειναι ακομη disabled τοτε να του πετάμε emptyContentForAllPages -->
        <mat-tab-group *ngIf="courseLessons.length > 0 && !courseLessons[0].disabled; else emptyContentForAllPages"
            #group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);"
            mat-stretch-tabs="false">
            <!-- <mat-tab-group  *ngIf="courseLessons.length > 0; else emptyContentForAllPages" #group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);" mat-stretch-tabs="false"> -->

            <mat-tab [disabled]="lesson.disabled" *ngFor="let lesson of courseLessons; let i = index"
                [label]="lesson.label" labelClass="courses">

                <div *ngIf="lesson.hasOwnProperty('id') && lessonHasStarted(lesson)" fxLayout="column"
                    style="margin-top:32px;gap: 32px;" fxLayoutAlign="center">

                    <!-- <div *ngIf="videosSectionExist(selectedLesson)" fxLayout.lt-md="column" fxLayout="row"
                        fxLayoutAlign="start" style="gap: 44px;padding:32px 0px 32px 0px;margin-bottom:32px">
                        <div fxLayout="column" fxLayoutAlign="start" [ngStyle.lt-md]="{'width': '100%'}"
                            style="width:75%">
                            <span style="font-size: 24px; line-height:32px; margin-bottom: 12px;" class="gray-800">
                                Βίντεο</span>
                            <div style="height:380px; margin-bottom: 24px;" [ngStyle.lt-md]="{'height': '280px'}">
                                <video fxFill style="object-fit: contain;" #videoPlayer controls
                                    class="video-js vjs-theme-fantasy vjs-big-play-centered"
                                    [poster]="selectedLesson.sections.videos?.[selectedLessonConfiguration.videoSelectedIndex]?.thumbnail"></video>
                            </div>
                            <div fxLayout="column" style="gap: 4px;">
                                <span class="gray-900">{{course.title}}</span>
                                <span class="gray-500"
                                    style="font-size:14px;line-height:24px">{{course.shortDescription}}</span>
                            </div>

                        </div>

                        <div fxLayout="column" style="gap: 12px;">
                            <span style="font-size: 24px; line-height:32px;" class="gray-800"> Περισσότερα βίντεο
                            </span>

                            <div fxLayout.lt-md="row" [ngStyle.lt-md]="{'max-width':'100%', 'overflow-x':'scroll'}"
                                fxLayout="column"
                                style="gap: 8px;max-height:380px; overflow: hidden; overflow-y: scroll;">
                                <div *ngFor="let video of  selectedLesson.sections?.videos; let videoIndex = index"
                                    style="gap: 24px;padding: 12px 12px 12px 2px; cursor:pointer"
                                    fxLayout.lt-md="column" fxLayout="row" fxLayoutAlign="start"
                                    [class.video-selected]="selectedLessonConfiguration.videoSelectedIndex == videoIndex"
                                    (click)="OnChangeVideo(video, videoIndex)">
                                    <img [src]="video.thumbnail"
                                        style="width:144px; height: 104px; object-fit: cover; border-radius: 4px"
                                        [ngStyle.xs]="{'width': '210px', 'height':'85'}">
                                    <div fxLayout="column">
                                        <span style="font-weight: 600; font-size: 16px;line-height: 24px;"
                                            class="gray-900"> {{video.name}}</span>
                                        <span> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div *ngIf="otherResourcesSectionExist(selectedLesson)" fxLayout="column"
                        fxLayoutAlign="center start"
                        style="gap: 12px;padding:32px; border-radius: 8px;background: #F0F7FC">
                        <span
                            style="color: #004B87;font-family: Arial;font-size: 18px;font-style: normal;font-weight: 700;line-height: 28px;">Διαφάνειες</span>
                        <div fxLayout="row" fxLayout.xs="column" style="gap: 12px;align-self:stretch">
                            <div style="padding:16px; background:white" fxFlex="33"
                                *ngFor="let resource of selectedLesson.sections?.otherResources" fxLayout="row"
                                fxLayoutAlign="start center" style="gap: 12px;">
                                <img style="width:40px; height:40px" [src]="resource.thumbnail" alt="">
                                <span class="gray-700"
                                    style="overflow: hidden;text-overflow: ellipsis;font-size: 14px;line-height: 20px;">{{resource.name}}</span>
                                <img style="width:40px; height:40px;cursor:pointer" src="/assets/download.png"
                                    (click)="onDownloadResource(resource)">
                            </div>
                        </div>
                    </div> -->

                    <div style="padding:0px 32px">
                        <div *ngFor="let instructor of course.instructors" fxLayout="row" fxLayout.lt-md="column"
                            style="margin-bottom:24px;gap: 24px;">
                            <img [src]="instructor.avatar" alt="" style="max-width: 150px; max-height:150px">
                            <div fxLayout="column">
                                <span class="gray-900"
                                    style="margin-bottom:32px;font-size: 24px;font-style: normal;font-weight: 700;line-height: 32px;">Καθηγητής:
                                    {{instructor.name}}</span>
                                <div fxLayout="column" style="gap: 8px;">
                                    <span class="gray-600"
                                        style="font-family: Arial;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;"
                                        *ngFor="let paragraph of instructor.bio.split('\n')">{{paragraph}}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!lesson.hasOwnProperty('id')" fxLayout="column" fxLayoutAlign="center"
                    style="gap: 32px;margin-top:32px">
                    <div *ngIf="currentLessonIndex == i-1 && $any(lesson).questions"
                        style="border-radius: 16px;background: #F0F7FC; padding:32px">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="12px"
                            fxLayoutAlign="space-between">

                            <div fxLayout="column" fxLayoutAlign="start start" style="gap: 8px;"
                                fxLayoutAlign.lt-lg="start start">
                                <span class="cosmote-blue"
                                    style="font-size: 32px;font-style: normal;font-weight: 700;line-height: 44px;letter-spacing: -0.64px;"
                                    [ngStyle.lt-md]="{ 'font-size': '24px', 'line-height': '32px', }">Online
                                    Άσκηση</span>
                                <span class="gray-500" style="font-size:18px; line-height:28px;"
                                    [ngStyle.lt-md]="{ 'font-size': '16px', 'line-height': '24px'}"> Η προθεσμία
                                    ολοκλήρωσης της άσκησης λήγει
                                    {{formatDateToDayMonth(selectedLesson.sections.test.expirationDate || null)}}
                                </span>
                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" style="gap: 12px;">
                                <button [ngStyle.lt-lg]="{'width': '300px'}" [ngStyle.lt-sm]="{'height': '50px'}"
                                    [disabled]="buttonDisabled('test')" mat-raised-button color="primary"
                                    (click)="onBeginTest()">Ξεκινήστε την άσκηση</button>
                            </div>
                        </div>

                        <div *ngIf="!selectedLessonConfiguration.test?.result && lessonTestAnswers; else showTestResult"
                            fxLayout="column"
                            style="padding: 64px 32px;border-radius: 16px;background: #F0F7FC;gap: 24px;"
                            fxLayoutAlign="center center">
                            <div class="container" [ngStyle.sm]="{'width': '600px'}"
                                [ngStyle.xs]="{'width': '280px', 'padding': '0px 16px'}"
                                style="padding: 0px 32px; width:700px;gap: 24px;" fxLayout="column"
                                fxLayoutAlign="center">
                                <div fxLayout="column" style="gap: 24px;">
                                    <div fxLayout="row" fxLayoutAlign="space-between">
                                        <span class="cosmote-blue "
                                            style="font-size:18px; line-height:28px; font-weight:700">Ερώτηση
                                            {{selectedLessonConfiguration.test!.currentQuestionIndex + 1}}</span>
                                        <div fxLayout="row" fxLayoutAlign="start center" style="gap: 8px;">
                                            <mat-progress-bar
                                                style="width:200px; border-radius: 4px;background: var(--gray-100, #F2F4F7);"
                                                [ngStyle.lt-md]="{'width': '100px'}" mode="determinate"
                                                [value]="onCalculateProgressPercentageFor('test')"></mat-progress-bar>
                                            <span class="gray-700 f500"
                                                style="font-size:14px; line-height: 20px;">{{onCalculateProgressPercentageFor('test')}}%</span>
                                        </div>
                                    </div>
                                </div>

                                <form style="box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
                        border-radius: 8px; background-color: white;" *ngIf="!questionIsLoading">
                                    <div [formGroup]="lessonTestAnswers.at(selectedLessonConfiguration.test!.currentQuestionIndex)"
                                        style="padding:24px;gap: 24px;" fxLayout="column" fxLayoutAlign="start">
                                        <span class="gray-700"
                                            style="font-size:18px;line-height:28px;white-space:pre-line">
                                            {{lessonTestAnswers.at(selectedLessonConfiguration.test!.currentQuestionIndex).get('questionName')?.value}}</span>
                                        <mat-radio-group color="primary" fxLayout="column" style="gap: 12px;"
                                            formControlName="answerId">
                                            <mat-radio-button
                                                *ngFor="let answer of selectedLesson.sections.test.questions[selectedLessonConfiguration.test!.currentQuestionIndex].answers"
                                                [value]="answer.id"
                                                style="border-radius:8px; border: 1px solid #EAECF0;  padding:8px">
                                                <span class="gray-700"
                                                    style="width:100%; font-weight:500">{{answer.text}} </span>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <mat-divider></mat-divider>
                                    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="padding:24px">
                                        <button mat-icon-button (click)="onPreviousQuestion()">
                                            <mat-icon class="gray-700">arrow_back</mat-icon>
                                        </button>
                                        <span class="gray-700"> {{selectedLessonConfiguration.test!.currentQuestionIndex
                                            + 1}} /
                                            {{selectedLesson.sections.test.questions.length}} </span>
                                        <button mat-icon-button (click)="onNextQuestion()"
                                            [disabled]="lessonTestAnswers.at(selectedLessonConfiguration.test!.currentQuestionIndex).invalid && !questionIsLoading"
                                            class="gray-700">
                                            <mat-icon>arrow_forward</mat-icon>
                                        </button>
                                    </div>
                                </form>
                                <div *ngIf="questionIsLoading"
                                    style="height:400px;display:flex;flex-flow:row; align-items: center; justify-content: center;
                            box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);border-radius: 8px; background-color: white;">
                                    <mat-spinner></mat-spinner>
                                </div>
                            </div>
                            <button style="width:100px;height:50px;" mat-raised-button color="primary"
                                [disabled]="lessonTestAnswers.invalid" (click)="onSubmitTest()">Υποβολή</button>
                        </div>
                    </div>
                </div>
                <ng-template #showTestResult>

                    <div *ngIf="selectedLessonConfiguration.test && selectedLessonConfiguration.test.result == 'success'"
                        style="padding: 64px 32px;gap: 24px;" fxLayout="column" fxLayoutAlign="center center">
                        <div style="padding: 24px 32px; width:350px;background-color: white;" fxLayout="column"
                            fxLayoutAlign="center center" style="gap: 20px;">
                            <img src="assets/green-check.png" style="width:48px; height:48px" alt="">
                            <div fxLayout="column" style="gap: 8px;">
                                <span class="cosmote-blue"
                                    style="font-size:18px; text-align:center; line-height:28px;font-weight:600">Συγχαρητήρια!</span>
                                <span class="gray-500" style="text-align:center"> Έχεις ολοκληρώσει την online άσκηση με
                                    επιτυχία!</span>
                                <span class="gray-500" style="text-align:center" *ngIf="course.exam"> Βεβαιώσου ότι
                                    έχεις ολοκληρώσει και το online τεστ αξιολόγησης με επιτυχία </span>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="testFailedPartially(selectedLessonConfiguration)" style="gap: 24px;padding: 64px 32px;"
                        fxLayout="column" fxLayoutAlign="center center">
                        <div style="gap: 20px;padding: 24px 32px; width:350px;background-color: white;"
                            [ngStyle.lt-md]="{'max-width': '280px'}" fxLayout="column" fxLayoutAlign="center center">
                            <img src="assets/warning.png" alt="" style="width:48px; height:48px">
                            <div fxLayout="column" fxLayoutAlign="start center" style="gap: 8px;">
                                <span class="gray-900 f600" style="font-size:18px;line-height:28px;"> Ουπς!</span>
                                <span class="gray-500 mat-small m0" style="text-align:center">
                                    Δεν απαντήθηκαν τουλάχιστον 6 από τις 10 ερωτήσεις σωστά. Μπορείς να προσπαθήσεις
                                    ξανά μέχρι να ολοκληρώσεις επιτυχώς</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="noMoreAttempsForThisTest(selectedLessonConfiguration)"
                        style="padding: 64px 32px;gap: 24px;" fxLayout="column" fxLayoutAlign="center center">
                        <div style="padding: 0px 32px; width:350px;background-color: white;"
                            [ngStyle.lt-md]="{'max-width': '280px'}" fxLayout="column" fxLayoutAlign="center center"
                            style="gap: 20px;">
                            <img src="assets/failed.png" alt="" style="width:48px; height:48px">
                            <div fxLayout="column" fxLayoutAlign="start center" style="gap: 8px;">
                                <span class="gray-900 f600" style="font-size:18px;line-height:28px;"> Δεν τα
                                    κατάφερες!</span>
                                <span class="gray-500 mat-small m0" style="text-align:center">
                                    Δυστυχώς δεν απάντησες αρκετές ερωτήσεις σωστά για να ολοκληρώσεις το
                                    τεστ.</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>

            <!-- [disabled]="nextLesson" -->
            <mat-tab label="Online Test Αξιολόγησης" [disabled]="!course.exam?.questions && !nextLesson"
                labelClass="courses">

                <div [ngStyle.gt-xs]="{'padding':'32px'}" style="gap: 32px;" fxLayout="column"
                    fxLayoutAlign="center center">

                    <div fxLayout="column" style="border-radius: 16px;background: #F0F7FC; width: 100%">

                        <div style="padding:32px; border-radius: 16px; width:100%; " fxLayout="row"
                            fxLayoutAlign="space-between start" fxLayout.lt-md="column"
                            fxLayoutAlign.lt-md="start center" fxLayoutGap.lt-md="48px">
                            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center"
                                style="gap: 8px;">
                                <span
                                    style="font-weight: 600; font-size: 36px; line-height: 44px; text-align:center; letter-spacing: -0.72px;"
                                    class="cosmote-blue" [ngStyle.xs]="{'font-size': '24px', 'line-height': '32px'}">
                                    Online test αξιολόγησης</span>
                                <span class="gray-500" style="text-align:center"
                                    style="font-size: 18px;line-height: 28px;"
                                    [ngStyle.xs]="{'font-size': '16px', 'line-height': '24px'}">{{textThatShouldDisplayForExam()}}
                                </span>

                            </div>

                            <div fxLayout="column" fxLayoutAlign="start center" style="gap: 12px;">
                                <button mat-raised-button color="primary" style="height:44px; width:244px"
                                    (click)="onBeginExam()" [disabled]="buttonDisabled('exam')">Ξεκινήστε</button>
                                <span
                                    *ngIf="examConfiguration && examConfiguration.stillHasAttempts && examConfiguration.stillHasAttempts > 0 "
                                    class="mat-small cosmote-blue"> Προσπάθεια {{examConfiguration.maxAttempts -
                                    examConfiguration.stillHasAttempts + 1}} απο {{examConfiguration.maxAttempts}}
                                </span>
                            </div>
                        </div>

                        <div *ngIf="examConfiguration && !examConfiguration.result && examAnswers; else showExamResult"
                            style="padding: 64px 32px;gap: 24px;" [ngStyle.lt-md]="{'padding': '24px 12px'}"
                            fxLayout="column" fxLayoutAlign="center center">
                            <div class="container" style="width:640px;gap: 24px;" fxLayout="column"
                                fxLayoutAlign="center" [ngStyle.sm]="{'width': '500px'}"
                                [ngStyle.xs]="{'width': '280px'}">
                                <div fxLayout="column" style="gap: 24px;">
                                    <div fxLayout="row" fxLayoutAlign="space-between">
                                        <span class="gray-900"
                                            style="font-size:18px;font-weight:500;line-height:28px">Ερώτηση
                                            {{examConfiguration.currentQuestionIndex + 1}}</span>
                                        <div fxLayout="row" fxLayoutAlign="start center" style="gap: 8px;">
                                            <mat-progress-bar style="width:200px" [ngStyle.lt-md]="{'width': '100px'}"
                                                mode="determinate"
                                                [value]="onCalculateProgressPercentageFor('exam')"></mat-progress-bar>
                                            <span class="gray-700"
                                                style="font-size:14px">{{onCalculateProgressPercentageFor('exam')}}%</span>
                                        </div>
                                    </div>
                                </div>

                                <form style="box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
                            border-radius: 8px; background-color:white" *ngIf="!questionIsLoading">
                                    <div [formGroup]="examAnswers.at(examConfiguration.currentQuestionIndex)"
                                        style="gap: 24px;padding:24px" fxLayout="column" fxLayoutAlign="start">
                                        <span class="gray-700"
                                            style="font-size:18px;line-height:28px;white-space:pre-line">{{examAnswers.at(examConfiguration.currentQuestionIndex).get('questionName')?.value}}</span>
                                        <mat-radio-group color="primary" fxLayout="column" style="gap: 12px;"
                                            formControlName="answerId">
                                            <mat-radio-button class="example-radio-button"
                                                *ngFor="let answer of course.exam?.questions?.[examConfiguration.currentQuestionIndex]?.answers"
                                                [value]="answer.id"
                                                style="border-radius:8px; border: 1px solid #EAECF0; width:100%">
                                                <span style="width:100%" class="gray-700 f500">{{answer.text}} </span>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <mat-divider></mat-divider>
                                    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="padding:24px">
                                        <button mat-icon-button (click)="onPreviousQuestion('exam')">
                                            <mat-icon>arrow_back</mat-icon>
                                        </button>
                                        <span> {{examConfiguration.currentQuestionIndex + 1}} /
                                            {{course.exam?.questions?.length}} </span>
                                        <button mat-icon-button (click)="onNextQuestion('exam')"
                                            [disabled]="examAnswers.at(examConfiguration.currentQuestionIndex).invalid">
                                            <mat-icon>arrow_forward</mat-icon>
                                        </button>
                                    </div>
                                </form>
                                <div *ngIf="questionIsLoading"
                                    style="height:400px;display:flex;flex-flow:row; align-items: center; justify-content: center;
                                box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);border-radius: 8px; background-color: white;">
                                    <mat-spinner></mat-spinner>
                                </div>
                            </div>
                            <button style="width:100px;height:50px" mat-raised-button color="primary"
                                [disabled]="examAnswers.invalid" (click)="onSubmitExam()">Υποβολή</button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <!-- [disabled]="nextLesson" -->
            <mat-tab label="H γνώμη σου μετράει" [disabled]="!course.opinionCountsLink && !nextLesson">
                <div [ngStyle.gt-xs]="{'padding':'32px'}" style="gap: 32px;" fxLayout="column"
                    fxLayoutAlign="center center">

                    <div fxLayout="column" style="padding:32px;border-radius: 16px;background: #F0F7FC; width: 100%">
                        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayout.lt-md="column"
                            fxLayoutAlign.lt-md="start center" fxLayoutGap.lt-md="48px">
                            <div fxLayout="column" style="gap: 8px;" fxLayoutAlign.lt-md="start center">
                                <div class="cosmote-blue"
                                    style="font-size: 32px;font-style: normal;font-weight: 700;line-height: 44px;letter-spacing: -0.64px;">
                                    Η γνώμη σου μετράει</div>
                                <div class="gray-500"
                                    style="font-size: 18px;font-style: normal;font-weight: 400;line-height: 28px;">
                                    Μοιράσου μαζί μας τη γνώμη σου για την ενότητα και το πρόγραμμα</div>
                            </div>
                            <button mat-raised-button color="primary" style="height:44px; width:244px"
                                (click)="onOpenLink(course.opinionCountsLink)">Ξεκινήστε το ερωτηματολόγιο</button>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>

</div>


<ng-template #emptyContentForAllPages>
    <div *ngIf="!selectedLesson?.sections?.test && !videosSectionExist(selectedLesson) && !otherResourcesSectionExist(selectedLesson)"
        style="padding:56px 32px; border-radius: 16px;background: var(--gray-100, #F2F4F7);gap: 20px;" fxLayout="column"
        fxLayoutAlign="center center">
        <div fxLayout="column" style="border-radius: 200px; padding:32px;">
            <img src="/assets/empty-lesson.png" style="width:172px; height:128px" alt="">
        </div>
        <div fxLayout="column" fxLayoutAlign="start center" style="gap: 8px;">
            <span class="cosmote-blue" style="font-size:18px; line-height:24px">Οι παρουσιάσεις των διαλέξεων θα είναι
                διαθέσιμες μετά την έναρξη του μαθήματος
            </span>

        </div>
    </div>
</ng-template>


<ng-template #showExamResult>
    <div *ngIf="examConfiguration && examConfiguration.result == 'success'" style="padding: 64px 32px;gap: 24px;"
        fxLayout="column" fxLayoutAlign="center center">
        <div style="padding: 24px; width:350px; background-color: white;  border-radius: 8px;gap: 20px;"
            fxLayout="column" fxLayoutAlign="center center" [ngStyle.lt-md]="{'padding': '0px 12px'}">
            <img src="assets/green-check.png" alt="" style="width:48px; height:48px">
            <div fxLayout="column" fxLayoutAlign="start center" style="gap: 8px;">
                <span class="cosmote-blue"
                    style="font-size:18px; line-height:28px; font-weight:600">Συγχαρητήρια!</span>
                <span class="gray-500" style="text-align:center"> Έχεις ολοκληρώσει το online test αξιολόγησης με
                    επιτυχία!</span>
                <span class="gray-500" style="text-align:center" *ngIf="courseHasTest()"> Βεβαιώσου ότι έχεις
                    ολοκληρώσει και την online άσκηση με επιτυχία</span>
            </div>
        </div>
    </div>

    <div *ngIf="examFailedPartially(examConfiguration)" style="padding: 64px 32px;gap: 24px;" fxLayout="column"
        fxLayoutAlign="center center">
        <div style="padding: 24px; width:350px; background-color: white; border-radius: 8px;gap: 20px;"
            [ngStyle.lt-md]="{'max-width': '300px'}" fxLayout="column" fxLayoutAlign="center center">
            <img src="assets/warning.png" alt="" style="width:48px; height:48px">
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoytGap="8px">
                <span class="gray-900 f600" style="font-size:18px;line-height:28px;"> Ουπς! </span>
                <span class="gray-500 mat-small m0" style="text-align:center"
                    *ngIf="examConfiguration.stillHasAttempts && examConfiguration.stillHasAttempts > 0; else unlimitedExamAttempts ">
                    Έχεις ακόμα <b>{{examConfiguration.stillHasAttempts}}</b> προσπάθειες και είμαστε σίγουροι πως
                    μπορείς να τα πας καλύτερα!</span>
                <ng-template #unlimitedExamAttempts>
                    <span class="gray-500 mat-small m0" style="text-align:center">Δεν απαντήθηκαν τουλάχιστον 6 από τις
                        10 ερωτήσεις σωστά. Μπορείς να προσπαθήσεις ξανά μέχρι να ολοκληρώσεις επιτυχώς</span>
                </ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="noMoreAttemptsForExam(examConfiguration);" style="padding: 64px 32px; border-radius: 8px;gap: 24px;"
        fxLayout="column" fxLayoutAlign="center center">
        <div style="gap: 20px;padding: 24px; width:350px" fxLayout="column" fxLayoutAlign="center center">
            <img src="assets/failed.png" style="width:48px; height:48px" alt="">
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoytGap="8px">
                <span class="gray-900 f600" style="font-size:18px; line-height:28px">Δεν τα κατάφερες!</span>
                <span class="gray-500 mat-small m0" style="text-align:center"> Δυστυχώς δεν απάντησες αρκετές ερωτήσεις
                    σωστά για να περάσεις το μάθημα.</span>
            </div>
        </div>
    </div>

</ng-template>