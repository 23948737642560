import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {MatGridListModule} from '@angular/material/grid-list';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatSidenavModule } from "@angular/material/sidenav";
import {MatPaginatorModule} from '@angular/material/paginator';




@NgModule({
    imports: [
        FlexLayoutModule,
		MatButtonModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatInputModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatIconModule,
		MatDividerModule,
		MatCardModule,
		MatSlideToggleModule,
		FormsModule,
		MatTabsModule,
		MatExpansionModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatMenuModule,
		MatListModule,
		MatDialogModule,
		MatProgressBarModule,
		MatTabsModule,
		MatGridListModule,
		MatRadioModule,
		MatSelectModule,
		MatTooltipModule,
		MatBadgeModule,
		MatAutocompleteModule,
		MatSnackBarModule,
		MatSidenavModule,
		MatPaginatorModule
    ],
    exports: [
        FlexLayoutModule,
		MatButtonModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatInputModule,
		MatNativeDateModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatIconModule,
		MatDividerModule,
		MatCardModule,
		MatSlideToggleModule,
		FormsModule,
		MatTabsModule,
		MatExpansionModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatMenuModule,
		MatListModule,
		MatDialogModule,
		MatProgressBarModule,
		MatTabsModule,
		MatGridListModule,
		MatRadioModule,
		MatSelectModule,
		MatTooltipModule,
		MatBadgeModule,
		MatAutocompleteModule,
		MatSnackBarModule,
		MatSidenavModule,
		MatPaginatorModule
    ]
})
export class AngularMaterialModule {}
