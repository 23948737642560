import { Injectable } from "@angular/core";

declare var gtag: Function;
declare let fbq: Function;

@Injectable({
    providedIn: 'root',
})
export class GrowYourBusinessAnalyticsService {

    sendEvent(eventName: string, eventParams: any) {
        gtag('event', eventName, eventParams);
    }

    analyticsAreLoaded() { 
        return typeof gtag !== 'undefined';
    }

    facebookTrackCustomEvent(eventName: string) {
        if(typeof fbq !== 'undefined') {
            fbq('trackCustom', eventName);
        }
    }
}