<div fxlayout="row" fxLayoutAlign="center center">
    <div style="width:960px" [ngStyle.md]="{'width': '700px'}">

        <h2 style="font-size:36px; line-height:50px">Δήλωση Ιδιωτικότητας Προγράμματος “COSMOTE GROW YOUR BUSINESS - Certification Program”</h2>

        <div style="font-size:28px; font-weight:700">Εισαγωγικό Σημείωμα</div> <br>

        <div>Στο πλαίσιο της συμμετοχής σας στο <span style="font-weight:700" > Εκπαιδευτικό Πρόγραμμα “COSMOTE GROW YOUR BUSINESS - Certification Program” </span> που συνδιοργανώνεται από την  <span style="font-weight:700" > EQUALITY Αστική Μη Κερδοσκοπική Εταιρία και την COSMOTE ΚΙΝΗΤΕΣ ΤΗΛΕΠΙΚΟΙΝΩΝΙΕΣ ΜΟΝΟΠΡΟΣΩΠΗ Α.Ε </span> θα χρειαστεί να συλλέξουμε και  επεξεργαστούμε μια σειρά από στοιχεία και πληροφορίες για εσάς, συμπεριλαμβανομένων και προσωπικών σας δεδομένων. Κάποια από αυτά τα δεδομένα συλλέγονται αυτοματοποιημένα μέσω cookies, ενώ άλλα μας τα παραχωρείτε εσείς και ο υπεύθυνος/νόμιμος εκπρόσωπος της εταιρίας στην οποία εργάζεστε συμπληρώνοντας ηλεκτρονικά τις Αιτήσεις Συμμετοχής του Προγράμματος. Σε κάθε περίπτωση, τα προσωπικά δεδομένα σας που συλλέγονται και επεξεργάζονται περιορίζονται στα απολύτως αναγκαία, και η επεξεργασία τους γίνεται αποκλειστικά στο πλαίσιο του σκοπού για τον οποίο γίνεται η συλλογή τους, σύμφωνα τις διατάξεις του Γενικού Κανονισμού Προστασίας Δεδομένων και την κείμενη νομοθεσία</div>
        <br>
        <div>Σχετικά με τα δεδομένα που συλλέγονται αυτοματοποιημένα, και για πληροφορίες που αφορούν τα cookies, παρακαλούμε ανατρέξτε στην Ενημέρωση για τα cookies. Για τα δεδομένα που συλλέγονται άμεσα από εσάς (και τον υπεύθυνο/νόμιμο εκπρόσωπο της εταιρίας) θα ενημερωθείτε μέσω του παρόντος. Συγκεκριμένα, παρακάτω θα βρείτε μια επισκόπηση των σκοπών και τρόπων με τους οποίους συλλέγουμε και επεξεργαζόμαστε τα προσωπικά δεδομένα σας, του χρόνου για τον οποίο τα διατηρούμε και πώς τα προστατεύουμε. Θα ενημερωθείτε επίσης για τα δικαιώματά σας και τον τρόπο με τον οποίο μπορείτε να επικοινωνήσετε μαζί μας</div>
        <br>
        <div>Θεωρούμε την ασφάλεια και την προστασία των προσωπικών σας δεδομένων σας πολύ σημαντική υπόθεση, γι΄ αυτό σας καλούμε να αφιερώσετε λίγο χρόνο στην παρούσα Δήλωση Ιδιωτικότητας. </div>
        <br>

        <div style="font-size:28px; font-weight:700">1. Ποιοι είμαστε</div> <br>
        <div>Η παρούσα Δήλωση Ιδιωτικότητας (εφεξής «η Δήλωση)  καλύπτει τα δεδομένα που συλλέγουν και επεξεργάζονται στο πλαίσιο του Εκπαιδευτικού Προγράμματος <span style="font-weight:700" >  “COSMOTE GROW YOUR BUSINESS - Certification Program” </span> (εφεξής το «Πρόγραμμα») ως  <span style="font-weight:700" > από κοινού Υπεύθυνοι Επεξεργασίας </span> (εφεξής «οι από κοινού Υπεύθυνοι») αφενός </div>
        <br>α) o «ΟΡΓΑΝΙΣΜΟΣ ΤΗΛΕΠΙΚΟΙΝΩΝΙΩΝ ΤΗΣ ΕΛΛΑΔΟΣ Α.Ε.» με τον δ.τ. «COSMOTE», (ΑΦΜ 094019245, .Δ.Ο.Υ. ΦΑΕ Αθηνών, αριθ. Γ.Ε.Μ.Η. 1037501000) που εδρεύει στο Μαρούσι Λ.Κηφισίας αρ. 99,
        και αφετέρου
        <div>β) η EQUALITY Αστική Μη Κερδοσκοπική Εταιρία (ΑΦΜ 996787689, Γ.Ε.ΜΗ 2197940), που εδρεύει στην Αθήνα, Ιπποκράτους 2, 10679.</div>
        <br>
        <div>Για τους σκοπούς της παρούσας Δήλωσης οι από κοινού Υπεύθυνοι ενδέχεται να αναφερθούν και ως «Συνδιοργανωτές», δεδομένου ότι διοργανώνουν από κοινού το Πρόγραμμα. </div>
        <br>

        <div style="font-size:28px; font-weight:700">2. Τι δεδομένα συλλέγουμε, πώς και για ποιο σκοπό τα επεξεργαζόμαστε:</div> <br>
        <div>Στο πλαίσιο και για το σκοπό της διεξαγωγής του Προγράμματος είναι απαραίτητο να συλλέξουμε και να επεξεργαστούμε μια σειρά από προσωπικά σας δεδομένα.  Τα δεδομένα αυτά μας τα παραχωρείτε εσείς μέσω της <span style="font-weight:700" >  Αίτησης Συμμετοχής </span>, που θα βρείτε στην ιστοσελίδα <a href="https://www.equality.org.gr/growyourbusiness">https://www.equality.org.gr/growyourbusiness.</a> </div>
        <br>
        <div>Η συλλογή και η επεξεργασία τους γίνεται με βάση τη δική σας  <span style="font-weight:700" > συγκατάθεση </span>, η οποία ζητείται <span style="font-weight:700" > γραπτώς, πριν τη συλλογή </span> οποιουδήποτε προσωπικού σας δεδομένου, με τρόπο που σας εξασφαλίζει σαφώς τη δυνατότητα επιλογής (<span style="font-weight:700" > opt-in </span>). Επιπλέον, των σκοπών αυτών, ενδεχομένως να επεξεργαστούμε τα προσωπικά σας δεδομένα για σκοπούς <span style="font-weight:700" > συμμόρφωσής μας με τη νομοθεσία και άσκησης των νόμιμων δικαιωμάτων μας</span>, όταν αυτά δεν παραγράπτονται από το δικαίωμά σας στην ιδιωτικότητα και την προστασία των προσωπικών σας δεδομένων. </div>
        <br>
        <div>Εκτός από τα προσωπικά δεδομένα σας η συλλογή και επεξεργασία των οποίων είναι απαραίτητη για τη διεξαγωγή του Προγράμματος, <span style="font-weight:700" > μπορείτε να μας δώσετε τη συγκατάθεσή σας για τη συλλογή και επεξεργασία των δεδομένων σας και για άλλους, επιπλέον σκοπούς </span> (βλ. 2.4 «Επιπλέον σκοποί συλλογής και επεξεργασίας»). </div>
        <br>
        <div>Τα δεδομένα που συλλέγουμε καθώς και ο τρόπος επεξεργασίας τους εξαρτάται κάθε φορά από το αν και πώς συμμετέχετε στο Πρόγραμμα.  Συγκεκριμένα:

            <ol style="list-style: none;">
                <li> 
                    <span  style="font-size:24px; font-weight:700">2.1.Αν είστε ο Νόμιμος Εκπρόσωπος/Υπεύθυνος Εταιρίας και ΔΕ συμμετέχετε στο Πρόγραμμα</span>
                    <br><br>
                    <ul>
                        <li><span style="font-weight:700" > το ονοματεπώνυμό σας </span> για σκοπούς <span style="font-weight:700" > ταυτοποίησης </span>,</li>
                        <li><span style="font-weight:700" > τα στοιχεία επικοινωνίας σας</span> (διεύθυνση e-mail και αριθμό κινητού τηλεφώνου) για σκοπούς <span style="font-weight:700" > επικοινωνίας</span> μαζί σας στο πλαίσιο του Προγράμματος. Συγκεκριμένα, για να σας στέλνουμε <span style="font-weight:700" > e-mail</span> και <span style="font-weight:700" > γραπτά μηνύματα</span> με νέα και ενημερώσεις για το Πρόγραμμα (π.χ. έναρξη διαλέξεων, καταληκτική ημερομηνία για τις ασκήσεις κτλ.), καθώς και για να <span style="font-weight:700" > ενεργοποιήσετε το Λογαριασμό Διαχείρισης Συμμετοχής </span> στην Πλατφόρμα e-Learning του Προγράμματος,   </li>
                        <li><span style="font-weight:700" > στοιχεία επικοινωνίας</span> (διεύθυνση e-mail) των υποψήφιων συμμετεχόντων που επιθυμείτε να εγγράψετε στο Εκπαιδευτικό Πρόγραμμα για να τους αποστείλουμε το σχετικό σύνδεσμο (link) ώστε να συμπληρώσουν τα στοιχεία τους στην Αίτηση Συμμετοχής</li>
                        <li><span style="font-weight:700" > ονοματεπώνυμο σας</span> για σκοπούς <span style="font-weight:700" > συμμόρφωσής μας με τη νομοθεσία</span> και <span style="font-weight:700" > άσκησης των νόμιμων δικαιωμάτων μας</span>, όταν αυτά δεν παραγράπτονται από το δικαίωμά σας στην ιδιωτικότητα και την προστασία των προσωπικών σας δεδομένων.</li>
                    </ul>
                    <br>

                    <span  style="font-size:20px; font-weight:700"> Σημαντικές σημειώσεις </span> <br> <br>
                    <ol>
                        <li>Πριν συμπληρώσετε τα στοιχεία των υπαλλήλων της εταιρίας που θα συμμετέχουν, θα πρέπει να τους έχετε ενημερώσει και να έχετε λάβει τη ρητή συγκατάθεσή τους.  Όταν υποβάλετε την Αίτηση Συμμετοχής θεωρούμε δεδομένο ότι έχετε ζητήσει και λάβει τη συγκατάθεση των προσώπων τα στοιχεία των οποίων συμπληρώνετε. </li>
                        <li>Η Επωνυμία και το ΑΦΜ της εταιρίας σας δεν αποτελούν προσωπικά δεδομένα.</li>
                    </ol>
                </li>
                <br>
                <li>
                    <span  style="font-size:24px; font-weight:700">2.2.Αν είστε Νόμιμος Εκπρόσωπος/Υπεύθυνος Εταιρίας και συμμετέχετε στο Πρόγραμμα</span>
                    <br><br>
                    <ul>
                        <li><span style="font-weight:700" > το ονοματεπώνυμό σας</span> και το <span style="font-weight:700" > πατρώνυμό σας</span> για σκοπούς <span style="font-weight:700" > ταυτοποίησης</span> και για την <span style="font-weight:700" > έκδοση των πιστοποιητικών </span> που απονέμονται μετά την επιτυχή ολοκλήρωση των μαθημάτων</li>
                        <li><span style="font-weight:700" > τα στοιχεία επικοινωνίας σας </span>(διεύθυνση e-mail και αριθμό κινητού τηλεφώνου) για σκοπούς <span style="font-weight:700" > επικοινωνίας</span> μαζί σας στο πλαίσιο του Προγράμματος. Συγκεκριμένα, για να σας στέλνουμε <span style="font-weight:700" > e-mail</span> και <span style="font-weight:700" > γραπτά μηνύματα </span> με νέα και ενημερώσεις για το Πρόγραμμα (π.χ. έναρξη διαλέξεων, καταληκτική ημερομηνία για τις ασκήσεις κτλ.), καθώς και για να <span style="font-weight:700" > ενεργοποιήσετε το Λογαριασμό Διαχείρισης Συμμετοχής </span> στην Πλατφόρμα e-Learning του Προγράμματος, </li>
                        <li><span style="font-weight:700" > ονοματεπώνυμο σας </span> για σκοπούς <span style="font-weight:700" > συμμόρφωσής μας με τη νομοθεσία </span> και <span style="font-weight:700" > άσκησης των νόμιμων δικαιωμάτων μας </span>, όταν αυτά δεν παραγράπτονται από το δικαίωμά σας στην ιδιωτικότητα και την προστασία των προσωπικών σας δεδομένων,</li>
                    </ul>
                    <br>
                    <span  style="font-size:20px; font-weight:700"> Σημαντικές σημειώσεις </span> <br> <br>
                    <ol>
                        <li>Πριν συμπληρώσετε τα στοιχεία των υπαλλήλων της εταιρίας που θα συμμετέχουν, θα πρέπει να τους έχετε ενημερώσει και να έχετε λάβει τη ρητή συγκατάθεσή τους.  Όταν υποβάλετε την Αίτηση Συμμετοχής θεωρούμε δεδομένο ότι έχετε ζητήσει και λάβει τη συγκατάθεση των προσώπων τα στοιχεία των οποίων συμπληρώνετε.  </li>
                        <li>Η Επωνυμία και το ΑΦΜ της εταιρίας σας δεν αποτελούν προσωπικά δεδομένα.</li>
                    </ol>

                </li>
                <br>
                <li>
                    <span  style="font-size:24px; font-weight:700">2.3.Αν είστε Συμμετέχουσα/ων</span>
                    <br><br>
                    <div>Αν συμπληρώσει για σας <span style="font-weight:700" > Αίτηση Συμμετοχής</span> ο Υπεύθυνος/ Νόμιμος Εκπρόσωπος της εταιρίας που εργάζεστε για να συμμετέχετε στο Πρόγραμμα, συλλέγουμε και επεξεργαζόμαστε <span style="font-weight:700" > μέσω της Αίτησης Συμμετοχής </span> τα <span style="font-weight:700" > στοιχεία επικοινωνίας σας</span> (e-mail) για να σας αποστείλουμε το σύνδεσμο (link) ώστε να συμπληρώσετε τα υπόλοιπα στοιχεία της Αίτηση και να ολοκληρωθεί η εγγραφή σας στο Πρόγραμμα.</div>
                    <br>
                    <div><span style="font-weight:700" > Μέσω της Αίτησης </span> που σας αποστέλλεται και συμπληρώνεται από εσάς συλλέγουμε και επεξεργαζόμαστε:  </div>
                    <ul>
                        <li><span style="font-weight:700" > το ονοματεπώνυμό σας</span> και το  <span style="font-weight:700" > πατρώνυμό σας </span> για σκοπούς <span style="font-weight:700" > ταυτοποίησης </span> και την έκδοση των <span style="font-weight:700" > πιστοποιητικών </span> που απονέμονται μετά την επιτυχή ολοκλήρωση των μαθημάτων</li>
                        <li><span style="font-weight:700" > τα στοιχεία επικοινωνίας σας </span> (διεύθυνση e-mail και αριθμό κινητού τηλεφώνου) για σκοπούς <span style="font-weight:700" > επικοινωνίας</span> μαζί σας στο πλαίσιο του Προγράμματος, για να σας στέλνουμε <span style="font-weight:700" > e-mail</span> και <span style="font-weight:700" > γραπτά μηνύματα</span> με νέα και ενημερώσεις για το Πρόγραμμα (π.χ. έναρξη διαλέξεων, καταληκτική ημερομηνία για τις ασκήσεις κτλ.), </li>
                        <li><span style="font-weight:700" > ονοματεπώνυμο και τα στοιχεία επικοινωνίας σας </span> (διεύθυνση e-mail) για τη δημιουργία και ενεργοποίηση του προσωπικού σας <span style="font-weight:700" > προφίλ στην Πλατφόρμα e-Learning του Προγράμματος </span>, για να παρακολουθείτε την εξέλιξη του Προγράμματος, να λύνετε ασκήσεις, και να παρακολουθείτε το σύνολο της ασύγχρονης διδασκαλίας των σεμιναρίων που έχετε επιλέξει, </li>
                        <li><span style="font-weight:700" > ονοματεπώνυμο σας </span> για σκοπούς <span style="font-weight:700" >  συμμόρφωσής μας με τη νομοθεσία </span> και <span style="font-weight:700" > άσκησης των νόμιμων δικαιωμάτων μας </span>, όταν αυτά δεν παραγράπτονται από το δικαίωμά σας στην ιδιωτικότητα και την προστασία των προσωπικών σας δεδομένων.</li>
                    </ul>
                    <br>
                </li>
                <br>
                <li>
                    <span  style="font-size:24px; font-weight:700">2.4.Επιπλέον σκοποί συλλογής και επεξεργασίας</span>
                    <br><br>
                    <div>Σε όλες τις ως άνω περιπτώσεις (2.1, 2.2, 2.3) μπορείτε επίσης να <span style="font-weight:700" > παραχωρήσετε τη συγκατάθεσή σας </span>  στον  <span style="font-weight:700" > OTE και την COSMOTE </span> για να συλλέξουν και να επεξεργάζονται το <span style="font-weight:700" > ονοματεπώνυμο και τα στοιχεία επικοινωνίας σας </span> (διεύθυνση e-mail και αριθμό κινητού τηλεφώνου) για να επικοινωνούν μαζί σας για <span style="font-weight:700" > προϊόντα και υπηρεσίες του ΟΤΕ και της Cosmote. </span></div>
                    <br>
                </li>
            </ol>

        </div>
        <br>

        <div style="font-size:28px; font-weight:700">3. Ποιοι έχουν πρόσβαση στα Προσωπικά Δεδομένα σας και γιατί;</div> <br>
        <div>
            <span style="font-weight:700"> 3.1.</span> Κατ΄αρχήν πρόσβαση στο σύνολο των δεδομένων των Υπευθύνων/Νόμιμων Εκπροσώπων Εταιριών και των Συμμετεχουσών/όντων έχουν και οι δύο από κοινού Υπεύθυνοι (Συνδιοργανωτές). <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 3.2.</span> Επιπλέον, πρόσβαση στο ονοματεπώνυμο και τα στοιχεία επικοινωνίας έχουν οι διδάσκοντες στο Πρόγραμμα για σκοπούς που σχετίζονται με τη διδασκαλία, την αξιολόγηση και την επικοινωνία με τις/τους Συμμετέχουσες/οντες. <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 3.3.</span> Επίσης, πρόσβαση στα στοιχεία σας (ονοματεπώνυμο, πατρώνυμο, e-mail) θα έχει και το Κέντρο Επιμόρφωσης και Δια Βίου Μάθησης (ΚΕ.ΔΙ.ΒΙ.Μ.) του Οικονομικού Πανεπιστημίου Αθηνών για την έκδοση των πιστοποιητικών παρακολούθησης και επιτυχούς ολοκλήρωσης του Προγράμματος.<br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 3.4.</span> Τέλος, αν δώσετε τη συγκατάθεσή σας προς αυτό (βλ.2.4.), πρόσβαση στα προσωπικά σας δεδομένα (ονοματεπώνυμο, e-mail, και κινητό τηλέφωνο) θα έχει επίσης η <span style="font-weight:700" >  Bold Ogilvy A.E. Επικοινωνίας </span>, η οποία εδρεύει στο <span style="font-weight:700" >  Ημαθίας 10Α – Γέρακας ΤΚ 15344 </span>, και διαχειρίζεται τον ιστότοπο <a href="https://www.growyourbusiness.gr"> www.growyourbusiness.gr </a> για την επικοινωνία της δράσης COSMOTE GROW YOUR BUSINESS.
        </div>
        <br>
        <div style="font-size:24px; font-weight:700">Σημαντική Σημείωση:</div> <br>
        <div>Σε όλες τις ως άνω περιπτώσεις, ενδέχεται επιπλέον να κοινοποιήσουμε τα προσωπικά σας δεδομένα σε αρμόδιες <span style="font-weight:700" >δημόσιες υπηρεσίες και αρχές </span>, εάν υποχρεούμαστε <span style="font-weight:700" >βάσει νόμου</span> ή κατά την <span style="font-weight:700" >άσκηση των νόμιμων δικαιωμάτων μας</span>, όταν αυτά δεν παραγράπτονται από το δικαίωμά σας στην ιδιωτικότητα και την προστασία των προσωπικών σας δεδομένων</div>
        <br>


        <div style="font-size:28px; font-weight:700">4. Για πόσο διάστημα διατηρούμε τα δεδομένα σας;</div> <br>
        <div>
            Το χρονικό διάστημα κατά το οποίο τα προσωπικά δεδομένα σας υποβάλλονται σε επεξεργασία και διατηρούνται εξαρτάται κάθε φορά από το σκοπό επεξεργασίας τους, τις εκ του νόμου υποχρεώσεις μας, και τα χρονικά περιθώρια που ορίζει η ισχύουσα νομοθεσία για λόγους απόδειξης και άσκησης των έννομων δικαιωμάτων μας <br><br>
            Συγκεκριμένα:
            <ol>
                <li>Τα στοιχεία του <span style="font-weight:700" > Υπεύθυνου/ Νόμιμου Εκπροσώπου</span> της Εταιρίας διατηρούνται  <span style="font-weight:700" > για όλη τη διάρκεια του Εκπαιδευτικού Προγράμματος και δύο (2) έτη επιπλέον </span>, για λόγους απόδειξης. </li>
                <li>Τα στοιχεία των <span style="font-weight:700" > Συμμετεχόντων</span> (συμπεριλαμβανομένων και των υπεύθυνων/νόμιμων εκπροσώπων, αν συμμετέχουν) διατηρούνται <span style="font-weight:700" >  για  όλη τη διάρκεια του Εκπαιδευτικού Προγράμματος και πέντε (5) έτη επιπλέον </span>, για λόγους απόδειξης και επανέκδοσης πιστοποιητικών.</li>
                <li>Η ομάδα της EQUALITY που χειρίζεται την Πλατφόρμα e-Learning διατηρεί και επεξεργάζεται το <span style="font-weight:700" > ονοματεπώνυμο</span> και το <span style="font-weight:700" > e-mail για όλη τη διάρκεια του Εκπαιδευτικού Προγράμματος και ένα (1) έτος επιπλέον</span>, για την περίπτωση που χρειαστείτε πρόσβαση στο προφίλ σας και μετά τη λήξη του Προγράμματος.</li>
                <li>Η <span style="font-weight:700" > COSMOTE</span>  και η <span style="font-weight:700" > Bold Ogilvy</span> διατηρεί και επεξεργάζεται τα δεδομένα σας για <span style="font-weight:700" > λόγους ενημέρωσης σχετικά με το πρόγραμμα</span> COSMOTE GROW YOUR BUSINESS  για όλη τη για <span style="font-weight:700" > όλη τη διάρκεια του Εκπαιδευτικού Προγράμματος και ένα (1) έτος επιπλέον. </span></li>
                <li>Αν επιλέξετε να δώσετε τη συγκατάθεσή σας <span style="font-weight:700" > για να επικοινωνεί μαζί σας η COSMOTE για να ενημερώνεστε για επιπλέον προϊόντα και υπηρεσίες πέραν του </span> COSMOTE GROW YOUR BUSINESS  μπορείτε να ενημερωθείτε για τους χρόνους διατήρησης επισκεπτόμενοι την Πολιτική Προστασίας της Cosmote που ια βρείτε εδώ <a href="https://www.cosmote.gr/cs/cosmote/gr/data_retention.html"> https://www.cosmote.gr/cs/cosmote/gr/data_retention.html</a>.</li>
                <li>Αν κάποια/ος Συμμετέχουσα/ων <span style="font-weight:700" > δε συμπληρώσει τα στοιχεία της/του στη Αίτηση Συμμετοχής μετά την αποστολή του συνδέσμου</span>, και αφού παρέλθει το χρονικό περιθώριο που τάσσουν οι Όροι και Προϋποθέσεις Συμμετοχής, το σύνολο των στοιχείων που έχουν συμπληρωθεί στην Αίτηση από τον Νόμιμο Εκπροσώπο/Υπεύθυνο της Εταιρίας <span style="font-weight:700" >  διαγράφονται οριστικά και με ασφαλή τρόπο σε διάστημα τριάντα (30) ημερών μετά την παρέλευση αυτού του χρονικού πλαισίου.</span></li>

            </ol>
        </div>

        <div style="font-size:28px; font-weight:700">5. Αποθήκευση και μέτρα προστασίας</div> <br>
        <div>Το σύνολο των προσωπικών δεδομένων που συλλέγουμε για όσο χρονικό διάστημα τα επεξεργαζόμαστε και τα διατηρούμε βρίσκονται αποθηκευμένα σε ασφαλείς και κρυπτογραφημένους servers στην Ευρώπη.</div>
        <br>
        <div>Έχουμε λάβει όλα τα κατάλληλα και απαραίτητα <span style="font-weight:700" > τεχνικά και οργανωτικά μέτρα ώστε τα δεδομένα σας να είναι ασφαλή </span> για όλο το διάστημα που παραμένουν στη βάση δεδομένων μας, και να προστατεύονται από υποκλοπή, μη εξουσιοδοτημένη ή κακόβουλη πρόσβαση, αλλοίωση, διαρροή ή κάθε άλλη παρόμοια κακόβουλη ή/και παράνομη ενέργεια. Έχουμε θεσπίσει <span style="font-weight:700" >  πολιτικές και διαδικασίες για την προστασία προσωπικών δεδομένων</span>, ενώ όλα τα μέλη μας γνωρίζουν την υποχρέωσή τους να προστατεύουν τα προσωπικά δεδομένα, έχουν λάβει σχετική εκπαίδευση, και δεσμεύονται από ρήτρες που σχετίζονται με την προστασία προσωπικών δεδομένων. Οι συνεργάτες μας που επεξεργάζονται προσωπικά δεδομένα στο πλαίσιο της υλοποίησης του Προγράμματος (διδάσκοντες, ΚΕ.ΔΙ.ΒΙ.Μ, κτλ.) δεσμεύονται να σέβονται και να προστατεύουν την ιδιωτικότητά σας και τα προσωπικά σας δεδομένα, και να επεξεργάζονται τα δεδομένα σας αποκλειστικά με βάση τις οδηγίες μας, και στο πλαίσιο του σκοπού για τον οποίο μας τα παραχωρήσατε.   </div>
        <br>
        <div>Επιπλέον, εφαρμόζουμε μέτρα ψευδωνυμοποίησης στο βαθμό που αυτό είναι εφικτό.</div>
        <br>
        <div> Αν επιλέξετε να παρέχετε επιπλέον στην COSMOTE τη συγκατάθεσή σας <span style="font-weight:700" > για να ενημερώνεστε για επιπλέον προϊόντα και υπηρεσίες πέραν του </span> COSMOTE GROW YOUR BUSINESS , μπορείτε να μάθετε για τους τρόπους προστασίας των δεδομένων στο σύνδεσμο https://www.cosmote.gr/cs/cosmote/gr/dataprivacypolicy.html</div>
        <br>

        <div style="font-size:28px; font-weight:700">6. Τα δικαιώματά σας στα προσωπικά σας δεδομένα </div> <br>
        <div>
            Ο νόμος παρέχει μια σειρά από δικαιώματα σχετικά με τα προσωπικά σας δεδομένα, τα οποία οι Συνδιοργανωτές σέβονται απόλυτα και θα σας βοηθήσουν να τα ασκήσετε ανά πάσα στιγμή.  Πιο συγκεκριμένα έχετε το δικαίωμα:
            <ol type="i">
                <li><span style="font-weight:700" > να έχετε πλήρη και λεπτομερή ενημέρωση</span> για τους λόγους και τους σκοπούς της συλλογής και επεξεργασίας των δεδομένων σας, τις προθεσμίες επεξεργασίας και αποθήκευσης των δεδομένων, τις αρχές επεξεργασίας, τυχόν τρίτους που έχουν πρόσβαση στα δεδομένα σου και λεπτομέρειες που σχετίζονται με τυχόν διαβιβάσεις,  και οποιαδήποτε άλλη σχετική πληροφορία. Αν οι πληροφορίες που παρέχονται σε αυτό το έντυπο δεν είναι αρκετές, σαφείς ή έχετε κι άλλες ερωτήσεις, θα χαρούμε να σας παρέχουμε επιπλέον πληροφορίες και διευκρινήσεις. </li>
                <li><span style="font-weight:700" > να έχετε πρόσβαση στα δεδομένα που έχουμε για εσας, και να λάβετε αντίγραφο.</span> </li>
                <li><span style="font-weight:700" > να ζητήσετε τη διόρθωση/επικαιροποίηση των δεδομένων που έχουμε για εσάς.</span></li>
                <li>Να ζητήσετε <span style="font-weight:700" > αντίγραφο των δεδομένων σας σε κατάλληλη μορφή </span> που θα μεταφερθεί σε τρίτους ή θα χρησιμοποιηθεί για άλλους σκοπούς (<span style="font-weight:700" > φορητότητα δεδομένων</span>) </li>
                <li><span style="font-weight:700" > να μας ζητήσετε να διαγράψουμε τα δεδομένα σας</span>, με την επιφύλαξη των υποχρεώσεών μας από το νόμο, και του δικαιώματός μας να ασκήσουμε τα νόμιμα δικαιώματά μας, και λαμβάνοντας υπόψη ότι κατά περίπτωση αυτό μπορεί να έχει επιπτώσεις στη συμμετοχή στο Εκπαιδευτικό Πρόγραμμα.</li>
                <li><span style="font-weight:700" > να ζητήσετε να μην επεξεργαστούμε περαιτέρω τα δεδομένα σας</span>, με την επιφύλαξη των υποχρεώσεών μας από το νόμο, και του δικαιώματός μας να ασκήσουμε τα νόμιμα δικαιώματά μας, και λαμβάνοντας υπόψη ότι κατά περίπτωση αυτό μπορεί να έχει επιπτώσεις στη συμμετοχή στο Εκπαιδευτικό Πρόγραμμα.</li>
                <li><span style="font-weight:700" > να ανακαλέσετε τη συγκατάθεσή σας οποιοδήποτε στιγμή</span>, με την επιφύλαξη των υποχρεώσεών μας από το νόμο, και του δικαιώματός μας να ασκήσουμε τα νόμιμα δικαιώματά μας, και λαμβάνοντας υπόψη ότι κατά περίπτωση αυτό μπορεί να έχει επιπτώσεις στη συμμετοχή στο Εκπαιδευτικό Πρόγραμμα</li>
                <li><span style="font-weight:700" > να υποβάλετε καταγγελία στις αρμόδιες αρχές</span>. Αν έχετε οποιαδήποτε απορία, επιφύλαξη ή παράπονο σε σχέση με το πώς επεξεργαζόμαστε και χρησιμοποιούμε τα προσωπικά σας δεδομένα σας καλούμε να επικοινωνήσετε μαζί μας. Ελπίζουμε ότι να επιλύσουμε οποιοδήποτε ζήτημα μπορεί να προκύψει. Ωστόσο, <span style="font-weight:700" > έχετε επίσης το δικαίωμα να υποβάλετε καταγγελία στις αρμόδιες αρχές προστασίας δεδομένων. </span> Για περισσότερες πληροφορίες και τον τρόπο υποβολής καταγγελίας, μπορείς να επισκεφθείς την ιστοσελίδα της Αρχής Προστασίας Προσωπικών Δεδομένων (www.dpa.gr).</li>
            </ol>
            Δε συλλέγουμε ούτε επεξεργαζόμαστε αυτοματοποιημένα τα προσωπικά σας δεδομένα για σκοπούς που σχετίζονται με την κατάρτιση προφίλ.
        </div>
        <br>

        <div style="font-size:28px; font-weight:700">7. Στοιχεία Επικοινωνίας </div> <br>
        <div>Για οποιαδήποτε απορία, για την άσκηση των δικαιωμάτων σας, ή για οποιοδήποτε ζήτημα ή/και παράπονο <span style="font-weight:700" > σχετικά με την προστασία των προσωπικών δεδομένων και της ιδιωτικότητας σας</span> μπορείτε να επικοινωνήσετε άμεσα με την <span style="font-weight:700" > Υπεύθυνη για την Προστασία Προσωπικών Δεδομένων (DPO) της EQUALITY, Dr. Βασιλική Κονιάκου </span>, μέσω e-mail στην ηλεκτρονική διεύθυνση koniakou&#64;eltrun.gr και στο <a href="mailto:customerprivacy@cosmote.gr"> customerprivacy&#64;cosmote.gr </a> ή fax στο 2102511888 ή επιστολή στη διεύθυνση «Εξυπηρέτηση Πελατών COSMOTE, Λ. Κηφισίας 99, 15124, Μαρούσι» με θέμα «Άσκηση δικαιωμάτων προσωπικών δεδομένων» και αναφέροντας το ονοματεπώνυμό σας και τον αριθμό σύνδεσης του κινητού ή του σταθερού τηλεφώνου σας.</div>
        <br>

        <div style="font-size:28px; font-weight:700">8. Επικαιροποίηση της παρούσας Δήλωσης Ιδιωτικότητας</div> <br>
        <div>Ενδέχεται να χρειαστεί να τροποποιήσουμε ή/και να αλλάξουμε την παρούσα Δήλωση στο μέλλον. Για οποιαδήποτε αλλαγή θα ενημερωθείτε μέσω banner ή pop-up window στην κεντρική σελίδα του Ιστότοπού μας. Η παρούσα Δήλωση επικαιροποιήθηκε τον Ιούλιο του 2023, αντικαθιστώντας κάθε προηγούμενη.</div>
        <br>

        <div style="font-size:28px; font-weight:700">9. Άτομα ηλικίας κάτω των 18 ετών</div> <br>
        <div>Το Εκπαιδευτικό Πρόγραμμα “ COSMOTE GROW YOUR BUSINESS  - Certification Program” προορίζεται για <span style="font-weight:700" >  άτομα άνω των 18 ετών. </span> Εάν είστε κάτω των δεκαοκτώ ετών, παρακαλούμε να μην προβείτε σε οποιαδήποτε απευθείας αλληλεπίδραση μαζί μας, καθώς δεν μπορούμε να συλλέξουμε και να χρησιμοποιήσουμε τα προσωπικά δεδομένα σας.</div>
        <br>



    </div>
</div>
