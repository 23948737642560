import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { pipe, map, of, Observable } from "rxjs";
import { Data } from '@angular/router';
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AppService {

    constructor(
        private httpClient: HttpClient
    ) {}

    
    vies(data: {countryCode: string, vat: string}): Observable<any> {
        return this.httpClient.post('https://u2jxa6iav0.execute-api.eu-west-1.amazonaws.com/prod/vies', data)
            .pipe(map((data: Data) => data['data']))
    }

    checkGRECAVat(vat: string): Observable<any> {
        return this.httpClient.post(`${environment.PROGRAM_SPECIFIC_API}/check-vat`, {vat})
            .pipe(map((data: Data) => data['data']))
    }

    contactUs(contactData: any) {
        console.log(`${environment.USERS_MESSAGING_API}/messages/contact-us`)
        return this.httpClient.post(`${environment.USERS_MESSAGING_API}/messages/contact-us`, contactData)

    }
}