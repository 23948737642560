import { Injectable, signal, WritableSignal } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, switchMap } from "rxjs";
import { Data } from '@angular/router';
import { environment } from "src/environments/environment";
import { Course, CourseEnrollment, CourseEnrollmentV2 } from "../shared/models/course.model";



@Injectable({
    providedIn: 'root'
})
export class CoursesService {

    constructor(
        private httpClient: HttpClient
    ) { }

    private _courses!: Course[];
    private myCourses: CourseEnrollment[] | null = null;

    public courses: WritableSignal<Course[]> = signal([]);
    public courseEnrollments: WritableSignal<CourseEnrollmentV2[]> = signal([]);

    getCourses(): Observable<Course[]> {
        if (this._courses) {
            return of(this._courses)
        } else {
            return this.httpClient.get(`${environment.COURSES_API}/courses`).pipe(
                switchMap((data: Data) => of(data['data'])),
                switchMap((courses: any) => {
                    this._courses = courses;
                    return of(this._courses)
                })
            )
        }
    }

    resetCourses() {
        (this._courses as any) = null;
        this.myCourses = null;
    }




    getCourse(courseId: string): Observable<Course> {
        return this.httpClient.get(`${environment.COURSES_API}/courses/authenticated/${courseId}`)
            .pipe(map((data: Data) => data['data']));
    }

    getCoursesForAdmins(): Observable<Course[]> {
        return this.httpClient.get(`${environment.COURSES_ADMIN_API}`)
            .pipe(map((data: Data) => data['data']));
    }

    getCourseForAmin(courseId: string): Observable<Course> {
        return this.httpClient.get(`${environment.COURSES_ADMIN_API}/${courseId}`)
            .pipe(map((data: Data) => data['data']));
    }

    getMyCourses(): Observable<any> {
        if (this.myCourses) {
            return of(this.myCourses)
        } else {
            return this.httpClient.get(`${environment.USERS_API}/me/enrollments`).pipe(
                switchMap((data: Data) => of(data['data'])),
                switchMap((courses: any) => {
                    this.myCourses = courses;
                    return of(this.myCourses)
                })
            )
        }
    }

    getUserCourseEnrollment(courseId: string): Observable<CourseEnrollment> {
        // let courseEnrollMent = this.myCourses?.find(c => c.course?.courseId === courseId);
        // if(courseEnrollMent) {
        //     return of(courseEnrollMent)
        // } else {
        //     return this.httpClient.get(`${environment.USERS_API}/me/enrollments/${courseId}`)
        //     .pipe(map( (data: Data) => data['data']));
        // }

        return this.httpClient.get(`${environment.USERS_API}/me/enrollments/${courseId}`)
            .pipe(map((data: Data) => data['data']));
    }

    putLecturesProgress(courseId:string, sectionId: string, lectureId: string): Observable<any> {
        return this.httpClient.put(`${environment.COURSE_ENROLLMENTS_API}/course-enrollments/${courseId}/lectures-progress`, {
            "sectionId": sectionId,
            "lectureId": lectureId
        });
    }

    getEnrollMentsPerCourse(): Observable<any> {
        return this.httpClient.get(`${environment.COURSES_ADMIN_API}/enrollments/count`)
            .pipe(map((data: Data) => data['data']));
    }

    submitTestAnswers(courseId: string, lessonId: string, answers: any[]) {
        return this.httpClient.post(`${environment.COURSES_API}/courses/${courseId}/test_answers`, { lessonId, answers })
            .pipe(map((data: Data) => data['data']));
    }

    submitExamAnswers(courseId: string, answers: any[]) {
        return this.httpClient.put(`${environment.COURSE_ENROLLMENTS_API}/course-enrollments/${courseId}/exam_answers`, { answers })
            .pipe(map((data: Data) => data['data']));
    }

    createCourse(courseData: any): Observable<Course> {
        return this.httpClient.post(`${environment.COURSES_ADMIN_API}`, courseData)
            .pipe(map((data: Data) => data['data']));
    }

    updateCourse(courseId: string, courseData: any): Observable<Course> {
        return this.httpClient.put(`${environment.COURSES_ADMIN_API}/${courseId}`, courseData)
            .pipe(map((data: Data) => data['data']));
    }

    deleteCourse(courseId: string): Observable<any> {
        return this.httpClient.delete(`${environment.COURSES_ADMIN_API}/${courseId}`);
    }

    activateCalendar(courseId: string): Observable<any> {
        return this.httpClient.put(`${environment.COURSES_ADMIN_API}/${courseId}/calendar`, { daysBefore: 7 })
            .pipe(map((data: Data) => data['data']));
    }

    slackNotify(data: any) {
        return this.httpClient.post(`https://l3ktslvj18.execute-api.eu-west-1.amazonaws.com/prod/slack-notify`, data)
        // return this.httpClient.post(`https://m4odvhth76.execute-api.eu-west-1.amazonaws.com/dev/slack-notify`, data)
    }
}