import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LinkForgotPasswordComponent } from "src/app/common/components/link-forgot-password.component";
import { AuthService } from "src/app/common/services/auth.service";
import { SpinnerService } from "src/app/common/spinner/spinner.service";

@Component({
    templateUrl: './grow-your-business-link-forgot-password.component.html',
})
export class GrowYourBusinessLinkForgotPasswordComponent extends LinkForgotPasswordComponent {
    constructor(
        route: ActivatedRoute,
        authService: AuthService,
        spinnerService: SpinnerService
    ) {
        super(route, authService, spinnerService);
    }
}