<div *ngIf="courses && display == 'double'" >
    <div [formGroup]="getCoursesFormGroup()"
    fxLayout="column" fxLayout.gt-sm="row wrap" fxFlex.gt-sm="true" [ngStyle]="{'max-height': calculateHeightForCoursesContainer(), 'overflow-y':'scroll'}" style="gap:12px">
        <ng-container   *ngFor="let course of courses; let i = index;">
            <mat-checkbox  style="width:calc(50% - 45px); display:flex" [ngStyle.lt-md]="{'width': '100%', 'box-sizing': 'border-box'}"   [formControlName]="course.courseId" color="primary" class="courses" > {{course.title}}</mat-checkbox>
        </ng-container>
    </div>

</div>

<ng-container [formGroup]="getCoursesFormGroup()" *ngIf="courses && display == 'single'" >

    <div fxLayout="column" [ngStyle]="{'max-height': calculateHeightForCoursesContainer(), 'overflow-y':'scroll'}" style="gap:12px">
        <ng-container style="width:100%"  *ngFor="let course of courses; let i = index;">
            <mat-checkbox [formControlName]="course.courseId" color="primary" class="courses" > {{course.title}}</mat-checkbox>
        </ng-container>
    </div>

</ng-container>


<div *ngIf="courses && courses.length == 0" style="border-radius: 8px;background: var(--gray-blue-50, #F8F9FC); padding:24px; gap:20px" fxLayout="row">
    <div fxLayout="column" style="gap: 8px;">
        <span style="color: #344054;font-size: 18px;font-style: normal;font-weight: 700;line-height: 28px;">Δεν βρέθηκαν μαθήματα</span>
        <span style="color: var(--gray-600, #475467);font-size: 14px;font-style: normal;font-weight: 400;line-height: 20px;">
            Είτε έχετε γραφτεί σε όλα τα μαθήματα του προγράμματος, είτε δεν υπάρχει κάποιο μάθημα διαθέσιμο για εγγραφή αυτή την στιγμή.
        </span>

    </div>
</div>
