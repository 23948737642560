import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Data } from "@angular/router";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private httpClient: HttpClient) { }

    requestPresignedUrl(payload: { Mime: string; type: string }) {
        return this.httpClient.post(`${environment.PRESIGNED_URLS_API}/upload`, payload).
            pipe(map((data: Data) => data['data']))
    }

    readImage(file: File, validation: { allowedTypes?: string[], maxSize?: number, requestedFormat?: string }):
        Observable<{ image: File, requestedFormat: string | ArrayBuffer | null; }> {
        return new Observable((observer) => {

            const reader = new FileReader();
            const fileType = file.type;
            const filesize = file.size;
            const maxSize = validation.maxSize ? validation.maxSize : 200000000
            const allowedTypes = validation.allowedTypes ? validation.allowedTypes : ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
            const requestedFormat = validation.requestedFormat ? validation.requestedFormat : 'arrayBuffer'
            reader.onload = (ev: any) => {
                if (!fileType) {
                    observer.error({ 'message': 'Cannot process image' });
                }
                if (filesize > maxSize) {
                    observer.error({ 'message': `File should be less than ${maxSize / 200000000} MB` });
                }

                const fileReader = new FileReader();
                let type = '';
                const that = this;
                fileReader.onloadend = function (e: any) {
                    const arr = new Uint8Array((e.target.result));
                    let header = '';
                    for (let i = 0; i < 4; i++) {
                        header += arr[i].toString(16);
                    }
                    // Check the file signature against known types
                    switch (header) {
                        case '89504e47':
                            type = 'image/png';
                            break;
                        case '47494638':
                            type = 'image/gif';
                            break;
                        case 'ffd8ffe0':
                        case 'ffd8ffe1':
                        case 'ffd8ffe2':
                        case 'ffd8ffe3':
                        case 'ffd8ffe8':
                            type = 'image/jpeg';
                            break;
                        default:
                            type = 'unknown'; // Or you can use the blob.type as fallback
                            break;
                    }
                    if (!allowedTypes.includes(type)) {
                        observer.error({ 'message': 'Format is not valid' });
                    }

                };
                fileReader.readAsArrayBuffer(file.slice(0, 4));
            };
            reader.onloadend = function (e: any) {
                console.log(reader.result)
                observer.next({ image: file, requestedFormat: reader.result })
                observer.complete()
            }
            switch (requestedFormat) {
                case 'arrayBuffer':
                    reader.readAsArrayBuffer(file);
                    break;
                case 'base64':
                    reader.readAsDataURL(file);
                    break;
                default:
                    reader.readAsArrayBuffer(file);
                    break;
            }
        });
    }

    uploadFileToPresignedUrl(presignedUrl: string, file: Buffer) {
        return this.httpClient.put(presignedUrl, file, {
            reportProgress: true,
            observe: 'events'
        })
    }

    // convert size from bytes to KB, MB, GB
    formatBytes(bytes: number, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

}