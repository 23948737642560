import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { SpinnerService } from '../spinner/spinner.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  template: ''
})
export class ForgotPasswordComponent {

	constructor(
		private authService: AuthService,
		private spinnerService: SpinnerService
	) {}

	public userEmail = new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
	public sendedCodeSuccessMessage !: string | null;

	onForgotPassword() {
		if(this.userEmail.invalid) {
			return;
		}
		this.spinnerService.show('forgot-password')
		this.authService.forgotPassword(this.userEmail?.value as string)
			.pipe(finalize(() => this.spinnerService.hide('forgot-password')))
			.subscribe({
			next:( (result) => {
				this.sendedCodeSuccessMessage = "Στείλαμε στο email σας ένα λινκ για ανάκτηση κωδικού. Αν δεν το δείτε στο inbox μην ξεχάσετε\
				να δείτε και τα spam σας."
			}),
			error: (cognitoError) => {
				console.log(cognitoError)

				if(cognitoError.code == 'UserNotFoundException') {
					this.userEmail.setErrors({'invalid': 'Δεν βρέθηκε καποια πληροφορία για το συγκεκριμένο email'})
				} else {
					this.userEmail.setErrors({'invalid': 'Υπήρξε καποιο θεμα. Δοκιμάστε παλι σε λίγο'})
				}
				this.sendedCodeSuccessMessage  = null
			}
		})
	}
}
