import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../shared/models/user.model';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) {}

    private user = null;

    getUserDetails() {
        if(this.user) {
            return of(this.user);
        } else {
            return this.http.get(`${environment.USERS_API}/me`).pipe(
                switchMap( (data: Data) => of(data['data'])),
                switchMap( user => {
                    this.user = user
                    return of(user)
                })
            )
        }
    }

    resetUser() {
        this.user = null;
    }

    getUsers(): Observable<User[]>  {
        return this.http.get(`${environment.USERS_ADMIN_API}`).
            pipe(map( (data: Data) => data['data']))
    }

    getInvoices(): Observable<User[]>  {
        return this.http.get(`${environment.USERS_ADMIN_API}/invoices`).
            pipe(map( (data: Data) => data['data']))
    }

    register(payload:any){
        return this.http.post<any>(`${environment.USERS_API}`, payload);
    }

    uploadInvoice(invoiceUrl: string | undefined) {
        return this.http.post(`${environment.USERS_API}/me/invoice`, {invoiceUrl})
            .pipe( map( (data:Data) => data['data']))
    }

    markUserInvoiceForDownload(userId: string) {
        return this.http.put(`${environment.USERS_ADMIN_API}/${userId}`, {invoiceDownloaded: Date.now()})
        .pipe( map( (data:Data) => data['data']))
    }

    approveUser(userId: string) {
        return this.http.put(`${environment.USERS_ADMIN_API}/${userId}/approve`, {})
        .pipe( map( (data:Data) => data['data']))
    }

    getUserAsContact(userId: string) {
        return this.http.get(`${environment.USERS_ADMIN_API}/${userId}/contact`,{}) 
        .pipe( map( (data:Data) => data['data']))
    }


}
