import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(
        @Inject('tenant') private tenant: any
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(request.url == 'https://645v2ekb1c.execute-api.eu-west-1.amazonaws.com/dev/vies-v2') {
            return next.handle(request);
        }
        const tenantReq = request.clone({
            headers: request.headers.set('x-source-tenant', this.tenant)
        });

        return next.handle(tenantReq);
    }
}
