import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq',
  template: '',
})
export class FaqComponent {

    constructor(private router:Router){}

    public faqs = [
        {
            title: '01',
            description: 'Τι είναι το πρόγραμμα «Executive E-commerce Certification: Mastering e-commerce skills;',
            text: `Πρόκειται για ένα πρόγραμμα το οποίο σχεδιάστηκε με την\ 
            επιστημονική ευθύνη του Εργαστηρίου Ηλεκτρονικού Εμπορίου και \
            Επιχειρείν-ELTRUN του Οικονομικού Πανεπιστημίου Αθηνών, και \
            έχει ως στόχο την εκπαίδευση των στελεχών των επιχειρήσεων σε\ 
            θέματα που αφορούν την οργάνωση και την λειτουργία μιας \
            ψηφιακής επιχείρησης.`,
            panelOpenState: false
        },
        {
            title: '02',
            description: 'Σε ποιους απευθύνεται;',
            html: `Το πρόγραμμα απευθύνεται:<br>
            - σε στελέχη επιχειρήσεων που ήδη βρίσκονται στον ψηφιακό χώρο <br>
            - σε ενδιαφερόμενους που θέλουν να κάνουν reskilling/upskilling σε θέματα ψηφιακού επιχειρείν <br>
            - σε άτομα που θέλουν να ανοίξουν τη δική τους ηλεκτρονική επιχείρηση <br>`,
            panelOpenState: false
        },
        {
            title: '03',
            description: 'Ποιο είναι το κόστος του προγράμματος;',
            html: `- Για όλους τους συμμετέχοντες που είναι μέλη του GR.EC.A., το 
            κόστος είναι 790 ευρώ/συμμετοχή (40% έκπτωση) <br>
             - Για τους συμμετέχοντες που δεν είναι σε εταιρείες μέλη του GR.EC.A., το κόστος είναι 1.320 ευρώ`,
            panelOpenState: false
        },
        {
            title: '04',
            description: 'Πώς μπορώ να κάνω αίτηση;',
            text: 'Η αίτηση πραγματοποιείται μέσω της σελίδας www.ecommerce-academy.gr',
            panelOpenState: false
        },
        {
            title: '05',
            description: 'Μπορώ να κόψω τιμολόγιο;',
            text: 'Υπάρχει τόσο η δυνατότητα έκδοσης τιμολογίου, όσο και απόδειξης.',
            panelOpenState: false
        },
        {
            title: '06',
            description: 'Ποια είναι τα κριτήρια για να ολοκληρώσω με επιτυχία το πρόγραμμα;',
            html: `Προκειμένου να ολοκληρώσει κάποιος με επιτυχία το πρόγραμμα, 
            θα πρέπει: <br>
            - Να παρακολουθήσει όλες τις ενότητες <br>
            - Να περάσει με επιτυχία τα test στο τέλος κάθε ενότητας 
            (ποσοστό επιτυχίας >70%) <br>
            - Να ολοκληρώσει το capstone <br>`,
            panelOpenState: false
        },
        {
            title: '07',
            description: 'Πώς πραγματοποιούνται τα μαθήματα;',
            text: `Το 50% των διαλέξεων θα πραγματοποιηθεί με σύγχρονο τρόπο 
            μέσω της πλατφόρμας teams, το υπόλοιπο 50% θα 
            πραγματοποιηθεί με ασύγχρονο τρόπο μέσω της elearning 
            πλατφόρμας του ecommerce-academy`,
            panelOpenState: false
        },
        {
            title: '08',
            description: 'Θα λάβω κάποια βεβαίωση συμμετοχής;',
            text: `Όσοι ολοκληρώσουν με επιτυχία το πρόγραμμα θα λάβουν 
            πιστοποιητικό κατάρτισης από το ΚΕΔΙΒΙΜ του Οικονομικού 
            Πανεπιστημίου Αθηνών`,
            panelOpenState: false
        },
        {
            title: '09',
            description: 'Πού μπορώ να επικοινωνήσω για περισσότερες πληροφορίες;',
            text: `Μπορείτε να επικοινωνήσετε μαζί μας μέσω του email 
            info@ecommerce-academy.gr`,
            panelOpenState: false
        },
        {
            title: '10',
            description: 'Πότε ξεκινά το πρόγραμμα;',
            text: `Το πρόγραμμα ξεκινά το Σεπτέμβριο του 2023`,
            panelOpenState: false
        },
        {
            title: '11',
            description: 'Τι διάρκεια έχει το πρόγραμμα;',
            text: `Η συνολική διάρκεια του προγράμματος είναι 100 ώρες`,
            panelOpenState: false
        },
        {
            title: '12',
            description: 'Ποιες ημέρες διεξάγονται τα σύγχρονα μαθήματα;',
            text: `Τα σύγχρονα προγράμματα διεξάγονται Παρασκευές 5-8μμ`,
            panelOpenState: false
        }, 
    ]

    onContactUs() {
        this.router.navigate(['contact-us'])
    }
}
