import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import {GrowYourBusinessCookiesSettingsDialogComponent} from "./cookie-settings-dialog/grow-your-business-cookie-settings-dialog.component";
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-cookie-dialog',
  templateUrl: './grow-your-business-cookie-dialog.component.html',
})
export class GrowYourBusinessCookieDialogComponent implements OnInit {

    constructor(
        private router: Router,
        private dialog: MatDialog
        ) { }
    public closePopup = false;
    private firstScript: any = null;


    ngOnInit() {
        // to google script θα το φορτώνουμε πάντα
        this.initializeGoogleScriptWithoutLoadingGoogleTag();

        let consent: string | null = this.userConsent()
        // console.log('consent', consent)
        // αν το consent ειναι null δεν εχουμε καποια προηγούμενη επιλογη του χρήστη
        if(consent == null) {
            return
        }
        // εδώ θα κανουμε grant και θα φορτώσουμε τα scripts
        if(consent == 'granted') {
            this.acceptAll();
        // εδώ θα κλείσουμε απλά το popup
        } else if (consent == 'denied') {
            this.onRejectAll()
        }
    }

    initializeGoogleScriptWithoutLoadingGoogleTag() {
        this.firstScript = document.createElement('script');
        this.firstScript.innerText = `window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('consent', 'default', {
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'ad_storage': 'denied',
          'analytics_storage': 'denied',
          'wait_for_update': 500,
        });
        gtag('js', new Date());
        gtag('config', '${ environment.GOOGLE_ANALYTICS_ID }');`;

        let body = <HTMLDivElement> document.body;
        body.appendChild(this.firstScript);
    }

    public showCookieDialog() {
        this.closePopup = false;
    }


    public acceptAll(): void {
        this.loadFacebookScript();
      
        gtag('consent', 'update', {
          ad_user_data: 'granted',
          ad_personalization: 'granted',
          ad_storage: 'granted',
          analytics_storage: 'granted'
        });
      
        // Load gtag.js script.
        var gtagScript = document.createElement('script');
        gtagScript.async = true;
        gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${ environment.GOOGLE_ANALYTICS_ID }`;
        this.firstScript.parentNode.insertBefore(gtagScript,this.firstScript);
        // consent for google analytics granted or denies
        let newConsentCookie = this.getCookie('consentGranted');
        if(newConsentCookie == null) {
            this.setCookie('consentGranted', 'true', 365)
        }
        this.closePopup = true;
    }

    public onRejectAll() {
        let newConsentCookie = this.getCookie('consentGranted');
        if(newConsentCookie == null) {
            this.setCookie('consentGranted', 'false', 365);
        }
        this.closePopup = true;
    }


    public loadFacebookScript() {

        let fbScript = document.createElement('script');
        fbScript.innerText = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${ environment.FB_ID }');
        fbq('track', 'PageView');`
        fbScript.setAttribute('async', 'true');
        let body = <HTMLDivElement> document.body;
        body.appendChild(fbScript);
    }


    //
    
    public userConsent(): 'granted' | 'denied' | null {
        let newCookie = this.getCookie('consentGranted');

        if(newCookie) {
            return newCookie == 'true' ? 'granted' : 'denied';
        }

        let oldCookie = this.getCookie('consent-policy');
        if(oldCookie != null) {
            // pairnw ola ta values tou cookie
            let parseCookie: any = JSON.parse(oldCookie);
            const values = Object.values(parseCookie!);
            // elegxw an ola ta values einai true h false
            const allTrue = values.every(value => value === true);
            const allFalse = values.every(value => value === false);
            // an einai ola true  tote  epistrefw granted
            // an einai ola false tote epistrefw denied
            // an einai kai apo ta 2 tote epistrefw null για να ξαναδώσει εκγριση ο χρήστης
            if(allTrue) {
                return 'granted'
            } else if(allFalse) {
                return 'denied'
            } else {
                return null
            }
        }

        return null
    }



    private getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return null;
    }


    private setCookie(name: string, value: string, expireDays: number, path: string = '') {
        let d: Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        let expires: string = `expires=${d.toUTCString()}`;
        let cpath: string = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }


}