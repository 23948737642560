import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { timer } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MessagesService } from '../services/messages-service';
import { NewMessageDialogComponent } from './new-message-dialog.component';
import { ChatDialogComponent } from './chat-dialog.component';

@Component({
  selector: 'app-my-messages',
  template: '',
})
export class MyMessagesComponent implements OnInit {

    public messages: any
    constructor(
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private authService: AuthService,
        private messagesService: MessagesService
    ) { }

    public user: any
    public isAdmin!: boolean;

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.messages = data['messages'].map((messageObject: any) => {
                messageObject.displayedFile = this.OnDetectIfShouldDisplayFile(messageObject)

                return messageObject
            });
        })

        from(this.authService.isAdmin()).subscribe((isAdmin) => {
            this.isAdmin = isAdmin;
            if(this.isAdmin) {
                this.authService.getUser().subscribe((user) => {
                    this.user = {
                        // id: user.attributes.sub,
                        id: user.userId
                        // eida oti to AuthUserAttributes exei ta parakatw kai upothetw oti to userId einai to id tou user kathws varaei error kai den mporw na kanw compile
                        // username: string;
                        // userId: string;
                    }
                })
            }
        })

        this.route?.parent?.data.subscribe(data => {
            this.user = data['user']
        });

        const source = timer(3000)
        const subscription = source.subscribe(() => {
            const notificationIds = this.messages.filter((messageObject: any) => {
                return messageObject.type == 'notification' && this.isNew(messageObject)
            }).map((messageObject: any) => {
                return messageObject.id
            })

            if(notificationIds.length > 0) {
                this.messagesService.markNotificationsAsSeen(notificationIds).subscribe((data) => {
                    console.log(data)
                })  
            }
        });

        console.log(this.messages)

    }

    OnCreateNewMessage() {
        this.dialog.open(NewMessageDialogComponent, {
            panelClass: 'radius-container',
            backdropClass: 'backdropBackground',
            disableClose: true,
            data: {}
            }).afterClosed().subscribe((newConversationMessage: any) => {
                if (newConversationMessage) {
                    this.messages.unshift(newConversationMessage);
                }
            }
        );
    }

    OnDetectIfShouldDisplayFile(messageObject: any) {
        // αν το πρώτο μήνυμα που έστειλε ειναι αρχείο
        if(messageObject?.messages?.[0].content?.type == 'file') {
            return messageObject?.messages?.[0]?.content
        }

        // αν το πρώτο μήνυμα που έστειλε είναι text και μετά αρχείο
        if(messageObject?.messages?.[1]?.content?.type == 'file' && 
            messageObject?.messages?.[0]?.userId == messageObject?.messages?.[1].userId ) {
            return messageObject?.messages?.[1].content
        }

        return null
    }

    /**
     * Ελέγχει αν το μήνυμα είναι νέο
     * 
     * 
     * @param messageObject 
     * @returns 
     */
    isNew(messageObject: any): boolean {

        if (messageObject.type == 'notification') {
            return messageObject.hasOwnProperty('notificationSeenTimestamp') ? false : true
        }

        if(messageObject.type == 'conversation') {
            if(this.isAdmin) {
                return messageObject.latestMessageAdminSeen != messageObject.latestMessage.id
            } else {
                return messageObject.latestMessageUserSeen != messageObject.latestMessage.id
            }
        }

        return false;
    }

    /**
     * Ελέγχει αν πρέπει να φανεί το μήνυμα σαν stacked
     * Stacked είναι μόνο τα conversations
     * 
     **/
    isStacked(messageObject: any): boolean {
        if (messageObject.type == 'notification') {
            return false
        }

        /**
         * Αν ειμαστε στην πλευρά του admin τότε θα πρέπει να ελέγξουμε αν υπάρχει καινούργιο μήνυμα
         * Επίσης κοιταμε γενικα να υπάρχουν πανω απο 1 μηνύματα στη συνομιλία γιατί αλλιως ειναι η πρώτη επικοινωνία και δεν εχει
         * νόημα να φαίνεται stacked
         * 
         */
        if(messageObject.type == 'conversation') {
            if(this.isAdmin) {
                return (messageObject.latestMessageAdminSeen != messageObject.latestMessage.id) && (messageObject.messages.length > 1)
            } else {
                return messageObject.latestMessageUserSeen != messageObject.latestMessage.id
            }
        }

        return false;
    }

    calculateNewMessages(messageObject: any): number {

        let index;
        if(this.isAdmin) {
            index = messageObject.messages.findIndex( (message: any) => message.id === messageObject.latestMessageAdminSeen);
        } else {
            index = messageObject.messages.findIndex( (message: any) => message.id === messageObject.latestMessageUserSeen);
        }

        return messageObject.messages.length - index - 1;
    }

    onOpenChatConversation(messageObject: any) {

        console.log(messageObject)
        if(messageObject.type != 'conversation') return;

        this.dialog.open(ChatDialogComponent, {
            panelClass: 'radius-container',
            backdropClass: 'backdropBackground',
            disableClose: true,
            data:  {
                conversation: messageObject,
                me: this.user,
                adminMode: this.isAdmin,
                conversationHasNewMessages: this.isNew(messageObject)
            },
            width: "400px",
            height: "500px"
        }).afterClosed().subscribe((conversation: any) => {
            console.log('RETURNED CONVERSATION', conversation)
            if(conversation) {
                messageObject = conversation
            }
        });
    }
}
