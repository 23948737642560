<div *ngIf="!closePopup" style="padding:0 10%;width:75%; position:fixed; bottom:0; ">
  <div  fxLayout="row" fxLayoutAlign="start center" style="gap: 4px;background-color:white; padding:16px; width:100% ;font-size:16px;">
      <span> We use cookies on our website to see how you interact with it. By accepting, you agree to our use of such cookies. </span>
        <a href="https://www.equality.org.gr/cookies" target="_blank" style="color:black"> Cookies Policy </a>
      <div fxFlex></div>
      <div class="text-end" fxLayout="row" style="gap: 12px;">
        <button mat-raised-button (click)="onRejectAll()">Reject all</button>
        <button  mat-raised-button style="background-color: black; font-size:14px; color:white" (click)="acceptAll()">Accept</button>
      </div >
  </div>
</div>

