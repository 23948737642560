import { Component, Input, OnInit } from "@angular/core";
import { Course } from 'src/app/common/shared/models/course.model';

@Component({
    selector: "app-course-lessons-schedule",
    templateUrl: "./grow-your-business-course-lessons-schedule.component.html",
})
export class GrowYourBusinessCourseLessonsScheduleComponent implements OnInit {

    @Input() public course!: Course;
	@Input('enable') public enable: string[] = [];

    public tableData: any[] = [];
	@Input('version') public version: string = 'v1';
	

	public displayedColumns: string[] = ['tableDateString', 'time', 'duration', 'topic'];
	public displayedColumnsXs = ['tableFullDateString', 'topic'];


    constructor() {}

    ngOnInit(): void {
		if(this.enable.includes('link')) {
			this.displayedColumns.splice(3, 0, 'link');
		}
        this.tableData = this.convertLessonsToCalendarEntities(this.course);
		if(this.course.courseId == 'search-engines') {
			this.tableData.unshift({
				tableDateString: 'Τετάρτη 28/02',
				time: '-',
				duration: '-',
				topic: 'Ακύρωση Διάλεξης λόγω ανωτέρας βίας'
			})
			this.tableData.unshift({
				tableDateString: 'Δευτέρα 26/02',
				time: '-',
				duration: '-',
				topic: 'Ακύρωση Διάλεξης λόγω ανωτέρας βίας'
			})
		}
		
    }

    public convertLessonsToCalendarEntities(course: Course) {

		let calendarEntities: any[] = [];

		course?.lessons?.forEach(lesson => {
			let new_data: any = {
				topic: lesson.topic,
				tableDateString: new Date(lesson.startsAt * 1000).toLocaleDateString('el-GR', { weekday: 'long', month: '2-digit', day: '2-digit' }),
				time: new Date(lesson.startsAt * 1000).toLocaleTimeString('el-GR', { hour: '2-digit', minute: '2-digit', hour12: false }),
				endTime: new Date(lesson.endsAt * 1000).toLocaleTimeString('el-GR', { hour: '2-digit', minute: '2-digit', hour12: false }),
				duration:	this.displayDuration(lesson.endsAt - lesson.startsAt),
			}
			new_data['tableFullDateString'] = `${new_data.tableDateString} ${new_data.time} - ${new_data.endTime}`
			console.log(lesson.endsAt - lesson.startsAt);
			if(lesson.link) {
				new_data['link'] = lesson.link
			}
			calendarEntities.push(new_data)

			if(lesson.sections?.test) {
				calendarEntities.push({
					topic: 'Ολοκλήρωση online ασκησης - Διάρκεια έως 1 ώρα',
					duration: '1 ώρα',
					tableDateString: `Έως ${new Date(lesson.sections?.test?.expirationDate).toLocaleDateString('el-GR', { weekday: 'long', month: '2-digit', day: '2-digit' })}`,
					tableFullDateString: `Έως ${new Date(lesson.sections?.test?.expirationDate).toLocaleDateString('el-GR', { weekday: 'long', month: '2-digit', day: '2-digit' })}`,
				})
			}
		})
		
		if(course.exam) {
			calendarEntities.push({
				topic: 'Online Test Αξιολόγησης',
				tableDateString: `Έως ${new Date(course.exam.expirationDate).toLocaleDateString('el-GR', { weekday: 'long', month: '2-digit', day: '2-digit' })}`,
				tableFullDateString: `Έως ${new Date(course.exam.expirationDate).toLocaleDateString('el-GR', { weekday: 'long', month: '2-digit', day: '2-digit' })}`,
			})
		}

		return calendarEntities
	}

	public displayDuration(durationInSeconds: number) {
		let message = ''
		let hours = Math.floor(durationInSeconds / 3600);
		if(hours > 1){
			message += `${hours} ώρες `
		} else if(hours == 1) {
			message += `${hours} ώρα `
		}
		let minutes = Math.floor((durationInSeconds % 3600) / 60);
		if(minutes > 1){
			message += `${minutes} λεπτά `
		}
		else if(minutes == 1) {
			message += `${minutes} λεπτό `
		}
	
		return message
	}
}