import { Component, inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router, RouterState } from "@angular/router";
import { GrowYourBusinessAnalyticsService } from '../grow-your-business-analytics.service';
import * as Sentry from '@sentry/browser';
import { GrowYourBusinessService } from "../grow-your-business.service";
import { CoursesService } from "src/app/common/services/courses.service";
@Component({
    selector: 'app-layout',
    templateUrl: './grow-your-business-layout.component.html',
})
export class GrowYourBusinessLayoutComponent implements OnInit {
    private firstTime = true;
    private growYourBusinessService = inject(GrowYourBusinessService);
    private coursesService = inject(CoursesService);

    
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private titleService: Title,
        private growYourBusinessGoogleAnalytics: GrowYourBusinessAnalyticsService,
        ) {
        this.handleRouteEvents();
      }

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            console.log('data', data)
            let user = data['user'];
            if(user) {
                Sentry.setUser({ 'email': user.email });
                this.growYourBusinessService.getCourseEnrollments().subscribe(data => {
                    // console.log('course enrollments', data)
                    this.coursesService.courseEnrollments.set(data);
                });       
            } 
            this.coursesService.getCourses().subscribe(data => {
                // console.log('courses', data)
                this.coursesService.courses.set(data);
            });
        });
    }

    handleRouteEvents() {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
            this.titleService.setTitle('COSMOTE - GROW YOUR BUSINESS');
            // console.log({
            //     page_title: title,
            //     page_path: event.urlAfterRedirects,
            //     page_location: document.location.href
            //   })
            // console.log('analytics loaded', this.growYourBusinessGoogleAnalytics.analyticsAreLoaded())
            // Επειδη τα analytics φορτώνουν ασύχρογνα. Αν είναι η πρώτη φορά που γίνεται navigation  κανουμε ενα timeout
            if(this.growYourBusinessGoogleAnalytics.analyticsAreLoaded()) {
                this.growYourBusinessGoogleAnalytics.sendEvent('page_view', {
                    page_title: title,
                    page_path: event.urlAfterRedirects,
                    page_location: document.location.href
                })
            } else {
                if(this.firstTime) {
                    setTimeout(() => {
                        // console.log('retry analytics')

                        if(this.growYourBusinessGoogleAnalytics.analyticsAreLoaded()) {
                            this.growYourBusinessGoogleAnalytics.sendEvent('page_view', {
                                page_title: title,
                                page_path: event.urlAfterRedirects,
                                page_location: document.location.href
                            })
                        }
                    }, 300)
                }
            }
            this.firstTime = false;
          }
        });
    }

    getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
            data.push(parent.snapshot.data['title']);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getTitle(state, parent.firstChild));
        }
        return data;
    }
}