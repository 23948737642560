<app-spinner name="my-courses"></app-spinner>


<div style="gap: 32px;padding:48px 0px;" fxLayout="column" fxLayoutAlign="start">

    <div fxLayout="column" fxLayoutAlign="center center" style="gap: 32px;">
        <img style="max-width:460px" [ngStyle.xs]="{'max-width':'300px'}" src="assets/growYourBusiness@x2.png">
    </div>

    <ng-container *ngIf="shouldDisplayCtaButtonForEmployer() else lessonDataView">
        <div fxLayout="column" style="gap: 24px;padding:0px 96px;margin-top: 48px;align-items: center;"
            [ngStyle.lt-md]="{'padding':  '0px 32px'}">
            <div fxLayout="row" style="gap: 16px;width: 1024px;" fxLayout.lt-sm="column">
                <div fxLayout="column" fxFlex>
                    <span class="cosmote-blue"
                        style="font-size: 30px;font-style: normal;font-weight: 700;line-height: 38px;">Μαθήματα</span>
                    <span class="cosmote-blue">Ως υπεύθυνος δεν εχετε εγγραφεί ακόμα σε μαθήματα. Αν θέλετε να
                        παρακολουθήσετε μαθήματα μπορείτε
                        να ξεκινήσετε την εγγραφή σας στην Διαχείριση Συμμετοχών.
                    </span>
                </div>
            </div>
            <div style="width: 1024px;">
                <button style="max-width:300px" mat-raised-button color="primary" routerLink="../manage">
                    Διαχείριση Συμμετοχών
                </button>
            </div>
        </div>
    </ng-container>

    <ng-template #lessonDataView>
        <div fxLayoutAlign="center" style="gap: 24px;padding:0px 96px;margin-top: 48px;"
            [ngStyle.lt-md]="{'padding':  '0px 32px'}">
            <div fxLayout="row" style="gap: 16px;width: 1024px;" fxLayout.lt-sm="column">
                <div fxLayout="column" fxFlex>
                    <span class="cosmote-blue"
                        style="font-size: 30px;font-style: normal;font-weight: 700;line-height: 38px;">Μαθήματα</span>
                </div>
                <div>
                    <button mat-raised-button color="success" style="padding:10px 16px; font-size:14px; line-height:20px; 
                        font-weight:700; border-radius:50px" (click)="onOpenEnrollmentDialog()"> Προσθήκη
                        Μαθημάτων</button>
                </div>

            </div>
        </div>

        <div fxLayoutAlign="center" style="gap: 24px;padding:0px 96px; width:100%"
            [ngStyle.lt-md]="{'padding':  '0px 32px'}">
            <div style="width: 1024px;">
                <table mat-table [dataSource]="tableDataForUserEnrollments" [ngStyle.lt-md]="{'width':'350 px'}" style="border-radius: 8px; 
                border: 1px solid var(--gray-200, #EAECF0);
                background: var(--white, #FFF);
                overflow: hidden;
                
                /* Shadow/sm */
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);">

                <ng-container matColumnDef="courseTitle">
                    <th mat-header-cell *matHeaderCellDef
                        style="font-size:16px;padding:12px 24px;color:black;font-weight:700">Ενότητα Μαθήματος</th>
                    <td mat-cell *matCellDef="let element" style="padding:16px 24px;font-weight: 700;"
                        class="cosmote-blue">
                        {{element.courseTitle}} </td>
                </ng-container>

                <ng-container matColumnDef="lecturesInfo">
                    <th mat-header-cell *matHeaderCellDef
                        style="font-size:16px;padding:12px 24px;color:black;font-weight:700">Video</th>
                    <td mat-cell *matCellDef="let element" style="padding:16px 24px">
                        {{element.completedLectures}}/{{element.totalLectures}} </td>
                </ng-container>

                <ng-container matColumnDef="courseStatus">
                    <th mat-header-cell *matHeaderCellDef
                        style="font-size:16px;padding:12px 24px;color:black;font-weight:700"> Κατάσταση </th>
                    <td mat-cell *matCellDef="let element" style="padding:16px 24px">
                        <!-- <span *ngIf="element.status == 'Εν αναμονή'" class="gray-500">{{element.status}}</span> -->
                        <span *ngIf="element.status == 'completed'" class="success-700" style="padding:8px 8px; font-weight:500; font-size:12px;line-height:18px; border-radius: 16px;
                            background: var(--success-50, #ECFDF3);">Ολοκληρώθηκε</span>
                        <!-- <span *ngIf="element.status == 'Σε εξέλιξη'" class="cosmote-blue" style="padding:8px 8px; font-weight:500; font-size:12px;line-height:18px; border-radius: 16px;
                            background: var(--blue-100, #D1E9FF);">{{element.status}}</span> -->
                        <span *ngIf="element.status == 'started'" style="color:#101828;padding:8px 8px; font-weight:500; font-size:12px;line-height:18px; border-radius: 16px;
                            background: #FF9999">Σε εξέλιξη</span>
                        <!-- <span *ngIf="element.status == 'Δεν ολοκληρώσατε'" class="orange-700" style="padding:8px 8px; font-weight:500; font-size:12px;line-height:18px; border-radius: 16px;
                            background: var(--orange-100, #FFEAD5);">{{element.status}}</span> -->
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef style="width: 250px;"></th>
                    <td mat-cell *matCellDef="let element" style="padding:16px 24px;display: flex; justify-content: center;" class="cosmote-blue">
                        <button mat-raised-button color="success"
                            [routerLink]="['/my-courses', element.courseId, 'lecture']" style="padding:10px 16px; font-size:14px; line-height:20px; 
                        font-weight:500; border-radius:50px" (click)="$event.stopPropagation()"> Δείτε τα
                            video</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"
                    style="height:44px; border-bottom: 1px solid var(--gray-200, #EAECF0);background: #b8e0ef;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="font-weight:500;height:72px;font-size: 16px;"></tr>
            </table>
            </div>
            

        </div>
    </ng-template>

    <div class="contentMathimaton">
        <span style="width: 1024px;">Το COSMOTE GROW YOUR BUSINESS - Certification Program είναι ένα ολοκληρωμένο δωρεάν εκπαιδευτικό πρόγραμμα από την COSMOTE και το Εργαστήριο Ηλεκτρονικού Εμπορίου - ELTRUN, του Οικονομικού Πανεπιστημίου Αθηνών.
            <br><br>
            Το πρόγραμμα αποτελείται από 10 θεματικές ενότητες μαθημάτων που επικεντρώνονται σε ψηφιακές δεξιότητες σε διάφορους τομείς λειτουργίας των επιχειρήσεων. Μπορείτε να δηλώσετε συμμετοχή σε όλες τις ενότητες μαθημάτων επιλέγοντας «Προσθήκη μαθημάτων».
            <br><br>
            Όλοι οι συμμετέχοντες έχουν τη δυνατότητα να λάβουν Βεβαίωση Παρακολούθησης για κάθε μάθημα που θα παρακολουθήσουν, από το Κέντρο Επιμόρφωσης και Δια Βίου Μάθησης (ΚΕ.ΔΙ.ΒΙ.Μ.) του Οικονομικού Πανεπιστημίου Αθηνών, αφού ολοκληρώσουν  με επιτυχία το αντίστοιχο Online Test Αξιολόγησης.
            <br><br>
            Όσοι συμμετέχοντες παρακολουθήσουν και ολοκληρώσουμε με επιτυχία το σύνολο των μαθημάτων του προγράμματος θα λάβουν πιστοποιητικό κατάρτισης από το ΚΕ.ΔΙ.ΒΙ.Μ. του Οικονομικού Πανεπιστημίου Αθηνών.
            
        </span>
    </div>

    <div style="padding:96px 96px 0px;gap: 24px;align-items: center;" [ngStyle.lt-md]="{'padding': '0px 32px', 'width': '100%'}"
        fxLayout="column">
        <div style="width: 1024px;">
            <span style="font-size:30px; font-weight:700; line-height: 38px;" class="cosmote-blue">Νομικά</span>
        </div>

        <mat-card style="border-radius: 12px;
            width: 1024px;
            border: 1px solid #EBEBEB;
            background: #FFF;
            box-shadow: 0px 4px 32px 0px rgba(170, 170, 170, 0.03);
            gap: 72px;" [ngStyle.lt-md]="{'padding':  '0px 12px'}"
            fxLayout="row" fxLayoutAlign="start center " fxLayout.lt-md="column"
            fxLayoutGap.lt-md="12px">
            <div fxLayout="row" style="gap: 56px;padding: 48px 24px;" fxLayoutAlign="center center">
                <span style="cursor: pointer; color:#3D6D99; font-size:14px;font-weight:700"
                    routerLink="/growyourbusiness-terms-of-use" target="_blank">Όροι Συμμετοχής</span>
                <span class="blue-700 f600 mat-small"
                    style="cursor: pointer; color:#3D6D99; font-size:14px;font-weight:700"
                    routerLink="/growyourbusiness-privacy-policy" target="_blank">Δήλωση Απορρήτου</span>
            </div>
        </mat-card>
    </div>



</div>