import { HttpInterceptor } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthInterceptor } from "../authentication.interceptor";
import { AuthService } from "../common/services/auth.service";

@Injectable()
export class GrowYourBusinessAuthInterceptor extends AuthInterceptor implements HttpInterceptor {
    constructor(authService: AuthService) {
        super(authService);
    } 

    override shouldUrlBeExcluded(targetUrl: string) {

        let excludedUrlsPerfectMatch = [
            `${environment.USERS_API}`,
            'https://645v2ekb1c.execute-api.eu-west-1.amazonaws.com/dev/vies-v2',
            "https://u2jxa6iav0.execute-api.eu-west-1.amazonaws.com/prod/vies",
            `${environment.PROGRAM_SPECIFIC_API}/employees/register`,
            `${environment.PROGRAM_SPECIFIC_API}/register`,
            `${environment.COURSES_API}/courses`,
            `${environment.USERS_MESSAGING_API}/messages/contact-us`,
            `${environment.COSMOTE_REGISTER_API}/register`,
            // αυτό θελει αλλαγή
            environment.NEWSLETTER_API,
        ];
        
        let excludedUrlsContainMatch = [
            `${environment.COURSES_API}/courses/[^/]*$`,
            `${environment.PROGRAM_SPECIFIC_API}/presignup-details/[^/]*$`,
            `${environment.PROGRAM_SPECIFIC_API}/validate-company-vat/[^/]*$`,
            `${environment.PROGRAM_SPECIFIC_API}/validate-email/[^/]*$`,
        ];

        for (let url of excludedUrlsPerfectMatch) {
            if(targetUrl == url) {
                return true
            }
        }

        for(let url of excludedUrlsContainMatch) {
            if(targetUrl.match(url)) {
                return true
            }
        }

        return false
    }
}
  