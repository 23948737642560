// import { EqualityModule } from "src/app/equality/equality.module";
import { GrowYourBusinessModule } from "src/app/grow-your-business/grow-your-business.module";

export const environment = {
    production: false,
    USERS_API: 'https://kkjk0fmo6g.execute-api.eu-west-1.amazonaws.com/dev/users',
    COURSES_API: 'https://0e1sljq48g.execute-api.eu-west-1.amazonaws.com/dev',
    COURSE_ENROLLMENTS_API: 'https://h7p5ejhlzl.execute-api.eu-west-1.amazonaws.com/dev',
    PRESIGNED_URLS_API: 'https://5zcx4x5anb.execute-api.eu-west-1.amazonaws.com/dev',
    USERS_MESSAGING_API: 'https://6jb2zlz4ye.execute-api.eu-west-1.amazonaws.com/dev',
    POOL_DATA: {
        userPoolId : 'eu-west-1_manKudW5I',
        userPoolClientId : '115cmtpfn77hno2rojvh2ob746'
    },

    // CORE_MODULE: [EqualityModule],
    CORE_MODULE: [GrowYourBusinessModule],

    USERS_ADMIN_API:'https://7okq7y17kk.execute-api.eu-west-1.amazonaws.com/dev/users',
    COURSES_ADMIN_API:'https://2yu4wr9vp4.execute-api.eu-west-1.amazonaws.com/dev/courses',
    MESSAGES_ADMIN_API: 'https://6cd9vmhmki.execute-api.eu-west-1.amazonaws.com/dev',
    PROGRAM_SPECIFIC_API: 'https://us41q68kr0.execute-api.eu-west-1.amazonaws.com/dev',
    COSMOTE_REGISTER_API: 'https://c1u7jqr56m.execute-api.eu-west-1.amazonaws.com/dev',

    LOGIN_URL: 'growyourbusiness-login',

    GOOGLE_ANALYTICS_ID: 'G-Z0H0Y3H0RC',
    FB_ID : '',

    ADMIN_REPORTING_API: 'https://wn2dx2lm27.execute-api.eu-west-1.amazonaws.com/dev',
    TRACK_EVENTS_API: 'https://6e6qkdexu2.execute-api.eu-west-1.amazonaws.com/dev/logs',
    SENTRY_DSN: null,
    NEWSLETTER_API: 'https://vi0k3lbxl2.execute-api.eu-west-1.amazonaws.com/dev/newsletter',



    MESSAGING_API: 'https://c7ckwogpn7.execute-api.eu-west-1.amazonaws.com/dev/'
};
