<app-spinner name="forgot-password"> </app-spinner>
<div style="gap: 32px;padding: 96px 32px 48px" fxLayout="column" fxLayoutAlign="start center"  class="ellipse">
    <div fxLayout="column" fxLayoutAlign="start center"  [ngStyle.gt-xs]="{ 'width':'350px'}" [ngStyle.lt-sm]="{ 'width':'250px'}">
        <div fxLayout="column" fxLayoutAlign="start center"  style="gap: 24px;margin-bottom:32px" >
            <img src="/assets/forgot-password.png" style="width: 109px;height: 83.5px;">
            <div fxLayout="column" fxLayoutAlign="start center" style="gap: 12px;">
                <span class="gray-900" style="font-size:24px; font-weight:600; line-height: 32px; text-align: center;">Ξεχάσατε το password;</span>
                <span style="text-align:center" class="gray-500">
                    Συμπληρώστε το email σας, και θα λάβετε μήνυμα για να δημιουργήσετε ένα νέο password.
                </span>
            </div>
        </div>

        <div fxLayout="column" style="width:100%">
            <input required matInput type="text" placeholder="Email" [formControl]="userEmail"
                [ngClass]=" {'input-error': userEmail.invalid && userEmail.touched}"
                class="input" matInput> 
            <div style="min-height:16px; padding:0px 12px 10px">
                <span class="error-message" *ngIf="userEmail.hasError('pattern') && userEmail.touched">
                    Το email δεν έχει σωστή μορφή
                </span>
                <span class="error-message" *ngIf="userEmail.hasError('invalid') && userEmail.touched">
                    {{userEmail.errors?.['invalid']}}
                </span>
            </div>
        </div>

        <div *ngIf="sendedCodeSuccessMessage" style="background-color: #ECFDF3; padding:16px; border-radius: 16px; width:100%; box-sizing: border-box; margin-bottom:24px;gap: 16px;" 
        fxLayout="row"> 
            <img src="/assets/success-check-icon.png" alt="" style="width: 20px;height: 20px;">
            <span style="color: #0D9403; font-size:14px; line-height:20px">{{sendedCodeSuccessMessage? 
                'Σας στείλαμε στο email σας οδηγίες για την ανάκτηση του password. Αν δεν το βρείτε στο inbox, σας, μην ξεχάσετε να δείτε και τα spam σας.' : ''}}</span>
        </div>

        <button mat-raised-button style= "font-size:16;font-weight:700; width:100%" color="primary"  [disabled] = "userEmail.invalid" (click)="onForgotPassword()"
         class="forgot-password"> Αποστολή</button>

    </div>
</div>