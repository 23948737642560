import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { MyMessagesComponent } from "src/app/common/components/my-messages.component";
import { AuthService } from "src/app/common/services/auth.service";
import { MessagesService } from "src/app/common/services/messages-service";

@Component({
    templateUrl: './grow-your-business-my-messages.component.html'
})
export class GrowYourBusinessMyMessagesComponent extends MyMessagesComponent {

    constructor(
        route: ActivatedRoute,
        dialog: MatDialog,
        authService: AuthService,
        messagesService: MessagesService
    ) { 
        super(route, dialog, authService, messagesService);
    }

}