import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Data } from "@angular/router";
import { map } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
})
export class GrowYourBusinessService {


    constructor(
        private httpClient: HttpClient
    ) { }

    changeEmail(userId: string, email: string) {
        return this.httpClient.put(`${environment.PROGRAM_SPECIFIC_API}/users/${userId}/email-change`, { email })
    }

    getEmployeeRegistrationDetails(employeeId: string) {
        return this.httpClient.get(`${environment.PROGRAM_SPECIFIC_API}/presignup-details/${employeeId}`)
            .pipe(map((data: Data) => data['data']))
    }

    completeEmployeeRegistration(sendData: any) {
        return this.httpClient.put(`${environment.PROGRAM_SPECIFIC_API}/employees/register`, sendData)
    }

    completeEmployerRegistration(sendData: any) {
        return this.httpClient.post(`${environment.COSMOTE_REGISTER_API}/register`, sendData)
    }

    enrollNewUserAsEmployee(sendData: { email: string, courses: any[] }) {
        return this.httpClient.post(`${environment.COSMOTE_REGISTER_API}/register-employee`, sendData)
            .pipe(map((data: Data) => data['data']['data']))
    }

    enrollUserOnCourses(userId: string, courses: any) {
        return this.httpClient.put(`${environment.PROGRAM_SPECIFIC_API}/users/${userId}/courses`, {
            courses: courses
        })
    }

    enrollEmployerOnCoursesFirssTime(data: { userId: string, courses: any[], sonOf: string }) {
        return this.httpClient.put(`${environment.PROGRAM_SPECIFIC_API}/users/${data.userId}/courses`, {
            courses: data.courses,
            sonOf: data.sonOf
        })
    }

    checkVatNumber(vatNumber: string) {
        return this.httpClient.get(`${environment.PROGRAM_SPECIFIC_API}/validate-company-vat/${vatNumber}`)
            .pipe(map((data: Data) => data['data']))
    }

    checkEmail(email: string) {
        console.log(email)
        return this.httpClient.get(`${environment.PROGRAM_SPECIFIC_API}/validate-email/${email}`)
    }

    getMyEnrollmentsWithEmployees() {
        return this.httpClient.get(`${environment.PROGRAM_SPECIFIC_API}/me/enrollments-with-employees`)
            .pipe(map((data: Data) => data['data']))
    }

    resendEmailInvitation(employeeId: string) {
        return this.httpClient.put(`${environment.PROGRAM_SPECIFIC_API}/employees/${employeeId}/invite`, {})
    }

    getCoursesDashboard(params: { startTime?: number, endTime?: number, format?: string }) {
        return this.httpClient.get(`${environment.ADMIN_REPORTING_API}/courses-dashboard`, { params })
    }

    getUsersDashboard(filters: any, params: { startTime?: number, endTime?: number, format?: string }) {
        return this.httpClient.post(`${environment.ADMIN_REPORTING_API}/users-dashboard`, { filters }, { params })
            .pipe(map((data: Data) => data['data']))
    }

    getVatNumbersDashboard(filters: any, params: { startTime?: number, endTime?: number, format?: string }) {
        return this.httpClient.post(`${environment.ADMIN_REPORTING_API}/vats-dashboard`, { filters }, { params })
            .pipe(map((data: Data) => data['data']))
    }


    getCourseEnrollments() {
        return this.httpClient.get(`${environment.COURSE_ENROLLMENTS_API}/course-enrollments/`)
            .pipe(map((data: Data) => data['data']))
    }


    


}