<div class="main">

    <!-- <div class="header-section">
        <div class="container">
            <div class="page-header">
                <div class="text">
                    Ερωτήσεις
                </div>
            </div>
        </div>
    </div> -->
    <img class="logo" style="max-width:460px" src="assets/growYourBusiness@x2.png">

    @if(role() && role() === 'admin') {
    <div style="display: flex;gap: 12px;">
        @for (course of courses(); track course.courseId) {
        <div (click)="getCourseConversations(course)"
            style="cursor: pointer;display: inline-flex;padding: var(--spacing-xs, 4px) var(--spacing-xs, 4px) var(--spacing-xs, 4px) 10px;justify-content: center;align-items: center;gap: 3px;border-radius: var(--radius-sm, 6px);border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);background: var(--Colors-Background-bg-primary, #FFF);">
            <div style="display: flex;align-items: center;gap: var(--spacing-sm, 6px);">
                <div
                    style="color: var(--colors-text-text-secondary-700, #344054);text-align: center;font-family: var(--Font-family-font-family-body, Inter);font-size: var(--Font-size-text-sm, 14px);font-style: normal;font-weight: 500;line-height: var(--Line-height-text-sm, 20px); /* 142.857% */">
                    {{course.title}}
                </div>
            </div>
        </div>
        }
    </div>
    }


    <div class="container">
        <div class="content">
            <div class="side-panel" [ngClass]="{'mobile-hide': isConversationRoute()}">
                <div class="card-header">
                    <div class="content">
                        <!-- <div class="text-and-supporting-text">
                            <div
                                style="display: flex;align-items: center;gap: var(--spacing-md, 8px);align-self: stretch;">
                                <div
                                    style="color: var(--colors-text-text-primary-900, #101828);/* Text lg/Semibold */font-family: var(--Font-family-font-family-body, Inter);font-size: var(--Font-size-text-lg, 18px);font-style: normal;font-weight: 600;line-height: var(--Line-height-text-lg, 28px); /* 155.556% */">
                                    Ερωτήσεις
                                </div>

                            </div>
                        </div> -->
                        <div class="actions">
                            <div matRipple class="button" (click)="askNewQuestionDialog(askTmpl)">
                                <img src="assets/edit-5.svg" alt="" width="20px" height="20px">
                                Κάνε μία ερώτηση
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 500px;overflow: scroll;scrollbar-width: none;width: 100%;">
                    @for (message of conversations(); track message.conversationId) {
                    <div class="message" (click)="getMessages(message)">
                        <div class="avatar-and-time">
                            <div class="avatar-and-dot">
                                @if(message.unreadMessages) {
                                <div class="dot"></div>
                                } @else if(message.resolved) {
                                <div class="dot-done"></div>
                                }

                                <div class="avatar-label-group">
                                    <div [ngStyle]="{'background-image': 'url(' + message.receiverAvatar + ')'}" class="avatar"></div>
                                    <div class="text-and-supporting-text">
                                        <div class="text">
                                            {{message.course.title}}
                                        </div>
                                        <div class="supporting-text">
                                            {{message.receiverName}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="supporting-text">
                                {{message.timestamp | timeAgo}}
                            </div>
                        </div>
                        <div class="message-preview-wrap">
                            {{message.lastMessage?.text}}
                        </div>
                    </div>
                    } @empty {
                    <!-- <li>There are no items.</li> -->
                    }
                </div>

            </div>

            <div class="content" [ngClass]="{'mobile-hide': !isConversationRoute()}">
                <div class="card-header">
                    <div class="content">
                        <div class="avatar-and-text">
                            <div [ngStyle]="{'background-image': 'url(' + conversation()?.receiverAvatar + ')'}" class="avatar"></div>
                            <div class="text-and-supporting-text">
                                <div class="text">
                                    {{conversation()?.course?.title}}
                                </div>
                                <div class="supporting-text">
                                    {{conversation()?.receiverName}}
                                </div>
                            </div>
                        </div>
                        <div class="actions">
                            @if(conversation()?.conversationId && role() && role() !== 'user') {
                            @if (!conversation()?.resolved) {
                            <div matRipple class="button-secondary" (click)="resolveConversation(true)">
                                <div class="text">
                                    Επίλυση ερώτησης
                                </div>
                            </div>
                            } @else {
                            <div matRipple class="button-accent" (click)="resolveConversation(false)">
                                <div class="text">
                                    Ακύρωση επίλυσης
                                </div>
                            </div>
                            }

                            }
                            @if (conversation()?.conversationId) {

                            <div matRipple (click)="goBack()" class="button mobile-shown"
                                style="border: 1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD);
                                    background: var(--Component-colors-Components-Buttons-Secondary-button-secondary-bg, #FFF);">
                                <div class="text"
                                    style="color: var(--Component-colors-Components-Buttons-Secondary-button-secondary-fg, #344054);">
                                    Πίσω
                                </div>
                            </div>


                            <div matRipple (click)="reply(replyTmpl)" class="button">
                                <div class="text">
                                    Απάντηση
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div class="divider"></div>
                </div>
                <div class="container">
                    <!-- <div class="section"></div> -->
                    <!-- <div class="content-divider"></div> -->
                    <div #messagesEl id="messagesEl" class="section"
                        style="height: 500px;overflow: scroll;scrollbar-width: none;align-items: center;">
                        <div style="height: 12px;"></div>

                        @for (message of conversation()?.messages; track message.messageId) {
                        @if (message.firstUnread){
                        <div class="content-divider">
                            <div class="divider"></div>
                            <div class="text">Αδιάβαστα</div>
                            <div class="divider"></div>
                        </div>
                        }
                        @if(message.senderId !== this.user().userId) {
                        <div class="row">
                            <div class="message">
                                <div class="avatar"></div>
                                <div class="content">
                                    <div class="name-and-time">
                                        <div class="text">{{message.senderName}}</div>
                                        <div class="subtext">{{message.timestamp | timeAgo}}</div>
                                    </div>
                                    <!-- <div class="input">
                                        <div class="text">
                                            {{message.text}}
                                        </div>
                                    </div> -->
                                    @if (message.text) {
                                    <div class="input">

                                        <div class="text">
                                            {{message.text}}
                                        </div>
                                    </div>
                                    } @else if (message.attachment) {
                                    <a [href]="message.attachment.url">
                                        <div class="input">
                                            <div class="content">
                                                <div class="file-type-icon">
                                                    @if(message.attachment.fileType ==="application/pdf") {
                                                    <img src="/assets/icon-pdf.svg" alt="">
                                                    } @else {
                                                    <img src="/assets/icon-img.svg" alt="">
                                                    }
                                                </div>
                                                <div class="content">
                                                    <div class="text-and-supporting-text">
                                                        <div class="text">
                                                            {{message.attachment.name}}
                                                        </div>
                                                        <div class="supporting-text">
                                                            {{onFormatBytes(message.attachment.size)}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    }
                                </div>
                            </div>
                        </div>
                        } @else {
                        <div class="row-mine">
                            <div class="message">
                                <div class="content">
                                    <div class="name-and-time">
                                        <div class="text">{{message.senderName}}</div>
                                        <div class="subtext">{{message.timestamp | timeAgo}}</div>
                                    </div>
                                    @if (message.text) {
                                    <div class="input">

                                        <div class="text">
                                            {{message.text}}
                                        </div>
                                    </div>
                                    } @else if (message.attachment) {
                                    <a [href]="message.attachment.url">
                                        <div class="input">
                                            <div class="content">
                                                <div class="file-type-icon">
                                                    @if(message.attachment.fileType ==="application/pdf") {
                                                    <img src="/assets/icon-pdf.svg" alt="">
                                                    } @else {
                                                    <img src="/assets/icon-img-red.svg" alt="">
                                                    }
                                                </div>
                                                <div class="content">
                                                    <div class="text-and-supporting-text">
                                                        <div class="text">
                                                            {{message.attachment.name}}
                                                        </div>
                                                        <div class="supporting-text">
                                                            {{onFormatBytes(message.attachment.size)}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    }
                                </div>
                            </div>
                        </div>
                        }

                        } @empty {
                        <div class="svg-background"
                            style="display: flex;width: 512px;justify-content: center;align-items: center;">
                            <div class="circle-svg-background">
                                <div class="text">
                                    Κάνε μία νέα ερώτηση ή επίλεξε μία υφιστάμενη
                                </div>

                            </div>
                        </div>
                        }
                        @if (conversation()?.resolved){
                        <div class="content-divider">
                            <div class="divider"></div>
                            <div class="text">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_3488_546967)">
                                        <path
                                            d="M8.74992 14L12.2499 17.5L19.2499 10.5M25.6666 14C25.6666 20.4433 20.4432 25.6666 13.9999 25.6666C7.5566 25.6666 2.33325 20.4433 2.33325 14C2.33325 7.55666 7.5566 2.33331 13.9999 2.33331C20.4432 2.33331 25.6666 7.55666 25.6666 14Z"
                                            stroke="#38B22F" stroke-width="2.33333" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3488_546967">
                                            <path
                                                d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
                                                fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>


                                Το ερώτημα επιλύθηκε
                            </div>
                            <div class="divider"></div>
                        </div>
                        <div style="height: 12px;"></div>
                        }
                    </div>
                </div>
                <!-- <div class="message-input"></div> -->
            </div>
        </div>

    </div>
</div>




<ng-template #replyTmpl>

    <div class="modal">
        <div class="modal-header">
            <div class="content">
                <div class="featured-icon">
                    <img src="assets/share.svg" alt="">
                </div>
                <div class="header-text">Απάντηση</div>
            </div>
            <div matRipple [mat-dialog-close]="true" class="button-close-x">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#98A2B3" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
            <div class="divider"></div>
        </div>
        <div class="content">
            <textarea class="reply" [formControl]="messageFormCtrl" matInput placeholder="Απάντηση"></textarea>

            <input hidden #fileInput type="file" (change)="OnFileSelected($event)" style="display:none">

            @if(!uploadingFile()) {
            <div class="uploadFile" (click)="fileInput.click()">Επισύναψη αρχείου (Εικόνα ή pdf)</div>



            @if(errorMessage()){
            <div class="error-msg">
                <div>
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.3">
                            <path
                                d="M6 19C6 11.8203 11.8203 6 19 6C26.1797 6 32 11.8203 32 19C32 26.1797 26.1797 32 19 32C11.8203 32 6 26.1797 6 19Z"
                                stroke="#D92D20" stroke-width="2" />
                        </g>
                        <g opacity="0.1">
                            <path
                                d="M1 19C1 9.05888 9.05888 1 19 1C28.9411 1 37 9.05888 37 19C37 28.9411 28.9411 37 19 37C9.05888 37 1 28.9411 1 19Z"
                                stroke="#D92D20" stroke-width="2" />
                        </g>
                        <g clip-path="url(#clip0_4890_415163)">
                            <path
                                d="M19.0001 15.6666V19M19.0001 22.3333H19.0084M27.3334 19C27.3334 23.6023 23.6025 27.3333 19.0001 27.3333C14.3977 27.3333 10.6667 23.6023 10.6667 19C10.6667 14.3976 14.3977 10.6666 19.0001 10.6666C23.6025 10.6666 27.3334 14.3976 27.3334 19Z"
                                stroke="#D92D20" stroke-width="1.66667" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_4890_415163">
                                <rect width="20" height="20" fill="white" transform="translate(9 9)" />
                            </clipPath>
                        </defs>
                    </svg>

                </div>
                <div class="content">
                    <div class="text-and-supporting-text">
                        <div class="text-wrap">
                            <div>{{errorMessage()}}</div>
                            <div style="flex:1"></div>
                            <div class="close-button" (click)="errorMessage.set(null)">
                                <img src="assets/icon-close-x.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }




            } @else {
            <div class="file-upload-item-base">
                <div class="content">
                    <div class="file-type-icon">
                        <img src="/assets/file_icon.svg">
                    </div>
                    <div class="content">
                        @if (this.uploadingFile()?.status === 'uploaded') {
                        <div (click)="this.uploadingFile.set(null);" matRipple class="delete-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                fill="none">
                                <path
                                    d="M13.3333 4.99996V4.33329C13.3333 3.39987 13.3333 2.93316 13.1517 2.57664C12.9919 2.26304 12.7369 2.00807 12.4233 1.84828C12.0668 1.66663 11.6001 1.66663 10.6667 1.66663H9.33333C8.39991 1.66663 7.9332 1.66663 7.57668 1.84828C7.26308 2.00807 7.00811 2.26304 6.84832 2.57664C6.66667 2.93316 6.66667 3.39987 6.66667 4.33329V4.99996M8.33333 9.58329V13.75M11.6667 9.58329V13.75M2.5 4.99996H17.5M15.8333 4.99996V14.3333C15.8333 15.7334 15.8333 16.4335 15.5608 16.9683C15.3212 17.4387 14.9387 17.8211 14.4683 18.0608C13.9335 18.3333 13.2335 18.3333 11.8333 18.3333H8.16667C6.76654 18.3333 6.06647 18.3333 5.53169 18.0608C5.06129 17.8211 4.67883 17.4387 4.43915 16.9683C4.16667 16.4335 4.16667 15.7334 4.16667 14.3333V4.99996"
                                    stroke="#475467" stroke-width="1.66667" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </div>
                        }
                        <div class="text-and-supporting-text">
                            <div class="text">{{this.uploadingFile()?.name}}
                            </div>
                            <div class="supporting-text">{{onFormatBytes(this.uploadingFile()?.size || 0)}}</div>
                        </div>
                        <div class="progress-bar">
                            <mat-progress-bar mode="determinate"
                                [value]="this.uploadingFile()?.percentage"></mat-progress-bar>
                            <div class="percentage">
                                @if (this.uploadingFile()?.percentage === 100) {
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_3488_546967)">
                                        <path
                                            d="M8.74992 14L12.2499 17.5L19.2499 10.5M25.6666 14C25.6666 20.4433 20.4432 25.6666 13.9999 25.6666C7.5566 25.6666 2.33325 20.4433 2.33325 14C2.33325 7.55666 7.5566 2.33331 13.9999 2.33331C20.4432 2.33331 25.6666 7.55666 25.6666 14Z"
                                            stroke="#38B22F" stroke-width="2.33333" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3488_546967">
                                            <path
                                                d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
                                                fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                } @else {
                                {{this.uploadingFile()?.percentage || 0 }}%
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            }
        </div>

        <div class="modal-actions">

            <div class="divider-wrap">
                <div class="divider"></div>
            </div>
            <div class="content">

                <div matRipple [mat-dialog-close]="true" class="button">
                    <div>Ακύρωση</div>
                </div>

                <div matRipple class="button primary"
                    (click)="sendMessage({text: messageFormCtrl.value, attachment: this.uploadingFile()}, replyTmpl)">
                    <div class="text">Υποβολή</div>
                </div>
            </div>
        </div>


    </div>
</ng-template>



<ng-template #askTmpl>
    <div class="modal">
        <div class="modal-header">
            <div class="content">
                <div class="featured-icon">
                    <img src="assets/icon-edit-05.svg" alt="">
                </div>
                <div class="header-text">Κάνε μία ερώτηση</div>
            </div>
            <div matRipple [mat-dialog-close]="true" class="button-close-x">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#98A2B3" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
            <div class="divider"></div>
        </div>
        <div class="content">

            <div style="display: flex;gap: 24px;width: 600px;">
                <div>
                    Μάθημα:
                </div>
                <mat-select [formControl]="selectedCourseFormCtrl" placeholder="Επιλογή μαθήματος">
                    <mat-option *ngFor="let course of coursesInstructors()" [value]="course">{{course.title}} - {{course.instructor.name}}</mat-option>
                </mat-select>
            </div>




            <textarea rows="10" class="reply" [formControl]="messageFormCtrl" matInput placeholder="Ερώτηση"></textarea>
            <input hidden #fileInput type="file" (change)="OnFileSelected($event)" style="display:none">
            @if(!uploadingFile()) {
            <div class="uploadFile" (click)="fileInput.click()">Επισύναψη αρχείου (Εικόνα ή pdf)</div>
            @if(errorMessage()){
            <div class="error-msg">
                <div>
                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.3">
                            <path
                                d="M6 19C6 11.8203 11.8203 6 19 6C26.1797 6 32 11.8203 32 19C32 26.1797 26.1797 32 19 32C11.8203 32 6 26.1797 6 19Z"
                                stroke="#D92D20" stroke-width="2" />
                        </g>
                        <g opacity="0.1">
                            <path
                                d="M1 19C1 9.05888 9.05888 1 19 1C28.9411 1 37 9.05888 37 19C37 28.9411 28.9411 37 19 37C9.05888 37 1 28.9411 1 19Z"
                                stroke="#D92D20" stroke-width="2" />
                        </g>
                        <g clip-path="url(#clip0_4890_415163)">
                            <path
                                d="M19.0001 15.6666V19M19.0001 22.3333H19.0084M27.3334 19C27.3334 23.6023 23.6025 27.3333 19.0001 27.3333C14.3977 27.3333 10.6667 23.6023 10.6667 19C10.6667 14.3976 14.3977 10.6666 19.0001 10.6666C23.6025 10.6666 27.3334 14.3976 27.3334 19Z"
                                stroke="#D92D20" stroke-width="1.66667" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_4890_415163">
                                <rect width="20" height="20" fill="white" transform="translate(9 9)" />
                            </clipPath>
                        </defs>
                    </svg>

                </div>
                <div class="content">
                    <div class="text-and-supporting-text">
                        <div class="text-wrap">
                            <div>{{errorMessage()}}</div>
                            <div style="flex:1"></div>
                            <div class="close-button" (click)="errorMessage.set(null)">
                                <img src="assets/icon-close-x.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            } @else {
            <div class="file-upload-item-base">
                <div class="content">
                    <div class="file-type-icon">
                        <img src="/assets/file_icon.svg">
                    </div>
                    <div class="content">
                        @if (this.uploadingFile()?.status === 'uploaded') {
                        <div (click)="this.uploadingFile.set(null);" matRipple class="delete-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                fill="none">
                                <path
                                    d="M13.3333 4.99996V4.33329C13.3333 3.39987 13.3333 2.93316 13.1517 2.57664C12.9919 2.26304 12.7369 2.00807 12.4233 1.84828C12.0668 1.66663 11.6001 1.66663 10.6667 1.66663H9.33333C8.39991 1.66663 7.9332 1.66663 7.57668 1.84828C7.26308 2.00807 7.00811 2.26304 6.84832 2.57664C6.66667 2.93316 6.66667 3.39987 6.66667 4.33329V4.99996M8.33333 9.58329V13.75M11.6667 9.58329V13.75M2.5 4.99996H17.5M15.8333 4.99996V14.3333C15.8333 15.7334 15.8333 16.4335 15.5608 16.9683C15.3212 17.4387 14.9387 17.8211 14.4683 18.0608C13.9335 18.3333 13.2335 18.3333 11.8333 18.3333H8.16667C6.76654 18.3333 6.06647 18.3333 5.53169 18.0608C5.06129 17.8211 4.67883 17.4387 4.43915 16.9683C4.16667 16.4335 4.16667 15.7334 4.16667 14.3333V4.99996"
                                    stroke="#475467" stroke-width="1.66667" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </div>
                        }
                        <div class="text-and-supporting-text">
                            <div class="text">{{this.uploadingFile()?.name}}
                            </div>
                            <div class="supporting-text">{{onFormatBytes(this.uploadingFile()?.size || 0)}}</div>
                        </div>
                        <div class="progress-bar">
                            <mat-progress-bar mode="determinate"
                                [value]="this.uploadingFile()?.percentage"></mat-progress-bar>
                            <div class="percentage">
                                @if (this.uploadingFile()?.percentage === 100) {
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_3488_546967)">
                                        <path
                                            d="M8.74992 14L12.2499 17.5L19.2499 10.5M25.6666 14C25.6666 20.4433 20.4432 25.6666 13.9999 25.6666C7.5566 25.6666 2.33325 20.4433 2.33325 14C2.33325 7.55666 7.5566 2.33331 13.9999 2.33331C20.4432 2.33331 25.6666 7.55666 25.6666 14Z"
                                            stroke="#38B22F" stroke-width="2.33333" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3488_546967">
                                            <path
                                                d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
                                                fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                } @else {
                                {{this.uploadingFile()?.percentage || 0 }}%
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
        <div class="modal-actions">
            <div class="divider-wrap">
                <div class="divider"></div>
            </div>
            <div class="content">

                <div matRipple [mat-dialog-close]="true" class="button">
                    <div>Ακύρωση</div>
                </div>

                <div matRipple class="button primary"
                    [ngStyle]="{'pointer-events': selectedCourseFormCtrl.valid && messageFormCtrl.valid ? 'auto' : 'none', opacity: selectedCourseFormCtrl.valid && messageFormCtrl.valid ? 1 : 0.5}"
                    (click)="submitNewQuestion({text: messageFormCtrl.value, attachment: this.uploadingFile(), course: selectedCourseFormCtrl.value}, askTmpl)">
                    <div class="text">Υποβολή</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>