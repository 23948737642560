import { SpinnerService } from './spinner.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'app-spinner',
    template: ` <div *ngIf="show">
                <mat-spinner class="loading-indicator" >
                </mat-spinner>
                <ng-content></ng-content>
                </div>`,
    styles: [`.loading-indicator {
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        overflow: show;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }`]
})
export class SpinnerComponent implements OnInit, OnDestroy {

    @Input() name!: string;
    @Input() show = false;

    constructor(private spinnerService: SpinnerService) {}

    ngOnInit(): void {
        if (!this.name) {
            throw new Error("Spinner must have a 'name' attribute.");
        }

        this.spinnerService._register(this);
    }

    ngOnDestroy(): void {
        this.spinnerService._unregister(this);
    }
}
