import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: '',
})
export class FooterComponent {

    onNavigateByUrl(url: string) {
        window.open(url, "_blank");
    }
}
