<app-spinner name="employee-registration"></app-spinner>

<div *ngIf="alreadyRegistered; else employeeRegistrationForm" fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center" style="width:360px; padding:96px 0px">
        <img src="/assets/employee-check.png" style="width:109px;height:84px; margin-bottom:24px" alt="">
        <span style="color: var(--gray-900, #101828);text-align: center;font-size: 24px;font-style: normal;font-weight: 700;
        line-height: 32px; margin-bottom: 12px;">Η εγγραφή έχει ολοκληρωθεί!</span> 

        <span style="color: var(--gray-500, #667085);text-align: center;font-size: 16px;font-style: normal;font-weight: 400;
        line-height: 24px; margin-bottom:32px"> Εχετε ήδη ολοκληρώσει την εγγραφη εργαζομένου και μπορείτε να συνδεθείτε στην πλατφόρμα πατώντας το παρακάτω κουμπί.</span>
    
        <button mat-raised-button color="primary" style="width:100%" (click)="onNavigateToLogin()"> Συνδέσου εδώ</button>
        
    </div> 
</div>

<ng-template #employeeRegistrationForm >

    <!-- <div fxLayout> </div> -->


    <div fxLayout="column"[formGroup]="employeeForm" fxLayoutAlign="start center">
        <div style="padding: 32px 0px 0px 0px;" fxLayout="column" fxLayoutAlign="center center">
            <img style="max-width:460px"  [ngStyle.xs]="{'max-width':'300px'}" src="assets/growYourBusiness@x2.png">
            <span class="gray-900" style="padding:24px 0px 48px 0px; font-size:36px; font-weight:700;line-height:44px;letter-spacing: -0.72px;">Καλως ήρθατε!</span>
        </div>
        <div fxLayout="column"fxLayoutAlign="start start" style="width:480px" [ngStyle.xs]="{'width': '300px'}"> 
            <span class="gray-700" style="color: var(--gray-700, #344054);font-size: 16px;font-style: normal;font-weight: 700; margin-bottom:24px;line-height: 24px; text-align: center;">Συμπληρώστε τα στοιχεία σας</span>
            <ng-container formGroupName="personalDetails">
                <div fxLayout="row"  style="gap: 32px;width:100%" fxLayout.xs="column" fxLayoutGap.xs="0px">
                    <div fxLayout="column" fxFlex>
                        <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Όνομα*</span> -->
                        <input formControlName="name" placeholder="Όνομα" type="text" 
                            [ngClass]=" {'input-error': employeeForm.get('personalDetails.name')?.invalid && employeeForm.get('personalDetails.name')?.touched}"
                            class="input" matInput> 
                        <div style="height:16px; padding:0px 12px 8px">
                            <span class="error-message" *ngIf="employeeForm.get('personalDetails.name')?.hasError('required') && employeeForm.get('personalDetails.name')?.touched">
                                Το πεδίο ειναι υποχρεωτικό
                            </span>
                        </div>
                    </div>

                    <div fxLayout="column" fxFlex>
                        <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Επίθετο*</span> -->
                        <input formControlName="lastName" placeholder="Επίθετο" type="text" 
                            [ngClass]=" {'input-error': employeeForm.get('personalDetails.lastName')?.invalid && employeeForm.get('personalDetails.lastName')?.touched}"
                            class="input" matInput> 
                        <div style="height:16px; padding:0px 12px 8px">
                            <span class="error-message" *ngIf="employeeForm.get('personalDetails.lastName')?.hasError('required') && employeeForm.get('personalDetails.lastName')?.touched">
                                Το πεδίο ειναι υποχρεωτικό
                            </span>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" style="width:100%">
                    <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Πατρώνυμο*</span> -->
                    <input formControlName="sonOf" placeholder="Πατρώνυμο" type="text" 
                        [ngClass]=" {'input-error': employeeForm.get('personalDetails.sonOf')?.invalid && employeeForm.get('personalDetails.sonOf')?.touched}"
                        class="input" matInput> 
                    <div style="height:16px; padding:0px 12px 8px">
                        <span class="error-message" *ngIf="employeeForm.get('personalDetails.sonOf')?.hasError('required') && employeeForm.get('personalDetails.sonOf')?.touched">
                            Το πεδίο ειναι υποχρεωτικό
                        </span>
                    </div>
                </div>
            </ng-container>

          

            <div fxLayout="column" style="width:100%">
                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Email*</span> -->
                <input formControlName="email" placeholder="Email" type="email" 
                    name="email" id="email"
                    [ngClass]=" {'input-error': employeeForm.get('email')?.invalid && employeeForm.get('email')?.touched}"
                    class="input" matInput> 
                <div style="height:16px; padding:0px 12px 8px">
                    <span class="error-message" *ngIf="employeeForm.get('email')?.hasError('required') && employeeForm.get('email')?.touched">
                        Το πεδίο ειναι υποχρεωτικό
                    </span>
                    <span class="error-message" *ngIf="employeeForm.get('email')?.hasError('pattern') && employeeForm.get('email')?.touched">
                        Το email δεν έχει σωστή μορφή
                    </span>
                </div>
            </div>

            <ng-container formGroupName="contactDetails">
            <div fxLayout="column" style="width:100%">
                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Κινητό τηλέφωνο*</span> -->
                <input formControlName="mobile" placeholder="Κινητό τηλέφωνο*" type="text" 
                    [ngClass]=" {'input-error': employeeForm.get('contactDetails.mobile')?.invalid && employeeForm.get('contactDetails.mobile')?.touched}"
                    class="input" matInput> 
                <div style="height:16px; padding:0px 12px 8px">
                    <span class="error-message" *ngIf="employeeForm.get('contactDetails.mobile')?.hasError('required') && employeeForm.get('contactDetails.mobile')?.touched">
                        Εισάγετε αριθμό κινητού
                    </span>
                    <span class="error-message" *ngIf="employeeForm.get('contactDetails.mobile')?.hasError('pattern')  && employeeForm.get('contactDetails.mobile')?.touched">
                        Εισάγετε αριθμό κινητού
                    </span>
                </div>
            </div>
            </ng-container>



            <div fxLayout="column" style="width:100%">
                <div fxLayout="row" fxLayoutAlign="start center" style="gap: 12px;">
                    <input fxFlex formControlName="password" placeholder="Δημιουργία Password" [type]="hidePassword ? 'password' : 'text'"
                        [ngClass]=" {'input-error': employeeForm.get('password')?.invalid && employeeForm.get('password')?.touched}"
                        class="input" matInput> 
                    <mat-icon style="cursor: pointer;" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ?
                    'visibility_off' : 'visibility'}}</mat-icon>
                </div>
                <div style="height:16px; padding:0px 12px 8px">
                    <span class="error-message" *ngIf="employeeForm.get('password')?.hasError('passwordIsNotLongEnough') && employeeForm.get('password')?.touched">
                        Το password πρεπει να εχει τουλάχιστον 6 χαρακτήρες
                    </span>
                </div>
            </div>

            <div fxLayout="column" style="width:100%">
                <div fxLayout="row" fxLayoutAlign="start center" style="gap: 12px;">
                    <input fxFlex formControlName="passwordConfirmation" placeholder="Επαλήθευση Password" [type]="hidePassword ? 'password' : 'text'"
                        [ngClass]=" {'input-error': employeeForm.get('passwordConfirmation')?.invalid && employeeForm.get('passwordConfirmation')?.touched}"
                        class="input" matInput> 
                    <mat-icon style="cursor: pointer;" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ?
                    'visibility_off' : 'visibility'}}</mat-icon>
                </div>
                <div style="height:16px; padding:0px 12px 8px">
                    <span class="error-message" *ngIf="employeeForm.get('passwordConfirmation')?.touched && 
                    employeeForm.get('passwordConfirmation')?.hasError('notSame')">
                        Επαληθεύστε σωστά το password σας
                    </span>
                </div>
            </div>

            <!-- <div fxLayout="column" style="padding:24px 0px; width:100%" style="gap: 12px;">
                <div fxLayout="column">
                    <span class="gray-700" style="font-size: 14px;font-style: normal;font-weight: 700;
                    line-height: 20px">Μαθήματα που σας εχει κάνει εγγραφή ο υπεύθυνος</span>
                    <span class="gray-600" style="font-size: 14px;font-style: normal;
                    line-height: 20px;">Δεν μπορεί να γίνει αλλαγή σε αυτά τα μαθήματα</span>
                </div>

                <ng-container  *ngFor="let course of employeeDetails.courses; let i = index">
                    <mat-checkbox color="primary" class="courses" [checked] = true [disabled]="true" > {{course.title}}</mat-checkbox>
                </ng-container>

            </div> -->

            <!-- ta terms exoun ena padding-top 10 px από μόνα τους -->
            <div fxLayout="column"  [formGroup]="consentForm" style="gap: 14px;margin-bottom:32px">
                <mat-checkbox [ngClass]="consentForm.get('term1')?.invalid && consentForm.get('term1')?.touched  ? 'error-message' : 'gray-500'" 
                formControlName="term1" class="terms" > Έχω διαβάσει και συμφωνώ με τους 

                <a [ngClass]="consentForm.get('term1')?.invalid && consentForm.get('term1')?.touched  ? 'error-message' : 'gray-500'" 
                    routerLink="/growyourbusiness-terms-of-use" target="_blank">Όρους Συμμετοχής</a> 
                    και τη <a [ngClass]="consentForm.get('term1')?.invalid && consentForm.get('term1')?.touched  ? 'error-message' : 'gray-500'" 
                    routerLink="/growyourbusiness-privacy-policy" target="_blank"> Δήλωση Απορρήτου </a> και 
                    Συναινώ για τη Συλλογή και Επεξεργασία των Προσωπικών μου Δεδομένων</mat-checkbox>
                <mat-checkbox [ngClass]="consentForm.get('term2')?.invalid && consentForm.get('term2')?.touched  ? 'error-message' : 'gray-500'"   formControlName="term2" class="terms"> 
                    Δίνω τη συγκατάθεσή μου η COSMOTE και η eQuality να επικοινωνούν μαζί μου με Email και τηλεφωνικώς για 
                    ενημερώσεις σχετικά με το COSMOTE GROW YOUR BUSINESS - Certification Program*</mat-checkbox>
                <mat-checkbox  [ngClass]="consentForm.get('term3')?.invalid && consentForm.get('term3')?.touched  ? 'error-message' : 'gray-500'"  formControlName="term3" class="terms">
                    Δίνω τη συγκατάθεσή μου η COSMOTE να επικοινωνεί μαζί μου με Email 
                    και τηλεφωνικώς για ενημερώσεις σχετικά με προϊόντα και υπηρεσίες COSMOTE</mat-checkbox>
            </div>


            <div  *ngIf="error" fxLayout="row" fxLayoutAlign="center center" style="margin-bottom:32px;" >
                <app-error-message fxFlex="90"  [message]="error.message" [details] ="error.details"></app-error-message>
            </div>


            <button mat-raised-button color="primary"  [disabled]="employeeForm.invalid"  (click)="onCompleteEmployeeRegistration()"
              style=" border-radius: 74px !important; margin-top:12px; width:100%;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important">Eγγραφή
            </button>
        </div>  

    </div>

</ng-template>