<app-spinner name="manage-employees"></app-spinner>
<div style="padding:64px 0px" fxLayout="column" fxLayoutAlign="center center">
    <div style="width:640px" [ngStyle.sm]="{'width':'600px'}" [ngStyle.xs]="{'width': '300px'}" fxLayout="column">
        <div fxLayout="row" style="gap: 16px;margin-bottom:20px" fxLayout.lt-md="column">
            <div fxLayout="column" fxFlex="70" fxFlex.lt-md="100">
                <span class="cosmote-blue" style="font-size: 18px;font-style: normal;font-weight: 700;line-height: 28px;
                    margin-bottom:4px">
                    Διαχείριση συμμετοχών</span>
                <span class="cosmote-blue"
                    style=" color: var(--gray-600, #475467);font-size: 14px;font-style: normal;line-height: 20px">
                    Εδώ μπορείτε να προσθέσετε συμμετέχοντες ή/και επιπλέον μαθήματα. Μπορείτε να δηλώσετε μέχρι 2 άτομα
                    ανά επιχείρηση</span>
            </div>

            <button mat-fab extended color="success"
                style="padding:10px 16px; border-radius: 37px;font-size: 14px;font-style: normal;font-weight: 700;line-height: 20px;"
                [disabled]="managedUsers.length >= 2" (click)="onOpenEnrollUserOnCoursesDialog(enrollUserOnCourses)">
                <!-- <img src="/assets/plus-icon.png" style="width:24px;height:24px;margin-right:8px;color:white">   -->
                <mat-icon style="margin-right:8px !important">add</mat-icon>
                Προσθήκη συμμετέχοντα
            </button>


        </div>

        <mat-divider style="margin-bottom:24px"></mat-divider>

        <div fxLayout="row" fxLayout.lt-md="column" style="gap: 24px;margin-bottom:24px">
            <mat-card *ngFor="let managedUser of managedUsers;" style="gap: 16px;padding:16px" fxFlex="48"
                fxLayout="column"
                [ngStyle]="{'border': (managedUser.userId == selectedManagedUser?.userId) ? '1px solid #004B87' : null}">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutAlign="start start" style="margin-right:6px">
                        <span class="cosmote-blue" style="font-size: 14px;font-weight: 700;line-height: 20px;">
                            {{managedUser.fullName || 'Εργαζόμενος' }}</span>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <img src="/assets/email_outlined.png" style="width:16px; height:16px;margin-right:8px">
                            <span class="gray-600" style="font-size: 14px; line-height: 20px">
                                {{managedUser.email}}</span>
                        </div>
                    </div>
                    <div>
                        <img (click)="onEditManagedUser(managedUser)" matTooltip="Επεξεργασία" matTooltipClass="tooltip"
                            src="/assets/edit_outlined.png"
                            style="width:20px; height:20px;padding:10px; cursor: pointer;">
                    </div>
                </div>

                <div fxLayoutAlign="center" *ngIf="managedUser.formRegistrationCompleted"
                    style="width:190px; padding: 2px 8px;border-radius: 16px;border: 1px solid #19990F;background: #EEFCED;mix-blend-mode: multiply;">
                    <span
                        style="color: #388033;text-align: center;font-size: 12px;font-style: normal;line-height: 18px;">Η
                        εγγραφή έχει ολοκληρωθεί</span>
                </div>

                <div fxLayoutAlign="center" *ngIf="!managedUser.formRegistrationCompleted" style="width:203px; padding: 2px 8px;border-radius: 16px;border: 1px solid var(--error-200, #FECDCA);
                background: var(--error-50, #FEF3F2);mix-blend-mode: multiply;">
                    <span
                        style="color: var(--error-700, #B42318);;text-align: center;font-size: 12px;font-style: normal;line-height: 18px;">
                        Η εγγραφή δεν έχει ολοκληρωθεί
                    </span>
                </div>

                <div>
                    <div style=" margin-bottom:8px;">
                        <span class="gray-600"
                            style="font-size: 14px;font-style: normal;line-height: 20px; margin-bottom:8px;">Συμμετέχει
                            στα μαθήματα</span>
                    </div>
                    <div fxLayout="column wrap" style="gap:10px">
                        <div *ngFor="let enrolledCourse of managedUser.enrolledOnCourses" style="width: fit-content;padding:2px 8px; border-radius: 16px;
                        border: 1px solid #66D5FF;background: #EDF8FC;mix-blend-mode: multiply;">
                            <span style="color: #1A6380;text-align: center;font-family: Arial;font-size: 12px;
                            line-height: 18px;">{{enrolledCourse.title}}</span>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>

        <ng-container *ngIf="selectedManagedUser">


            <div fxLayout="column" style="margin-bottom:20px;padding-top:12px;">
                <span class="cosmote-blue" style="font-size: 18px;font-style: normal;font-weight: 700;
                    line-height: 28px;">{{selectedManagedUser.fullName}}</span>
                <span class="gray-600" *ngIf="selectedManagedUser.isEmployer" style="font-size: 14px;font-style: normal;font-weight: 400;
                    line-height: 20px;">Από εδώ μπορείτε να εγγραφείτε σε νέα μαθήματα και να αλλαξετε το email
                    σας</span>
                <span class="gray-600" *ngIf="!selectedManagedUser.isEmployer" style="font-size: 14px;font-style: normal;font-weight: 400;
                    line-height: 20px;">Από εδώ μπορείτε να αλλάξετε το email του εργαζόμενού σας και να προσθέσετε
                    επιπλέον μαθήματα. Σε περίπτωση που ο εργαζόμενος έχει ολοκληρώσει την εγγραφή του, η αλλαγή του
                    email μπορεί να γίνει μόνο από τον δικό του λογαριασμό</span>
            </div>

            <mat-divider style="margin-bottom:12px; fill: var(--gray-200, #EAECF0);"></mat-divider>

            <div fxLayout="column">
                <span class="cosmote-blue"
                    style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Email*</span>
                <div fxLayout="row" fxLayout.lt-md="column" style="gap: 10px;">
                    <input placeholder="Email" type="text" class="input" [value]="selectedManagedUser.email" matInput
                        disabled="true" style="width:320px" [ngStyle.xs]="{'width':'280px'}">
                    <div fxFlex="50" *ngIf="selectedManagedUser.invitationSent"
                        style="border-radius: 82px; border: 1px solid var(--gray-blue-400, #717BBC);background: var(--gray-blue-50, #F8F9FC);padding:12px"
                        fxlayout="row" fxLayoutAlign="start center" style="gap: 12px;">
                        <img src="/assets/purple-warning.png" style="width:20px;height:20px">
                        <span
                            style="color: var(--gray-blue-400, #717BBC);font-size: 14px;font-style: normal;font-weight: 700;line-height: 20px;">Στάλθηκε
                            πρόσκληση συμμετοχής</span>
                    </div>
                </div>
                <div style=" margin-top:12px; ">
                    <span *ngIf="!selectedManagedUser.isEmployer && !selectedManagedUser.formRegistrationCompleted"
                        style="color: #004BB7;font-size: 14px;font-style: normal;font-weight: 700;
                    line-height: 20px; cursor:pointer; margin-right:16px"
                        (click)="onResendEmailInvitation()">Επαναποστολή πρόσκλησης</span>
                    <span *ngIf="selectedManagedUser.isEmployer || !selectedManagedUser.formRegistrationCompleted"
                        style="color: #004BB7;font-size: 14px;font-style: normal;font-weight: 700;
                    line-height: 20px; cursor:pointer" (click)="onOpenEmailDialog(changeEmailDialogTemplate)">Αλλαγή
                        email </span>
                </div>
            </div>

            <mat-divider style="margin-top:24px; margin-bottom: 24px; fill: var(--gray-200, #EAECF0);"></mat-divider>
            <div style="width:100%" [ngStyle.lt-md]="{'width': '100%'}">

                <div fxLayout="column" style="gap: 12px;padding:24px 0px; width:100%">
                    <div fxLayout="column">
                        <span class="gray-700" style="font-size: 14px;font-style: normal;font-weight: 700;
                        line-height: 20px">Μαθήματα που μπορεί να γραφτεί ο/η {{selectedManagedUser.fullName}}
                            επιπλέον</span>
                        <span class="gray-600" style="font-size: 14px;font-style: normal;
                        line-height: 20px;">Επιλέξτε τα μαθήματα που επιθυμείτε να προσθέσετε στο εκπαιδευτικό
                            πλάνο</span>
                    </div>

                    <!-- Χρησιμοποιούμε indexing και ngIf για να κανουμε το choose courses να κανει re instantiate και να εμφανισει τα σωστα courses -->
                    <ng-container *ngIf="selectedManagedUser.index == 0">
                        <app-choose-courses style="padding-bottom:24px; width:70%" [ngStyle.xs]="{'width': '100%'}"
                            [courses]="getMoreCoursesForSelectedUser(selectedManagedUser)" [display]="'double'"
                            [expandable]="true"
                            (selectedCoursesChange)=" setCoursesToFormArray(enrollOnCoursesFormGroup.get('courses'), $event)"></app-choose-courses>
                    </ng-container>
                    <ng-container *ngIf="selectedManagedUser.index == 1">
                        <app-choose-courses style="padding-bottom:24px; width:70%" [ngStyle.xs]="{'width': '100%'}"
                            [courses]="getMoreCoursesForSelectedUser(selectedManagedUser)" [display]="'double'"
                            [expandable]="true"
                            (selectedCoursesChange)=" setCoursesToFormArray(enrollOnCoursesFormGroup.get('courses'), $event)"></app-choose-courses>
                    </ng-container>

                </div>

            </div>
            <div fxLayout="row" fxLayoutAlign="end center" style="gap: 16px;">
                <span class="gray-600"
                    style="padding: 18px 10px; cursor:pointer;;font-size: 16px;font-style: normal;font-weight: 600;line-height: 24px;text-align:center">Άκυρο</span>
                <button mat-raised-button color="primary" style="border-radius: 74px;"
                    [disabled]="enrollOnCoursesFormGroup.invalid"
                    (click)="onEnrollManagedUserToCourses(selectedManagedUser)">Αποθήκευση μαθημάτων</button>
            </div>
        </ng-container>


    </div>
</div>



<ng-template #changeEmailDialogTemplate>

    <mat-card *ngIf="!emailChanged else emailChangedSuccess" style="padding:24px;height:100%">

        <span class="cosmote-blue" style="font-size: 18px;font-style: normal;font-weight: 600;line-height: 28px;">Αλλαγή
            Email</span>
        <span style="color: var(--gray-600, #475467);font-size: 14px;font-style: normal;font-weight: 400;line-height: 20px;
    padding-bottom:20px"> Αλλάξτε το email του συμμετέχοντα </span>

        <ng-container [formGroup]="changeEmailFormGroup">
            <div fxLayout="column">
                <span class="cosmote-blue"
                    style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Νέο email*</span>
                <input #emailInput formControlName="newEmail" placeholder="" type="text"
                    (input)="convertToLowercase(changeEmailFormGroup.get('newEmail'), $event)"
                    [ngClass]=" {'input-error': changeEmailFormGroup.get('newEmail')?.invalid && changeEmailFormGroup.get('newEmail')?.touched}"
                    class="input" matInput>
                <div style="height:16px; padding:0px 12px 8px">
                    <span class="error-message"
                        *ngIf="changeEmailFormGroup.get('newEmail')?.hasError('required') && changeEmailFormGroup.get('newEmail')?.touched">
                        Το πεδίο ειναι υποχρεωτικό
                    </span>
                    <span class="error-message"
                        *ngIf="changeEmailFormGroup.get('newEmail')?.hasError('pattern') && changeEmailFormGroup.get('newEmail')?.touched">
                        Το email δεν έχει σωστή μορφή
                    </span>
                    <span class="error-message"
                        *ngIf="changeEmailFormGroup.get('newEmail')?.hasError('alreadyExists') && changeEmailFormGroup.get('newEmail')?.touched">
                        Το email υπάρχει ήδη
                    </span>
                </div>
            </div>

            <div fxLayout="column" style="padding-bottom:8px">
                <span class="cosmote-blue"
                    style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Επιβεβαίωση
                    email*</span>
                <input #emailInput formControlName="newEmailConfirmation" placeholder="" type="text"
                    (input)="convertToLowercase(changeEmailFormGroup.get('newEmailConfirmation'), $event)"
                    [ngClass]=" {'input-error': changeEmailFormGroup.get('newEmailConfirmation')?.invalid && changeEmailFormGroup.get('newEmailConfirmation')?.touched}"
                    class="input" matInput>
                <div style="height:16px; padding:0px 12px 8px">
                    <span class="error-message"
                        *ngIf="changeEmailFormGroup.get('newEmailConfirmation')?.invalid && changeEmailFormGroup.get('newEmailConfirmation')?.touched">
                        Επαληθεύστε σωστά το email
                    </span>
                </div>
            </div>

            <div fxLayout="row">
                <span class="gray-600"
                    style="padding: 10px 18px; cursor:pointer;font-size: 16px;font-style: normal;font-weight: 600;line-height: 24px; width:170px; text-align: center;"
                    [ngStyle.xs]="{'width': '50px'}" [mat-dialog-close]="null">Άκυρο</span>
                <button mat-raised-button color="success" style="padding: 10px 18px; font-weight:600"
                    [disabled]="changeEmailFormGroup.invalid" (click)="onChageEmail()">Αλλαγή email</button>
            </div>

        </ng-container>
    </mat-card>



    <ng-template #emailChangedSuccess>
        <mat-card style="padding:24px; height:100%" [ngStyle.lt-md]="{'padding': '12px 24px'}">
            <div style="height:268px; margin-bottom:32px" fxLayout="column" fxLayoutAlign="center center">
                <img src="/assets/green-check.png" style="width:48px; height:48px; margin-bottom:16px">
                <span
                    style="color: var(--gray-900, #101828);text-align: center;font-size: 18px;font-style: normal;font-weight: 700;line-height: 28px;">
                    Το email άλλαξε με επιτυχία
                </span>
                <!-- <span style="color: var(--gray-600, #475467);text-align: center;font-size: 14px;font-style: normal;font-weight: 400;line-height: 20px;">
                Όλες οι επικοινωνίες πλέον, θα πηγαίνουν σε αυτό το email.
            </span> -->
            </div>
            <div style="border-radius: 50px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--base-white, #FFF);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
             width:316px; padding: 10px 18px; text-align:center; cursor:pointer" [ngStyle.xs]="{'width': '180px'}"
                [mat-dialog-close]="null">
                <span
                    style="color: var(--gray-700, #344054);font-size: 16px;font-style: normal;font-weight: 700;line-height: 24px;">
                    Κλείσιμο</span>
            </div>


        </mat-card>

    </ng-template>

</ng-template>



<ng-template #enrollUserOnCourses>
    <mat-card *ngIf="!actionCompletedMessage; else userEnrolledSuccesfully" style="padding:24px; border-radius: 12px; background: var(--base-white, #FFF);
            box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);"
        [formGroup]="userEnrollMentFormGroup">

        <span class="cosmote-blue" style="font-size: 18px;font-style: normal;font-weight: 600;line-height: 28px;
        padding-bottom:20px" [ngStyle.lt-md]="{'font-size': '17px', 'padding-bottom': '6px'}">Προσθήκη
            συμμετέχοντα</span>


        <mat-radio-group color="primary" aria-label="Select an option" fxLayout="row" fxLayout.lt-md="column"
            style="gap: 12px;padding-bottom:24px" [ngStyle.lt-md]="{'padding-bottom': '0px'}"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="userType" (change)="userTypeChanged($event)">
            <div fxFlex
                style="height:48px;border-radius: 35px;border: 2px solid #004BB7;background: var(--base-white, #FFF);padding: 2px"
                [ngClass]="{'active-radio': userType == 'employer'}">
                <mat-radio-button [value]="'employer'" style="font-size:14px;"
                    [disabled]="employerAlreadyEnrolledOnCourses"> Υπεύθυνος</mat-radio-button>
            </div>
            <div fxFlex
                style="height:48px;border-radius: 35px;border: 2px solid #004BB7;background: var(--base-white, #FFF);padding: 2px"
                [ngClass]="{'active-radio': userType == 'employee'}">
                <mat-radio-button [value]="'employee'" style="font-size:14px"> Εργαζόμενος</mat-radio-button>
            </div>
        </mat-radio-group>

        <div fxLayout="column" *ngIf="userEnrollMentFormGroup.get('email')?.enabled" style="margin: 8px 0px">
            <span class="cosmote-blue"
                style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Email*</span>
            <input formControlName="email" placeholder="Νέο email*" type="text"
                (input)="convertToLowercase(userEnrollMentFormGroup.get('email'), $event)"
                [ngClass]=" {'input-error': userEnrollMentFormGroup.get('email')?.invalid && userEnrollMentFormGroup.get('email')?.touched}"
                class="input" matInput>
            <div style="height:16px; padding:0px 12px 8px">
                <span class="error-message"
                    *ngIf="userEnrollMentFormGroup.get('email')?.hasError('required') && userEnrollMentFormGroup.get('email')?.touched">
                    Το πεδίο ειναι υποχρεωτικό
                </span>
                <span class="error-message"
                    *ngIf="userEnrollMentFormGroup.get('email')?.hasError('pattern') && userEnrollMentFormGroup.get('email')?.touched">
                    Το email δεν έχει σωστή μορφή
                </span>
                <span class="error-message"
                    *ngIf="userEnrollMentFormGroup.get('email')?.hasError('alreadyExists') && userEnrollMentFormGroup.get('email')?.touched">
                    Το email υπάρχει ήδη
                </span>
            </div>
        </div>

        <div fxLayout="column" *ngIf="userEnrollMentFormGroup.get('sonOf')?.enabled" style="margin: 8px 0px">
            <span class="cosmote-blue"
                style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Πατρώνυμο*</span>
            <input formControlName="sonOf" placeholder="Πατρώνυμο*" type="text"
                [ngClass]=" {'input-error': userEnrollMentFormGroup.get('sonOf')?.invalid && userEnrollMentFormGroup.get('sonOf')?.touched}"
                class="input" matInput>
            <div style="height:16px; padding:0px 12px 8px">
                <span class="error-message"
                    *ngIf="userEnrollMentFormGroup.get('sonOf')?.hasError('required') && userEnrollMentFormGroup.get('sonOf')?.touched">
                    Το πεδίο ειναι υποχρεωτικό
                </span>
            </div>
        </div>

        <div style="margin-bottom:32px" [ngStyle.lt-md]="{'margin-bottom': '12px'}">
            <span style="font-size: 14px;font-style: normal;font-weight: 700;line-height: 20px;"
                [ngClass]="userEnrollMentFormGroup.get('courses')?.invalid && 
                                userEnrollMentFormGroup.get('courses')?.touched ? 'error-message': 'gray-700'">Επιλέξετε μαθήματα που θέλετε να δηλώσετε συμμετοχή</span>
            <div style="height:12px"></div>
            <app-choose-courses *ngIf="courses && courses.length > 0" [courses]="courses" [numberOfDisplayedCourses]="2"
                (selectedCoursesChange)=" setCoursesToFormArray(userEnrollMentFormGroup.get('courses'), $event)"></app-choose-courses>

        </div>



        <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex style="padding: 18px 10px;">
                <span class="gray-600"
                    style="cursor:pointer;font-size: 16px;font-style: normal;font-weight: 600;line-height: 24px;"
                    [mat-dialog-close]="null">Άκυρο</span>
            </div>

            <button fxFlex mat-raised-button color="success" style="border-radius: 40px;"
                (click)="onSaveNewManagedUser()" [disabled]="userEnrollMentFormGroup.invalid">Προσθήκη</button>
        </div>

    </mat-card>

    <ng-template #userEnrolledSuccesfully>
        <mat-card style="height:100%; padding:24px">
            <div fxFlex style="gap: 16px;margin-bottom: 32px;" fxLayout="column" fxLayoutAlign="center center">
                <img src="/assets/success-check-icon.png" style="width:48px;height:48px;">
                <div fxLayout="column">
                    <span class="gray-900"
                        style="text-align: center;font-size: 18px;font-style: normal;font-weight: 700;line-height: 28px;">{{actionCompletedMessage?.title}}</span>
                    <span class="gray-600"
                        style="text-align: center;font-family: Arial;font-size: 14px;font-style: normal;font-weight: 400;line-height: 20px;">
                        {{actionCompletedMessage?.details}} </span>
                </div>
            </div>
            <div style="border-radius: 50px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--base-white, #FFF);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); padding: 10px 18px; text-align:center; cursor:pointer"
                [mat-dialog-close]="null">
                <span
                    style="color: var(--gray-700, #344054);font-size: 16px;font-style: normal;font-weight: 700;line-height: 24px;">
                    Κλείσιμο</span>
            </div>
        </mat-card>
    </ng-template>

</ng-template>