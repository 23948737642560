import { Component, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { SpinnerService } from '../spinner/spinner.service';
import { User } from '../shared/models/user.model';

@Component({
  selector: 'app-my-profile',
  template: '',
})
export class MyProfileComponent implements OnInit {

    public changeEmailFormGroup = new FormGroup({
        newEmail: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        newEmailConfirmation: new FormControl('', [Validators.required, this.checkNewEmail.bind(this)]),
    });

    public changePasswordFormGroup = new FormGroup({
        'oldPassword': new FormControl('', [Validators.required]),
        'newPassword': new FormControl('', [Validators.required, Validators.minLength(6)]),
        'newPasswordConfirm': new FormControl('', [Validators.required, this.checkNewPassword.bind(this)]),
    });
    public revealOldPassword: boolean = false;
    public revealNewPassword: boolean = false;
    public changePasswordMessage: string | null = null;
    public changePasswordMessageErrorMessage: string | null = null;


    constructor(
        public authService: AuthService,
        public spinnerService: SpinnerService
        ){}

    ngOnInit(): void {
        (this.changeEmailFormGroup?.get('newEmail') as FormControl).valueChanges.subscribe((value) => {
            if( (this.changeEmailFormGroup.get('newEmailConfirmation') as FormControl).dirty) {
                this.changeEmailFormGroup?.get('newEmailConfirmation')?.updateValueAndValidity();
            }
        });

        (this.changePasswordFormGroup?.get('newPassword') as FormControl).valueChanges.subscribe((value) => {
            if( (this.changePasswordFormGroup.get('newPasswordConfirm') as FormControl).dirty) {
                this.changePasswordFormGroup?.get('newPasswordConfirm')?.updateValueAndValidity();
            }
        });
    }

    // onChangePassword() {

    //     let sendData = this.changePasswordFormGroup.value;
    //     this.spinnerService.show('change-password')
    //     this.authService.changePassword({
    //         oldPassword: sendData.oldPassword as string,
    //         newPassword: sendData.newPassword as string
    //     }).pipe(finalize(() => this.spinnerService.hide('change-password'))) 
    //     .subscribe({
    //         next: (data) => {
    //             this.changePasswordMessage = 'Ο κωδικός σας άλλαξε επιτυχώς'
    //         }, 
    //         error: (error) => {
    //             this.changePasswordMessageErrorMessage = 'Βεβαιωθείτε ότι ο τρέχον κωδικός που εισάγεται είναι σωστός';
    //         }
    //     });
    // }

     //πέρα απο το setValue  πρεπει να κανουμε και το setSelectionRange για να μην χανεται η θεση του cursor
	 convertToLowercase(control: AbstractControl<string | null, string | null> | null | undefined, event: any) {
        const input = event.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;

        (control as FormControl).setValue((control as FormControl).value.toLowerCase());

        setTimeout(() => {
            input.setSelectionRange(start, end);
        }, 1);
    }

    onClearForm(form: FormGroup) {
        form.reset();
    }

    private checkNewPassword() {
        if (this.changePasswordFormGroup?.get('newPassword')?.value === this.changePasswordFormGroup?.get('newPasswordConfirm')?.value ) {
            return null;
        }
        return { notSame: true };
    }

    private checkNewEmail() {
        if (this.changeEmailFormGroup?.get('newEmail')?.value === this.changeEmailFormGroup?.get('newEmailConfirmation')?.value ) {
            return null;
        }
        return { notSame: true };
    }

}
