
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../common/services/auth.service';


@Injectable()
export class GrowYourBusinessRedirectFromLoginGuard{

    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return  new Promise<boolean>( (resolve, reject) => {
            this.authService.isAuthenticated()
            .then((result?: any) =>   {
                if(result) {
                    this.router.navigate(['/my-courses'])
                    return resolve(false)
                } else {
                    return resolve(true)
                }
            });
        });
    }
}
