import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessagesService } from '../services/messages-service';
import { FileService } from '../services/file.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-chat-dialog',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatDialogComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private messagesService: MessagesService,
        private cdr: ChangeDetectorRef,
        private fileService: FileService,
        private userService: UserService
    ) {}

    public messageControl = new FormControl('') ;
    public userContact: any;
    @ViewChild('scrollMe') private myScrollContainer: any

    ngOnInit(): void {
        // φτιαχνω τα margin στα μηνύματα
        this.data.conversation.messages.forEach( (element: any, i: number) => {
            element.shouldMargin = this.messageShouldHaveMargin(element, i + 1)
            element.shouldDisplayUserAbove =  this.messageShouldDisplayUserAbove(element, i -1)
            console.log(element.shouldMargin)
        });

        //Αν η συνομιλία εχει καινούργια μηνύματα τότε κανω update τη βάση και μαρκάρω τα μηνύματα ας seen
        if(this.data.conversationHasNewMessages ) {
            this.messagesService.updateConversation(this.data.conversation.id, {latestMessageSeen: this.data.conversation.latestMessage.id}).subscribe({
                next: (data) => {
                    const updatedAttributes = data.updatedAttributes
                    this.data.conversation.latestMessageAdminSeen = updatedAttributes.latestMessageAdminSeen
                    this.data.conversation.latestMessageUserSeen = updatedAttributes.latestMessageUserSeen
                },
                error: (error) => {console.log(error)}
            })
        }

        if(this.data.adminMode) {
            this.userService.getUserAsContact(this.data.conversation.userId).subscribe( (user) => {
                this.userContact = user
                console.log(this.userContact)
            })
        }
    }

    isMyMessage(message: any) {

        // αυτό εδώ ειναι πατέντα προς το παρόν γιατι conversation ξεκινάει μόνο ο user
        // οπότε αν το message δεν είναι του user τότε είναι του admin.
        if(this.data.adminMode) {
            if(this.data.conversation.hasOwnProperty('adminInitiated')) {
                return true
            } else {
                return message.userId != this.data.conversation.userId
            }
        }
        return message.userId == this.data.me.userId && !this.data.conversation.hasOwnProperty('adminInitiated')
    }

    messageShouldHaveMargin(message: any, nextMessageIndex: number) {        
        if (nextMessageIndex == this.data.conversation.messages.length) {
            return true
        }
        if (message.userId != this.data.conversation.messages[nextMessageIndex].userId) {
            return true
        }
        // αν δεν εχουν περασει 100 δευτερόλεπτα τοτε δεν εχουμε margin
        if (message.userId == this.data.conversation.messages[nextMessageIndex].userId
            &&  (this.data.conversation.messages[nextMessageIndex].createdAt -  message.createdAt < 100000 )) {
            return false
        }

        return true
    }

    messageShouldDisplayUserAbove(message: any, previousMessageIndex: number): boolean {
        if(previousMessageIndex <0) {
            return true
        }

        // είναι το μήνυμα που επεται απο τον αλλο συνομιλούντα αρα πρεπει να δείξουμε το όνομα του
        if (message.userId != this.data.conversation.messages[previousMessageIndex].userId) {
            return true
        }

        if(this.data.conversation.messages[previousMessageIndex].shouldMargin) {
            return true
        }

        return false
        
    }

    onFormatBytes(bytes: number, decimals = 2) {
        this.fileService.formatBytes(bytes, decimals)
    }
        

    onDownloadFile(file: any) {
        window.location.href = file.url
    }

    formatMessageTimestamp(message: any) {
        let currentDate = Date.now()

        if(currentDate - message.createdAt < 60*60*24*7*1000) {
            return new Date(message.createdAt).toLocaleString('el-GR', {weekday: 'long', hour: 'numeric', minute: 'numeric'})
        } else {
            return new Date(message.createdAt).toLocaleString('el-GR', {day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric'}).replace(',', '')

        }
    }

    onSendNewMessage() {
        const message = this.messageControl.value

        // το μήνυμα ειναι άδειο
        if(!message) return;

        let newMessage = {
            content: {
                type: 'text',
                text: message
            },
            userId: this.data.me.userId,
            createdAt: Date.now()
        }

        // Pushare το μήνυμα στην συνομιλία και κανε scroll
        this.data.conversation.messages.push(newMessage)
        setTimeout( () => {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
            this.messageControl.setValue('')
        }, 50)

        // Κανε update τη συνομιλία στην βάση
        // Αν υπάρξει error σετταρουμε το status του μηνύματος σε failed
        this.messagesService.updateConversationMessages(this.data.conversation.id,{messages:[newMessage]}).
        subscribe({
            next: (response: any) => {
                let conversationUpdatedAttributes = response.updatedAttributes
                console.log(conversationUpdatedAttributes)
                for (let attribute in conversationUpdatedAttributes) {
                    this.data.conversation[attribute] = conversationUpdatedAttributes[attribute]       
                }
            },
            error: (error: any) => {
                this.data.conversation.messages[this.data.conversation.messages.length - 1].status = 'failed'

                this.cdr.detectChanges();
            }
        });
    }

}
