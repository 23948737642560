import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Data } from "@angular/router";
import { Observable, combineLatest, finalize } from "rxjs";
import { Course } from "src/app/common/shared/models/course.model";
import { GrowYourBusinessService } from "../grow-your-business.service";
import { atLeastOneElement } from "../custom-validators";
import { SpinnerService } from "src/app/common/spinner/spinner.service";
import { UserService } from "src/app/common/services/user.service";
import { CoursesService } from "src/app/common/services/courses.service";



interface ManagedUser {
    fullName: string;
    email: string;
    isEmployer: boolean;
    enrolledOnCourses: {courseId: string, title: string}[];
    userId: string;
    formRegistrationCompleted: boolean;
    index?: number;
    invitationSent?: boolean;
}

@Component({
    selector: 'app-manage-employees',
    templateUrl: './grow-your-business-manage-employees.component.html',
    styleUrls: ['./grow-your-business-manage-employees.component.css']
})
export class GrowYourBusinessManageEmployeesComponent implements OnInit, OnDestroy {

    private routeSubscriptionRef: any;

    public managedUsers: ManagedUser[] = [];
    public selectedManagedUser!: ManagedUser | undefined;
    public courses!: Course[];

    public employer: any;

    public employerAlreadyEnrolledOnCourses = false;

    /** Add new member */
    public actionCompletedMessage: {title: string, details:string} | null = null;



    /** changeEmail */
    public changeEmailFormGroup = new FormGroup({
        newEmail: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        newEmailConfirmation: new FormControl('', [Validators.required, this.checkNewEmail.bind(this)]),
    });
    public emailChanged = false;
    

    public userType = 'employer'
    userEnrollMentFormGroup = new FormGroup({
        email: new FormControl({value: '', disabled: true}, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        sonOf: new FormControl('', [Validators.required]),
        courses: new FormArray([], [atLeastOneElement()]),
    });


    enrollOnCoursesFormGroup = new FormGroup({
        courses: new FormArray([], [Validators.required, atLeastOneElement()]),
    });


    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private growYourBusinessService: GrowYourBusinessService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private coursesService: CoursesService
    ) {}

    
    ngOnInit() {
        this.routeSubscriptionRef = combineLatest([
            this.route.parent?.data as Observable<Data>,
            this.route.data,
        ]).subscribe(([parentData, childData])  => {
            console.log(parentData);
            console.log(childData);

            this.employer = parentData['user'];
            let enrollmentsWithEmployees = childData['enrollmentsWithEmployees'];
            this.courses = childData['courses'];
            this.managedUsers =  this.createManagedUsers(enrollmentsWithEmployees, this.employer);
            if(this.managedUsers[0].isEmployer) {
                this.setEmployerAlreadyEnrolledOnCourses()
            }
            console.log(this.managedUsers)
        })
    }
    
    ngOnDestroy(): void {
        this.routeSubscriptionRef.unsubscribe();
    }

    onEditManagedUser(managedUser: any) {
        this.selectedManagedUser = managedUser;
    }

    /////////////////// ChangeEmailDialog ////////////////////////////////////

    onOpenEmailDialog(template: any) {
        // AYTO EDW THA GINETAI POLLES FORES
        (this.changeEmailFormGroup?.get('newEmail') as FormControl).valueChanges.subscribe((value) => {
            if( (this.changeEmailFormGroup.get('newEmailConfirmation') as FormControl).dirty) {
                this.changeEmailFormGroup?.get('newEmailConfirmation')?.updateValueAndValidity();
            }
        });
        this.emailChanged = false
        this.changeEmailFormGroup.reset();

        const dialogRef = this.dialog.open(template, {disableClose: true,  width: '400px', height:'376px' } )

    }

    onChageEmail() {
        this.growYourBusinessService.changeEmail(this.selectedManagedUser!.userId, this.changeEmailFormGroup.get('newEmail')?.value as string)
        .subscribe({
            next: (data: Data) => {
                console.log(data)
                this.selectedManagedUser!.email = this.changeEmailFormGroup.get('newEmail')?.value as string
                this.emailChanged = true;
            },
            error: (err) => {
                console.log(err)
                if(err.status == 422) {
                    if(err.error.code == 'alreadyExists') {
                        this.changeEmailFormGroup.get('newEmail')?.setErrors({alreadyExists: err.error.message});
                    }
                }
            }

        })

    }



    //////////////////////////////////////////////////////////////////////////////


    /////////////////// EnrollCourseDialog ////////////////////////////////////

    onOpenEnrollUserOnCoursesDialog(template: any) {
        console.log(this.userEnrollMentFormGroup)
        const dialogRef = this.dialog.open(template, {disableClose: true, width: '400px'} )
        dialogRef.afterClosed().subscribe(result => {
            console.log(result)
        });
    }

    userTypeChanged(event: any) {
        if(event.value == 'employer') {
            this.userEnrollMentFormGroup.get('sonOf')?.enable();
            this.userEnrollMentFormGroup.get('email')?.disable();
        } else {
            this.userEnrollMentFormGroup.get('sonOf')?.disable();
            this.userEnrollMentFormGroup.get('email')?.enable();
        }
    }

    setCoursesToFormArray(formArray: any, courseIds: string[]) {

        console.log('edw')
        formArray.markAllAsTouched()
        formArray.clear()
        for (let c of  courseIds) {
            (formArray as FormArray)?.push(new FormControl(c));
        }
    }


    onSaveNewManagedUser() {

        if(this.userType == 'employee') {
            let sendData = this.userEnrollMentFormGroup.value
            console.log(sendData)
            this.spinnerService.show('manage-employees')
            this.growYourBusinessService.enrollNewUserAsEmployee(sendData as any)
            .pipe(finalize(() => this.spinnerService.hide('manage-employees')))
            .subscribe({
                next: (employee: any) => {
                    console.log(employee)
                    this.managedUsers.push({
                        fullName: `Εργαζόμενος ${sendData.email}` as string,
                        email: sendData.email as string,
                        isEmployer: false,
                        enrolledOnCourses: sendData.courses?.map((courseId: any) => {
                            let courseDetais = this.courses.find ( course => course.courseId === courseId);
                            return {
                                courseId: courseId,
                                title: courseDetais?.title,
                            }
                        }) as {courseId: string, title: string}[],
                        userId: employee.userId,
                        formRegistrationCompleted: false,
                        index: 1
                    })
                    this.actionCompletedMessage = {
                        title: 'Ευχαριστούμε!',
                        details: 'Ο συμμετέχων θα λάβει πρόσκληση στο email που μας δώσατε, για να ολοκληρώσει την εγγραφή του.'
                    }
                },
                error: (customError: any) => {
                    console.log(customError)
                    if(customError.status == 422) {
                       this.userEnrollMentFormGroup.get('email')?.setErrors({alreadyExists: customError.error.message});
                    }
                }

            })
        } else if  (this.userType == 'employer') {
            let sendData: any = this.userEnrollMentFormGroup.value
            console.log(sendData)
            this.spinnerService.show('manage-employees');
            this.userService.getUserDetails().subscribe({
                next: (userDetails: any) => {
                    sendData['userId'] = userDetails.userId;
                    this.growYourBusinessService.enrollEmployerOnCoursesFirssTime(sendData)
                    .pipe(finalize(() => this.spinnerService.hide('manage-employees')))
                    .subscribe({
                        next: (result: any) => {
                            this.managedUsers.push({
                                fullName: this.employer.personalDetails.name + ' ' + this.employer.personalDetails.lastName,
                                email: this.employer.email,
                                isEmployer: true,
                                enrolledOnCourses: sendData.courses?.map((courseId: any) => {
                                    let courseDetais = this.courses.find ( course => course.courseId === courseId);
                                    return {
                                        courseId: courseId,
                                        title: courseDetais?.title,
                                    }
                                }),
                                userId: this.employer.userId,
                                formRegistrationCompleted: true,
                                index: 1
                            })
                            this.actionCompletedMessage = {
                                title: 'Η εγγραφή σας ολοκληρώθηκε με επιτυχία',
                                details: ''
                            }
                            this.setEmployerAlreadyEnrolledOnCourses()
                            this.coursesService.resetCourses();
                        },
                        error: (customError: any) => {
                            console.log(customError)
                        }
                    })
                }
            });
        }


    }


    onResendEmailInvitation() {
        console.log('resend email invitation')
        console.log(this.selectedManagedUser)
        this.spinnerService.show('manage-employees')
        this.growYourBusinessService.resendEmailInvitation(this.selectedManagedUser!.userId)
        .pipe(finalize(() => this.spinnerService.hide('manage-employees')))
        .subscribe({
            next: (data: Data) => {
                console.log(data)
                this.selectedManagedUser!.invitationSent = true;
            }
        })
    }

    onEnrollManagedUserToCourses(managedUser: any) {

        this.spinnerService.show('manage-employees')
        this.growYourBusinessService.enrollUserOnCourses(managedUser.userId, this.enrollOnCoursesFormGroup.get('courses')?.value)
        .pipe(finalize(() => this.spinnerService.hide('manage-employees')))
        .subscribe({
            next: (data: Data) => {
                console.log(data)
                const coursesToAdd: any = []
                for (let courseId of this.enrollOnCoursesFormGroup.get('courses')?.value as string[]) {
                    let newEnrolledCourse = this.courses.find(course => course.courseId === courseId)
                    managedUser.enrolledOnCourses.push({
                        courseId: newEnrolledCourse?.courseId,
                        title: newEnrolledCourse?.title,
                    })
                    coursesToAdd.push({course: 
                        newEnrolledCourse, 
                        courseId: newEnrolledCourse!.courseId,
                        examResults: {},
                        lecturesProgress: {},
                        status: 'started'
                    })                    
                }
                this.coursesService.resetCourses();
                this.coursesService.courseEnrollments.update( (enrollments: any) => {
                    return [...enrollments, ...coursesToAdd ]
                } )
            },
            error: (error: any) => {
                console.log(error)
            }
        })
    }

    //πέρα απο το setValue  πρεπει να κανουμε και το setSelectionRange για να μην χανεται η θεση του cursor
	public convertToLowercase(control: AbstractControl<string | null, string | null> | null | undefined, event: any) {
        const input = event.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;

        (control as FormControl).setValue((control as FormControl).value.toLowerCase());

        setTimeout(() => {
            input.setSelectionRange(start, end);
        }, 1);
    }

    private createManagedUsers(enrollmentsWithEmployees: any, employerDetails: any) {
        const managedUsers: ManagedUser[] = []
        if(enrollmentsWithEmployees.me.enrolledOnCourses && enrollmentsWithEmployees.me.enrolledOnCourses.length > 0 ) {
            managedUsers.push({
                fullName: employerDetails.personalDetails.name + ' ' + employerDetails.personalDetails.lastName,
                email: employerDetails.email,
                isEmployer: true,
                enrolledOnCourses: enrollmentsWithEmployees.me.enrolledOnCourses.map((courseEnrollMent: any) => {
                    let courseDetais = this.courses.find ( course => course.courseId === courseEnrollMent.courseId);
                    return {
                        courseId: courseEnrollMent.courseId,
                        title: courseDetais?.title,
                    }
                }),
                userId: employerDetails.userId,
                formRegistrationCompleted: employerDetails.formRegistrationCompleted,
            })
        }
        let index = 1;
        for (let employee of enrollmentsWithEmployees.employees) {
            managedUsers.push({
                fullName: employee.personalDetails? (employee.personalDetails?.name || '') + ' ' +  (employee.personalDetails?.lastName || '') : `Εργαζόμενος ${employee.email}` ,
                email: employee.email,
                isEmployer: false,
                enrolledOnCourses: employee.enrolledOnCourses.map((courseEnrollMent: any) => {
                    let courseDetais = this.courses.find ( course => course.courseId === courseEnrollMent.courseId);
                    return {
                        courseId: courseEnrollMent.courseId,
                        title: courseDetais?.title,
                    }
                }),
                userId: employee.userId,
                formRegistrationCompleted: employee.formRegistrationCompleted,
            })
            index++;
        }
        for (let i = 0; i < managedUsers.length; i++) {
            managedUsers[i]['index'] = i ;
        }

        // this.selectedManagedUser = managedUsers[0];

        return managedUsers;
    }

    private  checkNewEmail() {
        if (this.changeEmailFormGroup?.get('newEmail')?.value === this.changeEmailFormGroup?.get('newEmailConfirmation')?.value ) {
            return null;
        }
        return { notSame: true };
    }

    private setEmployerAlreadyEnrolledOnCourses() {
        this.employerAlreadyEnrolledOnCourses = true;
        this.userType = 'employee'
        this.userEnrollMentFormGroup.get('sonOf')?.disable();
        this.userEnrollMentFormGroup.get('email')?.enable();
    }

    public getMoreCoursesForSelectedUser(selectedManagedUser: any) {
        let courses = this.courses.filter(c=> !c.completed)
        return courses.filter(course => !selectedManagedUser.enrolledOnCourses.some( (enrolledCourse: any) => enrolledCourse.courseId === course.courseId))
    }
    
}