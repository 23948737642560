import { BreakpointObserver } from "@angular/cdk/layout";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MyCourseComponent } from "src/app/common/components/my-course.component";
import { CoursesService } from "src/app/common/services/courses.service";
import { LogsService } from "src/app/common/services/logs.service";

@Component({
    templateUrl: './grow-your-business-my-course.component.html',
    styleUrls: ['./grow-your-business-my-course.component.css']
})
export class GrowYourBusinessMyCourseComponent extends MyCourseComponent { 
    constructor(
        route: ActivatedRoute,
        courseService: CoursesService,
        breakpointObserver: BreakpointObserver,
        logsService: LogsService
    ) { 
        super(route, courseService, breakpointObserver, logsService);
    }

    override  textThatShouldDisplayForExam(): string {
        if(this.nextLesson) {
            return 'To Διαγώνισμα θα είναι διαθέσιμο μόλις ολοκληρωθεί το μάθημα'
        } else if(this.course.exam && this.course.exam.expirationDate >= Date.now()) {
            return 'Η προθεσμία ολοκλήρωσης του online test αξιολόγησης  λήγει ' + this.formatDateToDayMonth( Math.floor(this.course.exam?.expirationDate as number/ 1000))
        } else {
            return 'Η προθεσμία ολοκλήρωσης του online test αξιολόγησης  εληξε  ' + this.formatDateToDayMonth( Math.floor(this.course.exam?.expirationDate as number/ 1000))
        }
    }

    override afterCourseResolved(resolveData: any)  {
        this.courseLessons = this.courseLessons.slice()
        let detectTest = this.courseLessons.filter(l => l.sections?.test);
        if(detectTest.length > 0) {
            let index  = this.courseLessons.findIndex(obj => obj.id == detectTest[0].id);
            this.courseLessons.splice(index+1, 0, detectTest[0]!.sections!.test as any);
            this.courseLessons[index + 1].disabled = (this.courseLessons[index + 1] as any).questions == null
        }
        let lessonIndex = 1;
        this.courseLessons.forEach(lesson => {
            if(lesson.hasOwnProperty('id')) {
                (lesson as any).label = `Διάλεξη ${lessonIndex}`
                lessonIndex++;
            } else {
                (lesson as any).label = 'Online Άσκηση'
            }
        });
        console.log(this.examConfiguration);
    }

    /**
     * Όταν αλλάζουμε tab ενημερώνουμε τα index και  βαζουμε στο καταλληλο video player 
     * το πρώτο βίντεο της ενότητας
     */
    override onTabChanged(event: any) {
        this.selectedTabIndex = event.index;
        let index = event.index

        if(event.index >= this.courseLessons.length) {
            return;
        }
        // αν ειναι το test τότε το selectedLesson πρέπει να ειναι
        // μια θέση πιο πίσω
        if(!this.courseLessons[index].hasOwnProperty('id')) {
            index = index-1;
        }
        
        this.selectedLesson = this.courseLessons[index]
        this.currentLessonIndex = index;
        this.selectedLessonConfiguration = this.lessonsConfiguration[this.currentLessonIndex]
        if(this.selectedLesson.sections?.videos &&  this.selectedLesson.sections?.videos?.length > 0) {
            this.videoPlayers[this.currentLessonIndex].src({

                src: this.selectedLesson.sections?.videos?.[0].url,
                type: this.selectedLesson.sections?.videos?.[0].type
            });
            this.videoPlayers[this.currentLessonIndex].poster(this.selectedLesson.sections?.videos?.[0].thumbnail);
        }


        console.log(this.lessonsConfiguration)
    }

    onOpenLink(link: any) {
        window.open(link, '_blank');
    }

    courseHasTest(): boolean {
        return this.courseLessons.filter(l => l.sections?.test).length > 0;
    }
}