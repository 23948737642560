import { Component,OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CoursesComponent } from "src/app/common/components/courses.component";
import { Course } from "src/app/common/shared/models/course.model";

@Component({
    selector: "app-courses",
    templateUrl: "./grow-your-business-courses.component.html",
    styleUrls: ["./grow-your-business-courses.component.css"]
})
export class GrowYourBusinessCoursesComponent  extends CoursesComponent implements OnInit {
    constructor(route: ActivatedRoute) {
        super(route);
    }

    override ngOnInit(): void {
        this.coursesPerRow = 2;
        super.ngOnInit();
    }

    getCourseDurationDates(course: any): string {
        if( !course.lessons || course.lessons.length === 0) {
            return ''
        }
        const firstLesson = new Date(course.lessons[0].startsAt * 1000).toLocaleDateString('el-GR', {day: '2-digit', month:'2-digit'})
        const lastLesson = new Date(course.lessons[course.lessons.length - 1].startsAt * 1000).toLocaleDateString('el-GR', {day: '2-digit', month:'2-digit'})
        return `Από ${firstLesson} εώς ${lastLesson}`
    }

    getImageAsBackgroundUrl(image: string) {
        return `url(${image})`
    }

    override courseIsCompleted(course: Course): boolean {
        return course.completed
    }

    getCourseLink(courseId: string) {
        return `/certification-program/${courseId}`
    }
    
}