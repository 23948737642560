import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { GrowYourBusinessService } from './grow-your-business.service';
import { catchError, of, throwError } from 'rxjs';

@Injectable()
export class EmployeeRegistrationDetailsResolverService {

    constructor(
        private growYourBusinessService: GrowYourBusinessService,
        private router: Router
    ) {}

    resolve(route: ActivatedRouteSnapshot) {
        const employeeId = route.params['employeeId'];
        return this.growYourBusinessService.getEmployeeRegistrationDetails(employeeId)
            .pipe(
                catchError( (error) => {
                    if (error.status === 422) {
                      return of({alreadyRegistered: true})
                    } else if (error.status === 404) {
                        this.router.navigate(['']);
                    }
                    return of(null)
                })
            )
       
    }
}
