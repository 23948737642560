import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { SpinnerService } from '../spinner/spinner.service';
import { AuthService, IUser } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-verify',
  template: '',
})
export class VerifyComponent {
    public email: string | null = null;
    public code: string | null = null;
    public sendedCodeSuccessMessage: string | null = null;
    public verificationCode = new FormControl('', [Validators.required]);
	public error!: { [key: string]: string; }

    public successUrl = './my-courses'

    constructor(
        private auth: AuthService,
        private router: Router,
        private spinnerService: SpinnerService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {

        this.route.queryParams.subscribe(params => {
            this.email = params['email'];
            this.code = params['code'];
            console.log(this.email)
            if (!this.email) {
                this.router.navigate([`./${environment.LOGIN_URL}`])
            }
            if(this.code) {
                this.verificationCode.setValue(this.code)
            }

            let resend_code = params['resend_code']
            if(resend_code && resend_code == 'true') {
                this.onResendCode();
            }

        });
	}

    onResendCode() {
        this.spinnerService.show('verify')
        this.sendedCodeSuccessMessage = null
        if(this.verificationCode.hasError('invalid')) {
            delete this.verificationCode?.errors?.['invalid']
        }

        const that = this;
        this.auth.resendConfirmationCode(this.email as string)
        .pipe(
            finalize( () => this.spinnerService.hide('verify'))
        )
        .subscribe({
            next: (code) => {
                this.sendedCodeSuccessMessage = 'Σας έχει αποσταλεί ενας καινούργιος κωδικός στο email σας'
            },
            error: () => {}
        });
    }

    onVerifyUser() {
        const that = this;
        let user = {
            email: this.email,
            code: this.verificationCode.value || 'no_code'
        } as IUser;
        this.spinnerService.show('verify')
        this.auth.confirmSignUp(user)
        .pipe(
            finalize( () => this.spinnerService.hide("verify"))
        )
        .subscribe({
            next: () => {
                this.router.navigate([`./${environment.LOGIN_URL}`])
            },
            error: (cognitoError) => {
                console.log(cognitoError, cognitoError.name)
                if(cognitoError.name == 'CodeMismatchException') {
                    this.verificationCode.setErrors({invalid: 'O κωδικός που βάλατε δεν είναι εγκυρος'})
                }
            }
            
        });
    }
}
