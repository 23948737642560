import { Component, effect, inject, signal, Signal, WritableSignal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { from, of, switchMap } from "rxjs";
import { CourseDetailsComponent } from "src/app/common/components/course-details.component";
import { AuthService } from "src/app/common/services/auth.service";
import { CoursesService } from "src/app/common/services/courses.service";
import { UserService } from "src/app/common/services/user.service";
import { Course, CourseEnrollmentV2 } from "src/app/common/shared/models/course.model";

@Component({
    selector: "app-course-details",
    templateUrl: "./grow-your-business-course-details.component.html",
})
export class GrowYourBusinessCourseDetailsComponent extends CourseDetailsComponent {
    private auth = inject(AuthService);
    private coursesService = inject(CoursesService);
    public user: any;
    public alreadyEnrolled: WritableSignal<boolean> = signal(false);

    constructor(
        route: ActivatedRoute,
        activatedRoute: ActivatedRoute,
        private userService: UserService,
    ) {

        effect(() => {
            const enrollments = this.coursesService.courseEnrollments();
            const isEnrolled = enrollments.some((enrollment: CourseEnrollmentV2) => enrollment.courseId === this.course.courseId);
            this.alreadyEnrolled.set(isEnrolled);


            if (this.alreadyEnrolled()) {
                console.log("Effect");
            }
        }, { allowSignalWrites: true });

        super(route, activatedRoute)
    }

    override ngOnInit() {

        from(this.auth.isAuthenticated()).pipe(switchMap((result: boolean) => {
            return result ? this.userService.getUserDetails() : of(null);
        })).subscribe((userDetails) => {
            this.user = userDetails;
        });
        super.ngOnInit()
    }

    getCourseDurationDates(): string {
        if (!this.course.lessons || this.course.lessons.length === 0) {
            return ''
        }
        const firstLesson = new Date(this.course.lessons[0].date * 1000).toLocaleDateString('el-GR', { day: '2-digit', month: '2-digit' })
        const lastLesson = new Date(this.course.lessons[this.course.lessons.length - 1].date * 1000).toLocaleDateString('el-GR', { day: '2-digit', month: '2-digit' })
        console.log(firstLesson, lastLesson)
        return `Από ${firstLesson} εώς ${lastLesson}`
    }

    override courseIsCompleted(): boolean {
        return this.course.completed
    }
}
