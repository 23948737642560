<div style="padding:12px 0px 0px; height:100%; background-color:#F9FAFB">
<div style="padding:32px 0px 48px">
<div fxLayout="column" fxlayout="start" style="gap: 24px;">
<div style="gap: 24px;padding:0px 32px; width:100%" fxLayout="column"  fxlayout="start" >

    <div *ngIf="isAdmin" fxLayout="row" style="gap: 16px;">
        <div fxFlex fxLayout="column" fxLayoutAlign="start">
            <span class="mat-subtitle-1 f600">Καλώς ήρθες</span>
            <span> Δες και διαχειρίσου</span>
        </div>  
    </div>
    
    <div fxLayout="column" fxLayoutAlign="start center" style="gap: 64px;padding:64px 0px" >
    
        <div fxLayout="row" style="width:80%; padding:0px 32px" [ngStyle.xs] = "{'width': '100%', 'padding': '0px'}" *ngIf="!isAdmin">
            <div fxFlex></div>    
            <button mat-raised-button color="primary" (click)="OnCreateNewMessage()">Νέο μήνυμα</button>
        </div>
    
        <div fxLayout="column" fxLayoutAlign="start center"  style="gap: 12px;padding:0px 32px; width:80%" [ngStyle.lt-md] ="{'width': '100%', 'padding': '0px'}"  >
        
            <div *ngIf="messages.length == 0">
                Δεν έχετε ακόμα κανένα μήνυμα
            </div>
    
            <div  *ngFor="let messageObject of messages"  style="width:100%;">
            <mat-card (click)="onOpenChatConversation(messageObject)" style="cursor:pointer;z-index:3" >
                <mat-card-content style="border-radius:8px ;">
                    <div fxLayout="column" style="gap: 12px;">
                        <div fxLayout="row" style="gap: 12px;">
                            <span>{{messageObject.name + ' ' + messageObject.lastName }}</span>
                            <a class="cosmote-blue" [href]="'mailto:'+messageObject.email"> {{messageObject.email}}</a>
                            <span class="gray-500">{{messageObject.createdAt | date: 'dd/MM/yyyy'}}</span>
                        </div>
                        <span class="gray-500"> 
                            {{ messageObject.message}}
                        </span>
                    </div>
                </mat-card-content>
            </mat-card>
            <!-- {{isStacked(messageObject)}} -->
        </div>
    
        </div>
    
    </div>
</div>
</div>
</div>
</div>