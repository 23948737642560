<div class="ellipse">
  <div style="padding: 48px 32px;gap: 32px;" fxLayout="column" fxLayoutAlign="center center">
    <img style="max-width:460px" [ngStyle.xs]="{'max-width':'300px'}" src="assets/growYourBusiness@x2.png">
    <div [ngStyle.sm]="{'width': '550px'}" [ngStyle.xs]="{'width': '310px'}" style="width:930px;gap: 28px;"
      fxLayout="column" fxLayoutAlign="start center">

      <span class="cosmote-blue" style="text-align: center;font-size: 24px;font-style: normal;font-weight: 700;
          line-height: 29px;"> To COSMOTE GROW YOUR BUSINESS
        επιστρέφει με video on demand!</span>

      <span 
        style="text-align: center;font-size: 20px;font-style: normal;font-weight: 400;line-height: 26px;color: #212121;">
        Το επιτυχημένο πρόγραμμα για μικρομεσαίες επιχειρήσεις, επαναλαμβάνεται για τρίτη συνεχή χρονιά, αυτή τη φορά με
        την μορφή βιντεοσκοπημένων online μαθημάτων.
      </span>

      <span
        style="text-align: center;font-size: 18px;font-style: normal;font-weight: 400;line-height: 25px;color: #212121;">
        Σχεδιάστηκε από το Εργαστήριο Ηλεκτρονικού Εμπορίου (ELTRUN) του Οικονομικού Πανεπιστημίου Αθηνών σε συνεργασία
        με την COSMOTE, και παρέχει δωρεάν, πιστοποιημένη εκπαίδευση πάνω σε σύγχρονα ψηφιακά εργαλεία. Οι συμμετέχοντες
        έχουν τη δυνατότητα να παρακολουθήσουν τα βιντεοσκοπημένα μαθήματα στο χρόνο που τους εξυπηρετεί, και να
        αποκτήσουν πιστοποιητικό κατάρτισης από το Κέντρο Επιμόρφωσης και Δια Βίου Μάθησης (ΚΕΔΙΒΙΜ) του Οικονομικού
        Πανεπιστημίου Αθηνών.
      </span>
    </div>
  </div>

  <!-- [ngStyle]="{'background-image': getImageAsBackgroundUrl('assets/course-free-status.png'), 'background-repeat'}" -->
  <div style="gap: 64px;" [ngStyle.lt-lg]="{'padding': '32px 64px'}" [ngStyle.xs]="{'padding':'64px 24px'}"
    fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="column" style="gap: 32px;" fxLayoutAlign="center start">
      <div *ngFor="let courseRow of coursesSplitOnRows" fxLayout="row" fxLayout.lt-md="column" style="gap: 32px;">
        <div style="max-width:480px" [ngStyle.md]="{'width': '360px'}" fxLayout="column"
          *ngFor="let course of courseRow">
          <div
            style="width:100%; height:240px; background-position: center; background-repeat: no-repeat; background-size:cover; margin-bottom:24px; overflow:hidden;"
            [ngStyle]="{'background-image': getImageAsBackgroundUrl(course.smallImage)}">
            <div *ngIf="!courseIsCompleted(course)" [ngStyle]="{'background-image': getImageAsBackgroundUrl('assets/course-free-status.png'), 'background-repeat': 'no-repeat', 'background-size': 'contain', 
                'background-position': 'right 0', 'width':'100%','height':'134px'}"></div>
            <div *ngIf="courseIsCompleted(course)" [ngStyle]="{'background-image': getImageAsBackgroundUrl('assets/course-completed-status.png'), 'background-repeat': 'no-repeat', 'background-size': 'contain', 
                'background-position': 'right 0', 'width':'100%','height':'134px'}"></div>
          </div>
          <div fxLayout="column" style="gap: 16px;" fxFlex>

            <div fxLayout="column" style="gap: 16px;" fxFlex>
              <span class="cosmote-blue"
                style="font-size: 24px;font-style: normal;font-weight: 700;line-height: 32px;">{{course.title}}</span>
              <span class="cosmote-blue" style="font-size: 18px;font-style: normal;font-weight: 400;line-height: 26px;">
                {{course.shortDescription}}</span>
            </div>
            <!-- sto medium screen κανουμε to layout column γιατι εχουμε 3 καρτέλες και θέλουμε χώρος για αυτες τις πληροφορίες -->
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px" fxLayout.md="column">

              <!-- <div style="margin-right:12px" fxLayout="row" fxLayoutAlign="start end">
                    <img  style="width:28px; height:28px; margin-right:8px" src="/assets/calendar-date.png">
                    <span class="cosmote-blue" style="font-size:19px; line-height:1.4em" > {{getCourseDurationDates(course) }}</span>
                  </div> -->

              <div style="margin-right:12px" fxlayout="row" fxLayoutAlign="start center">
                <img style="width:28px; height:28px; margin-right:8px" src="/assets/clock.png">
                <span class="cosmote-blue"
                  style="font-size: 21px;font-weight: 400;line-height: 29.4px;">{{course.durationInHours}} Ώρες </span>
              </div>

            </div>

            <!-- <span class="gray-600" style="font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;"> {{course.shortDescription}}</span> -->

            <button mat-raised-button class="learn-more" [routerLink]=" getCourseLink(course.courseId)"
              style="font-size:21px">Μάθε Περισσότερα</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>