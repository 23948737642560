<app-spinner name="link-forgot-password"></app-spinner>

<app-spinner name="forgot-password"> </app-spinner>
<div style="gap: 32px;padding: 96px 32px 48px" fxLayout="column" fxLayoutAlign="start center" >
    <div fxLayout="column" fxLayoutAlign="start center"  [ngStyle.gt-xs]="{ 'width':'350px'}" [ngStyle.lt-md]="{ 'width':'250px'}">
        <div fxLayout="column" fxLayoutAlign="start center"  style="gap: 24px;margin-bottom:32px" >
            <img src="/assets/forgot-password.png" style="width: 109px;height: 83.5px;">
            <div fxLayout="column" fxLayoutAlign="start center" style="gap: 12px;">
                <span class="cosmote-blue" style="font-size:24px; font-weight:600; line-height: 32px; text-align: center">Δημιουργία νέου password</span>
                <span style="text-align:center" class="gray-500">
                    To password πρέπει να έχει πάνω από 6 χαρακτήρες.
                </span>
            </div>
        </div>

        <form [formGroup]="forgotPasswordForm" fxLayout="column" fxLayoutAlign="center center" style="width:100%">


            <div fxLayout="column" style="width:100%">
                <span class="cosmote-blue" style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Νέο password*</span>
                <div fxLayout="row" fxLayoutAlign="start center" style="gap: 8px;">
                    <input fxFlex  matInput formControlName="newPassword" [type]="hidePassword ? 'password' : 'text'"
                        [ngClass]=" {'input-error': forgotPasswordForm.get('newPassword')?.invalid && forgotPasswordForm.get('newPassword')?.touched}"
                        class="input" matInput> 
                        <mat-icon style="cursor: pointer;" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ?
                            'visibility_off' : 'visibility'}}</mat-icon>
                    </div>
                <div style="min-height:16px; padding:0px 12px 10px">
                    <span class="error-message" *ngIf="forgotPasswordForm.get('newPassword')?.invalid && forgotPasswordForm.get('newPassword')?.touched">
                        To password πρέπει να έχει πάνω από 6 χαρακτήρες.
                    </span>
                </div>
            </div>

            <div fxLayout="column" style="width:100%">
                <span class="cosmote-blue" style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Επιβεβαίωση password*</span>
                <div fxLayout="row" fxLayoutAlign="start center" style="gap: 8px;">
                    <input fxFlex  matInput formControlName="newPasswordConfirmation" [type]="hidePassword ? 'password' : 'text'"
                        [ngClass]=" {'input-error': forgotPasswordForm.get('newPasswordConfirmation')?.invalid && forgotPasswordForm.get('newPasswordConfirmation')?.touched}"
                        class="input" matInput> 
                    <mat-icon style="cursor: pointer;" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ?
                        'visibility_off' : 'visibility'}}</mat-icon>
                </div>
                <div style="min-height:16px; padding:0px 12px 10px">
                    <span class="error-message" *ngIf="forgotPasswordForm.get('newPasswordConfirmation')?.invalid && forgotPasswordForm.get('newPasswordConfirmation')?.touched"> 
                        Επαληθεύστε το password σας
                    </span>
                </div>
            </div>

            <div *ngIf="sendedCodeSuccessMessage" style="background-color: #ECFDF3; padding:16px; border-radius: 16px; width:100%; box-sizing: border-box; margin-bottom:24px;gap: 16px;" 
            fxLayout="row"> 
                <img src="/assets/success-check-icon.png" alt="" style="width: 20px;height: 20px;">
                <span style="color: #0D9403; font-size:14px; line-height:20px">Το password άλλαξε επιτυχώς</span>
            </div>
            <button mat-raised-button [disabled]="forgotPasswordForm.invalid" color="primary" style="width:100%" (click)="onForgotPasswordSubmit()">
                Δημιουργία password
           </button>
        </form>
</div>
