import { CommonModule } from "@angular/common";
import { SharedModule } from "../common/shared/shared.module";
import { ErrorHandler, Injectable, NgModule } from "@angular/core";
import { GrowYourBusinessRoutingModule } from "./grow-your-business-routing.module";
import { GrowYourBusinessLayoutComponent } from "./layout/grow-your-business-layout.component";
import { GrowYourBusinessHeaderComponent } from "./layout/header/grow-your-business-header.component";
import { GrowYourBusinessFooterComponent } from "./layout/footer/grow-your-business-footer.component";
import { GrowYourBusinessEmployeeRegistrationComponent } from "./employee-registration/employee-registration.component";
import { GrowYourBusinessAuthInterceptor } from "./grow-your-business-authentication-interceptor";
import { GrowYourBusinessRequestInterceptor } from "./grow-your-business-request-interceptor";
import { GrowYourBusinessLoginComponent } from "./login/grow-your-business-login.component";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { GrowYourBusinessVerifyComponent } from "./verify/grow-your-business-verify.component";
import { GrowYourBusinessEmployerRegistrationComponent } from "./employer-registration/grow-your-business-employer-registration.component";
import { GrowYourBusinessFaqComponent } from "./faq/grow-your-business-faq.component";
import { GrowYourBusinessContactUsComponent } from "./contact-us/grow-your-business-contact-us.component";
import { GrowYourBusinessCoursesComponent } from "./courses/grow-your-business-courses.component";
import { GrowYourBusinessCourseDetailsComponent } from "./course-details/grow-your-business-course-details.component";
import { GrowYourBusinessCookieDialogComponent } from "./cookie-dialog/grow-your-business-cookie-dialog.component";
import { GrowYourBusinessCookiesSettingsDialogComponent } from "./cookie-dialog/cookie-settings-dialog/grow-your-business-cookie-settings-dialog.component";
import { GrowYourBusinessMyProfileComponent } from "./my-profile/grow-your-business-my-profile.component";
import { GrowYourBusinessMyMessagesComponent } from "./my-messages/grow-your-business-my-messages.component";
import { MatStepperModule } from '@angular/material/stepper';
import { GrowYourBusinessForgotPasswordComponent } from "./forgot-password/grow-your-business-forgot-password.component";
import { GrowYourBusinessLinkForgotPasswordComponent } from "./link-forgot-password/grow-your-business-link-forgot-password.component";
import { GrowYourBusinessMyCourseComponent } from "./my-course/grow-your-business-my-course.component";
import { GrowYourBusinessMyCoursesComponent } from "./my-courses/grow-your-business-my-courses.component";
import { GrowYourBusinessManageEmployeesComponent } from "./manage-employees/grow-your-business-manage-employees.component";
import { EmployerGuard } from "./employer.guard.service";
import { GrowYourBusinessErrorMessageComponent } from "./error-message/grow-your-business-error-message.component";
import { GrowYourBusinessChooseCoursesComponent } from "./choose-courses/grow-your-business-choose-courses.component";
import { GrowYourBusinessAddMoreCoursesComponent } from "./my-courses/add-more-courses/grow-your-business-add-more-courses.component";
import { GrowYourBusinessCourseLessonsScheduleComponent } from "./course-lesson-schedule/grow-your-business-course-lessons-schedule.component";
import { GrowYourBusinessTermsOfUseComponent } from "./terms-of-use/grow-your-business-terms-of-use.component";
import { GrowYourBusinessPrivacyPolicyComponent } from "./privacy-policy/grow-your-business-privacy-policy.component";
import { ScrollListenerDirective } from "./scroll-listener-directive";
import * as Sentry from '@sentry/browser';
import { GrowYourBusinessCookiesPolicyComponent } from "./cookies/grow-your-business-cookies-policy.component";
import { UserService } from "../common/services/user.service";
import { BrowserModule } from "@angular/platform-browser";
import { GrowYourBusinessComponent } from "./grow-your-business.component";
import { CoursesResolverService } from "../common/resolvers/courses-resolver.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthGuard } from "../common/services/auth.guard.service";
import { MyCoursesResolverService } from "../common/resolvers/my-courses-resolver.service";
import { CourseResolverService } from "../common/resolvers/course-resolver.service";
import { CourseEnrollmentResolverService } from "../common/resolvers/course-enrollment-resolver.service";
// import { LottieModule } from 'ngx-lottie';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
	constructor() { }
	handleError(error: any) {
		Sentry.captureException(error.originalError || error);
		throw error;
	}
}

@NgModule({
	declarations: [
		GrowYourBusinessComponent,
		GrowYourBusinessLayoutComponent,
		GrowYourBusinessHeaderComponent,
		GrowYourBusinessFooterComponent,
		GrowYourBusinessEmployeeRegistrationComponent,
		GrowYourBusinessLoginComponent,
		GrowYourBusinessVerifyComponent,
		GrowYourBusinessEmployerRegistrationComponent,
		GrowYourBusinessFaqComponent,
		GrowYourBusinessContactUsComponent,
		GrowYourBusinessCoursesComponent,
		GrowYourBusinessCourseDetailsComponent,
		GrowYourBusinessCookieDialogComponent,
		GrowYourBusinessCookiesSettingsDialogComponent,
		GrowYourBusinessMyProfileComponent,
		GrowYourBusinessMyMessagesComponent,
		GrowYourBusinessForgotPasswordComponent,
		GrowYourBusinessLinkForgotPasswordComponent,
		GrowYourBusinessMyCoursesComponent,
		GrowYourBusinessMyCourseComponent,
		GrowYourBusinessManageEmployeesComponent,
		GrowYourBusinessErrorMessageComponent,
		GrowYourBusinessChooseCoursesComponent,
		GrowYourBusinessAddMoreCoursesComponent,
		GrowYourBusinessCourseLessonsScheduleComponent,
		GrowYourBusinessTermsOfUseComponent,
		GrowYourBusinessPrivacyPolicyComponent,
		GrowYourBusinessCookiesPolicyComponent,
		ScrollListenerDirective
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		CommonModule,
		SharedModule,
		GrowYourBusinessRoutingModule,
		MatStepperModule
	],
	providers: [
		{ provide: ErrorHandler, useClass: SentryErrorHandler },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: GrowYourBusinessRequestInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: GrowYourBusinessAuthInterceptor,
			multi: true
		},
		provideHttpClient(
			withInterceptorsFromDi() // Include this if you use HTTP interceptors
		),
		EmployerGuard,
		CoursesResolverService,
		MyCoursesResolverService,
		CourseResolverService,
		CourseEnrollmentResolverService,
		AuthGuard,
		UserService
		// sto app.module upirxan kai alla services/guards pou empainan edw. Den kserw an xreiazontai kai poia edw.
	],
	bootstrap: [GrowYourBusinessComponent],
	exports: [
		SharedModule,
		CommonModule,
	]
})
// export class EqualityModule { }
export class GrowYourBusinessModule { }
