import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MyProfileComponent } from "src/app/common/components/my-profile.component";
import { AuthService } from "src/app/common/services/auth.service";
import { User } from "src/app/common/shared/models/user.model";
import { SpinnerService } from "src/app/common/spinner/spinner.service";
import { GrowYourBusinessService } from '../grow-your-business.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { finalize } from "rxjs";

@Component({
    selector: 'app-my-profile',
    templateUrl: './grow-your-business-my-profile.component.html',
})
export class GrowYourBusinessMyProfileComponent extends MyProfileComponent implements OnInit {

    public user!: User;

    constructor(
        authService: AuthService,
        spinnerService: SpinnerService,
        private route: ActivatedRoute,
        private growYourBusinessService: GrowYourBusinessService,
        private snackBar: MatSnackBar
    ) { super(authService, spinnerService)}

    override ngOnInit(): void {
        this.route?.parent?.data.subscribe((data) => {
            this.user = data['user'];
        });
        super.ngOnInit();
    }

    onChangeEmail(): void {

        console.log(this.changeEmailFormGroup);

        let email = this.changeEmailFormGroup.get('newEmail')?.value as string
        if( email == this.user.email) {
            this.changeEmailFormGroup.get('newEmail')?.setErrors({sameWithOldEmail: true});
            return;
        }

        this.spinnerService.show('my-profile');

        this.growYourBusinessService.changeEmail(this.user.userId, this.changeEmailFormGroup.get('newEmail')?.value as string)
        .pipe(finalize( () => this.spinnerService.hide('my-profile')))
        .subscribe({
            next: () => {
            this.snackBar.open('Η αλλαγή email ολοκληρώθηκε επιτυχώς', 'Εντάξει', {duration: 5000});
            this.user.email = email
            this.changeEmailFormGroup.reset();
            },
            error: (err) => {
                console.log(err)
                if(err.status == 422) {
                    if(err.error.code == 'alreadyExists') {
                        this.changeEmailFormGroup.get('newEmail')?.setErrors({alreadyExists: err.error.message});
                    }
                }
            }
        });
    }

    // override onChangePassword(): void {
    //     let sendData = this.changePasswordFormGroup.value;
    //     this.spinnerService.show('my-profile');
    //     this.authService.changePassword({
    //         oldPassword: sendData.oldPassword as string,
    //         newPassword: sendData.newPassword as string
    //     }).pipe(finalize(() => this.spinnerService.hide('my-profile'))) 
    //     .subscribe({
    //         next: (data) => {
    //             this.snackBar.open('To password άλλαξε επιτυχώς', 'Εντάξει', {duration: 5000})
    //             this.changePasswordFormGroup.reset()
    //         }, 
    //         error: (error) => {
    //             console.log(error)
    //             this.changePasswordFormGroup.get('oldPassword')?.setErrors({ 'incorrect': 'Βεβαιωθείτε ότι το τρέχον password που εισάγετε είναι σωστό'});
    //         }
    //     });
    // }
}
