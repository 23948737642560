import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Course } from '../shared/models/course.model';
import { User } from '../shared/models/user.model';

@Component({
  selector: 'app-courses',
  template: '',
})
export class CoursesComponent implements OnInit {

	private courses!:Course[];

	public allCoursesSplitOnRows: Course[][] = []
	public coursesSplitOnRows: Course[][] = []

	public user!: User;

	public coursesPerRow = 3;


	constructor(
		private route: ActivatedRoute
	){}

	ngOnInit(): void {
		this.route.data.subscribe(
			(data: any) => {
				console.log(data)
				const courses = data.courses as Course[]
				this.courses = courses;
				this.courses = courses.sort((a, b) => {
					if(a.completed && !b.completed) {
						return 1; // if a is completed and b is not, put a after b
					} else if (!a.completed && b.completed) {
						return -1; // if b is completed and a is not, put a before b
					} else {
						return (a as any).startsAt - (b as any).startsAt;
					}
				})

				console.log(this.courses);
				for (let i = 0; i < this.courses.length; i += this.coursesPerRow) {
					let chunk = this.courses.slice(i, i + this.coursesPerRow);
					this.allCoursesSplitOnRows.push(chunk);
				}
				console.log(this.allCoursesSplitOnRows)
				this.coursesSplitOnRows = this.allCoursesSplitOnRows
			}
		)

		this.route?.parent?.data.subscribe(
			(parentData: any) => {
				this.user = parentData['user'];
			}
		 )

	}

	courseStartsAt(course: Course & {startsAt?: number}) {
		if(course.startsAt) {
			//format date to dd month yyyy
			return new Date(course.startsAt * 1000).toLocaleDateString('el-GR', {day: 'numeric', month: 'long', year: 'numeric'})
		}
		return 'σύντομα'
	}
	courseIsCompleted(course:Course) {
		if (!course.lessons || course.lessons.length == 0) {
			return false
		}
		let lastLesson = course.lessons[course.lessons.length - 1]
		return lastLesson.endsAt < Math.floor(Date.now() / 1000)
	}

	getAvatar(course: Course) {
		return `url(${course?.instructors?.[0]?.avatar})`
	}
}
