import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-error-message',
    templateUrl: './grow-your-business-error-message.component.html'
})
export class GrowYourBusinessErrorMessageComponent {
    constructor() {}


    @Input() message!: string;
    @Input() details!: string;
}
