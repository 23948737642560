<div fxlayout="row" fxLayoutAlign="center center">
    <div style="width:960px">

        <h2 style="font-size:36px; line-height:50px">Όροι και Προϋποθέσεις Συμμετοχής στο Εκπαιδευτικό Πρόγραμμα COSMOTE GROW YOUR BUSINESS - Certification Program</h2>

        <div style="font-size:28px; font-weight:700">Προοίμιο</div> <br>

        <div>Για άλλη μια χρονιά η COSMOTE και η EQUALITY ενώνουν τις δυνάμεις τους, για να προσφέρουν σε υπαλλήλους και στελέχη Μικρομεσαίων Επιχειρήσεων ένα πλήρες και δωρεάν εκπαιδευτικό πρόγραμμα για το ηλεκτρονικό επιχειρείν! <br> <br>
            Σκοπός του παρόντος είναι η σαφής περιγραφή του Προγράμματος, και ο καθορισμός των Όρων και των Προϋποθέσεων Συμμετοχής στο  Εκπαιδευτικό Πρόγραμμα «COSMOTE GROW YOUR BUSINESS - Certification Program». <br> <br>
            Οι παρόντες Όροι και Προϋποθέσεις Συμμετοχής είναι αναρτημένοι και προσβάσιμοι σε όλους μέσα από την Ιστοσελίδα του Προγράμματος https://www.equality.org.gr/growyourbusiness  (εφεξής «Ιστοσελίδα Προγράμματος») που φιλοξενείται στον Ιστότοπο της EQUALITY.<br> <br>
            Επιπλέον, έχουν κατατεθεί στη συμβολαιογράφο Αθηνών, κα Χαρίκλεια Σπυριδοπούλου (Ασκληπιού 26 - 28, Αθήνα). Οποιοσδήποτε Συμμετέχων ή τρίτος δικαιούται να λαμβάνει με έξοδά του ακριβές αντίγραφο.</div>
        <br>

        <div style="font-size:28px; font-weight:700">1. Διοργανωτές</div> <br>

        <div>Η ανώνυμη εταιρεία  με την επωνυμία ΟΡΓΑΝΙΣΜΟΣ ΤΗΛΕΠΙΚΟΙΝΩΝΙΩΝ ΤΗΣ ΕΛΛΑΔΟΣ Α.Ε.» και δ.τ. «COSMOTE», (ΑΦΜ 094019245, Δ.Ο.Υ. ΦΑΕ Αθηνών Αριθ. Γ.Ε.Μ.Η 1037501000 ) που εδρεύει στο Μαρούσι, Λεωφ. Κηφισίας 99σε συνεργασία με την Αστική Μη Κερδοσκοπική Εταιρία EQUALITY (ΑΦΜ 996787689, Γ.Ε.ΜΗ 2197940), που εδρεύει στην Αθήνα, Ιπποκράτους 2, αποκαλούμενοι εφ’ εξής από κοινού ως
            <span style="font-weight:700">Διοργανωτές</span> , αναλαμβάνουν την πρωτοβουλία να διοργανώσουν 
            το <span style="font-weight:700"> Εκπαιδευτικό Πρόγραμμα “COSMOTE GROW YOUR BUSINESS- Certification Program” </span>(εφεξής το <span style="font-weight:700">Πρόγραμμα</span>). 
            Το Πρόγραμμα πραγματοποιείται με την Επιστημονική Υποστήριξη του Εργαστηρίου Ηλεκτρονικού Εμπορίου (ELTRUN) του Οικονομικού Πανεπιστημίου Αθηνών .</div>

        <br>
        <div style="font-size:28px; font-weight:700">2. Περιγραφή του Προγράμματος</div> <br>

        <div>
            <span style="font-weight:700"> 2.1.</span> Το Πρόγραμμα απευθύνεται σε στελέχη και υπαλλήλους Μικρομεσαίων Επιχειρήσεων (ΜμΕ) 
            (εφεξής «Συμμετέχοντες»), όπως αυτές καθορίζονται πιο κάτω στον όρο 7 («Δικαίωμα Συμμετοχής»). <br> <br>
        </div>

        <div>
            <span style="font-weight:700"> 2.2.</span> Σκοπός του Προγράμματος είναι να προσφέρει στους Συμμετέχοντες δωρεάν και πιστοποιημένη 
            εκπαίδευση σε ζητήματα που σχετίζονται με τις ψηφιακές δεξιότητες και το ηλεκτρονικό εμπόριο.  <br> <br>
        </div>

        <div>
            <span style="font-weight:700"> 2.3.</span>  Το Πρόγραμμα απαρτίζεται από τις παρακάτω θεματικές ενότητες σεμιναρίων:
            <ol type="i">
                <li>Εισαγωγή στα Digital Skills</li>
                <li>Ανάπτυξη e-commerce Plan</li>
                <li>Logistics, Pricing & ERP</li>
                <li>Σχεδιασμός Digital Marketing Plan</li>
                <li>Usability και Content Marketing</li>
                <li>Search Engines</li>
                <li>Social Media</li>
                <li>Εmail Marketing και CRM</li>
                <li>ΙοΤ, Business Analytics & ΑΙ</li>
                <li>Βιώσιμη ανάπτυξη και ESG</li>
            </ol>
        </div>

        <div>
            <span style="font-weight:700"> 2.4.</span>  Οι Συμμετέχοντες μπορούν να επιλέξουν να παρακολουθήσουν μία ή περισσότερες θεματικές ενότητες.
            <br> Οι ημερομηνίες έναρξης και ολοκλήρωσης του Προγράμματος, καθώς και οι ακριβείς ημερομηνίες έναρξης και ολοκλήρωσης κάθε θεματικής 
            βρίσκονται στην Ιστοσελίδα του Προγράμματος.  <br> <br>
        </div>

        <div>
            <span style="font-weight:700"> 2.5.</span>  Η συνολική διάρκεια του Προγράμματος ορίζεται από 01/09/2023 έως και 30/07/2024. <br> <br>
        </div>

        <div>
            <span style="font-weight:700"> 2.6.</span>  Η παρακολούθηση είναι εξ’ολοκλήρου εξ’αποστάσεως, με συνδυασμό σύγχρονων και ασύγχορνων μεθόδων 
            διδασκαλίας, συνδυάζοντας online διαλέξεις και εκπαιδευτικές ασκήσεις για την εμπέδωση της ύλης μέσω πλατφόρμας e-Learning.  <br> <br>
        </div>

        <div>
            <span style="font-weight:700"> 2.7.</span>  Οι Συμμετέχοντες θα λαμβάνουν πληροφορίες και ενημερώσεις για το Πρόγραμμα, τις διαλέξεις, 
            και σημαντικές ανακοινώσεις μέσω της Πλατφόρμας e-Learning, με e-mail, και με sms, 
            αν έχουν δώσει την αντίστοιχη συγκατάθεση. <br> <br>
        </div>

        <div style="font-size:28px; font-weight:700">3. Διαλέξεις</div> <br>

         <!-- εδω θελει διορθωση -->
        <div>
            <span style="font-weight:700"> 3.1.</span> Κάθε σεμινάριο έχει συνολική διάρκεια από δέκα (10) έως δώδεκα (12) ώρες, και χωρίζεται σε έως τέσσερις 
            4 διαλέξεις.  <br> <br>
        </div>

        <div>
            <span style="font-weight:700"> 3.2.</span> Οι διαλέξεις πραγματοποιούνται μία ή δύο φορές την εβδομάδα διαδικτυακά, μέσω της πλατφόρμας Microsoft Τeams. 
            Επιπλέον, οι Συμμετέχοντες χρησιμοποιούν ειδικά σχεδιασμένη eLearning πλατφόρμα του Διοργανωτή  για την επίλυση ασκήσεων, 
            και την τελική δοκιμασία (τέστ) κάθε θεματικής ενότητας. 
            <br> <br>
        </div>

        <div>
            <span style="font-weight:700"> 3.3.</span> Οι ημερομηνίες των διαλέξεων κάθε μεμονωμένου σεμιναρίου βρίσκονται στην Ιστοσελίδα του Προγράμματος. 
            <br> <br>
        </div>

        <div>
            <span style="font-weight:700"> 3.4.</span> Το ανώτατο όριων Συμμετεχόντων ανά διάλεξη για εκπαιδευτικούς και τεχνικούς λόγους έχει οριστεί στα 3.000 άτομα ανά ενότητα
            <br> <br>
        </div>

        <div style="font-size:28px; font-weight:700">4. Εισηγητές</div> <br>

        <div>
            Οι Εισηγητές του Προγράμματος είναι Καθηγητές, Μέλη Διδακτικού Ερευνητικού Προσωπικού (ΔΕΠ), Μεταδιδακτορικοί Ερευνητές, 
            και Υποψήφιοι Διδάκτορες ειδικευμένοι στο ηλεκτρονικό εμπόριο και τις ψηφιακές δεξιότητες από το Οικονομικό Πανεπιστήμιο 
            Αθηνών και το Χαροκόπειο Πανεπιστήμιο. 
        </div> <br><br>

        <div style="font-size:28px; font-weight:700">5. Βεβαίωση Συμμετοχής και Πιστοποιητικό Επιτυχούς Ολοκλήρωσης</div> <br>
        <ol>
            <li>
                <span style="font-weight:700" >Βεβαίωση Συμμετοχής</span>
                <div>
                    Για κάθε σεμινάριο που παρακολουθούν οι Συμμετέχοντες μπορούν να λάβουν Βεβαίωση Συμμετοχής υπό τις εξής προϋποθέσεις:
                    <ol class="greek">
                        <li> Να έχουν παρακολουθήσει το 90% των διαλέξεων της θεματικής ενότητας </li>
                        <li>Εντός 10 ημερών μετά την ολοκλήρωση της θεματικής ενότητας να ολοκληρώσουν με επιτυχία το Online Test Αξιολόγησης της θεματικής ενότητας, ασύγχρονα μέσω της πλατφόρμας eLearning</li>
                        <li>Να έχουν ποσοστό επιτυχίας τουλάχιστον 65% στο Online Test Αξιολόγησης </li>
                    </ol>
                    <br>
                    Οι Βεβαιώσεις Συμμετοχής εκδίδονται από το Κέντρο Επιμόρφωσης και Δια Βίου Μάθησης (ΚΕ.ΔΙ.ΒΙ.Μ.) του Οικονομικού Πανεπιστημίου Αθηνών.
                </div>
            </li>
            <br>
            <li>
                <span style="font-weight:700" >Πιστοποιητικό Κατάρτισης</span>
                <div>Αν οι Συμμετέχοντες παρακολουθήσουν το σύνολο του Προγράμματος μπορούν να λάβουν Πιστοποιητικό Κατάρτισης, υπό τις εξής προϋποθέσεις</div><br>
                <ol class="greek">
                    <li>Να έχουν ολοκληρώσει επιτυχώς το 100% των θεματικών ενοτήτων του Προγράμματος </li>
                    <li>Εντός 10 ημερών από την ολοκλήρωση του Προγράμματος να ολοκληρώσουν με επιτυχία το Online Test Αξιολόγησης του Προγράμματος, ασύγχρονα μέσω της πλατφόρμας eLearning</li>
                    <li>Να έχουν ποσοστό επιτυχίας τουλάχιστον 65% στο Online Test Αξιολόγησης </li>
                </ol>
                <br>
                Τα Πιστοποιητικά Κατάρτισης εκδίδονται από το Κέντρο Επιμόρφωσης και Δια Βίου Μάθησης (ΚΕ.ΔΙ.ΒΙ.Μ ) του Οικονομικού Πανεπιστημίου Αθηνών.

            </li>
            
        </ol>

        <div style="font-size:28px; font-weight:700">6. Προϋποθέσεις Συμμετοχής στο Πρόγραμμα: </div> <br>
        Η συμμετοχή στο Πρόγραμμα προϋποθέτει σωρευτικά:
        <ol class="greek">
            <li> την ύπαρξη Δικαιώματος Συμμετοχής, όπως περιγράφεται παρακάτω στο 7, «Δικαίωμα Συμμετοχής»,</li>
            <li>την υποβολή Αίτησης Συμμετοχής με τον τρόπο που περιγράφεται παρακάτω, στο 8, «Τρόπος Συμμετοχής στο Πρόγραμμα», και εντός του χρονικού πλαισίου που ορίζεται παρακάτω στο 9. «Χρόνος Υποβολής Αιτήσεων Συμμέτοχής»</li>
            <li>την ανεπιφύλακτη αποδοχή των Όρων και Προϋποθέσεων Συμμετοχής στο Πρόγραμμα, όπως περιγράφονται στο παρόν και της Δήλωσης Ιδιωτικότητας του Προγράμματος, και την παροχή συγκατάθεσής για τη συλλογή και την επεξεργασία των προσωπικών δεδομένων που απαιτούνται στο πλαίσιο και για τους σκοπούς του Προγράμματος, όπως αναλυτικά περιγράφονται στη Δήλωση Ιδιωτικότητας,</li>
            <li>την τήρηση του χρονοδιαγράμματος που περιγράφεται στο παρόν, αναφορικά με την εμπρόθεσμη υποβολή Αιτήσεων Συμμετοχής, και την αποστολή των απαραίτητων προσωπικών δεδομένων,</li>
            <li>την ύπαρξη διαθέσιμων θέσεων, με βάση το όριο Συμμετεχόντων όπως αυτό υπαγορεύεται από τους σχετικούς τεχνικούς και εκπαιδευτικούς λόγους  (βλ. 3.4)</li>
            <li>τη δυνατότητα πρόσβασης των ενδιαφερομένων στο διαδίκτυο με δικά τους τεχνικά μέσα. Οι Διοργανωτές δεν αναλαμβάνουν υποχρέωση για την εξασφάλιση, την παροχή βοήθειας για την πρόσβαση σε χώρους διαδικτύου ή την παροχή διευκολύνσεων σε κάθε ενδιαφερόμενο.</li>
        </ol>

        <div style="font-size:28px; font-weight:700">7. Δικαίωμα Συμμετοχής στο Πρόγραμμα</div> <br>
        <div>
            <span style="font-weight:700"> 7.1.</span> Δικαίωμα συμμετοχής στο Πρόγραμμα έχουν μικρές και μεσαίες επιχειρήσεις και επιτηδευματίες (νομικά πρόσωπα και ατομικές επιχειρήσεις) που διαθέτουν έως και 50 εργαζομένους, έχουν καταστατική έδρα στην Ελλάδα και δραστηριοποιούνται εντός της ελληνικής επικράτειας.
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 7.2.</span> Εξαιρούνται από το Πρόγραμμα οι Επιχειρήσεις και οι επιτηδευματίες που 
            <ol class="greek">
                <li>δραστηριοποιούνται σε τομείς άμεσα ή έμμεσα ανταγωνιστικούς ττης COSMOTE και της ΓΕΡΜΑΝΟΣ ή/και των συνδεδεμένων με αυτές εταιρειών (όπως ενδεικτικά παροχής τηλεπικοινωνιακών υπηρεσιών, πληροφορικής, εμπορίας και διανομής προϊόντων τεχνολογίας και άλλων σχετικών ειδών και αναλωσίμων, κτλ).   </li>
                <li>δραστηριοποιούνται σε τομείς που προσβάλλουν ή δύναται να προσβάλλουν τη δημόσια αιδώ, τα χρηστά ήθη ή/και δε συνάδουν με την εταιρική εικόνα των Διοργανωτριών , κατά την απόλυτη κρίση τους.</li>
                <li> έχουν τεθεί σε καθεστώς πτώχευσης, αναγκαστικής διαχείρισης ή έχουν εκκινήσει διαδικασία συνδιαλλαγής με πιστωτές ή εκκρεμεί σε βάρος ή έχουν υποβάλλει αίτηση για την κήρυξη τους σε καθεστώς πτώχευσης, αναγκαστικής διαχείρισης ή άλλης αντίστοιχης διαδικασίας, ή βρίσκονται σε διαδικασία εκκαθάρισης ή προσωπικές εταιρίες των οποίων οι εταίροι εμπίπτουν στον παραπάνω περιορισμό.</li>
                <li>εκπροσωπούνται ή ανήκουν σε εργαζόμενους των εταιρειών της COSMOTE και της ΓΕΡΜΑΝΟΣ ή/και των συνδεδεμένων με αυτές εταιρειών και των Συνεργαζόμενων Εταιρειών ή σε συγγενείς α΄ και β΄ βαθμού των ανωτέρω προσώπων. </li>

            </ol>
        </div>


        <div style="font-size:28px; font-weight:700">8. Χρόνος Υποβολής Αιτήσεων Συμμετοχής </div> <br>
        <div>
            <span style="font-weight:700"> 8.1.</span> Οι Αιτήσεις Συμμετοχής υποβάλλονται από 1/9/2023 έως και μια (1) εβδομάδα πριν από την 
            έναρξη της  κάθε θεματικής ενότητας, όπως αυτές αναφέρονται στην Ιστοσελίδα του Προγράμματος. 
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 8.2.</span> Έγκυρες θεωρούνται οι αιτήσεις που υποβάλλονται εντός αυτού του χρονικού πλαισίου. 
            Αιτήσεις που υποβάλλονται εκτός του χρονικού πλαισίου δε λαμβάνονται υπόψιν, και τα στοιχεία που περιλαμβάνουν διαγράφονται.
            <br> <br>
        </div>

        <div style="font-size:28px; font-weight:700">9. Τρόπος Συμμετοχής στο Πρόγραμμα</div> <br>
        <div>
            <span style="font-weight:700"> 9.1.</span> Για τη συμμετοχή στο Πρόγραμμα απαιτείται η συμπλήρωση και υποβολή της Αίτησης Συμμετοχής,
             που βρίσκεται αναρτημένη στην Ιστοσελίδα του Προγράμματος.  
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 9.2.</span>  Κάθε ενδιαφερόμενος έχει δικαίωμα υποβολής μίας (1) Αίτησης Συμμετοχής ανά επιχείρηση και μόνο για έως και δυο 
            (2) φυσικά πρόσωπα/εργαζομένους της επιχείρησης που θα παρακολουθήσουν το Πρόγραμμα.  
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 9.3.</span>  Η συμπλήρωση και υποβολή της Αίτησης Συμμετοχής γίνεται από τον υπεύθυνο ή το 
            νόμιμο εκπρόσωπο της επιχείρησης μέσω της Ιστοσελίδας του Προγράμματος.
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 9.4.</span>  Ο υπεύθυνος ή ο νόμιμος εκπρόσωπος της επιχείρησης θα πρέπει να συμπληρώσει την Αίτηση Συμμετοχής, 
            συμπληρώνοντας τα στοιχεία που αφορούν την επιχείρηση (Όνομα, Επώνυμο, ΑΦΜ, Επωνυμία Επιχείρησης, 
            Τομέας Δραστηριότητας, τηλέφωνο επικοινωνίας και email), αλλά και τα μαθήματα που θέλουν να παρακολουθήσουν. Επιπλέον:  
            <ol class="greek">
                <li>Αν θα παρακολουθήσει το Πρόγραμμα μόνο ο υπεύθυνος ή ο νόμιμος εκπρόσωπος της επιχείρησης θα χρειαστεί να συμπληρώσει και το πατρώνυμο του, για την έκδοση της Βεβαίωσης Παρακολούθησης του Προγράμματος. </li>
                <li>Αν θα παρακολουθήσουν το πρόγραμμα ο υπεύθυνος ή ο νόμιμος εκπρόσωπος της επιχείρησης και ένας εργαζόμενος, θα χρειαστεί ο υπεύθυνος ή ο νόμιμος εκπρόσωπος να συμπληρώσει το πατρώνυμο του, για την έκδοση της Βεβαίωσης Παρακολούθησης του Προγράμματος, και το email του εργαζομένου που θα συμμετάσχει. Στην συνέχεια, ο εργαζόμενος θα λάβει email με σύνδεσμο, ώστε να μπορέσει να καταχωρήσει τα προσωπικά του στοιχεία ( Όνομα, Επώνυμο, Πατρώνυμο, τηλέφωνο επικοινωνίας και email). </li>
                <li>Αν θα παρακολουθήσουν το πρόγραμμα μόνο εργαζόμενοι της επιχείρησης, θα χρειαστεί ο υπεύθυνος ή ο νόμιμος εκπρόσωπος να συμπληρώσει τα email των εργαζομένων (μέχρι 2) που θα συμμετάσχουν. Στην συνέχεια, οι εργαζόμενοι θα λάβουν προσωπικά email με σύνδεσμο, ώστε να μπορέσουν να καταχωρήσουν τα προσωπικά τους στοιχεία (Όνομα, Επώνυμο, Πατρώνυμο, τηλέφωνο επικοινωνίας και email) στην Αίτηση Συμμετοχής. </li>
            </ol>
            <div>Οι εργαζόμενοι πρέπει να συμπληρώσουν τα στοιχεία τους στην Αίτηση Συμμετοχής εντός δεκαπέντε (15) ημερών από την αποστολή του σχετικού συνδέσμου με e-mail. Αν αυτή η περίοδος παρέλθει χωρίς να συμπληρωθεί η Αίτηση, αποστέλλεται  υπενθύμιση και προσφέρεται περιθώριο τριών (3) ημερών. Η πάροδος και αυτού του διαστήματος χωρίς να συμπληρωθεί η Αίτηση θα επιφέρει την άμεση και αυτοδίκαιη ακύρωση της συμμετοχής </div>
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 9.5.</span>   Ο υπεύθυνος/νομίμος εκπρόσωπος της επιχείρησης θα χρειαστεί να κάνει επιπλέον ενεργοποίηση του λογαριασμού
             της επιχείρησης στην Πλατφόρμα e-Learning (βλ. 10 «Πρόσβαση στην Πλατφόρμα e-Learning»). 
             Από το λογαριασμό αυτό έχει πρόσβαση σε όλες τις πληροφορίες του Προγράμματος, 
             και τα στοιχεία που έχει παραχωρήσει (προσωπικά δεδομένα και στοιχεία της επιχείρησης) 
             στο πλαίσιο της Αίτησης Συμμετοχής. Αντίστοιχος λογαριασμός δημιουργείται για κάθε Συμμετέχοντα και 
             ενεργοποιείται αυτόματα με τη συμπλήρωση των στοιχείων του/της στην Αίτηση Συμμετοχης (βλ. 9.4.γ)
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 9.6.</span>   Κάθε ενδιαφερόμενος φέρει την αποκλειστική ευθύνη για την αλήθεια, την ακρίβεια, 
            την πληρότητα και ορθότητα των υποβαλλόμενων στοιχείων. Οι Διοργανωτές δε φέρουν ουδεμία ευθύνη διασταύρωσης των στοιχείων. 
            Ελλιπής ή ανακριβής δήλωση /υποβολή στοιχείων παρέχει δικαίωμα στους Διοργανωτές να μην επιτρέψουν τη συμμετοχή στο Πρόγραμμα, 
            ή/και να ακυρώσουν τη συμμετοχή σε οποιοδήποτε στάδιο του Προγράμματος, χωρίς καμία αξίωση ή απαίτηση εκ μέρους των Συμμετεχόντων.
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 9.7.</span>  Η τυχόν λύση ή λήξη για οποιονδήποτε λόγο της συνεργασίας του Συμμετέχοντα με το/τα φυσικό/α 
            πρόσωπο/α του που παρακολουθεί/ουν το Πρόγραμμα, δεν επηρεάζουν τη συμμετοχή στο Πρόγραμμα, 
            ούτε και τις συναινέσεις και εξουσιοδοτήσεις που έχουν χορηγηθεί στο πλαίσιο του Προγράμματος.
            <br> <br>
        </div>


        <div style="font-size:28px; font-weight:700">10. Πρόσβαση στην Πλατφόρμα e-Learning </div> <br>
        <div>
            <span style="font-weight:700"> 10.1.</span>  Μετά την υποβολή της Αίτησης Συμμετοχής αποστέλεται μέσω ηλεκτρονικού ταχυδρομείου (e-mail) σύνδεσμος (link) για την ενεργοποίηση του λογαριασμού της επιχείρησης στην Πλατφόρμα e-Learning. Ο υπεύθυνος/νόμιμος εκπρόσωπος της επιχείρησης πρέπει να ολοκληρώσει τη διαδικασία ενεργοποίησης ακολουθώντας τις οδηγίες που περιλαμβάνονται στο e-mail. Για τους Συμμετέχοντες η διαδιακασία ενεργοποίησης γίνεται αυτόματα με τη συμπλήρωση των στοιχείων τους στην Αίτηση Συμμετοχής. Ο λογαριασμός της επιχείρησης και ο ατομικός λογαριασμός κάθε Συμμετέχοντα περιλαμβάνει στοιχεία και πληροφορίες για το Πρόγραμμα και τα μαθήματα, και προσφέρει τη δυνατότητα να αλλάξουν ή/και να διορθώσουν τα προσωπικά τους στοιχεία.  
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 10.2.</span>  Μια εβδομάδα πριν από την  έναρξη της κάθε θεματικής ενότητας   οι Συμμετέχοντες λαμβάνουν email με τα Microsoft Teams links για κάθε θεματική ενότητα και το link για την  πλατφόρμα eLearning. To e-mail αυτό περιλαμβάνει όλα τα στοιχεία και τις απαραίτητες πληροφορίες και οδηγίες σύνδεσης.
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 10.3.</span>  Με την Αίτηση Συμμετοχής οι Συμμετέχοντες ορίζουν έναν μοναδικό κωδικό (password) που θα χρησιμοποιηθεί για την πρόσβαση στην Πλατφόρμα e-Learning, μετά την αρχική ενεργοποίηση του λογαριασμού. Οι κωδικοί πρόσβασης είναι ατομικοί, μοναδικοί και πρέπει να τηρούνται μυστικοί. Οι Συμμετέχοντες καλούνται να διατηρούν τους κωδικούς τους μυστικούς και να μην τους μοιράζονται ή/και να τους αποκαλύπτουν σε τρίτους. 
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 10.4.</span> Οι Συμμετέχοντες έχουν την αποκλειστική ευθύνη για την ασφαλή τήρηση και την προστασία των μοναδικών κωδικών τους για την πλατφόρμα eLearning και οι Διοργανωτές δεν έχουν ούτε αναλαμβάνουν οιαδήποτε σχετική ευθύνη. Σε περίπτωση απώλειας οι Συμμετέχοντες μπορούν μέσα από την σελίδα της πλατφόρμας να δημιουργήσουν εκ νέου μυστικό κωδικό πρόσβασης. 
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 10.5.</span> Η πρόσβαση στην Πλατφόρμα e-Learning επιτρέπεται μόνο υπό τους όρους λειτουργίας του οικείου διακομιστή (server) και τις τεχνικές και λοιπές προϋποθέσεις και προδιαγραφές του Προγράμματος, και διέπεται από τους Όρους Χρήσης της.
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 10.6.</span> Οι Συμμετέχοντες οφείλουν να σέβονται και να τηρούν τους Όρους Χρήσης της Πλατφόρμας e-Learning, και να χρησιμοποιούν την Πλατφόρμα μόνο με τους τρόπους και για τους σκοπούς που ορίζονται εκεί, σε συνδυασμό με τους παρόντες Όρους.
            <br> <br>
        </div>

        <div style="font-size:28px; font-weight:700">11. Προσωπικά Δεδομένα  </div> <br>
        <div>
            <span style="font-weight:700"> 11.1.</span>   Για τη διεξαγωγή του Προγράμματος απαιτείται η συλλογή και επεξεργασία από πλευράς των Διοργανωτών μιας σειράς δεδομένων, συμπεριλαμβανομένων και δεδομένων προσωπικού χαρακτήρα, που αφορούν τους Συμμετέχοντες. 
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 11.2.</span>   Τα δεδομένα αυτά συλλέγονται μέσα από την Αίτηση Συμμετοχής, που υποβάλλεται μέσω της Ιστοσελίδας του Προγράμματος (βλ. 9. «Τρόπος Συμμετοχής στο Πρόγραμμα») καθώς επίσης και κατά τη διαδικασία εγγραφής στην πλατφόρμα e-Learning. 
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 11.3.</span>   Κάποια από τα δεδομένα αυτά είναι απαραίτητα για τη συμμετοχή στο Πρόγραμμα και την έκδοση των Βεβαιώσεων Συμμετοχής και των Πιστοποιητικών Επιτυχούς Ολοκλήρωσης, ενώ άλλα συλλέγονται προαιρετικά, και μπορείτε να επιλέξετε να μη δώσετε τη συγκατάθεσή σας για τη συλλογή και επεξεργασία τους.
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 11.4.</span>   Αναλυτικές πληροφορίες για τη συλλογή και επεξεργασία προσωπικών δεδομένων των Συμμετεχόντων βρίσκονται στην Δήλωση Ιδιωτικότητας του Προγράμματος. Η αποδοχή της Δήλωσης Ιδιωτικότητας και η παροχή συγκατάθεσης για τη συλλογή και επεξεργασία των αναγκαίων προσωπικών δεδομένων για τη διεξαγωγή του Προγράμματος αποτελούν μια εκ των Προϋποθέσεων Συμμετοχής (βλ. 6. «Προϋποθέσεις Συμμετοχής στο Πρόγραμμα»), καθώς η συλλογή και επεξεργασία των συγκεκριμένων προσωπικών δεδομένων συνδέεται ουσιωδώς και άμεσα με τη διεξαγωγή του Προγράμματος και την παροχή πιστοποιήσεων μετά την ολοκλήρωσή του. 
            <br> <br>
        </div>


        <div style="font-size:28px; font-weight:700">12. Ακύρωση της Συμμετοχής στο Πρόγραμμα </div> <br>
        <div>
            <span style="font-weight:700"> 12.1.</span>   Η συμμετοχή στο Πρόγραμμα μπορεί να ακυρωθεί σε οποιοδήποτε στάδιο του Προγράμματος:
            <ol class="greek">
                <li> αν διαπιστωθεί ότι κατά την υποβολή της Αίτησης Συμμετοχής δεν πληρούταν έστω μία από τις Προϋποθέσεις Συμμετοχής, όπως αυτές ορίζονται στο 6 «Προϋποθέσεις Συμμετοχής στο Πρόγραμμα»,</li>
                <li>αν ο Συμμετέχων, για οποιονδήποτε λόγο, στην πράξη δεν αποδέχεται και δεν τηρεί τους παρόντες Όρους,</li>
                <li>σε περίπτωση που για οποιοδήποτε λόγο δεν καταστεί δυνατή η επικοινωνία με τον ενδιαφερόμενο εντός των προκαθορισμένων προθεσμιών που τάσσονται στο παρόν,</li>
                <li>αν με τις λόγω ή έργω ενέργειες του, ο Συμμετέχων παρακωλύει ουσιωδώς την ομαλή διεξαγωγή του Προγράμματος.</li>

            </ol>
        </div>
        <div>
            <span style="font-weight:700"> 12.2.</span>   Η απόφαση για την ακύρωση της συμμετοχής στο Πρόγραμμα θα κοινοποιείται στο Συμμετέχοντα μέσω e-mail και θα ισχύει αυτοδίκαια μετά την ημερομηνία αποστολής της επικοινωνίας.
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 12.3.</span>   Σε περίπτωση ακύρωσης της συμμετοχής οι Συμμετέχοντες δεν επηρεάζονται ως προς την παροχή Βεβαίωσης Συμμετοχής για όσα σεμινάρια που έχουν ήδη παρακολουθήσει, εφόσον πληροί τις προς αυτό προϋποθέσεις (βλ. 5.1. «Βεβαίωση Συμμετοχής»)
            <br> <br>
        </div>

        <div style="font-size:28px; font-weight:700">13. Λοιποί Όροι </div> <br>
        <div>
            <span style="font-weight:700"> 13.1.</span>   Οι Διοργανωτές διατηρούν δικαίωμα να τροποποιήσουν τους παρόντες όρους, εν όλω ή εν μέρει, ή/και να κάνουν προσθήκες. Στην περίπτωση αυτή, θα υπάρξη σχετική ανακοίνωση στην Ιστοσελίδα του Προγράμματος, και θα ζητηθεί από τους συμμετέχοντες να επιβεβαιώσουν την αποδοχή των καινούριων όρων, δεδομένου ότι η παρακολούθηση του Προγράμματος συνεπάγεται την ανεπιφύλακτη αποδοχή των Όρων και Προϋποθέσεων Συμμετοχής.
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 13.2.</span>  Η ευθύνη των Διοργανωτών περιορίζεται αποκλειστικά και μόνο στην υλοποίηση του Προγράμματος. Οι Διοργανωτές δε φέρουν καμία ευθύνη ποινική ή αστική προς οποιονδήποτε Συμμετέχοντα  ή Τρίτο, για οποιοδήποτε ατύχημα ήθελε συμβεί και/ή ζημία και/ή για οποιαδήποτε σωματική ή άλλη βλάβη ήθελε προκληθεί σε αυτό/ούς σχετιζόμενη άμεσα ή έμμεσα με το Πρόγραμμα.
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 13.3.</span>   Οι Διοργανωτές διατηρούν δικαίωμα να παρατείνουν, μειώσουν, ματαιώσουν ή διακόψουν το Πρόγραμμα με σχετική ανακοίνωση στην Ιστοσελίδα του Προγράμματος  ή/και στην ιστοσελίδα <a href="https://www.growyourbusiness.gr">https://www.growyourbusiness.gr</a> του Ιστότοπου της COSMOTE, σε οποιοδήποτε χρονικό σημείο.  Οι Διοργανωτές  δεν υπέχουν ευθύνη έναντι των ενδιαφερομένων ή/και οποιουδήποτε τρίτου για τυχόν άμεσες ή έμμεσες ζημίες, δαπάνες και έξοδα που μπορεί να προκύψουν από τυχόν διακοπή προσωρινή ή μόνιμη, δυσλειτουργία ή καθυστέρηση ή οποιαδήποτε άλλη αιτία σχετικά με την υλοποίηση του Προγράμματος. 
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 13.4.</span>   Σε περίπτωση κατά την οποία τρίτος εγείρει οποιαδήποτε αξίωση κατά των Διοργανωτών για την προσβολή δικαιώματός του που γεννάται από τις δηλώσεις, πράξεις και παραλείψεις Συμμετέχοντα ή/και των προσώπων που ενεργούν για λογαριασμό του, στο πλαίσιο της συμμετοχής του στο Πρόγραμμα (πχ ενδεικτικά και όχι περιοριστικά, σε περίπτωση δήλωσης ψευδών στοιχείων ή στοιχείων άλλης επιχείρησης), ο Συμμετέχων οφείλει να αποζημιώσει πλήρως τον θιγόμενο τρίτο, φυσικό ή νομικό πρόσωπο και τους Διοργανωτές ή/και τις Συνεργαζόμενες Εταιρείες για κάθε ζημία τους. 
            <br> <br>
        </div>

        <div style="font-size:28px; font-weight:700">14.Επίλυση διαφορών – Εφαρμοστέο Δίκαιο  </div> <br>
        <div>
            <span style="font-weight:700"> 14.1.</span>   Σε περίπτωση διαφωνίας, διένεξης ή αμφισβήτησης από Συμμετέχοντα στο Πρόγραμμα, σχετικά με την ερμηνεία ή ορθή εφαρμογή όρων του Προγράμματος, ο Συμμετέχων θα απευθύνεται πριν οποιαδήποτε δικαστική ενέργεια σε τριμελή επιτροπή αποτελούμενη από εκπροσώπους του Διοργανωτή, η οποία θα καταβάλλει προσπάθεια συμβιβαστικής, φιλικής επίλυσης της διαφοράς. 
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 14.2.</span>   Κάθε διένεξη, διαφορά ή διαφωνία που δεν θα έχει επιλυθεί φιλικά κατά την ανωτέρω διαδικασία και απορρέει από τους παρόντες Όρους ή σχετίζεται με αυτούς, θα επιλύεται αποκλειστικά από τα δικαστήρια της Αθήνας (αποκλειόμενης οποιασδήποτε άλλης δικαιοδοσίας ακόμη και συντρέχουσας) σύμφωνα με το ελληνικό δίκαιο.
            <br> <br>
        </div>
        <div>
            <span style="font-weight:700"> 14.3.</span>   Οι παρόντες όροι και οι κάθε είδους συμβατικές και εξωσυμβατικές σχέσεις που προκύπτουν κατ' εφαρμογή αυτών διέπονται από το ελληνικό δίκαιο για όλους τους συμμετέχοντες. Κάθε διαφορά σχετικά με το Πρόγραμμα που τυχόν προκύψει στο μέλλον, υποβάλλεται στην αποκλειστική αρμοδιότητα των Δικαστηρίων της Αθήνας, ακόμη και από μη μόνιμους κατοίκους της Ελλάδας.
            <br> <br>
        </div>

        <div style="font-size:28px; font-weight:700">15.Πληροφορίες – Παράπονα  </div> <br>
        <div>
            Για οποιαδήποτε πληροφορία ή παράπονο σχετικά με το Πρόγραμμα οποιοσδήποτε Συμμετέχων ή τρίτος μπορεί να επικοινωνεί με τους Διοργανωτές μέσω μηνύματος ηλεκτρονικού ταχυδρομείου, στη διεύθυνση <a href="mailto: growyourbusiness@equality.org.gr"> growyourbusiness&#64;equality.org.gr</a>
        </div>

        <br> <br>
        <div>
            Οι όροι αυτοί ανανεώθηκαν την 23η Αυγούστου 2023 και αντικαθιστούν κάθε προηγούμενη έκδοση.
        </div>
        
    </div>
</div>