<div fxlayout="row" fxLayoutAlign="center center">
    <div style="width:960px" [ngStyle.lt-md]="{'width': '90%'}">

        <div style="text-align: center;">
            <h2 style="font-size:36px; line-height:50px">Cookies Policy and Notice</h2>
        </div>

        <div style="font-weight: 700">(Εμφανίζεται μόλις μπαίνεις στο site για πρώτη φορά ή κάθε φορά που μπαίνετε στο site αν δεν έχετε αποδεχτεί τα cookies)</div>
        <div>Η ιστοσελίδα χρησιμοποιεί cookies για τη διευκόλυνση των χρηστών, την καλύτερη λειτουργία της ιστοσελίδας, τη βελτίωση των υπηρεσιών μας όπως και για να σας προσφέρουμε εξατομικευμένη εμπειρία, περιεχόμενο και διαφημίσεις. Μπορείτε να συναινέσετε στη χρήση όλων ή κάποιών μόνο cookies και να αποκτήσετε πρόσβαση σε πιο λεπτομερείς πληροφορίες. Επίσης μπορείτε ανά πάσα στιγμή να αλλάξετε τις προτιμήσεις σας επισκεπτόμενοι την πολιτική μας για τα cookies</div>
        <div>
            Επιλογές:
            <ul>
                <li>Αποδέχομαι τα πάντα</li>
                <li>Απορρίπτω τα πάντα (εκτός από τα απαραίτητα)</li>
                <li>Μεμονωμένες επιλογές ανά cookie</li>
            </ul>
        </div>
        <div style="font-weight: 700">Cookies Policy</div>
        <div style="font-weight: 700">Τα cookies γενικά</div>
        <div>Τα cookies είναι ένα μικρά κομμάτια κώδικα που παράγονται από έναν ιστότοπο και αποθηκεύονται από το πρόγραμμα περιήγησης (browser). Ο σκοπός τους είναι να συγκρατούν συγκεκριμένες πληροφορίες σχετικά με το χρήστη, επιτρέποντας στις ιστοσελίδες να λειτουργούν απρόσκοπτα, και στους διαχειριστές να καταλαβαίνουν καλύτερα τις ανάγκες και τις προτιμήσεις σας και να σας προσφέρουν εξατομικευμένο περιεχόμενο και διαφημίσεις.</div>
        <div style="font-weight: 700">Οι βασικές κατηγορίες cookies</div>
        <div>
            Σε γενικές γραμμές, υπάρχουν αναγκαία cookies, που επιτρέπουν την εκτέλεση βασικών λειτουργιών, cookies λειτουργικότητας, τα οποία θυμούνται τις προτιμήσεις των χρηστών κατά την περιήγηση  στην ιστοσελίδα, cookies επιδόσεων, που  συλλέγουν πληροφορίες σχετικά με το πώς οι επισκέπτες χρησιμοποιούν την ιστοσελίδα, και χρησιμεύουν κυρίως για τη βελτίωση του τρόπου λειτουργίας των ιστοσελίδων χωρίς να συλλέγουν πληροφορίες που προσδιορίζουν τον επισκέπτη, και cookies analytics και διαφημίσεων, που επιτρέπουν την αξιολόγηση της αποτελεσματικότητας των διάφορων λειτουργιών της ιστοσελίδας και αποκαλύπτουν πληροφορίες χρήσιμες για την παραγωγή εξατομικευμένου περιεχομένου και διαφημίσεων που μπορεί να σας ενδιαφέρουν .
            <br>Επιπλέον, τα cookies διαιρούνται σε session και persistent, first-party και third-party.
            <br>Τα session cookies  είναι προσωρινά αρχεία, τα οποία διαγράφονται όταν κλείνει το πρόγραμμα περιήγησής. Τα persistent cookies παραμένουν σε έναν από τους υποφακέλους του προγράμματος περιήγησης, μέχρι να διαγραφούν με μη αυτόματο τρόπο ή το πρόγραμμα περιήγησής  να τα διαγράψει με βάση την διάρκεια κάθε cookie.
            <br>Τα first-party cookies προέρχονται από τη δική μας ιστοσελίδα, ενώ τα third-party cookies προέρχονται ή αποστέλλονται από τρίτους, για διαφημιστικούς ή άλλους σκοπούς marketing.
        </div>
        <div style="font-weight: 700">Τα cookies στη δική μας ιστοσελίδα</div>
        <div>Η ιστοσελίδα μας χρησιμοποιεί τις παρακάτω κατηγορίες cookies:</div>
        <ul>
            <li>Απαραίτητα cookies, δλδ cookies  χωρίς τα οποία ο ιστότοπός μας δεν θα μπορούσε να λειτουργήσει. Αυτά τα τοποθετούνται αυτόματα στη συσκευή σας και δεν μπορούν να απενεργοποιηθούν. Τα απαραίτητα cookies δεν μπορούν να σας ταυτοποιήσουν και υπάρχουν μόνο για να διασφαλιστεί ότι ο ιστότοπος λειτουργεί και προβάλλεται σωστά.</li>
            <li>Cookies για analytics, τα οποία μας επιτρέπουν να πραγματοποιούμε διάφορες στατιστικές αναλύσεις για τον ιστότοπό μας. Αυτά τα cookies χρησιμοποιούνται σε ανωνυμοποιημένη μορφή, πράγμα που σημαίνει ότι δεν μπορούν να σας ταυτοποιήσουν. Μπορείτε να τα απενεργοποιήσετε στο πρόγραμμα περιήγησής σας ή χρησιμοποιώντας τις «Ρυθμίσεις cookies» στο κάτω μέρος του ιστότοπού μας.</li>
            <li>Λειτουργικά cookies, δλδ cookies που επιτρέπουν διάφορες λειτουργίες του ιστότοπού μας, όπως ο καθορισμός της γλώσσας,. Αυτά τα cookies τοποθετούνται στη συσκευή σας μόνο με την προηγούμενη συγκατάθεσή σας.</li>
            <li>Cookies για marketing και advertising, δλδ cookies που χρησιμοποιούνται για την παροχή εξατομικευμένων διαφημιστικών υπηρεσιών. Χρησιμοποιώντας δεδομένα, αυτά τα cookies δημιουργούν το προφίλ σας βάσει του οποίου θα βλέπετε μόνο διαφημίσεις που σας ενδιαφέρουν. Αυτά τα cookies τοποθετούνται στη συσκευή σας μόνο με την προηγούμενη συγκατάθεσή σας. Η άρνηση χρήσης αυτών των cookies δεν εμποδίζει την προβολή διαφημίσεων, ωστόσο δεν προσαρμοσμένες στα ενδιαφέροντά σας.</li>
        </ul>
        <div style="font-weight:700">Πίνακας των cookies του ιστοτόπου</div>
        <br>
        <div style="font-weight:700">Απαραίτητα/Υποχρεωτικά</div>
        <table style="border: 2px solid rgb(126, 96, 223)">
            <tr style="border-bottom: 2px solid rgb(126, 96, 223); background-color: rgb(126, 96, 223); color:white">
              <th>Όνομα Cookie</th>
              <th>Κατηγορία</th>
              <th>Σκοπός</th>
              <th>Διάρκεια</th>
              <th >Μεταβίβαση σε τρίτους</th>
            </tr>
            <tr>
                <th>hs</th>
                <th>First party</th>
                <th>Εξασφαλίζει την ασφάλεια του επισκέπτη στη διάρκεια της περιήγησης</th>
                <th>Session</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Όχι</th>
            </tr>
            <tr>
                <th>ssr-caching</th>
                <th>First party</th>
                <th>Είναι απαραίτητο για τη λειτουργία της κρυφής μνήμης, ώστε η ιστοσελίδα να θυμάται τον επισκέπτη</th>
                <th>Μία μέρα</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Όχι</th>

            </tr>
            <tr>
                <th>XSRF-TOKEN</th>
                <th>First party</th>
                <th>Εξασφαλίζει την ασφάλεια της περιήγησης των επισκεπτών αποτρέποντας την πλαστογραφία μεταξύ ιστοτόπων</th>
                <th>Session</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Όχι</th>

            </tr>
        </table>
        <br>
        <div style="font-weight:700">Απαραίτητα/Υποχρεωτικά</div>
        <table style="border: 2px solid rgb(126, 96, 223)">
            <tr style="border-bottom: 2px solid rgb(126, 96, 223); background-color: rgb(126, 96, 223); color:white">
              <th>Όνομα Cookie</th>
              <th>Κατηγορία</th>
              <th>Σκοπός</th>
              <th>Διάρκεια</th>
              <th>Μεταβίβαση σε τρίτους</th>
            </tr>
            <tr>
                <th>fedops.logger.defaultOverrides</th>
                <th>First party</th>
                <th>Καταχωρεί στατιστικά στοιχεία για τη συμπεριφορά των χρηστών στον ιστότοπο. Χρησιμοποιείται για γενική</th>
                <th>Μια μέρα</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Όχι</th>
            </tr>
            <tr >
                <th>fedops.logger.sessionId</th>
                <th>First party</th>
                <th>Καταχωρεί στατιστικά στοιχεία για τη συμπεριφορά των χρηστών στον ιστότοπο. Χρησιμοποιείται για γενική</th>
                <th>Μόνιμο</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Όχι</th>
            </tr>
            <tr >
                <th>svSession</th>
                <th>First party</th>
                <th>Παρακολουθεί έναν επισκέπτη σε όλους τους ιστότοπους wix.com. Οι πληροφορίες που συλλέγονται μπορούν να</th>
                <th>Δύο έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Όχι</th>
            </tr>
            <tr >
                <th>c_user</th>
                <th>Third party (facebook)</th>
                <th>Χρησιμοποιείται για να αποθηκεύει το unique user ID</th>
                <th>Ένας μήνας</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>xs</th>
                <th>Third party (facebook)</th>
                <th>Χρησιμοποιείται για να αποθηκεύει το unique user ID</th>
                <th>Ένας μήνας</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>oo</th>
                <th>Third party (facebook)</th>
                <th>Παρακολουθεί έναν επισκέπτη διαφορετικούς ιστοτόπους με σκοπό με βάση τη συμπεριφορά και τις προτιμήσεις να</th>
                <th>Πέντε έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr>
                <th>sb</th>
                <th>Third party (facebook)</th>
                <th>Χρησιμοποιείται για να αποθηκεύει πληροφορίες σχετικά με το πρόγραμμα περιήγησής σας</th>
                <th>Δύο έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>presence</th>
                <th>Third party (facebook)</th>
                <th>Χρησιμοποιείται για να επιτρέψει τη σωστή λειτουργία προσθέτων του Facebook στον ιστότοπο</th>
                <th>Session</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>wd</th>
                <th>Third party (facebook)</th>
                <th>Χρησιμοποιείται για να αναγνωρίσει και να αποθηκεύσει την ανάλυση της οθόνης.</th>
                <th>Μια εβδομάδα</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>fr</th>
                <th>Third party (facebook)</th>
                <th>Χρησιμοποιείται για την προβολή διαφημίσεων ή την επαναστόχευση</th>
                <th>Τρεις μήνες</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>m_pixel_ratio</th>
                <th>Third party (facebook)</th>
                <th>Χρησιμοποιείται για να επιτρέψει τη σωστή λειτουργία προσθέτων του Facebook στον ιστότοπο</th>
                <th>Session</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>m_page_voice</th>
                <th>Third party (facebook)</th>
                <th>Χρησιμοποιείται για να επιτρέψει τη σωστή λειτουργία προσθέτων του Facebook στον ιστότοπο</th>
                <th>Session</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>datr</th>
                <th>Third party (facebook)</th>
                <th>Χρησιμοποιείται για να επιτρέψει τη σωστή λειτουργία προσθέτων του Facebook στον ιστότοπο</th>
                <th>Τρεις μήνες</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>_Secure-3PSIDCC</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται για τη δημιουργία προφίλ χρήστη και την προβολή σχετικών και εξατομικευμένων διαφημίσεων Google στον</th>
                <th>Δύο έτη</th>
                <th>Ναι</th>
            </tr>
            <tr >
                <th>_Secure-1PSIDCC</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται για να δημιουργηθεί προφίλ με βάση τα ενδιαφέροντα των επισκεπτών του ιστότοπου με σκοπό την</th>
                <th>Δύο έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>_Secure-3PSID</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται για να δημιουργηθεί προφίλ με βάση τα ενδιαφέροντα των επισκεπτών του ιστότοπου με σκοπό την</th>
                <th>Δύο έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>SIDCC</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται για την επιβεβαίωση της γνησιότητας του επισκέπτη, την αποτροπή της δόλιας χρήσης των</th>
                <th>Τρεις μήνες</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>SID</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται από το κουμπί κοινής χρήσης Google + 1 και απαιτείται για τη σύνδεση περιεχομένου με τον</th>
                <th>Δύο έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>_Secure-3PAPISID</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται για να δημιουργηθεί προφίλ με βάση τα ενδιαφέροντα των επισκεπτών του ιστότοπου με σκοπό την</th>
                <th>Δύο έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>APISID</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται ώστε η Google να συλλέγει πληροφορίες επισκεπτών για βίντεο που παίζονται από το YouTube.</th>
                <th>Persistent</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>SSID</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται ώστε η Google να συλλέγει πληροφορίες επισκεπτών για βίντεο που παίζονται από το YouTube, τα οποία</th>
                <th>Persistent</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>_Secure-1PAPISID</th>
                <th>Third party (google analytics)</th>
                <th></th>
                <th></th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>SAPISID</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται από το κουμπί κοινής χρήσης Google + 1 και απαιτείται για τη σύνδεση περιεχομένου με τον</th>
                <th>Δύο έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>HSID</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται για την επιβεβαίωση της γνησιότητας των επισκεπτών, την πρόληψη της δόλιας χρήσης των</th>
                <th>Δύο έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>_Secure-3PSID</th>
                <th>Third party (google analytics)</th>
                <th></th>
                <th></th>
                <th>Ναι</th>
            </tr>
            <tr >
                <th>_ga_0V7JNYGWCC</th>
                <th>Third party (google analytics)</th>
                <th></th>
                <th></th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>_ga_B8SKQ9HHPZ</th>
                <th>Third party (google analytics)</th>
                <th></th>
                <th></th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>_ga</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται για τη διάκριση των χρηστών.</th>
                <th>Δύο έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>ajs_user_id</th>
                <th>Third party (google analytics)</th>
                <th></th>
                <th></th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>NID</th>
                <th>Third party (google analytics)</th>
                <th>Αποθηκεύει τις προτιμήσεις των επισκεπτών και εξατομικεύει τις διαφημίσεις σε ιστότοπους</th>
                <th>Έξι μήνες</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>SEARCH_SAMESITE</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται για να εμποδίσει το πρόγραμμα περιήγησης να στείλει το cookie μαζί με αιτήματα μεταξύ τοποθεσιών</th>
                <th>Δύο έτη</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>AEC</th>
                <th>Third party (google analytics)</th>
                <th></th>
                <th></th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>CONSENT</th>
                <th>Third party (google analytics)</th>
                <th>Αποθηκεύει τις προτιμήσεις των επισκεπτών και εξατομικεύει τις διαφημίσεις.</th>
                <th>Persistent</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>_ga_X6LMX9VR0Y</th>
                <th>Third party (google analytics)</th>
                <th></th>
                <th></th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>1P_JAR</th>
                <th>Third party (google analytics)</th>
                <th>Χρησιμοποιείται για τη συλλογή στατιστικών στοιχείων ιστότοπου και την παρακολούθηση των ποσοστών μετατροπών.</th>
                <th>Ένας μήνας</th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
            <tr >
                <th>ajs_anonymous_id</th>
                <th>Third party (google analytics)</th>
                <th></th>
                <th></th>
                <th style="background-color: rgba(126, 96, 223, 0.1)">Ναι</th>
            </tr>
        </table>
        <br>

        <div style="font-weight:700">Οι σκοποί που χρησιμοποιούμε cookies</div>
        <div>Αξιοποιούμε τις πληροφορίες από τα απαραίτητα cookies με βάση το έννομο συμφέρον μας (Άρθρο 6 παράγραφος 1 στοιχείο στ) του Γενικού Κανονισμού για την Προστασία Δεδομένων) για να εξασφαλίζουμε ότι ο ιστότοπός μας λειτουργεί σωστά και παρέχει σε όλους τους επισκέπτες τις πρόσβαση στις πληροφορίες και τις ηλεκτρονικές υπηρεσίες που προσφέρουμε.
            <br>Αξιοποιούμε τις πληροφορίες από τα προαιρετικά cookies για να καταλάβουμε καλύτερα εσάς και τις ανάγκες σας, καθώς επίσης και για τη βελτίωση της εμπειρίας σας κατά τη χρήση του ιστότοπού, τη βελτίωση της λειτουργικότητας του ιστότοπου, για να βελτιώσουμε τα προϊόντα και τις υπηρεσίες μας, και για να σας εμφανίζουμε περιεχόμενο και διαφημίσεις που ανταποκρίνονται καλύτερα στις προτιμήσεις και τα ενδιαφέροντά σας, ή προτρέποντάς σας να επιστρέψετε στον ιστότοπό μας και να ολοκληρώσετε μια ενέργεια που δεν κάνατε πλήρως.<br>
            Για να συλλέξουμε και να επεξεργαστούμε τις πληροφορίες από τα προαιρετικά cookies ζητούμε τη συγκατάθεσή σας και σας, την οποία μπορείτε να αποσύρετε ελεύθερα, όποτε θελήσετε.
        </div>
        <div style="font-weight:700">Cookies που μοιραζόμαστε με τρίτους</div>
        <div>Τα προαιρετικά cookies 4 έως 36 τα μοιραζόμαστε με τη συγκατάθεσή σας με τους τρεις βασικούς συνεργάτες μας, στο Εκπαιδευτικό Πρόγραμμα COSMOTE GROW YOUR BUSINESS, δηλαδή με την COSMOTE A.E, και την mindshare, για σκοπούς ανάλυσης, marketing και παροχής εξατομικευμένων διαφημίσεων.</div>
        <div style="font-weight:700">Πώς μπορείτε να ρυθμίσετε τις προτιμήσεις σας;</div>
        <div>
            Όταν επισκεφθείτε τον ιστότοπό μας για πρώτη φορά, ανοίγεθ ένα αναδυόμενο παράθυρο μέσω του οποίου μπορείτε να επιλέξετε τις ρυθμίσεις cookies. Εάν έχετε ήδη επισκεφτεί τον ιστότοπό μας και έχετε επιβεβαιώσει τα επιλεγμένα cookies, μπορείτε να αλλάξετε αυτές τις ρυθμίσεις κάνοντας κλικ στις «Ρυθμίσεις cookies» στο κάτω μέρος του ιστότοπού μας.
            <br>
Οι ρυθμίσεις cookies μπορούν επίσης να ελέγχονται και να τροποποιούνται στο πρόγραμμα περιήγησής σας. Για πληροφορίες σχετικά με τις ρυθμίσεις των cookies, επιλέξτε το πρόγραμμα περιήγησης που χρησιμοποιείτε:
        </div>
        <ul>
            <li> <a target="_blank" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&hl=en"> Chrome</a> </li>
            <li> <a target="_blank" href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"> Firefox</a> </li>
            <li> <a target="_blank" href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"> Internet Explorer</a> </li>
            <li> <a target="_blank" href="https://support.apple.com/sl-si/guide/safari/sfri11471/mac"> Safari</a> </li>
            <li> <a target="_blank" href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"> Opera</a> </li>
        </ul>
        <div>Εάν αλλάξετε ή διαγράψετε το αρχείο cookies του προγράμματος περιήγησής σας ή αλλάξετε/αναβαθμίσετε το πρόγραμμα περιήγησης ή τη συσκευή σας, ίσως χρειαστεί να απενεργοποιήσετε ξανά τα cookies. Η διαδικασία διαχείρισης και διαγραφής των cookies εξαρτάται από το εκάστοτε πρόγραμμα περιήγησης. Εάν χρειάζεστε βοήθεια, μπορείτε να συμβουλευτείτε την ενότητα «Υποστήριξη χρήστη» του προγράμματος περιήγησής σας.</div>

        

    </div>
</div>