import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FaqComponent } from "src/app/common/components/faq.component";
import { OnInit } from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: './grow-your-business-faq.component.html',
    styleUrls: ['./grow-your-business-faq.component.css']
})
export class GrowYourBusinessFaqComponent  extends FaqComponent implements OnInit{
    constructor(router:Router){
        super(router);
    }

    public growYourBusinessMailHtml = "<a target='_blank' href= 'mailto:growyourbusiness@equality.org.gr'> growyourbusiness@equality.org.gr </a>";
    public equalityMailHtml = "<a target='_blank' href= 'mailto:info@equality.org.gr'> info@equality.org.gr </a>";
    private registrationLink = `<a  target='_blank'href='growyourbusiness-register'>εδώ</a>`
    private coursesLink = `<a target='_blank' href='growyourbusiness'>εδώ</a>`
    private loginLink = `<a target='_blank' href='growyourbusiness-login'>εδώ</a>`
    private setupLink =  `<a target='_blank' href='https://equality-prod.s3.eu-west-1.amazonaws.com/COSMOTE+GROW+YOUR+BUSINESS+CERTIFICATION+PROGRAM+Microsoft+Teams++eLearning+%CF%80%CE%BB%CE%B1%CF%84%CF%86%CE%BF%CC%81%CF%81%CE%BC%CE%B1.pdf'>εδώ</a>`

    ngOnInit(): void {
        this.faqs = [
            { 
                title: '01',
                description: 'Τι είναι το COSMOTE GROW YOUR BUSINESS – Certification Program;',
                text: 'Πρόκειται για ένα ολοκληρωμένο δωρεάν εκπαιδευτικό πρόγραμμα, το οποίο σχεδιάστηκε \
                μέσω της eQuality από την COSMOTE και το Εργαστήριο Ηλεκτρονικού Εμπορίου - ELTRUN, \
                του Οικονομικού Πανεπιστήμιου Αθηνών. Στόχος του είναι να εκπαιδεύσει τις Μικρομεσαίες \
                Επιχειρήσεις στα απαραίτητα ψηφιακά εργαλεία που θα τις οδηγήσουν στη νέα ψηφιακή εποχή.',
                panelOpenState: false
            },
            {
                title: '02',
                description:'Σε ποιους απευθύνεται το COSMOTE GROW YOUR BUSINESS – Certification Program;',
                text: 'Το πρόγραμμα απευθύνεται σε ιδιοκτήτες και σε εργαζόμενους μικρομεσαίων επιχειρήσεων.\
                 Από κάθε επιχείρηση μπορούν να λάβουν μέρος έως και 2 άτομα.',
                 panelOpenState: false
            },
            {
                title: '03',
                description:'Υπάρχει κόστος για την παρακολούθηση του COSMOTE GROW YOUR BUSINESS – Certification Program;',
                text: 'Το πρόγραμμα είναι Δωρεάν.',
                panelOpenState: false
            },
            {
                title: '04',
                description:'Δεν είμαι ο υπεύθυνος της επιχείρησης, μπορώ να δηλώσω συμμετοχή;',
                text: 'Μπορείτε να συμμετέχετε στο πρόγραμμα, αλλά η δήλωση συμμετοχής μπορεί να γίνει μόνο από τον υπεύθυνο της επιχείρησης και για μέχρι 2 άτομα.',
                panelOpenState: false
            },
            {
                title: '05',
                description:'Είμαι ο υπεύθυνος της επιχείρησης. Μπορώ να παρακολουθήσω εγώ και ένας εργαζόμενός μου το COSMOTE GROW YOUR BUSINESS – Certification Program;',
                text: 'Φυσικά! Μπορείτε να δηλώσετε μέχρι 2 άτομα της επιχείρησής σας, εκ των οποίον ο ένας μπορεί να είστε εσείς.',
                panelOpenState: false
            },
            {
                title: '06',
                description:'Δεν έχω κάνει ακόμα έναρξη επαγγελματικής δραστηριότητας, μπορώ να το παρακολουθήσω;',
                text: 'Το πρόγραμμα απευθύνεται σε ενεργές επιχειρήσεις.',
                panelOpenState: false
            },
            {
                title: '07',
                description: 'Καταχώρησα τα στοιχεία της επιχείρησης μου, αλλά δεν μπορώ να υποβάλω την αίτηση.',
                text: `Παρακαλούμε όπως επικοινωνήσετε μαζί μας στο ${this.growYourBusinessMailHtml} ή τηλεφωνικά στο 2102209167 (ΔΕΥ-ΠΑΡ: 10:00 – 15:00)`,
                panelOpenState: false
            },
            {
                title: '08',
                description: 'Έχει δηλωθεί συνάδελφος, που όμως δεν θα μπορεί να το παρακολουθήσει. Μπορώ να το παρακολουθήσω εγώ;',
                text: `Παρακαλώ όπως μας στείλεις email στο ${this.growYourBusinessMailHtml}`,
                panelOpenState: false
            },
            //auto edw thelei koitagma
            {
                title: '09',
                description: 'Έχει υποβάλει αίτηση συμμετοχής ο υπεύθυνος επιχείρησης για ένα άτομο. Μπορεί να περάσει ξανά αίτηση για ένα επιπλέον;',
                text: `Φυσικά! Θα πρέπει ο υπεύθυνος της επιχείρησης να συνδεθεί στην eLearning πλατφόρμα, επιλέγοντας Σύνδεση στο menu. Στην συνέχεια, επιλέγοντας Διαχείριση συμμετοχών από το menu, μπορεί να επιλέξει Προσθήκη συμμετέχοντα και να εντάξει το επιπλέον άτομο (τον εαυτό του ή άλλον εργαζόμενο).  Σε περίπτωση που αντιμετωπίζετε κάποιο πρόβλημα, μπορείτε να επικοινωνήσετε μαζί μας στο ${this.growYourBusinessMailHtml}`,
                panelOpenState: false
            },
            {
                title: '10',
                description: 'Δεν είμαι πελάτης COSMOTE, μπορώ να το παρακολουθήσω;',
                text: "Φυσικά! Θα χαρούμε να λάβουμε την αίτησή σας.",
                panelOpenState: false
            },
            {
                title: '11',
                description: 'Πώς μπορώ να πάρω μέρος στο πρόγραμμα;',
                text: `Μπορείτε να συμπληρώσετε την αίτηση συμμετοχής ${this.registrationLink} και να επιλέξετε τα μαθήματα που θέλετε να παρακολουθήσετε.`,
                panelOpenState: false
            },
            {
                title: '12',
                description: 'Πως μπορώ να λάβω την βεβαίωση παρακολούθησης;',
                text: `Για κάθε μάθημα που θα παρακολουθήσετε και θα ολοκληρώσετε με επιτυχία περνώντας ένα Online Test Αξιολόγησης (και μια Online άσκηση όπου είναι απαραίτητο), το Οικονομικό Πανεπιστήμιο Αθηνών θα εκδώσει αυτόματα τη Βεβαίωση Παρακολούθησης, η οποία και θα σας αποσταλεί από το πανεπιστήμιο με email από το ${this.equalityMailHtml} ή το ${this.growYourBusinessMailHtml}`,
                panelOpenState: false
            },
            {
                title: '13',
                description: 'Δεν πρόλαβα να δώσω το Online Test Αξιολόγησης για ένα μάθημα. Δεν θα λάβω τη Βεβαίωση Παρακολούθησης;',
                text: "Η ολοκλήρωση του Online Test Αξιολόγησης με επιτυχία, στους χρόνους που υποδεικνύονται για κάθε μάθημα, είναι απαραίτητη προϋπόθεση για την έκδοση Βεβαίωσης Παρακολούθησης.",
                panelOpenState: false

            },
            {
                title: '14',
                description: 'Δεν έλαβα ποτέ τη Βεβαίωση Παρακολούθησης για κάποιο μάθημα. Τι πρέπει να κάνω;',
                text: `Παρακαλούμε όπως επικοινωνήσετε μαζί μας με email στο ${this.growYourBusinessMailHtml}`,
                panelOpenState: false
            },
            // Εδώ θα πρεπει να αλλάξει το κείμενο
            {
                title: '15',
                description: 'Πώς μπορώ να λάβω Πιστοποιητικό Κατάρτισης από το Κέντρο Επιμόρφωσης και Δια Βίου Μάθησης του Οικονομικού Πανεπιστημίου Αθηνών;',
                text: `Για να λάβετε Πιστοποιητικό Κατάρτισης θα πρέπει να έχετε παρακολουθήσει όλα τα μαθήματα και να έχετε περάσει με επιτυχία τα Online Test Αξιολόγησης κάθε μαθήματος (και τις Online ασκήσεις όπου είναι απαραίτητες). Το Οικονομικό Πανεπιστήμιο Αθηνών θα εκδώσει αυτόματα το Πιστοποιητικό Kατάρτισης και θα σας το στείλει με email από το ${this.equalityMailHtml} ή το ${this.growYourBusinessMailHtml}. Οι πιστοποιήσεις θα σταλούν τον Μάιο του 2024.`,
                panelOpenState: false
            },
            {
                title: '16',
                description: 'Δεν παρακολούθησα όλα τα μαθήματα. Μπορώ να πάρω το Πιστοποιητικό κατάρτισης από το Κέντρο Επιμόρφωσης και Δια Βίου Μάθησης του Οικονομικού Πανεπιστημίου Αθηνών;',
                text: "Θα λάβετε Βεβαίωση Παρακολούθησης για τα μαθήματα που έχετε παρακολουθήσει και ολοκληρώσει\
                με επιτυχία τα αντίστοιχα Online Test Αξιολόγησης. Για να λάβετε το Πιστοποιητικό κατάρτισης θα πρέπει να έχετε ολοκληρώσει όλα τα μαθήματα.",
                panelOpenState: false
            },
            {
                title: '17',
                description: 'Δεν πρόλαβα να καταθέσω δήλωση συμμετοχής και οι θέσεις έχουν συμπληρωθεί. Τι κάνω;',
                text: `Παρακαλούμε όπως επικοινωνήσετε μαζί μας στο ${this.growYourBusinessMailHtml}`,
                panelOpenState: false
            },
            // Και αυτό θελει αλλαγή επίσης εδώ εχει link me ta courses
            {
                title: '18',
                description: 'Ποια είναι η διαδικασία διεξαγωγής των μαθημάτων;',
                text: `Κάθε ενότητα μαθήματος έχει διάρκεια έως 13 ώρες. Μπορείτε να δείτε τις ημερομηνίες και ώρες ανά ενότητα μαθήματος ${this.coursesLink}. Τα μαθήματα πραγματοποιούνται διαδικτυακά, μέσω Microsoft Teams. Οι συμμετέχοντες λαμβάνουν email με το link για το κάθε μάθημα, αλλά μπορούν επίσης μέσα από την eLearning πλατφόρμα, να δουν τα link ανά μάθημα στη σελίδα του κάθε μαθήματος. Για την είσοδο τους στην eLearning πλατφόρμα οι συμμετέχοντες χρησιμοποιούν το email και το password τους. Μέσα από την eLearning πλατφόρμα θα μπορούν να κατεβάσουν το υλικό διδασκαλίας κάθε μαθήματος, να δώσουν ανά μάθημα το Online Test αξιολόγησης και την Online Άσκηση (όπου είναι απαραίτητη). Με την ολοκλήρωση του μαθήματος κι αφού περάσουν το Online Test Αξιολόγησης (και μια Online άσκηση όπου είναι απαραίτητο), θα λάβουν τη Βεβαίωση Παρακολούθησης ανά ενότητα μαθήματος.`,
                panelOpenState: false
            },
            {
                title: '19',
                description: 'Δεν έχω λάβει το link για το Microsoft Teams.',
                text: `Μπορείτε να συνδεθείτε στην eLearning πλατφόρμα ${this.loginLink} με τους κωδικούς πρόσβασης. Επιλέξτε από το menu τα μαθήματα μου. Πατήστε στο μάθημα που σας ενδιαφέρει και δείτε τα Microsoft Team links. Ενναλακτικά μπορείτε να επικοινωνήσετε μαζί μας στο ${this.growYourBusinessMailHtml}`,
                panelOpenState: false
            },
            // το 20 δεν θελει αλλαγη εδώ https://04225606-c52a-404f-a141-1f1d45c5999d.usrfiles.com/ugd/042256_459d9c0409da46d39b5517d853153064.pdf
            {
                title: '20',
                description: 'Δεν έχω εγκατεστημένο στον υπολογιστή μου το Microsoft Teams.',
                text: `Μην ανησυχείτε! Δείτε ${this.setupLink} οδηγίες για το πως μπορείτε να κατεβάσετε το Microsoft Teams ή να το παρακολουθήσετε μέσα από τον browser σας.`,
                panelOpenState: false
            },
            {
                title: '21',
                description: 'Δεν μπορώ να συνδεθώ στο μάθημα.',
                text: "Παρακαλούμε όπως επικοινωνήσετε μαζί μας στο 2102209167",
                panelOpenState: false
            },
            { 
                title: '22',
                description: "Ποιος είναι ο βαθμός επιτυχίας για το Online Test Αξιολόγησης ;",
                text: "Θα πρέπει να περάσετε το Online Test Αξιολόγησης με ποσοστό μεγαλύτερο ή ίσο του 70%. Έχετε την επιλογή να δώσετε το τεστ περισσότερες από μία φορές για να το περάσετε.",
                panelOpenState: false
            },
            {
                title: '23',
                description: 'Θα φαίνεται ο βαθμός επιτυχίας του Online Test Αξιολόγησης στη Βεβαίωση Παρακολούθησης;',
                text: "Όχι.",
                panelOpenState: false
            },
            {
                title: '24',
                description: 'Πρέπει να παρακολουθήσω όλα τα μαθήματα του προγράμματος;',
                text: "Δεν είναι υποχρεωτικό. Εμείς όμως σας προτρέπουμε να τα παρακολουθήσετε όλα, για να έχετε τη συνολική εικόνα των εργαλείων της ψηφιακής εποχής. \
                Επίσης, έτσι θα μπορέσετε να λάβετε το Πιστοποιητικό κατάρτισης από το Κέντρο Επιμόρφωσης και Δια Βίου Μάθησης του Οικονομικού Πανεπιστημίου Αθηνών.",
                panelOpenState: false
            },
            {
                title: '25',
                description: 'Θα πρέπει να αγοράσω προϊόντα ή υπηρεσίες COSMOTE;',
                text: "Όχι, δεν απαιτείται αγορά προϊόντων ή υπηρεσιών για τη συμμέτοχή σου.",
                panelOpenState: false
            }
          
        ];
    }

    
}