import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { VerifyComponent } from "src/app/common/components/verify.component";
import { AuthService } from "src/app/common/services/auth.service";
import { SpinnerService } from "src/app/common/spinner/spinner.service";

@Component({
    selector: 'grow-your-business-verify',
    templateUrl: './grow-your-business-verify.component.html',
})
export class GrowYourBusinessVerifyComponent extends VerifyComponent implements OnInit {
    constructor(  
        auth: AuthService,
        router: Router,
        spinnerService: SpinnerService,
        route: ActivatedRoute) {
        super(auth, router, spinnerService, route);
    }

    override ngOnInit(): void {
        this.successUrl = 'manage';
        super.ngOnInit();
    }
    
}