
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGuard {

    constructor(private authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return  new Promise<boolean>( (resolve, reject) => {
            this.authService.isAuthenticated()
            .then((result) =>   {
                if(result) {
                    return resolve(true)
                } else {
                    this.router.navigate([environment.LOGIN_URL], {queryParams: route.queryParams})
                    return resolve(false)
                }
            });
        });
    }
}
