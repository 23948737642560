import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { atLeastOneElement } from "../../custom-validators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Course } from "src/app/common/shared/models/course.model";
import { UserService } from "src/app/common/services/user.service";
import {GrowYourBusinessService} from "../../grow-your-business.service";
import { CoursesService } from 'src/app/common/services/courses.service';

@Component({
    selector: "app-grow-your-business-add-more-courses",
    templateUrl: "./grow-your-business-add-more-courses.component.html"
})
export class GrowYourBusinessAddMoreCoursesComponent implements OnInit {

    public actionCompleted = false;
    public courses: Course[] = [];
    public newCourses: any;
    public newEnrollments: any;


    public  userEnrollMentFormGroup = new FormGroup({
        courses: new FormArray([], [atLeastOneElement()]),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userService: UserService,
        private growYourBusinessService: GrowYourBusinessService,
        private dialogRef: MatDialogRef<GrowYourBusinessAddMoreCoursesComponent>,
        private coursesService: CoursesService
    ) {}

    ngOnInit() {
        this.courses = this.data['courses']
        console.log(this.courses)
    }

    setCoursesToFormArray(formArray: any, courseIds: string[]) {
        formArray.markAllAsTouched()
        formArray.clear()
        for (let c of  courseIds) {
            (formArray as FormArray)?.push(new FormControl(c));
        }
    }

    onEnrollMeOnCourses() {
        let courseIds = this.userEnrollMentFormGroup.get('courses')?.value as any[]
        console.log(courseIds)

        this.userService.getUserDetails().subscribe((userDetails) => {
            this.growYourBusinessService.enrollUserOnCourses(userDetails.userId, courseIds).subscribe((result) => {

                let newCourses = this.courses.filter((course) => {
                    return courseIds.includes(course.courseId)
                })
                this.newEnrollments = newCourses.map((course: any) => {
                    return {
                        course: course,
                        lecturesProgress: {},
                        courseId: course.courseId,
                        status: 'started',
                        examAttempted: {},
                    }
                })
                console.log(this.newEnrollments)
                this.actionCompleted = true;
                this.coursesService.resetCourses();
            })
        })
    }

    onCloseDialog() {
        this.dialogRef.close(this.newEnrollments);
    }

}