import { Directive, ElementRef, HostListener } from '@angular/core';
import { GrowYourBusinessAnalyticsService } from './grow-your-business-analytics.service';

@Directive({
  selector: '[scrollListener]'
})
export class ScrollListenerDirective {

  public disabled=false

  constructor( 
    private growYourBusinessAnalyticsService: GrowYourBusinessAnalyticsService,
    private el: ElementRef) { }


  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    if(this.disabled) return

    const element = this.el.nativeElement;
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    const elementHeight = element.scrollHeight;
    // την πρώτη φορά που το scroll ξεπεράνει το 30% στελνουμε event
    if(scrollPosition/elementHeight > 0.3) {
      this.growYourBusinessAnalyticsService.sendEvent('scroll', {
        scrollPosition: 0.3
      })
      console.log('event send')
      this.disabled = true
    }
  }
}