import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs";
import { LoginComponent } from "src/app/common/components/login.component";
import { AuthService } from "src/app/common/services/auth.service";
import { SpinnerService } from "src/app/common/spinner/spinner.service";

@Component({
	selector: 'app-grow-your-business-login',
	templateUrl: './grow-your-business-login.component.html',
})
export class GrowYourBusinessLoginComponent extends LoginComponent {
	constructor(
		auth: AuthService,
		router: Router,
		spinnerService: SpinnerService
	) {
		super(auth, router, spinnerService)
	}

	public hidePassword = true;

	override onLogin(route = 'my-courses') {
		console.log(this.loginForm)
		this.user.email = this.loginForm?.get('email')?.value
		this.user.password = this.loginForm?.get('password')?.value
		this.spinnerService.show('global');
		this.auth.signIn(this.user).pipe(
			finalize(() => setTimeout(
				() => this.spinnerService.hide('global'), 1000))
		)
			.subscribe({
				next: (data) => {
					this.router.navigate([route])
				},
				error: (cognitoError) => {
					console.log(cognitoError.code);
					console.log(cognitoError);
					if (cognitoError.name === 'UserNotConfirmedException') {
						this.auth.setUserToVerify({ email: this.loginForm.value.email, password: this.loginForm?.get('password')?.value });

						// // flag password to enable reconfirm on verify
						// this.auth.setPassword('conf');
						this.router.navigateByUrl('/verify');
					} else if (cognitoError.code === 'NetworkError') {
						// this.spinnerService.hide('signIn');
						// this.spinner_loading = false;
						// this.loginError = null;
						// this.snackbarService.open('Network connection error', 'ok', {
						// 	duration: 7000,
						// 	verticalPosition: 'top'
						// });
					} else if (cognitoError.code === 'NotAuthorizedException' || cognitoError.code === 'UserNotFoundException') {
						this.loginError = 'Είτε το Email είτε το Password είναι εσφαλμένα. Σας παρακαλούμε ξανάπροσπαθήστε';
						// this.spinnerService.hide('signIn');
						// this.spinner_loading = false;
					} else if (cognitoError.toString().includes('NotAuthorizedException: Incorrect username or password')) {
						this.loginError = 'Tα στοιχεία είναι λάθος. Προσπαθήστε πάλι.';
					} else if (cognitoError.toString().includes('UserNotFoundException: User does not exist.')) {
						this.loginError = 'Tα στοιχεία είναι λάθος. Προσπαθήστε πάλι.';
					}
				}
			});
	}
}