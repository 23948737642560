import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { Amplify } from 'aws-amplify';
import { 
  signIn, 
  signOut, 
  getCurrentUser, 
  fetchAuthSession, 
  confirmSignUp, 
  resendSignUpCode, 
  updateUserAttributes,
  resetPassword, 
  confirmResetPassword,
  AuthUser
} from 'aws-amplify/auth';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

export interface IUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authenticationSubject: BehaviorSubject<boolean>;
  private userToBeVerified = {} as IUser;

  constructor(private router: Router, private userService: UserService) {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.POOL_DATA.userPoolId,
          userPoolClientId: environment.POOL_DATA.userPoolClientId
        },
      },
    });

    this.authenticationSubject = new BehaviorSubject<boolean>(false);

    this.isAuthenticated().then(result => {
      // console.log(4356676,result);
      this.authenticationSubject.next(result);
    });
  }

  public confirmSignUp(user: IUser): Observable<any> {
    return from(confirmSignUp({ username: user.email, confirmationCode: user.code }));
  }

  public resendConfirmationCode(username: string): Observable<any> {
    return from(resendSignUpCode({ username }));
  }

  public signIn(user: IUser): Observable<any> {
    return from(
      signIn({ username: user.email, password: user.password }).then((d) => {
        console.log('User signed in', d);
        this.authenticationSubject.next(true);
        this.userService.resetUser();
      })
    );
  }

  public signOut(redirect = true): Observable<any> {
    return from(
      signOut().then(() => {
        this.authenticationSubject.next(false);
        if (redirect) {
          this.router.navigate([environment.LOGIN_URL]);
        }
      })
    );
  }

  public async isAuthenticated(): Promise<boolean> {
    // console.log(434343, this.authenticationSubject.value);
    if (this.authenticationSubject.value) {
      return true;
    } else {
      try {
        const user = await getCurrentUser();
        return !!user;
      } catch {
        return false;
      }
    }
  }

  public async isAdmin(): Promise<boolean> {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const groups = accessToken?.payload['cognito:groups'] as string[] | undefined;
      return Array.isArray(groups) && groups.includes('admin');
    } catch {
      return false;
    }
  }

  public async isAdminOrAnalyst(): Promise<boolean> {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const groups = accessToken?.payload['cognito:groups'] as string[] | undefined;
      return Array.isArray(groups) && (groups.includes('admin') || groups.includes('analytics'));
    } catch {
      return false;
    }
  }

  public async getRole(): Promise<string> {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const groups = accessToken?.payload['cognito:groups'] as string[] | undefined;
      if (Array.isArray(groups)) {
        if (groups.includes('admin')) return 'admin';
        if (groups.includes('analytics')) return 'analyst';
      }
      return 'user';
    } catch {
      return 'user';
    }
  }

  public getUser(): Observable<AuthUser> {
    return from(getCurrentUser());
  }

  public getUserAsPromise(): Promise<AuthUser> {
    return getCurrentUser();
  }

  public async updateUser(user: Partial<IUser>): Promise<void> {
    try {
      const result = await updateUserAttributes({
        userAttributes: {
          email: user.email,
          name: user.name,
          // Add other attributes as needed
        }
      });
      console.log('User attributes updated:', result);
    } catch (error) {
      console.error('Error updating user attributes', error);
      throw error;
    }
  }

  setUserToVerify(data: { email: string; password: string }) {
    this.userToBeVerified.email = data.email;
    this.userToBeVerified.password = data.password;
  }

  getUserToVerify(): IUser {
    return this.userToBeVerified;
  }

  getCurrentSession(): Observable<any> {
    return from(fetchAuthSession());
  }

  forgotPassword(username: string): Observable<any> {
    return from(resetPassword({ username }));
  }

  forgotPasswordSubmit(data: { username: string; confirmationCode: string; newPassword: string }): Observable<any> {
    return from(confirmResetPassword(data));
  }
}