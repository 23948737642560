import { CommonModule } from '@angular/common';
import { Component, effect, ElementRef, signal, untracked, ViewChild, WritableSignal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AskAQuestionComponent } from 'src/app/common/ask-a-question/ask-a-question.component';
import { MessagesV2Service } from 'src/app/common/services/messages-v2.service';
import { TimeAgoPipe } from './time-ago.pipe';
import { MatInputModule } from '@angular/material/input';
import { FileUpload } from 'src/app/common/shared/models/file.model';
import { FileService } from 'src/app/common/services/file.service';
import { catchError, filter, finalize, switchMap, throwError } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from 'src/app/common/services/auth.service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { CoursesService } from 'src/app/common/services/courses.service';

@Component({
  selector: 'app-ask-a-question',
  standalone: true,
  imports: [CommonModule, MatRippleModule, TimeAgoPipe, MatInputModule, ReactiveFormsModule, MatDialogModule, MatProgressBarModule, MatIconModule, MatSelectModule],
  templateUrl: './grow-your-business-ask-a-question.component.html',
  styleUrl: './grow-your-business-ask-a-question.component.css'
})
export class GrowYourBusinessAskAQuestionComponent extends AskAQuestionComponent {
  @ViewChild('messagesEl', { static: false }) messagesEl!: ElementRef;

  public errorMessage: any = signal(null);

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    // private route: ActivatedRoute,
    private dialog: MatDialog,
    private fileService: FileService,
    messagesV2Service: MessagesV2Service,
    coursesService: CoursesService,
    authService: AuthService,
    route: ActivatedRoute,
    router: Router,
  ) {
    super(messagesV2Service, authService, coursesService, route, router);

    effect(() => {
      console.log('isConversationRoute: ', this.isConversationRoute());
    });
    effect(() => {
      console.log('conversations: ', this.conversations());
      const conversation = untracked(() => this.conversation());
      const conversationId = this.route.firstChild?.snapshot.paramMap.get('conversationId');
      // console.log(conversation , conversationId);
      if (!conversation && conversationId && this.conversations().length > 0) {
        this.getMessages(this.conversations().filter(d => d.conversationId === conversationId)[0]);
      }
    });
    effect(() => {
      if (this.conversation()) {
        console.log(this.route);


        this.route.url.subscribe(console.log);
        console.log(111111, this.route.snapshot.url);
        console.log('new conversation: ', this.conversation());


        console.log(34234234, this.getFullRoutePath(this.route.root));
        const getFullRoutePath = this.getFullRoutePath(this.route.root);
        let routePath = ['ask-a-question', this.conversation().conversationId];
        if (getFullRoutePath.includes('/admin/')) {
          routePath = ['admin', 'ask-a-question', this.conversation().conversationId];
        }
        this.router.navigate(routePath);

        setTimeout(() => {
          this.messagesEl.nativeElement.scrollTop = this.messagesEl.nativeElement.scrollHeight;
          this.readMessages();
          // this.route.url.subscribe(console.log); 
        }, 1);
      }
    });
  }

  public askNewQuestionDialog(template: any) {
    this.dialogRef = this.dialog.open(template);
    this.dialogRef.afterClosed().subscribe(result => {
      this.getMessages(this.conversation());
      // this.messagesEl.scrollTop = this.messagesEl.scrollHeight;
    });
  }

  public reply(template: any) {
    this.dialogRef = this.dialog.open(template);
    this.dialogRef.afterClosed().subscribe(result => {
      this.getMessages(this.conversation());
      // this.messagesEl.scrollTop = this.messagesEl.scrollHeight;
    });
  }

  OnFileSelected(event: any) {
    if (event.target.files.length <= 0) {
      return;
    }
    this.uploadingFile.set({
      name: event.target.files[0].name,
      status: 'uploading',
      size: event.target.files[0].size,
    } as FileUpload);
    let fileData: any;
    let imageUrl: string | null;
    let fileType: string;
    this.fileService.readImage(event.target.files[0], {})
      .pipe(
        switchMap(resultFile => {
          // console.log(123123, resultFile)
          fileData = resultFile;
          fileType = resultFile.image.type;
          return this.fileService.requestPresignedUrl({ Mime: resultFile.image.type, type: 'messageAttachment' })
            .pipe(catchError((err) => {
              // console.log(err)
              this.errorMessage.set('Βεβαιωθείτε ότι ανεβάζετε καποιο αρχείο εικόνας ή καποιο pdf');
              this.uploadingFile.set(null);
              return throwError(() => err)
            }))
        }),
        switchMap(presigedUrlResponse => {
          // console.log(presigedUrlResponse)
          imageUrl = presigedUrlResponse.url
          return this.fileService.uploadFileToPresignedUrl(presigedUrlResponse.url, fileData.requestedFormat);
        }),
        finalize(() => {
          event.target.value = '';
        })
      )
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            // Calculate and report progress
            const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            this.uploadingFile.set({
              ...this.uploadingFile(),
              percentage: percentDone
            } as FileUpload);
          } else if (event.type === HttpEventType.Response) {
            // Upload completed successfully
            this.uploadingFile.set({
              ...this.uploadingFile(),
              status: 'uploaded',
              fileType: fileType,
              url: imageUrl?.split('?')[0]
            } as FileUpload);
            // console.log('File uploaded successfully', this.uploadingFile());
          }
        },
        error: (err) => {
          this.uploadingFile.set(null);
          // console.log(err)
        }
      })

  }

  onFormatBytes(bytes: number, decimals = 2) {
    return this.fileService.formatBytes(bytes, decimals)
  }


  goBack() {
    const getFullRoutePath = this.getFullRoutePath(this.route.root);
    let routePath = ['ask-a-question'];
    if (getFullRoutePath.includes('/admin/')) {
      routePath = ['admin', 'ask-a-question'];
    }
    this.router.navigate(routePath);
  }



  private getFullRoutePath(route: ActivatedRoute): string {
    let path = '';
    while (route.firstChild) {
      route = route.firstChild;
      if (route.snapshot.url.length) {
        path += '/' + route.snapshot.url.map(segment => segment.path).join('/');
      }
    }
    return path || '/';
  }



}
