import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Course, CourseLesson, Instructor } from '../shared/models/course.model';

@Component({
	selector: 'app-course-details',
	template: '',
})
export class CourseDetailsComponent implements OnInit {
	public course!: Course;

	public tableData: any[] = [];

	public displayedColumns = ['tableDateString', 'time', 'duration', 'topic'];
	public displayedColumnsXs = ['tableFullDateString', 'topic'];



	constructor(
		private route: ActivatedRoute,
		private activatedRoute: ActivatedRoute
	) {

	}

	ngOnInit() {
		this.route?.data.subscribe(
			(data: any) => {
				this.course = data.courses.filter((c: Course) => c.courseId == this.activatedRoute.snapshot.params['courseId'])[0]
				this.course.sections.map((d : any) => {
					d.totalDuration = d.lectures.reduce((total: number, lecture: any) => {
						return total + (lecture.durationInMins || 0);
					}, 0);
					d.totalLectures = d.lectures.length;
				});


				this.tableData = this.convertLessonsToCalendarEntities(this.course)
			}
		)
	}

	public convertLessonsToCalendarEntities(course: Course) {

		let calendarEntities: any[] = [];

		course?.lessons?.forEach(lesson => {
			let new_data: any = {
				topic: lesson.topic,
				tableDateString: new Date(lesson.startsAt * 1000).toLocaleDateString('el-GR', { weekday: 'long', month: '2-digit', day: '2-digit' }),
				time: new Date(lesson.startsAt * 1000).toLocaleTimeString('el-GR', { hour: '2-digit', minute: '2-digit', hour12: false }),
				endTime: new Date(lesson.endsAt * 1000).toLocaleTimeString('el-GR', { hour: '2-digit', minute: '2-digit', hour12: false }),
				duration: this.displayDuration(lesson.endsAt - lesson.startsAt),
			}
			new_data['tableFullDateString'] = `${new_data.tableDateString} ${new_data.time} - ${new_data.endTime}`
			console.log(lesson.endsAt - lesson.startsAt);
			calendarEntities.push(new_data)

			if (lesson.sections?.test) {
				calendarEntities.push({
					topic: 'Ολοκλήρωση online ασκησης - Διάρκεια έως 1 ώρα',
					tableDateString: `Έως ${new Date(lesson.sections?.test?.expirationDate).toLocaleDateString('el-GR', { weekday: 'long', month: '2-digit', day: '2-digit' })}`,
					tableFullDateString: `Έως ${new Date(lesson.sections?.test?.expirationDate).toLocaleDateString('el-GR', { weekday: 'long', month: '2-digit', day: '2-digit' })}`,
				})
			}
		})

		if (course.exam) {
			calendarEntities.push({
				topic: 'Ολοκλήρωση online διαγωνίσματος',
				tableDateString: `Έως ${new Date(course.exam.expirationDate).toLocaleDateString('el-GR', { weekday: 'long', month: '2-digit', day: '2-digit' })}`,
				tableFullDateString: `Έως ${new Date(course.exam.expirationDate).toLocaleDateString('el-GR', { weekday: 'long', month: '2-digit', day: '2-digit' })}`,
			})
		}

		return calendarEntities
	}

	public displayDuration(durationInSeconds: number) {
		let message = ''
		let hours = Math.floor(durationInSeconds / 3600);
		if (hours > 1) {
			message += `${hours} ώρες `
		} else if (hours == 1) {
			message += `${hours} ώρα `
		}
		let minutes = Math.floor((durationInSeconds % 3600) / 60);
		if (minutes > 1) {
			message += `${minutes} λεπτά `
		}
		else if (minutes == 1) {
			message += `${minutes} λεπτό `
		}

		return message
	}

	getAvatar(instructor: Instructor) {
		return `url(${instructor.avatar})`
	}

	courseIsCompleted() {
		if (!this.course.lessons || this.course.lessons.length == 0) {
			return false
		}
		let lastLesson = this.course.lessons[this.course.lessons.length - 1]
		return lastLesson.endsAt < Math.floor(Date.now() / 1000)
	}
}
