import { Injectable } from "@angular/core";
import { GrowYourBusinessService } from "./grow-your-business.service";

@Injectable({
    providedIn: 'root',
})
export class  GrowYourBusinessEnrollmentsWithEmployeesResolverService {

    constructor(
        private growYourBusinessService: GrowYourBusinessService
    ) {}

    resolve() {
        return  this.growYourBusinessService.getMyEnrollmentsWithEmployees();
    }
}