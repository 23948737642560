
<app-spinner name="employer-registration"></app-spinner>
<div fxLayout="column" style="gap: 64px;">
    <div style="padding: 32px 0px;" fxLayout="column" fxLayoutAlign="center center">
        <img style="max-width:500px"  [ngStyle.xs]="{'max-width':'300px'}" src="assets/growYourBusiness@x2.png">
        <div style="height:24px"></div>
        <span class="cosmote-blue" style="font-size:28px; font-weight:700; margin-bottom:24px;
        text-align: center;"> Αίτηση συμμετοχής</span>

        <mat-stepper linear="true" #stepper labelPosition="bottom" [ngStyle.xs]="{'width': '300px'}">
            <ng-template matStepperIcon="number">
                <mat-icon style="width:24px; height:24px; font-size:24px">adjust</mat-icon>
            </ng-template>

            <ng-template matStepperIcon="edit">
                <mat-icon style="width:24px; height:24px; font-size:24px">check_circle</mat-icon>
              </ng-template>
<!-- 
              <ng-template matStepperIcon="edit">
                <mat-icon style="width:24px; height:24px; font-size:24px">check_circle_outlined</mat-icon>
              </ng-template> -->

            <mat-step editable="true" [completed]="employerForm.valid">
                <form [formGroup]="employerForm"  style="margin-top:28px; width:560px" [ngStyle.xs]="{'width': '300px'}" fxLayout="column" #formEl >
                    <ng-template matStepLabel>Στοιχεία Eπιχείρησης</ng-template>

                    <ng-container formGroupName="personalDetails">

                        <div fxLayout="row" style="gap: 32px;" fxLayout.xs="column" fxLayoutGap.xs="0px">
                            <div fxLayout="column" fxFlex>
                                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Όνομα*</span> -->
                                <input formControlName="name" placeholder="Όνομα*" type="text" 
                                    [ngClass]=" {'input-error': employerForm.get('personalDetails.name')?.invalid && employerForm.get('personalDetails.name')?.touched}"
                                    class="input" matInput> 
                                <div style="height:16px; padding:0px 12px 8px">
                                    <span class="error-message" *ngIf="employerForm.get('personalDetails.name')?.hasError('required') && employerForm.get('personalDetails.name')?.touched">
                                        Το πεδίο ειναι υποχρεωτικό
                                    </span>
                                </div>
                            </div>

                             <div fxLayout="column" fxFlex>
                                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Επίθετο*</span> -->
                                <input formControlName="lastName" placeholder="Επίθετο*" type="text" 
                                    [ngClass]=" {'input-error': employerForm.get('personalDetails.lastName')?.invalid && employerForm.get('personalDetails.lastName')?.touched}"
                                    class="input" matInput> 
                                <div style="height:16px; padding:0px 12px 8px">
                                    <span class="error-message" *ngIf="employerForm.get('personalDetails.lastName')?.hasError('required') && employerForm.get('personalDetails.lastName')?.touched">
                                        Το πεδίο ειναι υποχρεωτικό
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container formGroupName="contactDetails">
                        <div fxLayout="row"  style="gap: 32px;" fxLayout.xs = "column"  fxLayoutGap.xs="0px">
                            <div fxLayout="column" fxFlex="50">
                                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Email*</span> -->
                                <input #emailInput formControlName="email" placeholder="Email*" type="text" 
                                    (input) = "convertToLowercase(employerForm.get('contactDetails')?.get('email'), $event)"
                                    [ngClass]=" {'input-error': employerForm.get('contactDetails.email')?.invalid && employerForm.get('contactDetails.email')?.touched}"
                                    class="input" matInput> 
                                <div style="height:16px; padding:0px 12px 8px">
                                    <span class="error-message" *ngIf="employerForm.get('contactDetails.email')?.hasError('required') && employerForm.get('contactDetails.email')?.touched">
                                        Το πεδίο ειναι υποχρεωτικό
                                    </span>
                                    <span class="error-message" *ngIf="employerForm.get('contactDetails.email')?.hasError('pattern') && employerForm.get('contactDetails.email')?.touched">
                                        Το email δεν έχει σωστή μορφή
                                    </span>
                                    <span class="error-message" *ngIf="employerForm.get('contactDetails.email')?.hasError('alreadyExists') && employerForm.get('contactDetails.email')?.touched">
                                        Το email υπάρχει ήδη
                                    </span>
                                </div>
                            </div>

                            <div fxLayout="column" fxFlex="50">
                                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Κινητό τηλέφωνο*</span> -->
                                <input formControlName="mobile" placeholder="Κινητό τηλέφωνο*" type="text" 
                                    [ngClass]=" {'input-error': employerForm.get('contactDetails.mobile')?.invalid && employerForm.get('contactDetails.mobile')?.touched}"
                                    class="input" matInput> 
                                <div style="height:16px; padding:0px 12px 8px">
                                    <span class="error-message" *ngIf="employerForm.get('contactDetails.mobile')?.hasError('required') && employerForm.get('contactDetails.mobile')?.touched">
                                        Eισάγετε αριθμό κινητού
                                    </span>
                                    <span class="error-message" *ngIf="employerForm.get('contactDetails.mobile')?.hasError('pattern')  && employerForm.get('contactDetails.mobile')?.touched">
                                        Eισάγετε αριθμό κινητού
                                    </span>
                                </div>
                            </div>
                        </div>

                    </ng-container>


                    <div fxLayout="row"  style="gap: 32px;" fxLayout.xs = "column" fxLayoutGap.xs="0px" >
                        <div fxLayout="column" style="width:212px" [ngStyle.lt-md] = "{'width': '100%'}">
                            <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Δημιουργία Password*</span> -->
                            <div fxLayout="row" fxLayoutAlign="start center" style="gap: 12px;">
                                <input fxFlex formControlName="password" placeholder="Δημιουργία Password*" [type]="hidePassword ? 'password' : 'text'"
                                    [ngClass]=" {'input-error': employerForm.get('password')?.invalid && employerForm.get('password')?.touched}"
                                    class="input" matInput> 
                                <!-- <mat-icon style="cursor: pointer;" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ?
                                'visibility' : 'visibility_off'}}</mat-icon> -->
                            </div>
                            <div style="height:16px; padding:0px 12px 8px">
                                <span class="error-message" *ngIf="employerForm.get('password')?.hasError('passwordIsNotLongEnough') && employerForm.get('password')?.touched">
                                    Tουλάχιστον 6 χαρακτήρες
                                </span>
                            </div>
                        </div>

                        <div fxLayout="column" >
                            <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Επαλήθευση Password*</span> -->
                            <div fxLayout="row" fxLayoutAlign="start center" >
                                <input fxFlex formControlName="passwordConfirmation" placeholder="Επαλήθευση Password*" [type]="hidePassword ? 'password' : 'text'"
                                    [ngClass]=" {'input-error': employerForm.get('passwordConfirmation')?.invalid && employerForm.get('passwordConfirmation')?.touched}"
                                    class="input"  style="width:212px" matInput> 
                                <mat-icon style="cursor: pointer; padding:0px 12px" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ?
                                'visibility_off' : 'visibility'}}</mat-icon>
                            </div>
                            <div style="height:16px; padding:0px 12px 8px">
                                <span class="error-message" *ngIf="employerForm.get('passwordConfirmation')?.touched && 
                                employerForm.get('passwordConfirmation')?.hasError('notSame')">
                                    Το password δεν είναι ιδιο
                                </span>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start start" style="gap: 12px;">
                        <div fxLayout="column" fxFlex fxFlex.xs="false"  [ngStyle.xs]="{'max-width': '130px'} ">
                            <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">ΑΦΜ Επιχείρησης*</span> -->
                            <input formControlName="vatNumber" placeholder="ΑΦΜ Επιχείρησης*" type="text" 
                                [ngClass]=" {'input-error': employerForm.get('vatNumber')?.invalid && employerForm.get('vatNumber')?.touched}"
                                class="input" matInput> 
                        </div>

                        <div>
                            <button *ngIf="!vatNumberVerified" mat-raised-button color="primary" [disabled]="employerForm.get('vatNumber')?.invalid" style="border-radius: 74px;                        
                            /* Shadow/xs */
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                            (click)="onCheckVatNumber()"
                            > Έλεγχος ΑΦΜ</button>
    
                            <button *ngIf="vatNumberVerified"  mat-raised-button color="primary"  style="border-radius: 74px;                        
                            /* Shadow/xs */
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);"
                            (click)="onEditVatNumber()"
                            > Αλλαγή ΑΦΜ</button>
                        </div>
                      
                    </div>

                    <div  style="min-height:16px; padding:0px 12px 8px">
                        <span class="error-message" *ngIf="employerForm.get('vatNumber')?.hasError('pattern')">
                            Ο Α.Φ.Μ Επιχείρησης πρεπει να ειναι 9 ψηφία
                        </span>

                        <span class="error-message" *ngIf="employerForm.get('vatNumber')?.hasError('invalid')">
                            {{employerForm.get('vatNumber')?.getError('invalid')}}
                        </span>
                    </div>

                    <div *ngIf="vatNumberVerified" fxLayout="row" fxLayoutAlign="start start" style="gap: 32px;" fxLayout.xs="column"  fxLayoutGap.xs="24px"
                    style="margin-bottom:32px" >
                        <div fxLayout="column" fxFlex="50" fxFlex.xs ="false" [ngStyle.xs]="{'width': '294px'}">
                            <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Επωνυμία Επιχείρησης</span> -->
                            <input formControlName="companyName" placeholder="Επωνυμία Επιχείρησης" type="text" 
                                [ngClass]=" {'input-error': employerForm.get('companyName')?.invalid && employerForm.get('companyName')?.touched}"
                                class="input" matInput> 
                        </div>

                        <div fxLayout="column" fxFlex="50">
                            <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Τομέας Δραστηριότητας*</span> -->
                            <mat-select  style="padding:12px 16px; border-radius: 100px; width:auto" [ngStyle.xs]="{'width': '260px' }"  
                            placeholder="--Κλάδος--" formControlName="industry" >
                                <mat-option *ngFor="let industry of industries" [value]="industry">{{industry}}</mat-option>
                            </mat-select>
                        </div>

                    </div>

                    <button mat-raised-button color="primary"  [disabled]="employerForm.invalid"
                    style="padding:12px 20px !important; border-radius: 74px !important; margin-top:8px;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important" (click)="stepperGoNext()">Επόμενο</button>
    
                </form>

                
            </mat-step>
        
            <mat-step [stepControl]="step2CustomForm" [ngClass]="{'disabled': employerForm.invalid }" editable="false" [completed]="step2Completed">
                <form [formGroup]="step2CustomForm"  style="margin-top:28px; width:560px" [ngStyle.xs]="{'width': '300px'}" fxLayout="column" >
                    <ng-template matStepLabel>Στοιχεία Συμμετέχοντα</ng-template>

                    <div  fxLayout="column" style= "margin-bottom:12px">
                        <span class="gray-600" style="font-size:16px; line-height:20px; font-weight:400;">Μπορείτε να δηλώσετε μέχρι 2 άτομα για να παρακολουθήσουν τα μαθήματα.</span>
                    </div>
                    
                    <mat-radio-group color="primary" aria-label="Select an option" fxLayout="column"  style="gap: 12px;padding-bottom:24px"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="registrationChoice" >
                        <div style="border-radius: 35px;border: 2px solid #004B87;background: var(--base-white, #FFF);padding: 2px"
                        [ngClass]="{'active-radio': registrationChoice == 1}">
                            <mat-radio-button  [value]="1" style="font-size:14px" > Υπεύθυνος</mat-radio-button>
                        </div>
                        <div style="border-radius: 35px;border: 2px solid #004B87;background: var(--base-white, #FFF);padding: 2px" 
                        [ngClass]="{'active-radio': registrationChoice == 2}">
                            <mat-radio-button  [value]="2" style="font-size:14px"> Υπεύθυνος και εργαζόμενος</mat-radio-button>
                        </div>
                        <div style="border-radius: 35px;border: 2px solid #004B87;background: var(--base-white, #FFF);padding: 2px" 
                        [ngClass]="{'active-radio': registrationChoice == 3}">
                            <mat-radio-button  [value]="3" style="font-size:14px"> Εργαζόμενος/οι</mat-radio-button>
                        </div>
                    </mat-radio-group>

                    <!------------------------------------ CASE 1 ------------------------------------------>
                    <mat-tab-group *ngIf="registrationChoice == 1" mat-stretch-tabs="false" style="margin-top:24px; margin-bottom:14px">
                        <mat-tab label="Στοιχεία υπευθύνου" [formGroup]="onlyEmployerFormGroup">
                            <ng-template mat-tab-label>
                                <span style="color: #3D6D99;font-size: 16px;font-style: normal;font-weight: 700;line-height: 24px;">Στοιχεία υπευθύνου</span>
                            </ng-template>

                            <div fxLayout="column" style="margin: 24px 0px">
                                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Πατρώνυμο Υπευθύνου/Εκπροσώπου*</span> -->
                                <input formControlName="sonOf" placeholder="Πατρώνυμο Υπευθύνου/Εκπροσώπου" type="text" 
                                    [ngClass]=" {'input-error': onlyEmployerFormGroup.get('sonOf')?.invalid && onlyEmployerFormGroup.get('sonOf')?.touched}"
                                    class="input" matInput> 
                                <span
                                [ngClass]="onlyEmployerFormGroup.get('sonOf')?.invalid && onlyEmployerFormGroup.get('sonOf')?.touched ? 'error-message': 'gray-500'"
                                 style="margin-top:6px; font-size:14px; font-weight:400; line-height:20px">Απαραίτητο για την έκδοση βεβαίωσης παρακολούθησης</span>

                                <!-- <div style="height:16px; padding:0px 12px 8px">
                                     edw tha mpei error
                                </div> -->
                            </div>


                            <div style="gap: 12px;padding:24px 0px" fxLayout="column"  formArrayName="courses" *ngIf="courses">
                                <span  style="font-size:14px; line-height:20px;font-weight:700"
                                    [ngClass]="onlyEmployerFormGroup.get('courses')?.invalid && onlyEmployerFormGroup.get('courses')?.touched ? 'error-message': 'gray-700'"
                                >
                                    Επιλέξετε τα μαθήματα που θέλετε να δηλώσετε συμμετοχή
                                </span>
                                <app-choose-courses *ngIf="courses && courses.length > 0" [courses]="courses"  [display]="'double'" [expandable]="true"
                                    (selectedCoursesChange)=" setCoursesToFormArray(onlyEmployerFormGroup.get('courses'), $event)"></app-choose-courses>
                            </div>

                        </mat-tab>
                    </mat-tab-group>


                    <!------------------------------------ CASE 2 ------------------------------------------>
                    <mat-tab-group *ngIf="registrationChoice == 2" [formGroup]="employerWithOneEmployeeFormGroup" mat-stretch-tabs="false" style="margin-top:24px; margin-bottom:14px"
                    [(selectedIndex)]="employerWithOneEmployeeFormGroupTabIndex">
                        <!----------------------------- TAB ΥΠΕΥΘΥΝΟΥ ---------------------------------------->
                        <mat-tab formGroupName="employer">
                            <ng-template mat-tab-label>
                                <span style="color: #3D6D99;font-size: 16px;font-style: normal;font-weight: 700;line-height: 24px;">Στοιχεία υπευθύνου</span>
                            </ng-template>

                            <div fxLayout="column" style="margin: 24px 0px">
                                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Πατρώνυμο Υπευθύνου/Εκπροσώπου*</span> -->
                                <input formControlName="sonOf" placeholder="Πατρώνυμο Υπευθύνου/Εκπροσώπου" type="text" 
                                    [ngClass]=" {'input-error': employerWithOneEmployeeFormGroup.get('employer')?.get('sonOf')?.invalid && 
                                    employerWithOneEmployeeFormGroup.get('employer')?.get('sonOf')?.touched}"
                                    class="input" matInput> 
                                <span style="margin-top:6px; font-size:14px; font-weight:400; line-height:20px"
                                [ngClass]="employerWithOneEmployeeFormGroup.get('employer')?.get('sonOf')?.invalid && 
                                employerWithOneEmployeeFormGroup.get('employer')?.get('sonOf')?.touched ? 'error-message': 'gray-500'"
                                
                                >Απαραίτητο για την έκδοση βεβαίωσης παρακολούθησης</span>
                                <!-- <div style="height:16px; padding:0px 12px 8px">
                                     edw tha mpei error
                                </div> -->
                            </div>

                            <div style="gap: 12px;padding:24px 0px" fxLayout="column"  formArrayName="courses" *ngIf="courses">
                                <span style="font-size:14px; line-height:20px;font-weight:700"
                                [ngClass]="employerWithOneEmployeeFormGroup.get('employer')?.get('courses')?.invalid && 
                                employerWithOneEmployeeFormGroup.get('employer')?.get('courses')?.touched ? 'error-message': 'gray-700'">
                                    Επιλέξετε τα μαθήματα που θέλετε να δηλώσετε συμμετοχή
                                </span>
                                <app-choose-courses *ngIf="courses && courses.length > 0" [courses]="courses" [display]="'double'" [expandable]="true"
                                    (selectedCoursesChange)=" setCoursesToFormArray(employerWithOneEmployeeFormGroup.get('employer')?.get('courses'), $event)"></app-choose-courses>
                            </div>
                        </mat-tab>
                        <mat-tab formGroupName="employee"> 
                            <ng-template mat-tab-label>
                                <span style="color: #3D6D99;font-size: 16px;font-style: normal;font-weight: 700;line-height: 24px;">Στοιχεία εργαζομένου</span>
                            </ng-template>
                            <div fxLayout="column" style="margin: 24px 0px">
                                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Email εργαζόμενου*</span> -->
                                <input formControlName="email" placeholder="Email εργαζόμενου" type="email" 
                                    name="email" id="email"
                                    (input) = "convertToLowercase(employerWithOneEmployeeFormGroup.get('employee')?.get('email'), $event)"
                                    [ngClass]=" {'input-error': employerWithOneEmployeeFormGroup.get('employee')?.get('email')?.invalid && 
                                    employerWithOneEmployeeFormGroup.get('employee')?.get('email')?.touched}"
                                    class="input" matInput> 
                                <div style="height:16px; padding:0px 12px 10px">
                                    <span class="error-message" *ngIf="employerWithOneEmployeeFormGroup.get('employee')?.get('email')?.hasError('required') && employerWithOneEmployeeFormGroup.get('employee')?.get('email')?.touched">
                                        Το πεδίο ειναι υποχρεωτικό
                                    </span>
                                    <span class="error-message" *ngIf="employerWithOneEmployeeFormGroup.get('employee')?.get('email')?.hasError('pattern') && employerWithOneEmployeeFormGroup.get('employee')?.get('email')?.touched">
                                        Το email δεν έχει σωστή μορφή
                                    </span>
                                    <span class="error-message" *ngIf="employerWithOneEmployeeFormGroup.get('employee')?.get('email')?.hasError('alreadyExists') && employerWithOneEmployeeFormGroup.get('employee')?.get('email')?.touched">
                                        Το email υπάρχει ήδη
                                    </span>
                                </div>
                            </div>

                            <div style="gap: 12px;padding:24px 0px" fxLayout="column"  formArrayName="courses" *ngIf="courses">
                                <span style="font-size:14px; line-height:20px;font-weight:700"
                                
                                [ngClass]="employerWithOneEmployeeFormGroup.get('employee')?.get('courses')?.invalid && 
                                employerWithOneEmployeeFormGroup.get('employee')?.get('courses')?.touched ? 'error-message': 'gray-700'">
                                    Επιλέξετε τα μαθήματα που θέλετε να δηλώσετε συμμετοχή
                                </span>
                                <app-choose-courses *ngIf="courses && courses.length > 0" [courses]="courses" [display]="'double'" [expandable]="true"
                                    (selectedCoursesChange)=" setCoursesToFormArray(employerWithOneEmployeeFormGroup.get('employee')?.get('courses'), $event)">
                                </app-choose-courses>

                            </div>
                        </mat-tab>

                        <!----------------------------- TAB ΕΡΓΑΖΟΜΕΝΟΥ ---------------------------------------->


                    </mat-tab-group>


                    <!-------------------------------------CASE 3 ------------------------------------------>
                    <mat-tab-group *ngIf="registrationChoice == 3" [formGroup]="upToTwoEmployeesFormGroup" mat-stretch-tabs="false" style="margin-top:24px; margin-bottom:14px"
                    [(selectedIndex)]="employerWithOneEmployeeFormGroupTabIndex">
                        <!----------------------------- TAB ΥΠΕΥΘΥΝΟΥ ---------------------------------------->
                        <mat-tab formGroupName="employee1"> 
                            <ng-template mat-tab-label>
                                <span style="color: #3D6D99;font-size: 16px;font-style: normal;font-weight: 700;line-height: 24px;">Στοιχεία 1ου εργαζομένου</span>
                            </ng-template>
                            <div fxLayout="column" style="margin: 24px 0px">
                                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Email 1ου εργαζόμενου*</span> -->
                                <input formControlName="email" placeholder="Email 1ου εργαζόμενου" type="text" 
                                    (input) = "convertToLowercase(upToTwoEmployeesFormGroup.get('employee1')?.get('email'), $event)"
                                    [ngClass]=" {'input-error': upToTwoEmployeesFormGroup.get('employee1')?.get('email')?.invalid && 
                                    upToTwoEmployeesFormGroup.get('employee1')?.get('email')?.touched}"
                                    class="input" matInput> 
                                <div style="height:16px; padding:0px 12px 10px">
                                    <span class="error-message" *ngIf="upToTwoEmployeesFormGroup.get('employee1')?.get('email')?.hasError('required') && upToTwoEmployeesFormGroup.get('employee1')?.get('email')?.touched">
                                        Το πεδίο ειναι υποχρεωτικό
                                    </span>
                                    <span class="error-message" *ngIf="upToTwoEmployeesFormGroup.get('employee1')?.get('email')?.hasError('pattern') && upToTwoEmployeesFormGroup.get('employee1')?.get('email')?.touched">
                                        Το email δεν έχει σωστή μορφή
                                    </span>
                                    <span class="error-message" *ngIf="upToTwoEmployeesFormGroup.get('employee1')?.get('email')?.hasError('alreadyExists') && upToTwoEmployeesFormGroup.get('employee1')?.get('email')?.touched">
                                        Το email υπάρχει ήδη
                                    </span>
                                </div>
                            </div>

                            <div style="gap: 12px;padding:24px 0px" fxLayout="column"  formArrayName="courses" *ngIf="courses">
                                <span style="font-size:14px; line-height:20px;font-weight:700" class="gray-700"
                                [ngClass]="upToTwoEmployeesFormGroup.get('employee1')?.get('courses')?.invalid && 
                                upToTwoEmployeesFormGroup.get('employee1')?.get('courses')?.touched ? 'error-message': 'gray-700'">
                                    Επιλέξτε τα μαθήματα που θέλετε να εγγραφεί ο 1ος εργαζόμενος
                                </span>
                                <app-choose-courses *ngIf="courses && courses.length > 0" [courses]="courses"  [display]="'double'" [expandable]="true"
                                    (selectedCoursesChange)="setCoursesToFormArray(upToTwoEmployeesFormGroup.get('employee1')?.get('courses'), $event)"></app-choose-courses>
                            </div>
                        </mat-tab>

                        <mat-tab formGroupName="employee2"> 
                            <ng-template mat-tab-label>
                                <span style="color: #3D6D99;font-size: 16px;font-style: normal;font-weight: 700;line-height: 24px; ">Στοιχεία 2ου εργαζομένου</span>
                            </ng-template>
                            <div fxLayout="column" style="margin: 24px 0px">
                                <!-- <span style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Email 2ου εργαζόμενου</span> -->
                                <input formControlName="email" placeholder="Email 2ου εργαζόμενου" type="text" 
                                    (input) = "convertToLowercase(upToTwoEmployeesFormGroup.get('employee2')?.get('email'), $event)"

                                    [ngClass]=" {'input-error': upToTwoEmployeesFormGroup.get('employee2')?.get('email')?.invalid && 
                                    upToTwoEmployeesFormGroup.get('employee2')?.get('email')?.touched}"
                                    class="input" matInput> 
                                <div style="height:16px; padding:0px 12px 10px">
                                     <!-- edw tha mpei error -->
                                </div>
                            </div>

                            <div style="gap: 12px;padding:24px 0px" fxLayout="column"  formArrayName="courses" *ngIf="courses">
                                <span  style="font-size:14px; line-height:20px;font-weight:700"
                                [ngClass]="upToTwoEmployeesFormGroup.get('employee2')?.get('courses')?.invalid && 
                                upToTwoEmployeesFormGroup.get('employee2')?.get('courses')?.touched ? 'error-message': 'gray-700'">
                                    Επιλέξτε τα μαθήματα που θέλετε να εγγραφεί ο 2ος εργαζόμενος
                                </span>
                                <app-choose-courses *ngIf="courses && courses.length > 0" [courses]="courses" [display]="'double'" [expandable]="true"
                                    (selectedCoursesChange)="setCoursesToFormArray(upToTwoEmployeesFormGroup.get('employee2')?.get('courses'), $event)"></app-choose-courses>
                            </div>
                        </mat-tab>

                        <!----------------------------- TAB ΕΡΓΑΖΟΜΕΝΟΥ ---------------------------------------->


                    </mat-tab-group>

                   
                      <!-- ta terms exoun ena padding-top 10 px από μόνα τους -->
                    <div fxLayout="column" style="margin-bottom:32px;gap: 14px;" [formGroup]="consentForm" >
                        <mat-checkbox [ngClass]="consentForm.get('term1')?.invalid && consentForm.get('term1')?.touched  ? 'error-message' : 'gray-500'" 
                        formControlName="term1" class="terms" > Έχω διαβάσει και συμφωνώ με τους 

                        <a [ngClass]="consentForm.get('term1')?.invalid && consentForm.get('term1')?.touched  ? 'error-message' : 'gray-500'" 
                             routerLink="/growyourbusiness-terms-of-use" target="_blank">Όρους Συμμετοχής</a> 
                            και τη <a [ngClass]="consentForm.get('term1')?.invalid && consentForm.get('term1')?.touched  ? 'error-message' : 'gray-500'" 
                             routerLink="/growyourbusiness-privacy-policy" target="_blank"> Δήλωση Απορρήτου </a> και 
                            Συναινώ για τη Συλλογή και Επεξεργασία των Προσωπικών μου Δεδομένων</mat-checkbox>
                        <mat-checkbox [ngClass]="consentForm.get('term2')?.invalid && consentForm.get('term2')?.touched  ? 'error-message' : 'gray-500'"   formControlName="term2" class="terms"> 
                            Δίνω τη συγκατάθεσή μου η COSMOTE και η eQuality να επικοινωνούν μαζί μου με Email και τηλεφωνικώς για 
                            ενημερώσεις σχετικά με το COSMOTE GROW YOUR BUSINESS</mat-checkbox>
                        <mat-checkbox  [ngClass]="consentForm.get('term3')?.invalid && consentForm.get('term3')?.touched  ? 'error-message' : 'gray-500'"  formControlName="term3" class="terms">
                            Δίνω τη συγκατάθεσή μου η COSMOTE να επικοινωνεί μαζί μου με Email 
                            και τηλεφωνικώς για ενημερώσεις σχετικά με προϊόντα και υπηρεσίες COSMOTE</mat-checkbox>
                    </div>

                    <div  *ngIf="error" fxLayout="row" fxLayoutAlign="center center" style="margin-bottom:32px;" >
                        <app-error-message fxFlex="80"  [message]="error.message" [details] ="error.details"></app-error-message>

                    </div>

                    <button mat-raised-button color="primary"  (click)="onSubmit()"
                    style="padding:12px 20px !important; border-radius: 74px !important; 
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important">Εγγραφή</button>

                </form>
            </mat-step>
        
        </mat-stepper>
    </div>
</div>
