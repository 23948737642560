import { Component, computed, effect, ElementRef, signal, WritableSignal } from '@angular/core';
import { MessagesV2Service } from '../services/messages-v2.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize, forkJoin, Observable, of, switchMap } from 'rxjs';
import { FileUpload } from '../shared/models/file.model';
import { FormControl, Validators } from '@angular/forms';
import { CoursesService } from '../services/courses.service';
import { Course } from '../shared/models/course.model';

@Component({
  selector: 'app-ask-a-question',
  standalone: true,
  imports: [],
  templateUrl: './ask-a-question.component.html',
  styleUrl: './ask-a-question.component.css'
})
export class AskAQuestionComponent {

  public conversations: WritableSignal<any[]> = signal([]);
  public conversation: WritableSignal<any> = signal(null);
  public user: WritableSignal<any> = signal({});
  public role: WritableSignal<any> = signal(null);
  public dialogRef: MatDialogRef<any> | null = null;
  public isConversationRoute: WritableSignal<any> = signal(false);

  public uploadingFile: WritableSignal<FileUpload | null> = signal(null);
  public messageFormCtrl = new FormControl(null, Validators.required);
  public selectedCourseFormCtrl = new FormControl(null, Validators.required);
  public courses: WritableSignal<Course[]> = signal([]);
  public coursesInstructors = computed(() => {
    const courses = this.courses();
    const result: any = [];

    courses.forEach((course: any) => {
      course.instructors.forEach((instructor: any) => {
        // Dynamically merge instructor and course details
        result.push({
          ...course,
          instructor: {
            ...instructor
          },
          // instructorId: instructor.instructorId // Add additional required fields or transformations
        });
      });
    });

    // Remove instructor array from course
    return result.map(({ instructors, ...rest }: any) => rest);
  });

  constructor(
    protected messagesV2Service: MessagesV2Service,
    protected authService: AuthService,
    protected coursesService: CoursesService,
    protected route: ActivatedRoute,
    protected router: Router,
  ) {
    effect(() => {
      console.log('courses', this.courses());
    })
    effect(() => {
      console.log('coursesInstructors', this.coursesInstructors());
    });
  }

  ngOnInit() {

    this.authService.getRole()
      .then((d: any) => {
        this.role.set(d);
      });
    this.coursesService.getCourses().subscribe(d => this.courses.set(d));
    console.log(3232323, this.router.url);



    if (!this.router.url.startsWith('/admin/')) {
      forkJoin({
        conversations: this.messagesV2Service.getAllConversations(),
        aggregation: this.messagesV2Service.aggregation(),
        user: this.authService.getUser()
      }).pipe(
        switchMap(({ conversations, aggregation, user }) => {
          const unreadConversations = Object.keys(aggregation.conversations);
          console.log(conversations);
          const updatedConversations = conversations.map((conversation: any) => ({
            ...conversation,
            unreadMessages: unreadConversations.includes(conversation.conversationId)
          }));

          return of({
            conversations: { ...conversations, conversations: updatedConversations },
            user
          });
        })
      ).subscribe(({ conversations, user }) => {
        this.conversations.set(conversations.conversations);
        this.user.set(user);
        console.log('Updated conversations:', this.conversations());
        console.log('User:', this.user());
      });
    }

    // Handle initial load
    this.updateRouteStatus(this.route);
    // Subscribe to route changes
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateRouteStatus(this.route);
      }
    });


  }

  private updateRouteStatus(route: ActivatedRoute) {
    const conversationId = route.firstChild?.snapshot.paramMap.get('conversationId');
    this.isConversationRoute.set(!!conversationId);
  }

  getCourseConversations(course: any) {
    console.log('Course:', course);
    this.messagesV2Service.getCourseConversations(course.courseId)
      .subscribe((data) => {
        console.log('Course conversations:', data);
        this.conversations.set(data);
      });
  }


  getMessages(conversation: any) {

    this.messagesV2Service.getMessages(conversation.conversationId)
      .subscribe((data: any) => {
        data = data.map((d: any) => (d.senderId === conversation.receiverId ? (d.senderName = conversation.receiverName) : (d.senderName = conversation.senderName), d));
        let foundFirstUnread = false;
        this.conversation.set({
          ...conversation,
          messages: data.sort((a: any, b: any) => a.timestamp - b.timestamp).map((message: any) => {
            if (!foundFirstUnread && message.unread) {
              foundFirstUnread = true;
              return { ...message, firstUnread: true };
            }
            return message;
          })
        });
      });
  }

  public sendMessage(newMessage: any, replyTmpl: any): void {
    if (!newMessage.text && !newMessage.attachment) {
      return;
    }
    let request$: Observable<any> = of(null);
    if (newMessage.text !== '') {
      request$ = this.messagesV2Service.reply(this.conversation(), { text: newMessage.text });
    }
    if (newMessage.attachment !== null) {
      request$ = request$.pipe(
        switchMap((textResponse) => {
          return this.messagesV2Service.reply(this.conversation(), { attachment: newMessage.attachment });
        })
      );
    }
    request$
      .pipe(
        finalize(() => {
          this.resetForm();
          this.dialogRef?.close();
        })
      )
      .subscribe({
        next: (response) => {
          console.log('Final response:', response);
        },
        error: (error) => {
          console.error('Error sending message:', error);
        }
      });
  }

  public submitNewQuestion(newMessage: any, replyTmpl: any): void {

    const alreadyQuestion = this.conversations().filter(d => d.course.courseId === newMessage.course.courseId);
    if (alreadyQuestion.length > 0) {
      this.sendMessage(newMessage, replyTmpl);
    } else {
      if (!newMessage.text && !newMessage.attachment) {
        return;
      }
      let request$: Observable<any>;
      // Handle all possible first message scenarios
      if (newMessage.text !== '') {
        // Text present, may or may not have attachment
        request$ = this.messagesV2Service.firstMessage({ ...newMessage, attachment: null });
        if (newMessage.attachment !== null) {
          // If attachment is also present, chain a reply
          request$ = request$.pipe(
            switchMap((firstResponse) => {
              // console.log('First message response:', firstResponse);
              return this.messagesV2Service.reply(this.conversation(), { attachment: newMessage.attachment });
            })
          );
        }
      } else if (newMessage.attachment !== null) {
        // Attachment-only first message
        request$ = this.messagesV2Service.firstMessage({ ...newMessage, text: undefined });
      } else {
        // This case should not occur due to the earlier check, but included for completeness
        return;
      }
      request$
        .pipe(
          finalize(() => {
            this.resetForm();
            this.dialogRef?.close();
          })
        )
        .subscribe({
          next: (response) => {
            // console.log('Final response:', response);
            this.messagesV2Service.getAllConversations()
              .subscribe((data) => {
                this.conversations.set(data);
                this.getMessages(response.data.conversation)
              });
          },
          error: (error) => {
            console.error('Error sending message:', error);
          }
        });
    }
  }

  private resetForm(): void {
    this.messageFormCtrl.reset();
    this.uploadingFile.set(null);
  }

  public readMessages() {
    // {
    //   "readMessages": [
    //     "c02138be-1559-43d3-8559-5e8057778d3c",
    //     "08fc2ed7-d029-49e9-9b6b-c2889e336557"
    //   ]
    // }
    const messages = {
      readMessages: this.conversation().messages.filter((d: any) => d.unread).map((d: any) => d.messageId)
    }
    this.messagesV2Service.readMessages(this.conversation(), messages)
      .subscribe((d: any) => {
        // console.log(4433, d);
      })
  }

  public resolveConversation(newMessage: any) {
    this.messagesV2Service.resolveConversation(this.conversation(), newMessage)
      .subscribe((d: any) => {
        this.messagesV2Service.getAllConversations()
          .subscribe((data) => {
            this.conversations.set(data);
            this.getMessages(this.conversations().filter(d => d.conversationId === this.conversation().conversationId)[0]);
          });
      })
  }

}
