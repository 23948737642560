<table *ngIf="tableData && tableData.length > 0" fxHide fxShow.gt-xs="true" mat-table [dataSource]="tableData" 
    style="border-radius: 8px;
    border: 1px solid var(--gray-200, #EAECF0);
    background: var(--white, #FFF);
    overflow: hidden;
    
    /* Shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);" >
        <ng-container matColumnDef="tableDateString">
            <th mat-header-cell *matHeaderCellDef style="font-size:21px; font-weight:700; line-height:18px;padding:12px 24px; color:white;"> Ημερομηνία </th>
            <td mat-cell class="cosmote-blue" *matCellDef="let element"  style="font-size:17px; padding:16px 24px;"> {{element.tableDateString}} </td>
        </ng-container>

        <ng-container  matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef style="font-size:21px; font-weight:700; line-height:18px;padding:12px 24px; color:white;"> Ώρα </th>
            <td mat-cell class="cosmote-blue" *matCellDef="let element"  style="font-size:17px;padding:16px 24px;"> {{element.time}} </td>
        </ng-container>

        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef style="font-size:21px; font-weight:700; line-height:18px;padding:12px 24px; color:white; min-width:155px"> Teams link </th>
            <td mat-cell class="cosmote-blue" *matCellDef="let element"  style="font-size:17px;padding:16px 24px;"> 
                <a class="cosmote-blue" *ngIf="element.link" href="{{element.link}}" target="_blank" >link</a>
            </td>

        </ng-container>

        <ng-container  matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef style="font-size:21px; font-weight:700; line-height:18px;padding:12px 24px; color:white;"> Διάρκεια </th>
            <td mat-cell class="cosmote-blue" *matCellDef="let element"  style="font-size:17px;padding:16px 24px;"> {{element.duration}} </td>
        </ng-container>

        <ng-container matColumnDef="topic">
            <th mat-header-cell *matHeaderCellDef style="font-size:21px; font-weight:700; line-height:18px;padding:12px 24px; color:white;"> Θεματολογία </th>
            <td mat-cell class="cosmote-blue" *matCellDef="let element"  style="font-size:17px;padding:16px 24px;" > {{element.topic}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" style="border-bottom: 1px solid var(--gray-200, #EAECF0); 
        background: #004B87;"></tr>
        <tr mat-row style="height:72px" *matRowDef="let element; columns: displayedColumns;"></tr>
    </table>

<table *ngIf="tableData && tableData.length > 0 && version == 'v1'"  fxHide fxShow.xs="true" mat-table [dataSource]="tableData" 
style="border-radius: 8px;
border: 1px solid var(--gray-200, #EAECF0);
background: var(--white, #FFF);
overflow: hidden;

/* Shadow/sm */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);" >
    <ng-container matColumnDef="tableFullDateString">
        <th mat-header-cell *matHeaderCellDef class="cosmote-blue" style="font-size:21px; font-weight:700; line-height:18px;padding:0px 12px;color:white"> Ημερομηνία </th>
        <td mat-cell *matCellDef="let element"  class="cosmote-blue" style="padding:0px 12px"> {{element.tableFullDateString}} </td>
    </ng-container>

    <ng-container  matColumnDef="topic">
        <th mat-header-cell *matHeaderCellDef class="cosmote-blue" style="font-size:21px; font-weight:700; line-height:18px;padding:0px 12px; color:white"> Θεματολογία  </th>
        <td mat-cell *matCellDef="let element" class="cosmote-blue" style="padding:0px 12px"> {{element.topic}}  </td>
    </ng-container>
    

    <tr mat-header-row *matHeaderRowDef="displayedColumnsXs" style="border-bottom: 1px solid #3b61a6;
    background: var(--cosmote-blue);"></tr>
    <tr mat-row style="height:72px; border-bottom: 1px solid #3b61a6" *matRowDef="let element; columns: displayedColumnsXs;"></tr>
</table>


<div *ngIf="tableData && tableData.length > 0 && version == 'v2' " fxHide fxShow.xs="true" fxLayout="column" style="gap: 16px;">
    <div *ngFor="let tableRow of tableData" fxLayout="column"   style="gap: 12px;border-bottom: 1px solid var(--gray-400, #98A2B3);">
        <div style="padding:12px 0px">
            <span class="cosmote-blue" style="font-size: 16px;font-style: normal;font-weight: 700;
                line-height: 20px;">{{ tableRow.topic }} </span>
        </div>
        <div style="padding-bottom:8px; border-bottom: 1px solid var(--gray-200, #EAECF0);" fxLayout="row">
            <span class="cosmote-blue" style="width:120px"> Ημερομηνία</span>
            <span class="gray-500">{{tableRow.tableFullDateString}}</span>
        </div>

        <div style="padding-bottom:8px; border-bottom: 1px solid var(--gray-200, #EAECF0);" fxLayout="row" fxLayoutAlign="center" *ngIf="tableRow.link">
            <a [href]="tableRow.link" class="cosmote-blue" style="width:120px"> Teams link</a>
        </div>

    </div>
</div>