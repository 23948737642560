import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { SpinnerService } from '../spinner/spinner.service';
import { AuthService, IUser } from '../services/auth.service';

@Component({
  selector: 'app-login',
  template: '',
})
export class LoginComponent {

	public loginError!: string | null;
	public user!: IUser

	loginForm: FormGroup = new FormGroup({
		email: new FormControl(null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
		password: new FormControl(null, [Validators.required])
	})

	constructor(
		public auth: AuthService,
        public router: Router,
		public spinnerService: SpinnerService
	){
		this.user = {} as IUser;
		this.loginForm.valueChanges.subscribe(() => {
			this.loginError = null;
		  });
	}

	
	onLogin(route = 'my-courses') {
		console.log(this.loginForm)
		this.user.email = this.loginForm?.get('email')?.value
		this.user.password = this.loginForm?.get('password')?.value
		this.spinnerService.show('global');
		this.auth.signIn(this.user).pipe(
				finalize(() => setTimeout(
					() => this.spinnerService.hide('global'), 1000))
		)
		.subscribe({
			next: (data) => {
				this.router.navigate([route])
			},
			error: (cognitoError) => {
				console.log(cognitoError)
				if (cognitoError.name === 'UserNotConfirmedException') {
					this.auth.setUserToVerify({email: this.loginForm.value.email, password: this.loginForm?.get('password')?.value});

					// // flag password to enable reconfirm on verify
					// this.auth.setPassword('conf');
					this.router.navigateByUrl('/verify');
				} else if (cognitoError.code === 'NetworkError') {
					// this.spinnerService.hide('signIn');
					// this.spinner_loading = false;
					// this.loginError = null;
					// this.snackbarService.open('Network connection error', 'ok', {
					// 	duration: 7000,
					// 	verticalPosition: 'top'
					// });
				} else if (cognitoError.code === 'NotAuthorizedException' || cognitoError.code === 'UserNotFoundException') {
					this.loginError = 'Ο συνδυασμός των στοιχείων που δώσατε δεν ειναι έγκυρος';
					// this.spinnerService.hide('signIn');
					// this.spinner_loading = false;
				}
			}
		})
	}

}
