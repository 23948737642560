<app-spinner name="login"></app-spinner>
<div fxLayout="row">
    <div fxFlex fxLayout="column" style="justify-content: center;" [ngStyle.lt-lg]=" {'padding-top': '96px'}">

        <div fxLayout="row" style="gap: 32px;" fxLayoutAlign="center start">
            <div fxLayout="column" fxFlex.gt-xs="350px" fxFlex.xs="280px">
                <div fxLayout="column" fxLayoutAlign="start center" style="margin-bottom:24px;gap: 12px;" >
                    <span style="font-size: 30px; font-weight:700; line-height:38px" class="cosmote-blue">
                        Σύνδεση</span>
                    <span class="cosmote-blue" style="text-align:center">Καλώς ήρθατε! Εισάγετε τα στοιχεία σας, για να
                        συνδεθείτε στην πλατφόρμα eLearning</span>
                </div>

                <form [formGroup]="loginForm" fxLayout="column" style="margin-bottom:32px">
                    <div fxLayout="column">
                        <span class="cosmote-blue"
                            style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Email</span>
                        <input formControlName="email" placeholder="" type="text" [ngClass]=" {'input-error': loginForm.get('email')?.invalid && 
                                loginForm.get('email')?.touched}" class="input" matInput>
                        <div style="height:16px; padding:0px 12px 10px">
                            <span class="error-message"
                                *ngIf="loginForm.get('email')?.hasError('required') && loginForm.get('email')?.touched">
                                Το πεδίο ειναι υποχρεωτικό
                            </span>
                            <span class="error-message"
                                *ngIf="loginForm.get('email')?.hasError('pattern') && loginForm.get('email')?.touched">
                                Το email δεν έχει σωστή μορφή
                            </span>
                        </div>
                    </div>

                    <div fxLayout="column">
                        <span class="cosmote-blue"
                            style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Password</span>
                        <div style="position: relative;
                        display: inline-block;
                        width: 100%;">
                            <input fxFlex formControlName="password" placeholder=""
                                [type]="hidePassword ? 'password' : 'text'" [ngClass]=" {'input-error': loginForm.get('password')?.invalid && 
                                loginForm.get('password')?.touched}" class="input" matInput>
                            <mat-icon style="cursor: pointer; width: 24px;position: absolute; top: 25%;left: 85%;" matSuffix
                                (click)="hidePassword = !hidePassword">{{hidePassword ?
                                'visibility_off' : 'visibility'}}</mat-icon>
                        </div>
                        <div style="height:16px; padding:0px 12px 10px">
                            <span class="error-message"
                                *ngIf="loginForm.get('password')?.hasError('required') && loginForm.get('password')?.touched">
                                Εισάγετε password
                            </span>
                            <span class="error-message" *ngIf="loginError" style="margin-bottom:24px;"> {{loginError}} <br>
                            </span>
                        </div>
                    </div>


                    <div style="margin-bottom:24px; width:150px">
                        <span style=" cursor: pointer; color:#3D6D99; font-size:14px; " routerLink="../forgot-password"
                            router>Υπενθύμιση password</span>
                    </div>

                    <!-- <span class="error-message" *ngIf="loginError" style="margin-bottom:24px;"> {{loginError}} <br>
                    </span> -->

                    <button mat-raised-button color="success" style="padding: 10px 18px; border-radius:50px"
                        (click)="onLogin('manage')" [disabled]="loginForm.invalid || loginError">Σύνδεση</button>
                </form>

                <div fxLayout="row" fxLayoutAlign="center center" style="gap: 4px;">

                    <span class="gray-500 mat-small">Δεν έχετε λογαριασμό; </span>
                    <span class="blue-700 f600 mat-small"
                        style="cursor: pointer; color:#3D6D99; font-size:14px;font-weight:700"
                        routerLink="../growyourbusiness-register" router>Εγγραφή</span>
                </div>


                <div fxLayout="row" style="gap: 12px;padding-top: 128px;" fxLayoutAlign="center center" >
                    <span style="cursor: pointer; color:#3D6D99; font-size:14px;font-weight:700"
                        routerLink="/growyourbusiness-terms-of-use" target="_blank">Όροι Συμμετοχής</span>
                    <span class="blue-700 f600 mat-small"
                        style="cursor: pointer; color:#3D6D99; font-size:14px;font-weight:700"
                        routerLink="/growyourbusiness-privacy-policy" target="_blank"> Δήλωση Απορρήτου </span>
                </div>

            </div>
        </div>
    </div>

    <div fxHide fxShow.gt-md="true" fxFlex="50" style="background-image: url('/assets/login-page.jpg'); background-size: cover; background-position: center; 
         background-repeat: no-repeat;height:calc(100vh - 209px)">
    </div>

</div>