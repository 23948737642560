import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "./common/services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {} 

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  

        if  (request.url.startsWith('../assets') || request.url.startsWith('assets') || request.url.includes('.s3.') ||
        this.shouldUrlBeExcluded(request.url) ) {
            return next.handle(request);
        }

        return this.authService.getCurrentSession().pipe(switchMap( (session) => {
            // console.log('session', session, session.tokens?.idToken.toString())
            // let apiReq = this.setIdToken(request, session.getIdToken().getJwtToken());
            //     return next.handle(apiReq)
            // }));
            let apiReq = this.setIdToken(request, session.tokens.idToken.toString());
                return next.handle(apiReq)
            }));
    }

    private  setIdToken(request:any, token: any) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    public shouldUrlBeExcluded(targetUrl: string) {

        let excludedUrlsPerfectMatch = [
            `${environment.USERS_API}`,
            `${environment.COURSES_API}/courses`,
            `${environment.USERS_MESSAGING_API}/messages/contact-us`,
            'https://645v2ekb1c.execute-api.eu-west-1.amazonaws.com/dev/vies-v2',
            `${environment.PROGRAM_SPECIFIC_API}/check-vat`,
        ];

        let excludedUrlsContainMatch = [
            `${environment.COURSES_API}/courses/[^/]*$`,
        ];

        for (let url of excludedUrlsPerfectMatch) {
            if(targetUrl == url) {
                return true
            }
        }

        for(let url of excludedUrlsContainMatch) {
            console.log( url, targetUrl, targetUrl.match(url))
            if(targetUrl.match(url)) {
                return true
            }
        }

        return false
    }
}
  