import { Injectable } from '@angular/core';
import { CoursesService } from '../services/courses.service';

@Injectable()
export class CoursesResolverService {

    constructor(
        private coursesService: CoursesService
    ) {}

    resolve() {
       return this.coursesService.getCourses()
    }
}
