
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../common/services/user.service';

@Injectable()
export class EmployerGuard {

    constructor(private userService: UserService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        return  new Promise<boolean>( (resolve, reject) => {
            this.userService.getUserDetails().subscribe({
                next: (user) => {
                    // console.log(user);
                    if(!user?.employerId) {
                        return resolve(true)
                    } else {
                        this.router.navigate(['my-courses'], {queryParams: route.queryParams})
                        return resolve(false)

                    }
                }
            });
        });
    }
}
