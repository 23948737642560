import { Component } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GrowYourBusinessService } from "../grow-your-business.service";
import { SpinnerService } from "src/app/common/spinner/spinner.service";
import { catchError, finalize, from, of, switchMap, take } from "rxjs";
import { IUser } from "src/app/common/services/auth.service";
import { AuthService } from 'src/app/common/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from "src/environments/environment";
import { GrowYourBusinessAnalyticsService } from '../grow-your-business-analytics.service';


@Component({
    selector: 'app-employee-registration-details',
    templateUrl: './employee-registration.component.html',
    styleUrls: ['./employee-registration.component.css']
})
export class GrowYourBusinessEmployeeRegistrationComponent {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private growYourBusinessService: GrowYourBusinessService,
        private spinnerService: SpinnerService,
        private authService: AuthService,
        private snackBar: MatSnackBar,
        private growYourBusinessAnalyticsService: GrowYourBusinessAnalyticsService
    ) { }

    public employeeDetails: any;
    public hidePassword = true;
    public alreadyRegistered = false
    public error!: { message: string, details: string } | null

    public consentForm = new FormGroup({
        term1: new FormControl(false, [Validators.requiredTrue]),
        term2: new FormControl(false, [Validators.requiredTrue]),
        term3: new FormControl(false,),
    })

    public employeeForm = new FormGroup({
        personalDetails: new FormGroup({
            name: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            sonOf: new FormControl('', [Validators.required]),
        }),
        contactDetails: new FormGroup({
            mobile: new FormControl('', [Validators.required, Validators.pattern(/^69\d{8}$/)]),
        }),
        email: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        password: new FormControl('', [Validators.required, this.passwordIsValid]),
        passwordConfirmation: new FormControl('', [Validators.required, this.checkPassword.bind(this)]),
    })

    ngOnInit() {
        this.route.data.subscribe(data => {

            console.log(data)

            if (data["employeeRegistrationDetails"]?.['alreadyRegistered']) {
                this.alreadyRegistered = true
            } else {
                this.employeeDetails = data['employeeRegistrationDetails'];
                this.employeeForm.get('email')?.setValue(this.employeeDetails.email);
            }
        });

        (this.employeeForm as FormGroup).valueChanges.pipe(take(1)).subscribe((value) => {
            console.log('sending form start event')
            this.growYourBusinessAnalyticsService.sendEvent('form_start', {})
        });

        /** αυτό το θελουμε για να τρεχει  το validation για το passwordConfirmation όταν πειράζουμε το password */
        (this.employeeForm.get('password') as FormControl).valueChanges.subscribe((value) => {
            if ((this.employeeForm.get('passwordConfirmation') as FormControl).dirty) {
                this.employeeForm.get('passwordConfirmation')?.updateValueAndValidity();
            }
        });
    }

    passwordIsValid(control: AbstractControl): ValidationErrors | null {
        if (!control.value || control.value.length < 6) {
            return { 'passwordIsNotLongEnough': true };
        }
        return null;
    }

    checkPassword() {
        if (this.employeeForm?.get('password')?.value === this.employeeForm?.get('passwordConfirmation')?.value) {
            return null;
        }
        return { notSame: true };
    }



    onCompleteEmployeeRegistration() {
        console.log(this.employeeForm.value);

        this.error = null;

        if (this.consentForm.invalid) {
            this.consentForm.markAllAsTouched()

            if (this.consentForm.get('term1')?.invalid) {

                this.error = {
                    message: '',
                    details: 'Παρακαλούμε όπως μας επιβεβαιώσετε πώς έχετε διαβάσει και συμφωνείτε με τους Όρους Συμμετοχής και τη Δήλωση Απορρήτου και Συναινείτε για τη Συλλογή και Επεξεργασία των Προσωπικών σας Δεδομένων.'
                }
            } else if (this.consentForm.get('term2')?.invalid) {
                this.error = {
                    message: "",
                    details: "Χρειαζόμαστε την συγκατάθεση σας για να επικοινωνούμε μαζί σας για το COSMOTE GROW YOUR BUSINESS – Certification Program."
                }
            }

            return;
        }
        const sendData = {
            ...this.employeeForm.value,
            ...{ userId: this.employeeDetails.userId }
        }
        delete sendData.passwordConfirmation;
        console.log('data to send', sendData);

        this.spinnerService.show('employee-registration')

        // this.growYourBusinessService.completeEmployeeRegistration(sendData)
        //     .pipe(finalize(() => this.spinnerService.hide('employee-registration')))
        //     .subscribe({
        //         next: (result: any) => {
        //             console.log(result)
        //             this.growYourBusinessAnalyticsService.sendEvent('form_completed', {})
        //             this.growYourBusinessAnalyticsService.facebookTrackCustomEvent('GYB_Eggrafi');
        //             let user = {
        //                 email: this.employeeDetails.email,
        //                 password: this.employeeForm.get('password')?.value
        //             } as IUser;


        //             this.authService.isAuthenticated()
        //             .then((result?: any) =>   {
        //                 if(result) {
        //                     this.authService.signOut(false).subscribe({
        //                         next: () => {
        //                             this.authService.signIn(user).subscribe({
        //                                 next: (result: any) => {
        //                                     console.log(result)
        //                                     this.router.navigate(['/my-courses'])
        //                                 }
        //                             });
        //                         }
        //                     });
        //                 } else {

        //                 }
        //             });
        //         }
        // });

        this.growYourBusinessService.completeEmployeeRegistration(sendData)
            .pipe(
                finalize(() => this.spinnerService.hide('employee-registration')),
                switchMap((result: any) => {
                    console.log(result);
                    this.growYourBusinessAnalyticsService.sendEvent('form_completed', {});
                    this.growYourBusinessAnalyticsService.facebookTrackCustomEvent('GYB_Eggrafi');

                    const user = {
                        email: this.employeeDetails.email,
                        password: this.employeeForm.get('password')?.value
                    } as IUser;

                    return from(this.authService.isAuthenticated()).pipe(
                        switchMap((isAuthenticated: boolean) => {
                            if (isAuthenticated) {
                                return this.authService.signOut(false).pipe(
                                    switchMap(() => this.authService.signIn(user))
                                );
                            } else {
                                return this.authService.signIn(user);
                            }
                        })
                    );
                }),
                catchError((error: any) => {
                    console.error('Error during employee registration:', error);
                    return of(false);
                })
            )
            .subscribe({
                next: (result: any) => {
                    console.log(33333, result);
                    if (result !== false) {
                        console.log(result);
                        this.router.navigate(['/my-courses']);
                    }
                }
            });

    }

    onNavigateToLogin() {
        this.router.navigate([`./${environment.LOGIN_URL}`])
    }
}


