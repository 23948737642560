<div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="92" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-lg="column"
        style="padding: 24px 32px 24px 32px" [ngStyle.lt-lg]="{'padding': '48px 32px'}">
        <div fxLayout="row" fxLayoutAlign="start end" style="gap: 12px;padding-bottom:12px"
            [ngStyle.lt-lg]="{'margin-bottom': '32px', 'padding-bottom': '0px'}">
            <span style="font-family: montserrat,sans-serif; font-size:16px;font-weight:700; line-height:1.3em">powered
                by </span><img src="assets/powered-by-equality.webp" />
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" style="gap: 28px;"
            fxLayoutGap.lt-lg="12px" [ngStyle.md]="{'padding-bottom': '24px'}">
            <span class="gray-600" style="cursor:pointer; font-size:16px; font-weight:600; line-height:24px"
                routerLink="/growyourbusiness-terms-of-use">Όροι χρήσης site</span>
            <span class="gray-600" style="cursor:pointer; font-size:16px;font-weight:600; line-height:24px"
                routerLink="/growyourbusiness-privacy-policy">Προστασία δεδομένων</span>
            <span class="gray-600" style="cursor:pointer; font-size:16px;font-weight:600; line-height:24px"
                routerLink="/growyourbusiness-cookies">Cookies</span>
        </div>
        <span class="gray-500" style="font-size:16px; line-height:24px" [ngStyle.lt-md]="{'margin-top': '48px'}"
            [ngStyle.lt-md]="{'margin-top': '36px'}">© 2023 All rights reserved.</span>

    </div>
</div>