import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AbstractControl, Form, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { GrowYourBusinessService } from "../grow-your-business.service";
import { AppService } from "src/app/common/services/app.service";
import { debounceTime, finalize, fromEvent, take } from "rxjs";
import { SpinnerService } from "src/app/common/spinner/spinner.service";
import { CoursesService } from 'src/app/common/services/courses.service';
import { Course } from "src/app/common/shared/models/course.model";
import { MatStepper } from "@angular/material/stepper";
import {MatSnackBar} from '@angular/material/snack-bar';
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { atLeastOneElement } from "../custom-validators";
import { AuthService } from "src/app/common/services/auth.service";
import { GrowYourBusinessAnalyticsService } from '../grow-your-business-analytics.service';


@Component({
    selector: "app-employer-registration",
    templateUrl: "./grow-your-business-employer-registration.component.html",
    styleUrls: ["./grow-your-business-employer-registration.component.css"]
})
export class GrowYourBusinessEmployerRegistrationComponent implements OnInit {

    constructor(
        private growYourBusinessService: GrowYourBusinessService,
        private appService: AppService,
        private spinnerService: SpinnerService,
        private coursesService: CoursesService,
        private snackBar: MatSnackBar,
        private router: Router,
        private authService: AuthService,
        private growYourBusinessAnalyticsService: GrowYourBusinessAnalyticsService
    ) { }

    @ViewChild('stepper', {static:false})  myStepper!: MatStepper;
    @ViewChild('emailInput') emailInput!: ElementRef;
    @ViewChild('formEl') formElementRef!: ElementRef<any>;

    public selectedCourses: string[] = [];

    public industries = [
        "Αγροτικός τομέας", "Ακίνητη Περιουσία", "Αντιπροσωπείες- Εξαγωγές – Εισαγωγές Αυτοκινητοβιομηχανία",
        "Βιομηχανία", "Εκδόσεις", "Εκπαίδευση", "Εμπόριο", "Ενέργεια", "Ερευνητικά κέντρα", "Κατασκευαστικός κλάδος",
        "Κλωστοϋφαντουργία", "Λιανικό εμπόριο", "Μαζική Εστίαση", "Μέσα Μαζικής Ενημέρωσης", "Μεταφορές - Logistics",
        "Περιβάλλον", "Πληροφορική-Τεχνολογίες", "Τηλεπικοινωνίες", "Τουρισμός",
        "Τρόφιμα", "Υπηρεσίες υγείας", "Φαρμακευτικός Τομέας", "Χημική Βιομηχανία",
        "Χρηματοπιστωτικός Τομέας",
        "Δημόσιος Τομέας: Τοπική Αυτοδιοίκηση, Οργανισμοί, ΔΕΚΟ, Επιμελητήρια, Εκπαιδευτικά Ιδρύματα κλπ"
    ]

    public error!: { message: string, details: string} | null

    public step2CustomForm = new FormGroup({});
    public courses: Course[] = [];
    public vatNumberVerified = false;
    public hidePassword = true
    public registrationChoice = 1;
    
    public employerForm = new FormGroup({
        personalDetails: new FormGroup({
            name: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required)
        }),
        contactDetails: new FormGroup({
            mobile: new FormControl('', [Validators.required, Validators.pattern(/^69\d{8}$/)]),
            email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        }),
        vatNumber: new FormControl('', [Validators.required,  Validators.pattern("[0-9 ]{9}")]),
        companyName: new FormControl('', Validators.required),
        industry: new FormControl('', Validators.required),
        password: new FormControl('', [Validators.required, this.passwordIsValid]),
        passwordConfirmation: new FormControl('', [Validators.required, this.checkPassword.bind(this)]),
    })

    public step2Completed = this.employerForm.valid


    onlyEmployerFormGroup = new FormGroup({
        sonOf: new FormControl('', Validators.required),
        courses: new FormArray([], [atLeastOneElement()])
    })

    employerWithOneEmployeeFormGroup = new FormGroup({
        employer: new FormGroup({
            sonOf: new FormControl('', Validators.required),
            courses: new FormArray([], [atLeastOneElement()]),
        }),
        employee: new FormGroup({
            email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
            courses:  new FormArray([], [atLeastOneElement()])
        })
    })
    // Αυτό εδώ το σεττάρουμε για data binding για να μπορούμε να αλλάξουμε tab προγραμματιστικά στην περίπτωση υπεύθυνος + εργαζόμενος
    public employerWithOneEmployeeFormGroupTabIndex = 0

    upToTwoEmployeesFormGroup = new FormGroup ({
        employee1:  new FormGroup({
            email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
            courses:  new FormArray([], [atLeastOneElement()])
        }),
        employee2: new FormGroup({
            email: new FormControl(''),
            courses:  new FormArray([], [atLeastOneElement()])
        })
    })
    // Αυτό εδώ το σεττάρουμε για data binding για να μπορούμε να αλλάξουμε tab προγραμματιστικά στην περίπτωση εργαζόμενος/οι

    public upToTwoEmployeesFormGroupTabIndex = 0

    consentForm = new FormGroup({
        term1: new FormControl(false, Validators.requiredTrue),
        term2: new FormControl(false, Validators.requiredTrue),
        term3: new FormControl(false),
    });

    // atLeastOneTrueValidator(): ValidatorFn {
    //     return (control: AbstractControl) => {
    //         if (!control) return null;
    //         const formArray = control as FormArray;
    //         const hasTrueValue = formArray.controls.some(control => control.value === true);
      
    //         return hasTrueValue ? null : { atLeastOneTrue: true };
    //     };
    // }
    /**
     * Μεσω αυτού του function μεταφέρουμε τα επιλεγμένα μαθήματα στον formController
     * @param formArray 
     * @param courseIds 
     */
    setCoursesToFormArray(formArray: any, courseIds: string[]) {

        (formArray as FormArray)?.clear()
        for (let c of  courseIds) {
            (formArray as FormArray)?.push(new FormControl(c));
        }
    }

    
    ngOnInit() {

        (this.employerForm as FormGroup).valueChanges.pipe(take(1)).subscribe( (value) => {
            console.log('sending form start event')
            this.growYourBusinessAnalyticsService.sendEvent('form_start', {})
        });

        /** αυτό το θελουμε για να τρεχει  το validation για το passwordConfirmation όταν πειράζουμε το password */
        (this.employerForm.get('password') as FormControl).valueChanges.subscribe((value) => {
            if( (this.employerForm.get('passwordConfirmation') as FormControl).dirty) {
                this.employerForm.get('passwordConfirmation')?.updateValueAndValidity();
            }
        });

        this.coursesService.getCourses().subscribe({
            next: (courses: any) => {
                this.courses = courses;
            }
        })

        // this.patchFakeValues();
    }


    checkPassword() {
        if (this.employerForm?.get('password')?.value === this.employerForm?.get('passwordConfirmation')?.value) {
            return null;
        }
        return { notSame: true };
    }

    passwordIsValid(control: AbstractControl): ValidationErrors | null {
        if (!control.value || control.value.length < 6) {
            return { 'passwordIsNotLongEnough': true };
        }
        return null;
    }

    onCheckVatNumber() {
        this.spinnerService.show('employer-registration')

        this.appService.vies({
            countryCode: 'EL',
            vat: this.employerForm?.get('vatNumber')?.value as any
        })
       
        .subscribe({
            next: (response: any) => {
                console.log(response)
                let companyName = response.companyName
                this.growYourBusinessService.checkVatNumber(this.employerForm?.get('vatNumber')?.value as string)
                .pipe(finalize(() => this.spinnerService.hide('employer-registration')))
                .subscribe({
                    next: (result: any) => {
                        let vatNumberExists = result.vatNumberExists;
                        console.log(vatNumberExists)
                        if(vatNumberExists) {
                            this.employerForm.get('vatNumber')?.setErrors({invalid: 'Υπάρχει ήδη εγγραφή με αυτό το ΑΦΜ'})
                        } else {
                            this.employerForm.get('vatNumber')?.disable()

                            console.log(companyName)

                            this.employerForm.get('companyName')?.setValue(companyName)
                            this.employerForm.get('companyName')?.disable()
                            
                            this.vatNumberVerified = true
                        }
                    }
                })
            },
            error: (err => {
                console.log(err)
                this.spinnerService.hide('employer-registration')
                if (err.status == 422) {
                    this.employerForm.get('vatNumber')?.setErrors({ "invalid": 'O Α.Φ.Μ. δεν αντιστοιχεί σε επιχείρηση. Προσπαθήστε ξανά.' })
                }
            })
        })
    }

    onEditVatNumber() {
        this.vatNumberVerified = false
        this.employerForm.get('vatNumber')?.enable()
        this.employerForm.get('vatNumber')?.reset()
        this.employerForm.get('companyName')?.reset()
        this.employerForm.get('industry')?.reset()
    }

    onSubmit() {

        let requestPayload: any = this.employerForm.getRawValue()
        requestPayload.email = this.employerForm.get('contactDetails.email')?.value
        delete requestPayload.contactDetails.emailConfirmation
        requestPayload.client = environment.POOL_DATA.userPoolClientId
        requestPayload.tenantId = 'cosmote'

        console.log(requestPayload);

        // επειδη καποια companyName είναι πολύ μεγάλα τα κανουμε μεχρι 100 χαρακτήρες 
        //για να αποθηκεύονται στο σύστημα χωρίς προβλήματα
        if(requestPayload.companyName.length > 100) {
            requestPayload.companyName = requestPayload.companyName.substring(0, 100)
        }

        this.error = null

        console.log(this.consentForm)
        if(this.consentForm.invalid) {
            this.consentForm.markAllAsTouched()

            if(this.consentForm.get('term1')?.invalid) {

                this.error = {
                    message: '',
                    details: 'Παρακαλούμε όπως μας επιβεβαιώσετε πώς έχετε διαβάσει και συμφωνείτε με τους Όρους Συμμετοχής και τη Δήλωση Απορρήτου και Συναινείτε για τη Συλλογή και Επεξεργασία των Προσωπικών σας Δεδομένων.'
                }
            } else if (this.consentForm.get('term2')?.invalid) {
                this.error = {
                    message: "",
                    details: "Χρειαζόμαστε την συγκατάθεση σας για να επικοινωνούμε μαζί σας για το COSMOTE GROW YOUR BUSINESS – Certification Program."
                }
            }

            return;
        }

        switch (this.registrationChoice) {
            case 1:
                console.log(this.onlyEmployerFormGroup);
                if(this.onlyEmployerFormGroup.invalid) {
                    this.error = {
                        message: 'Συμπληρώστε όλα τα στοιχεία υπευθύνου',
                        details: 'Συμπληρώστε το πατρώνυμο του υπεθύνου και επιλέξτε μαθήματα'
                    }
                    this.onlyEmployerFormGroup.markAllAsTouched()
                    return;
                }

                requestPayload.personalDetails.sonOf = this.onlyEmployerFormGroup.get('sonOf')?.value
                requestPayload.courses = this.onlyEmployerFormGroup.get('courses')?.value

                break;
            case 2:
                console.log(this.employerWithOneEmployeeFormGroup);
                (this.employerWithOneEmployeeFormGroup.get('employer') as FormGroup).markAllAsTouched()
                if(this.employerWithOneEmployeeFormGroup.get('employer')?.invalid) {
                    this.error = {
                        message: 'Συμπληρώστε όλα τα στοιχεία υπευθύνου',
                        details: 'Συμπληρώστε το πατρώνυμο του υπευθύνου και επιλέξτε μαθήματα'
                    }
                    this.employerWithOneEmployeeFormGroupTabIndex = 0
                    return;
                }

                if(this.employerWithOneEmployeeFormGroup.get('employee')?.invalid) {
                    this.error = {
                        message: '',
                        details: 'Παρακαλούμε όπως συμπληρώσετε το email του εργαζομένου και επιλέξετε τα μαθήματα που θέλετε να παρακολουθήσει.'
                    }
                    this.employerWithOneEmployeeFormGroupTabIndex = 1
                    return
                }

                requestPayload.personalDetails.sonOf = this.employerWithOneEmployeeFormGroup.get('employer')?.get('sonOf')?.value
                requestPayload.courses = this.employerWithOneEmployeeFormGroup.get('employer')?.get('courses')?.getRawValue()
                requestPayload.employees = [
                    this.employerWithOneEmployeeFormGroup.get('employee')?.getRawValue()
                ]

                break;

            case 3:

                if(this.upToTwoEmployeesFormGroup.get('employee1')?.invalid) {
                    this.error = {
                        message: 'Συμπληρώστε όλα τα στοιχεία 1ου εργαζομένου ',
                        details: 'Συμπληρώστε το email του 1ου εργαζομένου και επιλέξτε μαθήματα'
                    }
                    this.upToTwoEmployeesFormGroupTabIndex = 0;
                    (this.upToTwoEmployeesFormGroup.get('employee1') as FormGroup).markAllAsTouched()

                    return;
                }

                if(this.upToTwoEmployeesFormGroup.get('employee2')?.get('email')?.value != "" && this.upToTwoEmployeesFormGroup.get('employee2')?.get('courses')?.invalid) {
                    this.error = {
                        message: 'Συμπληρώστε όλα τα στοιχεία 2ου εργαζομένου',
                        details: 'Εφόσν εχετε email για τον 2ο εργαζόμενο  πρεπει να επιλέξτε μαθήματα και υποχρεωτικά'
                    }

                    this.upToTwoEmployeesFormGroupTabIndex = 1 ;
                    (this.upToTwoEmployeesFormGroup.get('employee2') as FormGroup).markAllAsTouched();

                    return
                }

                if(this.upToTwoEmployeesFormGroup.get('employee2')?.get('email')?.value == "" && this.upToTwoEmployeesFormGroup.get('employee2')?.get('courses')?.valid) {
                    this.error = {
                        message: 'Συμπληρώστε όλα τα στοιχεία 2ου εργαζομένου',
                        details: 'Εφόσν εχετε επιλέξει μαθήματα για τον 2ο εργαζόμενο  πρεπει να εισάγετε και το email του'
                    }
                    this.upToTwoEmployeesFormGroupTabIndex = 1;
                    (this.upToTwoEmployeesFormGroup.get('employee2') as FormGroup).markAllAsTouched();
                    return
                }


                requestPayload.employees = [
                    this.upToTwoEmployeesFormGroup.get('employee1')?.getRawValue()
                ]
                if(this.upToTwoEmployeesFormGroup.get('employee2')?.get('courses')?.valid){
                    requestPayload.employees.push(this.upToTwoEmployeesFormGroup.get('employee2')?.getRawValue())
                }

                break;
                
        }
        
        this.spinnerService.show('employer-registration')

        this.growYourBusinessService.completeEmployerRegistration(requestPayload)
        .pipe(finalize(() => this.spinnerService.hide('employer-registration')))
        .subscribe({
            next: (response: any) => {
                this.growYourBusinessAnalyticsService.sendEvent('form_completed', {})
                this.growYourBusinessAnalyticsService.facebookTrackCustomEvent('GYB_Eggrafi');
                this.router.navigate(['verify'], { queryParams: { email: requestPayload.email } })
            },
            error: (err => {
                this.error = {
                    message: 'Σφάλμα κατά την εγγραφή',
                    details: err.error.message
                }
                console.log(err)
            })
        })
    }

    stepperGoNext() {
        // auto to kanoume gia na kanei done to step1 kai na kanoyme validate to email

        this.spinnerService.show('employer-registration')
        this.growYourBusinessService.checkEmail(this.employerForm.get('contactDetails.email')?.value as string)
            .pipe( finalize( () => this.spinnerService.hide('employer-registration')))
            .subscribe({
            next: (response: any) => {
                this.myStepper.next();
            },
            error: ( (err) => {
                console.log(err)
                if(err.status == 409){
                    this.employerForm.get('contactDetails.email')?.setErrors({alreadyExists: 'Υπάρχει ηδη εγγραφή με αυτό το email'})
                    this.employerForm.get('contactDetails.email')?.markAsTouched()
                } else if(err.status == 400) {
                    this.employerForm.get('contactDetails.email')?.setErrors({pattern: 'Το email δεν εχει σωστή μορφή'})
                    this.employerForm.get('contactDetails.email')?.markAsTouched()
                }

                setTimeout(() => {
                    this.scrollToFirstInvalidControl();
                });
            })
        })
    }


     //πέρα απο το setValue  πρεπει να κανουμε και το setSelectionRange για να μην χανεται η θεση του cursor
    convertToLowercase(control: AbstractControl<string | null, string | null> | null | undefined, event: any) {
        const input = event.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;

        (control as FormControl).setValue((control as FormControl).value.toLowerCase());

        setTimeout(() => {
            input.setSelectionRange(start, end);
        }, 1);
    }

    // private transformCoursesCheckboxesToIds(coursesFormArray: FormArray, courses: Course[]) {
    //     const courseIds = [];

    //     for (let i = 0; i < coursesFormArray.controls.length; i++) {
    //         const control = coursesFormArray.controls[i];
    //         if (control.value === true) {
    //             courseIds.push(courses[i].courseId);
    //         }
    //     }

    //     return courseIds
    // }

    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = this.formElementRef?.nativeElement.querySelector(
            ".ng-invalid"
        );

        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: "smooth"
        });

        fromEvent(window, "scroll")
            .pipe(
                debounceTime(100),
                take(1)
            )
            .subscribe(() => firstInvalidControl.focus());
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }


    private patchFakeValues() {
        this.employerForm.patchValue({
          personalDetails: {
            name: 'John',
            lastName: 'Doe'
          },
          contactDetails: {
            mobile: '6985789010',
            email: 'johndoe1@example.com'
          },
          vatNumber: '801095580',
          companyName: 'My Company',
          industry: 'IT',
          password: 'kavvadias1',
          passwordConfirmation: 'kavvadias1'
        });
    }
   


}