import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Data } from "@angular/router";
import { map } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MessagesService {

    constructor(private http: HttpClient) {}

    getAllUserMessages() {
        return this.http.get(`${environment.USERS_MESSAGING_API}/messages/all`)
            .pipe(map((data: Data) => data['data']))
    }

    getAllAdminMessage() {
        return this.http.get(`${environment.MESSAGES_ADMIN_API}/messages/all`)
            .pipe(map((data: Data) => data['data']))
    }

    getConversationsFromAllUsers() {
        return this.http.get(`${environment.MESSAGES_ADMIN_API}/conversations`)
        .pipe(map((data: Data) => data['data']))
    }

    startConversation(sendData: {title: string; messages: any;}) {
        return this.http.post(`${environment.USERS_MESSAGING_API}/conversations`, sendData)
            .pipe(map((data: Data) => data['data']))
    }

    adminSendNotificationAsConversation(sendData: {title: string; messages: any;}, userId: string) {
        return this.http.post(`${environment.MESSAGES_ADMIN_API}/conversations/${userId}`, sendData)
            .pipe(map((data: Data) => data['data']))
    }

    updateConversation(conversationId: string, sendData: {latestMessageSeen?: Object[];}) {
        return this.http.put(`${environment.USERS_MESSAGING_API}/conversations/${conversationId}`, sendData)
            .pipe(map((data: Data) => data['data']))
    }

    updateConversationMessages(conversationId: string, sendData: {messages?: Object[];}) {
        return this.http.put(`${environment.USERS_MESSAGING_API}/conversations/${conversationId}/messages`, sendData)
            .pipe(map((data: Data) => data['data']))
    }

    getUserNumberOfNewMessages() {
        return this.http.get(`${environment.USERS_MESSAGING_API}/messages/count/new`)
            .pipe(map((data: Data) => data['data']))
    }

    markNotificationsAsSeen(notificationIds: string[]) {
        return this.http.put(`${environment.USERS_MESSAGING_API}/notifications/seen`, {notificationIds})
            .pipe(map((data: Data) => data['data']))
    }

    notifyAllUsersOnCourse(courseId: string, sendData: any) {
        return this.http.post(`${environment.MESSAGES_ADMIN_API}/notifications/course/${courseId}`, sendData)
            .pipe(map((data: Data) => data['data']))
    }
}