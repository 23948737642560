
<div style="gap: 32px;padding: 48px 32px;" fxLayout="column" fxLayoutAlign="center center" >
    <img style="max-width:460px"  [ngStyle.xs]="{'max-width':'300px'}" src="assets/growYourBusiness@x2.png">
</div>
<div fxLayout="row" fxLayoutAlign="center center" style="padding:0px 0px 20px 0px">
    <span class="cosmote-blue" style="font-size:25px; font-weight:bold">Συχνές Ερωτήσεις</span>
</div>

<div  [ngStyle.gt-sm]="{ 'padding': '0px 110px'}" [ngStyle.sm]="{ 'padding': '0px 24px'}" [ngStyle.xs]="{ 'padding': '0px'}" 
    fxLayout="column" fxLayoutAlign="center center">
    <div style="width:70%" [ngStyle.lt-lg] = "{'width': '100%'}">
        <mat-accordion *ngFor="let faq of faqs">
            <mat-expansion-panel hideToggle (opened)="faq.panelOpenState = true"
            (closed)="faq.panelOpenState = false" >
            <mat-expansion-panel-header style="padding:32px">
                <mat-panel-title fxHide fxShow.gt-xs="true" style="flex-grow: 0 !important; margin-right: 32px;">
                <span class="cosmote-blue" style="font-size:36px; font-weight:600; line-height: 44px; font-style: normal;"
                >{{faq.title}}</span>
                </mat-panel-title>
                <mat-panel-description fxFlex >
                <span class="cosmote-blue" style="font-size: 20px;font:bold 18px / 1.2em arial;
                line-height: 30px;">{{faq.description}}</span>
                </mat-panel-description>
                <!-- <mat-icon fxHide fxShow.gt-xs="true"  class="custom-icon-outline" *ngIf="faq.panelOpenState" 
                style="flex-grow: 0 !important; width:38px; height:38px; color:#8D8BA7"> close</mat-icon>
                <mat-icon fxHide fxShow.gt-xs="true" class="custom-icon-outline" *ngIf="!faq.panelOpenState" 
                style="flex-grow: 0 !important; width:38px; height:38px; color:#8D8BA7"> add</mat-icon> -->

            </mat-expansion-panel-header>
            <p class="cosmote-blue" *ngIf="faq.text" style="font:19px / 1.2em arial !important" [innerHtml]="faq.text"></p>
            <div class="cosmote-blue"  *ngIf="faq.html" style="font:19px / 1.2em arial !important" [innerHtml]="faq.html"></div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>  