import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Course, CourseEnrollment, CourseLesson, LessonTest } from '../shared/models/course.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CoursesService } from '../services/courses.service';
import { LogsService } from '../services/logs.service';


declare const videojs: any;

interface LessonConfiguration {
    videoSelectedIndex: number;         // to index του τρέχοντος video που παίζει
    test?: {
        result?: 'fail' | 'success';    // αν ο χρήστης έχει περάσει το τεστ της ενότητας
        currentQuestionIndex: number;   // to index της τρέχουσας ερωτησης
        stillHasAttempts?: number;      // αν ο χρήστης έχει ακόμα προσπάθειες για το τεστ της ενότητας
        maxAttempts?: number            // το συνολικό αριθμό προσπαθειών που μπορεί να κάνει ο χρήστης για το τεστ της ενότητας
    }
}

interface ExamConfiguration {
    currentQuestionIndex: number;       //to index της τρέχουσας ερωτησης στην τελική εξέταση
    result?: 'fail' | 'success';        // το αποτέλεσμα της τελικής εξέτασης
    stillHasAttempts?: number;          // αν ο χρήστης έχει ακόμα προσπάθειες για την τελική εξέταση
    maxAttempts: number                 // το συνολικό αριθμό προσπαθειών που μπορεί να κάνει ο χρήστης για την τελική εξέταση
}

@Component({
  selector: 'app-my-course',
  template: '',
})
export class MyCourseComponent implements OnInit, AfterViewInit {

    public course!: Course;                                     // το course το τωρινό
    public courseLessons!: CourseLesson[];                      // τα lessons του τωρινού course
    public courseEnrollment!: CourseEnrollment;                 // to course enrollment του τωρινού course του χρήστη
    public currentLessonIndex: number = 0;                      // το index του τρέχοντος lesson
    public selectedTabIndex: number = 0;                        // το index του τρέχοντος tab
    public selectedLesson!: CourseLesson;                       // το τρέχον lesson που καθορίζεται απο το tab index
    public nextLesson !: CourseLesson | null;                   // to επόμενο lesson που θα πραγματοποιηθεί
	public lessonsConfiguration: LessonConfiguration[] = [];    // εδω κρατάμε τα configuration για όλα τα lessons
    public selectedLessonConfiguration!: LessonConfiguration    // εδώ είναι το configuration του τρέχοντος lesson
    public otherResourcesGridImplementation = true;             // αν θα χρησιμοποιηθεί το grid implementation για τα other resources                     
    public examConfiguration!: ExamConfiguration     
    public lessonTestAnswers!: FormArray<FormGroup<any>> | null;           // form array για τις απαντήσεις του τεστ της ενότητας
    public examAnswers!: FormArray<FormGroup<any>> | null;                 // form array για τις απαντήσεις της τελικής εξέτασης
    public options: any;                                        // τα options του videojs
    public videoPlayers: any[] = [];                            // τα video players του videojs.Εχουμε ενα για καθε lesson
    public questionIsLoading = false;                           // αν η ερώτηση του τεστ ειναι σε φορτωση

    public user: any;

    // patenta
    public asyncVideoIndex: any

    @ViewChildren('videoPlayer') videoPlayerList!: QueryList<ElementRef>;
    @ViewChild('group', {static: false}) matGroupElement!: ElementRef

    constructor(
        private route: ActivatedRoute,
        private courseService: CoursesService,
        private breakpointObserver: BreakpointObserver,
        private logsService: LogsService
    ) { }

    ngOnInit() {
        //patenta
        this.asyncVideoIndex = 0;
        // καθορίζουμε πως θα φαίνονται τα other resources ανάλογα με το breakpoint
        this.breakpointObserver.observe([
			Breakpoints.XSmall,
			Breakpoints.Small,
        ]).subscribe(result => {
            if(result.matches) {
                this.otherResourcesGridImplementation = false;
            }
        });

        this.route.parent?.data.subscribe(
            (parentData) => {
                this.user = parentData['user']
            }
        )

        this.route.data.subscribe(
            (data: any) => {
                console.log(data)
                
                this.course           = data['course']
                this.courseEnrollment = data['courseEnrollment']
                this.courseLessons    = data['course'].lessons || [];
                this.selectedLesson   = this.courseLessons[this.currentLessonIndex]

                if(this.course.type != 'async') {
                    this.logsService.trackEventForCourse('CourseViewed', this.course.courseId);
                }

                // edw na steilw to request


                if(!this.courseEnrollment.lessons) {
                    this.courseEnrollment.lessons = {}
                }

                //////// auta einai gia test////////
                // this.courseLessons[3].disabled = false;

                // Δημιουργούμε το αρχικό configuration για κάθε lesson
                for (let lesson of this.courseLessons) {

                    let config: any = {
                        videoSelectedIndex: 0,
                    }
                    if(lesson.sections?.test) {
                        config['test'] = {}
                        // Αν ο χρήστης εχει δώσει το τεστ της ενότητας τότε θα πρέπει να εμφανίζεται το αποτέλεσμα του
                        if(this.courseEnrollment?.lessons?.[lesson.id]?.test?.result) {
                            config['test'].result = this.courseEnrollment?.lessons?.[lesson.id]?.test?.result;
                        }
                        /*
                        * Κοιτάμε πόσες προσπάθειες εχει το τεστ της ενότητας
                        * αν το τεστ στη συγκεκριμένη ενότητα εχει συγκεκριμένο αριθμό προσπαθειών τότε πρεπει να καταγράψουμε
                        * πόσες εχει κανει ο χρήστης και πόσες του μένουν ακόμα
                        **/ 
                        config['test'].maxAttempts =  lesson.sections?.test?.maxAttempts
                        if(config['test'].maxAttempts != - 1) {
                            let currentAttempts = this.courseEnrollment?.lessons?.[lesson.id]?.test?.attempts || 0
                            config['test'].stillHasAttempts = config['test'].maxAttempts  - currentAttempts; 
                        }
                    }

                    this.lessonsConfiguration.push(config)
                }
                if(this.course.exam) {
                    this.examConfiguration = {
                        currentQuestionIndex: 0,
                        maxAttempts: this.course.exam.maxAttempts,
                    }
                    // αν ο χρήστης εχει δώσει το τελικό διαγώνισμα θα πρεπει να εμφανίζεται το αποτέλεσμα του
                    if(this.courseEnrollment.exam?.result) {
                        this.examConfiguration.result = this.courseEnrollment.exam.result;
                    }
                    /**
                     * Κοιτάμε πόσες προσπάθειες εχει το τελικό διαγώνισμα
                     * αν το τελικό διαγώνισμα εχει συγκεκριμένο αριθμό προσπαθειών τότε πρεπει να καταγράψουμε
                     * πόσες εχει κανει ο χρήστης και πόσες του μένουν ακόμα
                     */
                    if(this.examConfiguration.maxAttempts != -1) {
                        let attemptsForExam = this.courseEnrollment.exam?.attempts || 0
                        this.examConfiguration.stillHasAttempts =  (this.course?.exam?.maxAttempts as number) - attemptsForExam; 
                    }
                }

                console.log(this.lessonsConfiguration)
                
                this.selectedLessonConfiguration = this.lessonsConfiguration[this.currentLessonIndex]
                console.log(this.courseLessons);
                this.nextLesson = this.courseLessons.find( (lesson) => lesson.disabled == false ) || null;
                console.log(this.nextLesson)

                this.afterCourseResolved(data);
            }
        )

        this.options = {
            controlBar: {
                playToggle: true,
                captionsButton: false,
                pictureInPictureToggle: false,
                chaptersButton: false,
                remainingTimeDisplay: false,
                progressControl: {
                    seekBar: true
                },
                fullscreenToggle: true,
            },
            autoplay: false,
            controls: true,
            preload: 'metadata',
        };
    };

    public afterCourseResolved(data: any) {

    }

    /**
     * Αφου δημιουργηθεί το view αρχικοποιούμε ενα video player για κάθε lesson
     * Επίσης για τωρινό lesson που έχουμε επιλέξει αναθέτουμε το πρώτο βίντεο της ενότητας
     */
    ngAfterViewInit() {

        this.videoPlayerList.toArray().forEach( (videoPlayer) => {
            this.videoPlayers.push(videojs(videoPlayer.nativeElement, this.options));
        })

        if(this.selectedLesson?.sections?.videos && this.selectedLesson?.sections?.videos?.length > 0) {
            this.videoPlayers[this.currentLessonIndex].src({
                src: this.selectedLesson.sections?.videos?.[0].url,
                type: this.selectedLesson.sections?.videos?.[0].type || 'video/mp4'
            });
        } else if (this.course.asyncSection?.videos) {
            // patenta
            this.videoPlayers[0].src({
                src: this.course.asyncSection.videos[0].url,
                type: this.course.asyncSection.videos[0].type || 'video/mp4'
            });
        }

        console.log(this.videoPlayers)
    }

    /**
     * Όταν αλλάζουμε tab ενημερώνουμε τα index και  βαζουμε στο καταλληλο video player 
     * το πρώτο βίντεο της ενότητας
     */
    onTabChanged(event: any) {
        this.selectedTabIndex = event.index;

        // τότε επιλέξαμε το tab του exa,
        if(event.index >= this.courseLessons.length) {
            console.log('edw');
            return;
        }


        this.selectedLesson = this.courseLessons[event.index]
        this.currentLessonIndex = event.index;
        this.selectedLessonConfiguration = this.lessonsConfiguration[this.currentLessonIndex]

        if(this.selectedLesson.sections?.videos &&  this.selectedLesson.sections?.videos?.length > 0) {
            this.videoPlayers[this.currentLessonIndex].src({

                src: this.selectedLesson.sections?.videos?.[0].url,
                type: this.selectedLesson.sections?.videos?.[0].type
            });
            this.videoPlayers[this.currentLessonIndex].poster(this.selectedLesson.sections?.videos?.[0].thumbnail);
        }

    }

    videosSectionExist(selectedLesson: CourseLesson) {
        if(selectedLesson?.sections?.videos && selectedLesson?.sections?.videos.length > 0) {
            return true;
        }
        return false
    }

    otherResourcesSectionExist(selectedLesson: CourseLesson) {
        if(selectedLesson?.sections?.otherResources && selectedLesson?.sections?.otherResources.length > 0) {
            return true;
        }
        return false
    }

   

    OnChangeVideo(video: any, videoIndex: number) {
        if(videoIndex == this.selectedLessonConfiguration.videoSelectedIndex) return;
        this.selectedLessonConfiguration.videoSelectedIndex = videoIndex;
        this.videoPlayers[this.currentLessonIndex].src({
            src: video.url,
            type: 'video/mp4'
        });
        this.videoPlayers[this.currentLessonIndex].poster(video.thumbnail);
    }

    // patenta
    onChangeAsyncVideo(video: any, videoIndex: number) {
        this.asyncVideoIndex = videoIndex;
        this.videoPlayers[0].src({
            src: video.url,
            type: 'video/mp4'
        });

    }

    /////////////////////////// ΜΕΘΟΔΟΙ ΓΙΑ ΤΗΝ ΑΞΙΟΛΟΓΗΣΗ ΕΝΟΤΗΤΑΣ ////////////////////////

    onBeginTest() {
        this.lessonTestAnswers = new FormArray([] as any);
        this.selectedLessonConfiguration.test!.currentQuestionIndex = 0;
        delete this.selectedLessonConfiguration.test!.result;
        for (let question of this.selectedLesson?.sections.test.questions) {
            this.lessonTestAnswers.push(new FormGroup({
                'questionId': new FormControl(question.id),
                'answerId': new FormControl('', Validators.required),
                'questionName': new FormControl(question.text)
            }));
        }

        this.logsService.trackEventForCourse('TestAttempted', this.course.courseId, {
            lessonId: this.selectedLesson.id
        });
    }

    onSubmitTest() {
        this.courseService.submitTestAnswers(this.course.courseId, this.selectedLesson.id, this.lessonTestAnswers?.value as any[]).subscribe({
            next: (response: any) => {
                this.selectedLessonConfiguration.test!.result = response.lessons?.[this.selectedLesson.id]?.test?.result;
                this.courseEnrollment = response;
                this.lessonTestAnswers = null;

                if(this.selectedLessonConfiguration.test!.maxAttempts != -1) {
                    if(this.selectedLessonConfiguration.test!.stillHasAttempts && this.selectedLessonConfiguration.test!.stillHasAttempts > 0) {
                        this.selectedLessonConfiguration.test!.stillHasAttempts -= 1;
                    }
                }
            }
        })
    }

    userCanStillTakeTestForLimitedAttempts() {
        return this.selectedLessonConfiguration.test!.stillHasAttempts && this.selectedLessonConfiguration.test!.stillHasAttempts > 0
    }


    /** 
     * To τεστ αποτυγχάνει εξολοκλήρουμε  όταν οι προσπάθειες δεν είναι απεριόριστες και δεν έχουμε αλλες προσπάθειες να κάνουμε 
     * Αυτό ταυτόχρονα σημαίνει οτι την τελευταία φορά που καναμε το τεστ είχαμε αποτύχει
    */
    noMoreAttempsForThisTest(selectedLessonConfiguration: any) {
        if (selectedLessonConfiguration.test.result == 'fail') {
            // ειναι απεριόριστες η προσπάθειες
            if(selectedLessonConfiguration.test.maxAttempts == -1) {
                return false
            } else {
                return selectedLessonConfiguration.test.stillHasAttempts <= 0
            }
        }
        return false
    }



    testFailedPartially(selectedLessonConfiguration: any) {

        if (selectedLessonConfiguration.test.result == 'fail') {
            // ειναι απεριόριστες η προσπάθειες
            if(selectedLessonConfiguration.test.maxAttempts == -1) {
                return true
            } else {
                return selectedLessonConfiguration.test.stillHasAttempts > 0
            }
        }
        return false
    }



    getTestPartialFailMessage() {
        if (this.selectedLessonConfiguration.test!.maxAttempts == -1) {
            return 'Έχεις ακόμα όσες προσπάθειες θες και και είμαστε σίγουροι πως μπορείς να τα πας καλύτερα'
        } else {
            let attemptMessage = this.selectedLessonConfiguration.test?.stillHasAttempts == 1 ? 'προσπάθεια' : 'προσπάθειες'
            return `Έχεις ακόμα ${this.selectedLessonConfiguration.test!.stillHasAttempts}  ${attemptMessage} και είμαστε σίγουροι πως μπορείς να τα πάς καλύτερα`
        }
    }
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////// ΚΟΙΝΕΣ ΜΕΘΟΔΟΙ ///////////////////////////////////////
    onCalculateProgressPercentageFor(type: 'test' | 'exam' = 'test') {
        if(type == 'test') {
            return Math.round((this.selectedLessonConfiguration.test!.currentQuestionIndex + 1) / this.selectedLesson?.sections.test.questions.length * 100);
        } else if(type == 'exam') {
            return Math.round((this.examConfiguration.currentQuestionIndex + 1) / (this.course.exam?.questions.length as number) * 100);
        }
        return;
    }

    onPreviousQuestion(type: 'test' | 'exam' = 'test') {
        if(type == 'test') {
            if (this.selectedLessonConfiguration.test!.currentQuestionIndex > 0) {
                this.questionIsLoading = true;
                this.selectedLessonConfiguration.test!.currentQuestionIndex--;
                this.courseService.slackNotify({
                    type: "previous question",
                    index: this.selectedLessonConfiguration.test!.currentQuestionIndex,
                    question: this.lessonTestAnswers!.at(this.selectedLessonConfiguration.test!.currentQuestionIndex).get('questionName')?.value,
                    answers: JSON.stringify(this.selectedLesson.sections.test.questions[this.selectedLessonConfiguration.test!.currentQuestionIndex].answers)
                }).subscribe();
                setTimeout(() => {
                    this.questionIsLoading = false;
                }, 800)
            }
        } else if (type == 'exam') {
            if(this.examConfiguration.currentQuestionIndex > 0) {
                this.questionIsLoading = true;
                this.examConfiguration.currentQuestionIndex--;
                this.courseService.slackNotify({
                    type: "previous question",
                    index : this.examConfiguration.currentQuestionIndex,
                    question: this.examAnswers!.at(this.examConfiguration.currentQuestionIndex).get('questionName')?.value,
                    answers: JSON.stringify(this.course.exam?.questions?.[this.examConfiguration.currentQuestionIndex]?.answers),
                }).subscribe();
                setTimeout(() => {
                    this.questionIsLoading = false;
                }, 800)
            }
        }
    }

    onNextQuestion(type: 'test' | 'exam' = 'test') {
        if(type == 'test') {
            if (this.selectedLessonConfiguration.test!.currentQuestionIndex < this.selectedLesson?.sections.test.questions.length - 1) {
                this.questionIsLoading = true;
                this.selectedLessonConfiguration.test!.currentQuestionIndex++;
                this.courseService.slackNotify({
                    type: "next question",
                    index: this.selectedLessonConfiguration.test!.currentQuestionIndex,
                    question: this.lessonTestAnswers!.at(this.selectedLessonConfiguration.test!.currentQuestionIndex).get('questionName')?.value,
                    answers: JSON.stringify(this.selectedLesson.sections.test.questions[this.selectedLessonConfiguration.test!.currentQuestionIndex].answers)
                }).subscribe();
                setTimeout(() => {
                    this.questionIsLoading = false;
                }, 800)

            }
        } else if (type == 'exam') {
            if(this.examConfiguration.currentQuestionIndex < (this.course.exam?.questions?.length as number) - 1) {
                this.questionIsLoading = true;
                this.examConfiguration.currentQuestionIndex++;
                this.courseService.slackNotify({
                    type: "next question",
                    index : this.examConfiguration.currentQuestionIndex,
                    question: this.examAnswers!.at(this.examConfiguration.currentQuestionIndex).get('questionName')?.value,
                    answers: JSON.stringify(this.course.exam?.questions?.[this.examConfiguration.currentQuestionIndex]?.answers),
                }).subscribe();
                setTimeout(() => {
                    this.questionIsLoading = false;
                }, 800)

            }
        }

    }
    ///////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////// ΜΕΘΟΔΟΙ ΓΙΑ ΤΟ ΤΕΛΙΚΟ ΔΙΑΓΩΝΙΣΜΑ ////////////////////////

    onBeginExam() {
        this.examAnswers = new FormArray([] as any);
        this.examConfiguration.currentQuestionIndex = 0;
        delete this.examConfiguration.result;

        for (let question of this.course.exam?.questions as any[]) {
            this.examAnswers.push(new FormGroup({
                'questionId': new FormControl(question.id),
                'answerId': new FormControl('', Validators.required),
                'questionName': new FormControl(question.text)
            }));
        }
        this.logsService.trackEventForCourse('ExamAttempted', this.course.courseId);
    }

    onSubmitExam() {
    
        this.courseService.submitExamAnswers(this.course.courseId, this.examAnswers?.value as any[]).subscribe({
            next: (response: any) => {
                console.log(response)
                this.examConfiguration.result = response.exam?.result;
                this.examAnswers = null
                // η προσπάθειες δεν ειναι απεριόριστες
                if(this.examConfiguration.maxAttempts != -1) {
                    if(this.examConfiguration.stillHasAttempts && this.examConfiguration.stillHasAttempts > 0) {
                        this.examConfiguration.stillHasAttempts -= 1;
                    }
                }
            }
        })
    }

    /**
     * Δεν έχουμε αλλες προσπάθειες για το διαγώνισμα. Αυτό επίσης σημαίνει οτι η τελευταία μας προσπάθεια ήταν αποτυχημένη
     */
    noMoreAttemptsForExam(examConfiguration: any) {

        if (examConfiguration?.result == 'fail') {
            if(examConfiguration.maxAttempts == -1) {
                return false
            } else {
                return examConfiguration.stillHasAttempts <= 0
            }
        }


        return false
    }

    examFailedPartially(examConfiguration: any) {

        if (examConfiguration?.result == 'fail') {
            if(examConfiguration.maxAttempts == -1) {
                return true
            } else {
                return examConfiguration.stillHasAttempts > 0
            }
        }
        return false
    }
    ////////////////////////////////////////////////////////////////////////////////////////////

    // ΑΥΤΟ ΜΠΟΡΕΙ ΝΑ ΓΙΝΕΤΑΙ ΠΙΟ ΕΥΚΟΛΑ
    formatDateToDayMonth(date: string | number | null) {

        if(date == null) return;

        let dateObject = null;
        if(typeof date == 'string') {
            dateObject = new Date(date);
        }

        if(typeof date == 'number') {
            dateObject = new Date(date * 1000);
        }

        if(dateObject == null) return;

        let  months= ['Ιανουαρίου', 'Φεβρουαρίου', 'Μαρτίου','Απριλίου','Μαίου','Ιουνίου','Ιουλίου','Αυγούστου','Σεπτεμβρίου','Οκτωβρίου','Νοεμβρίου','Δεκεμβρίου']
        let month = months[dateObject.getMonth()];
        let day = dateObject.getDate();
        let year = dateObject.getFullYear()
        return  day + ' ' + month + ' ' + year;
    }

    formatLessonTimeFromTo(startsAt:number, endsAt: number) {

        let startsAtDate = new Date(startsAt * 1000).toLocaleTimeString('el-GR', { hour: "2-digit", minute: "2-digit", hour12:false })
        let endsAtDate = new Date(endsAt * 1000).toLocaleTimeString('el-GR', { hour: "2-digit", minute: "2-digit", hour12:false })

        return startsAtDate + ' με ' + endsAtDate;
    }

    /**
     * Αυτό το function καθορίζει αν το κουμπί που πατάς για να ξεκινήσεις το τεστ η το διαγώνισμα  είναι disabled η όχι
     * @param type 
     * @returns 
     */
    buttonDisabled(type: 'test' | 'exam') {

        // 1. Αρχικά αν υπάρχουν lessonTestAnswers τότε το τεστ τρέχει αυτή τη στιγμή οπότε ειναι disabled το κουμπί
        // 2. Αν έχουμε περάσει το τεστ τότε παλι πρέπει να ειναι disabled
        // 3. Αν έχουμε κλείσει χειροκίνητα το τέστ τότε παλι πρεπει να ειναι κλειστό
        // 4. Αν δεν εχουμε αλλες προσπάθειες για το τεστ
        if(type == 'test') {
            if(this.lessonTestAnswers) return true
            if(this.selectedLessonConfiguration.test?.result == 'success') return true;
            if(this.selectedLesson.sections.test?.closed) return true
            if(this.noMoreAttempsForThisTest(this.selectedLessonConfiguration)) return true;
        }

        // ΣΗΜΕΙΩΣΗ ---> ΓΙΑ ΤΩΡΑ ΔΕΝ ΛΑΜΒΑΝΟΥΜΕ ΥΠΟΨΗΝ ΤΗΝ ΗΜΕΡΟΜΗΝΙΑ ΠΟΥ ΚΛΕΙΝΕΙ ΤΟ ΔΙΑΓΩΝΙΣΜΑ ΓΙΑ ΝΑ ΤΟ ΚΛΕΊΝΟΥΜΕ


        // 1. Αρχικά αν υπάρχουν examAnswers τότε τότε το διαγώνισμα τρέχει αυτή τη στιγμή οπότε ειναι disabled το κουμπί
        // 2. Αν έχουμε περάσει το διαγώνισμα τότε παλι πρέπει να ειναι disabled
        // 3. Αν υπάρχει επόμενο μάθημα τότε το διαγώνισμα δεν μπορεί να ανοίξει ακόμα
        // 4. Αν το διαγώνισμα έχει κλείσει χειροκίνητα
        // 5. Αν έχουμε εξαντλήσει τις προσπάθειες για το διαγώνισμα
        if(type == 'exam') {
            if(this.examAnswers) return true
            if(this.examConfiguration?.result == 'success') return true;
            if(this.nextLesson) return true;
            if(this.course.exam?.closed) return true;
            if(this.noMoreAttemptsForExam(this.examConfiguration)) return true;
        }
        return false;
    }  

    lessonHasStarted(lesson: CourseLesson) {
       return lesson.startsAt <= Math.floor( Date.now() / 1000)
    }

    lessonCompleted(lesson: CourseLesson) {
        if(this.lessonIsAsync(lesson)) {
            return this.courseEnrollment.lessons[lesson.id]?.test?.result == 'success'
        } else {
            return lesson.endsAt < Math.floor( Date.now() / 1000)
        }
    }

    getLessonStatusText(lesson: CourseLesson) {
        if(this.lessonCompleted(lesson)) {
            return 'Ολοκληρώθηκε'
        } else {
            return 'σε εξέλιξη'
        }
    }
    
    textThatShouldDisplayForExam(): string {
        if(this.nextLesson) {
            return 'To Διαγώνισμα θα είναι διαθέσιμο μόλις ολοκληρωθεί το μάθημα'
        } else if(this.course.exam && this.course.exam.expirationDate >= Math.floor(Date.now()/ 1000)) {
            return 'Η προθεσμία ολοκλήρωσης του διαγωνίσματος λήγει ' + this.formatDateToDayMonth(this.course.exam?.expirationDate as number)
        } else {
            return 'Η προθεσμία ολοκλήρωσης εληξε  ' + this.formatDateToDayMonth(this.course.exam?.expirationDate as number)
        }
    }

    onDisplayTestAttemptsForSelectedLesson() {
        return `Προσπάθεια`  
    }

    onDownloadResource(resource: any) {
        this.logsService.trackEventForCourse('Resource Downloaded', this.course.courseId, {
            resource: resource
        })
        window.location.href = resource.url
    }

    onDisplayNextLessonDate() {
        if(this.nextLesson?.startsAt) {
            return new Date(this.nextLesson?.startsAt * 1000).toLocaleDateString('el-GR', {  month: 'long', day: '2-digit' })
                + ' '
                + new Date(this.nextLesson?.startsAt * 1000).toLocaleTimeString('el-GR', { hour: "2-digit", minute: "2-digit", hour12:false })
        }

        return ''
    }

    private lessonIsAsync(lesson: CourseLesson) {
        return lesson.sections?.test?.questions?.length > 0;
    }
        
}
