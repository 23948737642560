import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Data } from "@angular/router";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LogsService {

    constructor(private http: HttpClient) { }

    trackEvent(eventType: string, details?: any) {
        const apiUrl = environment.TRACK_EVENTS_API;
        let payload: any = {eventType}
        if(details) {
            payload.properties = details
        }
        return this.http.post(apiUrl, payload).subscribe();
    }

    trackEventForCourse(eventType: string, courseId: string, details?: any) {
        const apiUrl = environment.TRACK_EVENTS_API;
        let payload = {
            eventType , properties: {
                courseId
            }
        };
        if(details) {
            payload.properties = {...payload.properties, ...details}
        }
        return this.http.post(apiUrl, payload).subscribe();
    }

}