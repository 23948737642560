import { Component, Input } from "@angular/core";
import { FooterComponent } from "src/app/common/components/footer.component";
import { GrowYourBusinessCookieDialogComponent } from "../../cookie-dialog/grow-your-business-cookie-dialog.component";

@Component({
    selector: 'app-footer',
    templateUrl: './grow-your-business-footer.component.html',
})
export class GrowYourBusinessFooterComponent extends FooterComponent {
    @Input()
    public cookieComponent!: GrowYourBusinessCookieDialogComponent;
  
    showCookieDialog() {
      this.cookieComponent.showCookieDialog();
    }
}