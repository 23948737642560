<div fxLayout="column" class="header-layout" fxLayoutAlign="center center"
    style="position:fixed; top:0; background-color: white; width:100%; z-index:2">
    <div fxLayout="row" fxLayoutAlign="start space-between" class="header-inner-container" fxHide fxShow.gt-md="true"
        style="width:100%; padding:10px 8px; gap: 12px;">
        <div class="header-content" fxLayout="row" fxLayoutAlign="start center" fxFlex>
            <div style="padding-right:12px;display: flex;align-items: center;">
                <img src="/assets/cosmote-logo.webp" style="max-height:61px; cursor:pointer; margin-right:12px"
                    routerLink="/growyourbusiness" [ngStyle.md]="{'max-height':'52px'}">
                <!-- <img src="/assets/t_group_logo.jpeg" style="max-height:22px; cursor:pointer; margin-right:12px"
                    routerLink="/growyourbusiness"> -->
                <img src="/assets/logo_eltrun.webp" style="max-height:61px; cursor:pointer"
                    routerLink="/growyourbusiness" [ngStyle.md]="{'max-height':'52px'}">
            </div>
            <div fxFlex></div>
            <nav mat-tab-nav-bar color="primary" class="navigation" fxLayout="row" fxHide fxShow.gt-sm="true"
                [tabPanel]="tabPanel">
                <span mat-tab-link style="cursor: pointer;font-weight:500;line-height:24px; font-style:normal;"
                    [ngStyle.md]="{'padding-left':'12px', 'padding-right':'12px'}"
                    *ngFor="let routeLink of routeLinks; let i = index;" [routerLink]="routeLink.link"
                    routerLinkActive="activeLink" #rla="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }">
                    {{routeLink.label}}
                </span>
            </nav>
            <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
        </div>

        <div></div>
        <ng-container *ngIf="!isLoggedInUser; else loggedIn">
            <div fxLayout="row" style="gap: 12px;" fxLayoutAlign="start center">
                <button mat-button routerLink="/growyourbusiness-login"
                    style="padding:10px 18px; font-size:16px; font-weight:600; color: var(--gray-500, #667085);">Σύνδεση</button>
                <button mat-raised-button color="success" routerLink="/growyourbusiness-register"
                    style="padding:10px 20px; font-weight:700">Εγγραφή</button>
            </div>
        </ng-container>
        <ng-template #loggedIn>
            <div fxLayout="row" fxLayoutAlign="start center" style="gap: 12px;">
                <!-- <button fxHide fxShow.gt-sm="true" mat-button (click)="onLogout()">Αποσύνδεση</button> -->
                <div matBadgeOverlap="true" [matBadge]="messageCount" matBadgeSize="medium" matBadgePosition="below"
                    [matBadgeHidden]="messageCount == 0" matBadgeColor="primary">
                    <img src="/assets/user.png" style="cursor: pointer;" [matMenuTriggerFor]="hamburger_menu_profile">
                </div>
            </div>
        </ng-template>

    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="header-inner-container"
        style="width:100%; padding:10px 8px" fxHide fxShow.lt-lg="true">
        <div class="header-content" fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="row" fxFlex fxLayoutAlign="start center">
                <div style="width: 190px">
                    <img src="/assets/cosmote-logo.webp" style="cursor:pointer; margin-right:12px;width: 100%;"
                        routerLink="">
                    <!-- <img src="/assets/t_group_logo.jpeg" style="width: 70px;
                    position: relative;
                    left: 139px;
                    bottom: 12px;" routerLink=""> -->
                </div>

                <img fxFlex="48" src="/assets/logo_eltrun.webp" style="max-height:61px; cursor:pointer" routerLink="">
            </div>
            <nav mat-tab-nav-bar color="primary" class="navigation" fxLayout="row" fxHide fxShow.gt-md="true"
                [tabPanel]="tabPanel">
                <span mat-tab-link style="cursor: pointer;font-weight:500;line-height:24px; font-style:normal "
                    *ngFor="let routeLink of routeLinks; let i = index;" [routerLink]="routeLink.link"
                    routerLinkActive="activeLink" #rla="routerLinkActive">
                    {{routeLink.label}}
                </span>

            </nav>
            <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
        </div>



        <div fxLayout="row" fxLayoutAlign="start center" style="gap: 12px;" fxLayoutGap.xs="8px">


            <button mat-icon-button [matMenuTriggerFor]="hamburger_menu">
                <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #hamburger_menu="matMenu" class="user-menu">
                <span mat-menu-item class="gray-700" style="font-size:14px;padding: 10px 16px"
                    *ngFor="let routeLink of routeLinks; let i = index;" [routerLink]="routeLink.link">
                    {{routeLink.label}}
                </span>
                <mat-divider *ngIf="!isLoggedInUser;"></mat-divider>
                <span mat-menu-item *ngIf="!isLoggedInUser;" class="gray-700" style="font-size:14px;padding: 10px 16px"
                    routerLink="/growyourbusiness-login">Σύνδεση</span>
                <span mat-menu-item *ngIf="!isLoggedInUser;" class="gray-700" style="font-size:14px;padding: 10px 16px"
                    color="primary" routerLink="/growyourbusiness-register" routerLinkActive="activeLink"
                    #rla="routerLinkActive">
                    Εγγραφή</span>
                <!-- <span  mat-menu-item *ngIf="isLoggedInUser;" class="gray-700" style="font-size:14px;padding: 10px 16px"  (click)="onLogout()">Αποσύνδεση</span> -->

            </mat-menu>

            <div *ngIf="isLoggedInUser" matBadgeOverlap="true" [matBadge]="messageCount" matBadgeSize="medium"
                matBadgePosition="below" [matBadgeHidden]="messageCount == 0" matBadgeColor="primary">
                <img src="/assets/user.png" style="cursor: pointer;" [matMenuTriggerFor]="hamburger_menu_profile">
            </div>

        </div>
    </div>
</div>

<div style="height:88px"></div>

<mat-menu #hamburger_menu_profile="matMenu" class="user-menu">
    <!-- <span  mat-menu-item class="gray-700" style="font-size:14px;padding: 10px 16px"  *ngFor="let extraMenu of extraMenus" 
        [routerLink]="extraMenu.link">{{extraMenu.label}}</span> -->
    <span mat-menu-item class="gray-700" style="font-size:14px;padding: 10px 16px" routerLink="ask-a-question">Κάνε μία
        Ερώτηση</span>
    <span mat-menu-item class="gray-700" style="font-size:14px;padding: 10px 16px" routerLink="my-profile">Ρυθμίσεις
        Προφίλ</span>
    <span mat-menu-item class="gray-700" style="font-size:14px;padding: 10px 16px"
        (click)="onLogout()">Αποσύνδεση</span>
</mat-menu>