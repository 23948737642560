<app-spinner name="contactUs"></app-spinner>
<div style="padding: 48px 32px;" fxLayout="column" fxLayoutAlign="center center">
    <img style="max-width:460px" [ngStyle.xs]="{'max-width':'300px'}" src="assets/growYourBusiness@x2.png">
</div>
<div fxLayout="column" fxLayoutAlign="center center" style="gap: 32px;padding: 0px 16px 32px 16px;">
    <span class="cosmote-blue" style="font-size:28px; font-weight:bold">Επικοινωνία</span>
</div>

<div fxLayout="column" fxLayoutAlign="start center" style="gap: 64px;padding:0px 0px 96px;">
    <div [ngStyle.gt-xs]="{'padding':'0px 32px'}" fxLayout="column" fxLayoutAlign="start center" style="gap: 64px;">
        <div  fxLayout="column" fxLayoutAlign="center center" style="gap: 64px;padding:0px;">
            <form fxLayout="column" fxLayoutAlign="start center" [formGroup]="contactUsForm" style="width:420px"
                [ngStyle.xs]=" {'width':'280px'}">
                <div fxLayout="column" fxLayoutAlign="start start" [ngStyle.xs]=" {'width':'280px'}" style="width:100%">
                    <div fxLayout="row" fxLayout.xs="column"  style="gap: 32px;width:100%" fxLayoutGap.xs="0px">
                        <div fxLayout="column" style="width:100%" fxFlex>
                            <span class="cosmote-blue"
                                style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Όνομα*</span>
                            <input formControlName="name" type="text" class="input" matInput
                                [ngClass]=" {'input-error': contactUsForm.get('name')?.invalid && contactUsForm.get('name')?.touched}">
                            <div style="height:16px; padding:0px 12px 8px">
                                <span class="error-message"
                                    *ngIf="contactUsForm.get('name')?.hasError('required') && contactUsForm.get('name')?.touched">
                                    Το πεδίο ειναι υποχρεωτικό
                                </span>
                            </div>
                        </div>
                        <div fxLayout="column" style="width:100%" fxFlex>
                            <span class="cosmote-blue"
                                style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Επίθετο*</span>
                            <input formControlName="lastName" type="text" class="input" matInput
                                [ngClass]=" {'input-error': contactUsForm.get('lastName')?.invalid && contactUsForm.get('lastName')?.touched}">
                            <div style="height:16px; padding:0px 12px 8px">
                                <span class="error-message"
                                    *ngIf="contactUsForm.get('lastName')?.hasError('required') && contactUsForm.get('lastName')?.touched">
                                    Το πεδίο ειναι υποχρεωτικό
                                </span>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" style="width:100%">
                        <span class="cosmote-blue"
                            style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Email*</span>
                        <input formControlName="email" type="text"
                            (input)="convertToLowercase(contactUsForm.get('email'), $event)"
                            [ngClass]=" {'input-error': contactUsForm.get('email')?.invalid && contactUsForm.get('email')?.touched}"
                            class="input" matInput>
                        <div style="height:16px; padding:0px 12px 8px">
                            <span class="error-message"
                                *ngIf="contactUsForm.get('email')?.hasError('required') && contactUsForm.get('email')?.touched">
                                Το πεδίο ειναι υποχρεωτικό
                            </span>
                            <span class="error-message"
                                *ngIf="contactUsForm.get('email')?.hasError('pattern') && contactUsForm.get('email')?.touched">
                                Το email δεν έχει σωστή μορφή
                            </span>
                        </div>
                    </div>

                    <div fxLayout="column" style="width:100%">
                        <span class="cosmote-blue"
                            style="margin-bottom:6px; font-size:14px; font-weight:700; line-height:20px">Μήνυμα*</span>
                        <textarea
                            style="border-radius: 8px;    border: 1px solid var(--gray-300, #D0D5DD);background: var(--white, #FFF);
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);font-size:16px; color:var(--gray-500);line-height: 24px;"
                            formControlName="message" cols="30" rows="4"
                            [ngClass]=" {'input-error': contactUsForm.get('message')?.invalid && contactUsForm.get('message')?.touched}">
                        </textarea>
                        <div style="height:16px; padding:0px 12px 8px">
                            <span class="error-message"
                                *ngIf="contactUsForm.get('message')?.hasError('required') && contactUsForm.get('message')?.touched">
                                Το πεδίο ειναι υποχρεωτικό
                            </span>
                        </div>

                    </div>
                </div>
                <div *ngIf="success" fxLayout="row" fxLayoutAlign="center center"
                    style="width:100%; margin-bottom:24px">
                    <div fxLayout="row" fxLayoutAlign="start center"
                        style="border-radius: 50px;border: 1px solid #60AD5B;background: #EBFEEA; padding:16px">
                        <img src="/assets/success-check-icon.png" style="width:20px; height:20px; margin-right:8px"
                            alt="">
                        <span
                            style="color: #0D9403;font-size: 14px;font-style: normal;font-weight: 400;line-height: 20px;">Ευχαριστούμε
                            για το μήνυμά σας. Θα επικοινωνήσουμε σύντομα μαζί σας.</span>
                    </div>
                </div>
                <button mat-raised-button color="primary" (click)="onSendFormCompletedEvent();onSendMessage()"
                    style="width:100%;" [disabled]="contactUsForm.invalid || !acceptedCheckBox">
                    Αποστολή μηνύματος</button>
            </form>

        </div>
    </div>
</div>

<div fxLayout="column" fxLayoutAlign="start center" style="gap: 64px;">
    <div style="padding:0px 32px; width:90%" fxLayout="column" fxLayoutAlign="start center">
        <div style="padding:0px; width:100%;gap: 32px;" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap.lt-md="48px">

            <div fxLayout="column" fxLayoutAlign="center center" style="gap: 24px;"
                style="width:286px;text-align: center; border-radius: 8px;border: 1px solid #004b87;padding:24px">
                <img src="/assets/email-chat-icon.png" style="width:48px; height:48px" alt="">
                <div fxLayout="column" fxLayoutAlign="start center" style="gap: 8px;">
                    <span class="cosmote-blue"
                        style="text-align: center;font-size: 20px;font-style: normal;font-weight: 700;line-height: 30px;">
                        Email</span>
                    <span class="cosmote-blue"
                        style="text-align: center;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;">
                        Η ομάδα μας είναι εδώ για να σας βοηθήσει</span>
                </div>
                <a class="cosmote-blue" [href]="'mailto:' + email"
                    style="font-size: 16px;font-style: normal;font-weight: 600;line-height: 24px;" target="_top">
                    {{email}}</a>
            </div>


            <div fxLayout="column" fxLayoutAlign="center center" style="gap: 24px;"
                style="width:286px;text-align: center; border-radius: 8px;background: white; padding:24px; border: 1px solid #004b87">
                <img src="/assets/phone-icon.png" style="width:48px; height:48px" alt="">
                <div fxLayout="column" fxLayoutAlign="start center" style="gap: 8px;">
                    <span class="cosmote-blue"
                        style="text-align: center;font-size: 20px;font-style: normal;font-weight: 700;line-height: 30px;">
                        Τηλέφωνο</span>
                    <div fxLayout="column">
                        <span class="cosmote-blue"
                            style="text-align: center;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;">
                            Ωράριο λειτουργίας
                        </span>
                        <span class="cosmote-blue"
                            style="text-align: center;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;">
                            Δευ. - Παρ. 10πμ έως 3μμ.
                        </span>
                    </div>
                </div>
                <a class="cosmote-blue" [href]="'tel:' + phone"
                    style="font-size: 16px;font-style: normal;font-weight: 600;line-height: 24px;" target="_top">
                    {{phone}}</a>
            </div>

        </div>
    </div>
</div>