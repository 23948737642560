import { Component } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
    selector: 'app-cookies-settings',
    templateUrl: './grow-your-business-cookie-settings-dialog.component.html',
})
export class GrowYourBusinessCookiesSettingsDialogComponent {

    public cookiesFormGroup: FormGroup = new FormGroup({
        ess: new FormControl({ value: true, disabled: true }),
        anl: new FormControl(false),
        mrk: new FormControl(false),
    });

}