<mat-card *ngIf="!actionCompleted; else userEnrolledSuccesfully" style="height:100%;padding:24px; border-radius: 12px; background: var(--base-white, #FFF);
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08); width:400px" 
    [ngStyle.xs] = "{'width': '250px', 'padding':'24px 12px'}" 
    [formGroup]="userEnrollMentFormGroup"  fxLayout="column">

    <span class="cosmote-blue" style="font-size: 18px;font-style: normal;font-weight: 600;line-height: 28px;
    padding-bottom:20px">Προσθήκη Μαθημάτων</span>
        
    <div style="margin-bottom:32px">
        <span  style="font-size: 14px;font-style: normal;font-weight: 700;line-height: 20px;"
        [ngClass]="userEnrollMentFormGroup.get('courses')?.invalid && 
                            userEnrollMentFormGroup.get('courses')?.touched ? 'error-message': 'gray-700'"
        >Επιλέξτε τα επιπλέον μαθήματα που θέλετε να δηλώσετε συμμετοχή.</span>
        <div style="height:12px"></div>
            <app-choose-courses *ngIf="courses"  [courses]="courses"  [numberOfDisplayedCourses]="3"
                (selectedCoursesChange)=" setCoursesToFormArray(userEnrollMentFormGroup.get('courses'), $event)">
            </app-choose-courses>


    </div>

    <div fxFlex></div>

    <div fxLayout="row" fxLayoutAlign="start center" >
        <div fxFlex style="padding: 18px 10px;" fxLayout="row" fxLayoutAlign="center">
            <span class="gray-600" style="cursor:pointer;font-size: 16px;font-style: normal;font-weight: 600;line-height: 24px;" [mat-dialog-close]="null">Άκυρο</span>
        </div>

        <button fxFlex mat-raised-button color="success" style="border-radius: 40px;" (click) = "onEnrollMeOnCourses()"
        [disabled]="userEnrollMentFormGroup.invalid">Προσθήκη</button>
    </div>

</mat-card>

<ng-template #userEnrolledSuccesfully>
    <mat-card  style="height:100%; padding:24px; width:400px" [ngStyle.xs] = "{'width': '250px', 'padding':'24px 0px'}" fxLayout="column">
        <div fxFlex style="gap: 16px;margin-bottom: 32px;"  fxLayout="column"  fxLayoutAlign="center center" >
            <img src="/assets/success-check-icon.png" style="width:48px;height:48px;">
            <div fxLayout="column">
                <span class="cosmote-blue" style="text-align: center;font-size: 18px;font-style: normal;font-weight: 700;line-height: 28px;">Tα μαθήματα προστέθηκαν επιτυχώς</span>
                <!-- <span class="gray-600" style="text-align: center;font-family: Arial;font-size: 14px;font-style: normal;font-weight: 400;line-height: 20px;"> 
                    Θα μπορείτε να παρακολουθήσετε και να εχετε πρόσβαση στο υλικό των μαθημάτων </span> -->
            </div>
        </div>
        <div style="border-radius: 50px;border: 1px solid var(--gray-300, #D0D5DD);background: var(--base-white, #FFF);box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
         padding: 10px 18px; text-align:center; cursor:pointer" (click)="onCloseDialog()">
        <span style="color: var(--gray-700, #344054);font-size: 16px;font-style: normal;font-weight: 700;line-height: 24px;"> Κλείσιμο</span>
        </div>
    </mat-card>
</ng-template>