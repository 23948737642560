import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { take } from "rxjs";
import { ContactUsComponent } from "src/app/common/components/contact-us.component";
import { AppService } from "src/app/common/services/app.service";
import { AuthService } from "src/app/common/services/auth.service";
import { SpinnerService } from "src/app/common/spinner/spinner.service";
import { GrowYourBusinessAnalyticsService } from "../grow-your-business-analytics.service";
import { LogsService } from "src/app/common/services/logs.service";

@Component({
    selector: "app-contact-us",
    templateUrl: "./grow-your-business-contact-us.component.html"
})
export class GrowYourBusinessContactUsComponent extends ContactUsComponent implements OnInit {
    constructor(
        spinnerService: SpinnerService,
		appService: AppService,
        route: ActivatedRoute,
		authService: AuthService,
        logsService: LogsService,
        private growYourBusinessAnalyticsService:  GrowYourBusinessAnalyticsService
        ){
            super(spinnerService, appService, route, authService, logsService);
        }

    override ngOnInit(): void {
        this.email = "growyourbusiness@equality.org.gr"
        this.phone = "+302102209167 "
        /// κανουμε disabled και κανουμε checked το checkbox αφου δεν χρησιμοποιόύνται εδώ
        this.acceptedCheckBox = true
        this.contactUsForm.get('phone')?.disable();

        (this.contactUsForm as FormGroup).valueChanges.pipe(take(1)).subscribe( (value) => {
            console.log('sending form start event')
            this.growYourBusinessAnalyticsService.sendEvent('form_start', {})
        });
        super.ngOnInit();
    }

    onSendFormCompletedEvent(): void {
        console.log('sending form completed event')
        this.growYourBusinessAnalyticsService.sendEvent('form_completed', {})
    }
}