import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { Course } from "src/app/common/shared/models/course.model";

@Component({
    selector: 'app-choose-courses',
    templateUrl: './grow-your-business-choose-courses.component.html'
})
export class GrowYourBusinessChooseCoursesComponent  implements OnInit, OnChanges{
    @Input() courses: Course[] = [];
    @Input() numberOfDisplayedCourses: number = 5;
    @Input() display: | "single" | "double" ="single"
    @Input() expandable: boolean = false;
    @Output() selectedCoursesChange = new EventEmitter<string[]>();

    private subscription: any;

    
    public chooseCoursesformGroup: FormGroup = new FormGroup({
        courses: new FormGroup({})
    });

    constructor() {}

    // ta input ginontai initialize meta ton constructor kai prin thn ngOnInit
    ngOnInit(): void {
        this.courses = this.courses.filter(c => !c.completed)
        this.courses.forEach(course => {
            (this.chooseCoursesformGroup.controls['courses'] as FormGroup).addControl(course.courseId, new FormControl(false));
        })
        
        this.subscription = this.chooseCoursesformGroup.get('courses')?.valueChanges.subscribe(() => {
            console.log('value changed');
            this.onCourseChange();
        });
    } 
    
    ngOnChanges(changes: SimpleChanges){
        if (changes['courses'] && !changes['courses'].firstChange) {
            console.log(changes['courses']);
            let removedCourses = this.findDeletedCourses(changes['courses'] as SimpleChange)
            console.log(removedCourses)
            for (let removedCouse of removedCourses) {
                (this.chooseCoursesformGroup.get('courses') as FormGroup).removeControl(removedCouse.courseId);
                console.log(this.chooseCoursesformGroup.get('courses'))
            }
        }
      }
    
    
    calculateHeightForCoursesContainer() {
        let coursesToDisplay = this.expandable ? this.courses.length: this.numberOfDisplayedCourses;
        return `${(52 * coursesToDisplay) + (12 * (coursesToDisplay)-1) + 26}px`;
    }

    private onCourseChange() {
        const courseIds = this.transformCoursesCheckboxesToIds(this.chooseCoursesformGroup.get('courses') as FormGroup, this.courses);
        this.selectedCoursesChange.emit(courseIds);
    }


    private transformCoursesCheckboxesToIds(coursesFormGroup: FormGroup, courses: Course[]) {
        const courseIds: any = [];
        for (let controName of Object.keys(coursesFormGroup.controls)) {
            if(coursesFormGroup.controls[controName].value) {
                courseIds.push(controName);
            }
        }
        return courseIds
    }

    private findDeletedCourses(coursesChange: SimpleChange) {
        let previousValue = coursesChange.previousValue;
        // αν επιλεξουμε ολα τα μαθήματα το currentValue γίνεται undefined
        let currentValue = coursesChange.currentValue || [];
        // εδώ βρίσκουμε τα στοιχεία που δεν είναι κοινά στις δύο λίστες
        let result = previousValue.filter( (o1: any) => !currentValue.some( (o2:any) => o1.courseId === o2.courseId));
        return result
    }

    getCoursesFormGroup() {
        return this.chooseCoursesformGroup.get('courses') as FormGroup;
    }

}