import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data } from '@angular/router';
import { finalize } from 'rxjs';
import { SpinnerService } from '../spinner/spinner.service';
import { AppService } from 'src/app/common/services/app.service';
import { AuthService } from '../services/auth.service';
import { LogsService } from '../services/logs.service';

@Component({
  selector: 'app-contact-us',
  template: '',
})
export class ContactUsComponent implements OnInit {

	constructor( 
		private spinnerService: SpinnerService,
		private appService: AppService,
        private route: ActivatedRoute,
		private authService: AuthService,
		private logsService: LogsService
	) {}

	public email = "info@ecommerce-academy.gr"
	public phone = "+30 210 220 9167"
	public success = false;

	public user: any;

	public contactUsForm = new FormGroup({
		name: new FormControl('',[Validators.required]),
		lastName: new FormControl('',[Validators.required]),
		email: new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
		phone: new FormControl('',[Validators.required,  Validators.pattern("[0-9 ]{10}")]),
		message: new FormControl('',[Validators.required]),
	})
    public acceptedCheckBox = false

	ngOnInit() {
		this.route?.parent?.data
			.subscribe((data: Data) => { 
				console.log(data)
				if(data['user']) {
					this.user = data['user']
					this.contactUsForm.get('name')?.setValue(data['user'].personalDetails?.name)
					this.contactUsForm.get('lastName')?.setValue(data['user'].personalDetails?.lastName)
					this.contactUsForm.get('email')?.setValue(data['user'].email)
					this.contactUsForm.get('phone')?.setValue(data['user'].contactDetails?.mobile)	
					this.acceptedCheckBox = true
				} else {
					this.user = null
				}
							 
			})
	}

	onSendMessage(){
		const sendData = this.contactUsForm.value
		console.log(sendData)
		this.spinnerService.show('contactUs')

		this.appService.contactUs(sendData)
		.pipe(
			finalize(() =>  {
				this.spinnerService.hide('contactUs')
				// αν ειναι loggarismenos χρήστης
				if(this.user) {
					this.logsService.trackEvent('Contact Us Submitted', sendData)
				}
			})  
		)	
		.subscribe({
			next:((data)=>  {
				console.log(data)
				this.success = true
			}),
			error: (err) => {},
		})
	}

	 //πέρα απο το setValue  πρεπει να κανουμε και το setSelectionRange για να μην χανεται η θεση του cursor
	 convertToLowercase(control: AbstractControl<string | null, string | null> | null | undefined, event: any) {
        const input = event.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;

        (control as FormControl).setValue((control as FormControl).value.toLowerCase());

        setTimeout(() => {
            input.setSelectionRange(start, end);
        }, 1);
    }

	onSendEmail(){}

	onCallNumber(){}

}
