<app-spinner name="verify"></app-spinner>
<div fxLayout="column" style="gap: 32px;padding: 96px 32px 48px" fxLayoutAlign="center center"  class="ellipse">

    <div fxLayout="column" fxLayoutAlign="center center" style="width:350px;" [ngStyle.xs]= " {'width':'280px'}">
        <div fxLayout="column" fxLayoutAlign="center center"  style="gap: 24px;margin-bottom: 32px">
            <img src="/assets/verify-icon.png" style="width:109px; height:83px">
            <div  fxLayout="column" fxLayoutAlign="start center"  style="gap: 12px;" >
                <span class="cosmote-blue" style="font-size: 24px; font-weight:700; line-height: 32px;">Επιβεβαίωση εγγραφής</span>
                <span style="text-align:center" class="cosmote-blue">Παρακαλούμε όπως συμπληρώσετε τον 6ψήφιο κωδικό που λάβατε με email (αναζητήστε το στον φάκελο «Ανεπιθύμητα» εάν δεν μπορείτε να το βρείτε στα εισερχόμενα).</span>
            </div>
        </div>

        <div fxLayout="column" style="width:100%">
            <input [formControl]="verificationCode"  placeholder="6ψηφιος κωδικός" type="text" 
                [ngClass]=" {'input-error': verificationCode.invalid && verificationCode.touched}"
                class="input" matInput> 
            <div style="height:16px; padding:0px 12px 8px">
                <span class="error-message" *ngIf="verificationCode.hasError('invalid') && verificationCode.touched">
                    O κωδικός δεν είναι σωστός
                </span>
            </div>
        </div>


        <div fxLayout="column" fxLayoutAlign="center center"   style="gap: 12px;width:100%">
            <div *ngIf="sendedCodeSuccessMessage" style="background-color: #ECFDF3; padding:24px; border-radius: 16px; width:100%; box-sizing: border-box;"> 
                <span style="color: #027A48">{{sendedCodeSuccessMessage}}</span>
            </div>
            <button mat-raised-button color="primary" [disabled]="verificationCode.invalid" (click)="onVerifyUser()"
            style="width:100%"> Επιβεβαίωση</button>
            <div fxLayout="row" style="gap: 4px;" fxLayout.xs="column" fxLayoutAlign="start start"  fxLayoutAlign.xs = "center center">
                <!-- <span class="mat-small gray-500" >Δεν λάβατε email;</span> -->
                <span style="cursor:pointer; color:#3D6D99; font-size:14px"  (click)="$event.preventDefault(); onResendCode()" >Eπαναποστολή κωδικoύ</span>
            </div>
        </div>
    </div>
</div>
