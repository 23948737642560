<div fxLayout="column" [formGroup]="cookiesFormGroup">
	<div style="padding:24px 24px 24px 24px" fxLayout="row" fxLayoutAlign="space-between center">
		<span style="font-size:24px">Advanced Cookie Settings </span>
		<mat-icon [mat-dialog-close]="null" style="cursor:pointer">close</mat-icon>
	</div>

	<div style="height:350px; overflow-y: scroll;">

		<div fxLayout="column" style="gap: 12px;border-bottom: 1px solid rgba(0,0,0,.1);padding:0px 24px 24px 24px" >
			<div >
				<span style="font-size:16px; line-height:1.5; letter-spacing:normal">Essential Cookies</span>
				<div fxFlex></div>
				<mat-slide-toggle formControlName="ess"></mat-slide-toggle>
			</div>
			<div style="font-size:12px; line-height:1.33; letter-spacing:normal">
				These cookies enable core functionality such as security, verification of identity and network management. These cookies can’t be disabled.
			</div>
		</div>

		<div fxLayout="column" style="gap: 12px;border-bottom: 1px solid rgba(0,0,0,.1);padding:24px" >
			<div>
				<span style="font-size:16px; line-height:1.5; letter-spacing:normal">Enable Marketing Cookies</span>
				<div fxFlex></div>
				<mat-slide-toggle formControlName="mrk"></mat-slide-toggle>
			</div>
			<div style="font-size:12px; line-height:1.33; letter-spacing:normal">
				These cookies are used to track advertising effectiveness to provide a more relevant service and deliver better ads to suit your interests.
			</div>
		</div>

		<div fxLayout="column" style="gap: 12px;border-bottom: 1px solid rgba(0,0,0,.1);padding:24px" >
			<div>
				<span style="font-size:16px; line-height:1.5; letter-spacing:normal">Enable Analytics Cookies</span>
				<div fxFlex></div>
				<mat-slide-toggle formControlName="anl"></mat-slide-toggle>
			</div>
			<div style="font-size:12px; line-height:1.33; letter-spacing:normal">
				These cookies help us to understand how visitors interact with our website, discover errors and provide a better overall analytics.

			</div>
		</div>

	</div>

	<div fxLayout="row" fxLayoutAlign="end center" style="height:60px">
		<div style="padding:24px">
			<button mat-raised-button style="background-color: #000000; font-size:14px; color:white" [mat-dialog-close]="cookiesFormGroup.getRawValue()">Save</button>
		</div>
	</div>
	

</div>
