import { Component } from "@angular/core";
import { ForgotPasswordComponent } from "src/app/common/components/forgot-password.component";
import { AuthService } from "src/app/common/services/auth.service";
import { SpinnerService } from "src/app/common/spinner/spinner.service";

@Component({
    templateUrl: './grow-your-business-forgot-password.component.html',
})
export class GrowYourBusinessForgotPasswordComponent extends ForgotPasswordComponent {
    constructor(
		authService: AuthService,
		spinnerService: SpinnerService
	) {
        super(authService, spinnerService);
    }
}