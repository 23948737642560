import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HeaderComponent } from "src/app/common/components/header.component";
import { AuthService } from "src/app/common/services/auth.service";
import { CoursesService } from "src/app/common/services/courses.service";
import { UserService } from "src/app/common/services/user.service";

@Component({
    selector: 'app-header',
    templateUrl: './grow-your-business-header.component.html',
    styleUrls: ['./grow-your-business-header.component.css']
})
export class GrowYourBusinessHeaderComponent{
	constructor(
        public auth: AuthService,
        private route: ActivatedRoute,
        private userService: UserService,
        private coursesService: CoursesService) {	

    }

    public routeLinks: any
    public messageCount!:number;

    public unAuthLinks = [
        {
            label: 'Μαθήματα',
            link: ''
        }, 
        {
            label: 'Συχνές Eρωτήσεις',
            link: 'growyourbusiness-faq'
        },
        {
            label: 'Επικοινωνία',
            link: 'growyourbusiness-contact'
        }
    ];

    public authLinks = [
        {
            label: 'Μαθήματα',
            link: ''
        }, 
        {
            label: 'Συχνές Eρωτήσεις',
            link: 'growyourbusiness-faq'
        },
        {
            label: 'Επικοινωνία',
            link: 'growyourbusiness-contact'
        },
        {
            label: 'Τα μαθήματα μου',
            link: 'my-courses'
        }
    ];

    public extraMenus: any[]= [];


    public isLoggedInUser!: any;


	ngOnInit() {

        this.route.data.subscribe((data) => {
            // console.log(data)
            this.messageCount = data['messageCount']
            // console.log(this.messageCount)
        })

		this.auth.authenticationSubject.subscribe((isLoggedInUser) => {
            this.isLoggedInUser = isLoggedInUser
            if ( isLoggedInUser) {
                this.routeLinks = [ ...this.authLinks]
                setTimeout(() => {
                    this.userService.getUserDetails().subscribe((user) => {
                        // console.log(user)
                        if (!user.employerId) {
                            this.routeLinks.push({
                                label: 'Διαχείριση συμμετοχών',
                                link: 'manage'
                            })
                        }
                    })}, 100);
            } else {
                this.routeLinks = [...this.unAuthLinks]
            }
        })
	}

    onLogout(): void {
        this.auth.signOut()
        this.userService.resetUser()
        this.coursesService.resetCourses()
    }
}