import { Injectable } from '@angular/core';
import { from, of, switchMap } from 'rxjs';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class UserResolverService {

    constructor(
        private userService: UserService,
        private auth: AuthService
    ) {}

    resolve() {
        return from(this.auth.isAuthenticated()).pipe( switchMap( (result:boolean ) => {
            return result ? this.userService.getUserDetails() : of(null);
        })) 
    }
}
