import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, finalize, switchMap, throwError } from 'rxjs';
import { FileUpload } from '../shared/models/file.model';
import { MessagesService } from '../services/messages-service';
import { FileService } from '../services/file.service';


@Component({
  selector: 'app-new-message-dialog',
  template: '',
})
export class NewMessageDialogComponent {
  
    constructor(
        private messagesService: MessagesService,
        private dialogRef: MatDialogRef < NewMessageDialogComponent >,
        private fileService: FileService,
    ) { }

    public messageForm = new FormGroup({
        title: new FormControl('', [Validators.required]),
        message: new FormControl('',[this.messageRequired.bind(this)])
    })

    public uploadingFile: FileUpload | null = null
    public errorMessage:any;


    OnSendMessage() {

        let conversationMessages: any[] = [];

        // Το μήνυμα μπορεί να ειναι κενό αν ανεβάσαμε μόνο αρχείο
        if(this.messageForm?.get('message')?.value != '') {
            conversationMessages.push({
                "type": "text",
                "text": this.messageForm.value.message
            })
        }
        // αν  ανεβασαμε αρχείο το προσθέτουμε σαν μήνυμα
        if(this.uploadingFile) {
            conversationMessages.push({
                "type": "file",
                "url": this.uploadingFile.url,
                "name": this.uploadingFile.name,
                "size": this.uploadingFile.size,
            })
        }

        this.messagesService.startConversation({
            title: this.messageForm.value.title as string,
            messages: conversationMessages
        }).subscribe({
            next: (conversation) => {
                this.dialogRef.close(conversation);
            }
        });
    }

    OnFileSelected(event: any) {
        if(event.target.files.length <= 0) {
            return;
        }

        this.uploadingFile =  {
            name: event.target.files[0].name,
            status: 'uploading',
            size: event.target.files[0].size,
        } as FileUpload;
        console.log(event.target.files)
        let fileData: any;
        let imageUrl: string | null;
        this.fileService.readImage(event.target.files[0],{})
            .pipe(
                switchMap(resultFile => {
                    console.log(resultFile)
                    fileData = resultFile;
                    return this.fileService.requestPresignedUrl({Mime: resultFile.image.type, type: 'messageAttachment'})
                    .pipe( catchError( (err) => {
                        console.log(err)
                        this.errorMessage = 'Βεβαιωθείτε ότι ανεβάζετε καποιο αρχείο εικόνας ή καποιο pdf';
                        this.uploadingFile = null;
                        return throwError(err)
                    }))
                }),
                switchMap(presigedUrlResponse => {
                    console.log(presigedUrlResponse)
                    imageUrl = presigedUrlResponse.url
                    return this.fileService.uploadFileToPresignedUrl(presigedUrlResponse.url, fileData.requestedFormat);
                })
            )
            .pipe(
                finalize(() => {
                    event.target.value = '';
                })
            )
            .subscribe({
                next: () => {
                    if(this.uploadingFile) {
                        this.uploadingFile.status = 'uploaded';
                        this.uploadingFile.url = imageUrl?.split('?')[0];    
                    }
                    this.messageForm.get('message')?.updateValueAndValidity();
                },
                error: (err) => {
                    this.uploadingFile = null;
                    console.log(err)
                }
            })
    
    }

    onFormatBytes(bytes: number, decimals = 2) {
        return this.fileService.formatBytes(bytes, decimals)
    }

    // αν δεν υπαρχει uploaded file τότε το μήνυμα ειναι υποχρεωρικό
    messageRequired(control: AbstractControl): ValidationErrors | null {
        if(this.uploadingFile ) {
            return null
        }

        if(control.value == '') {
            return {required: true}
        }

        return null;
    }

    onDeleteUploadingFile( ) {
        this.uploadingFile = null;
        this.messageForm.get('message')?.updateValueAndValidity();
    }
   
}
